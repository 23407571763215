import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withHint } from '../../hoc';
import { withTranslation } from '../../hoc/withTranslation';
import date from '../../utils/date';
import { object } from '../../utils';
import Image from '../../components/Image';
import { Button } from '../../building-blocks';
import { withRouter } from 'react-router-dom';
import ReactLinkWithRoundIcon from './ReactLinkWithRoundIcon';
import withHandleNotFound from '../Infofiche/withHandleNotFound';
import Helmet from 'react-helmet';

const InternshipApplication = ({
  t,
  rendering = {},
  location = {},
  sitecoreContext = {}
}) => {
  const { fields = {} } = rendering;
  const {
    FirstName = {},
    LastName = {},
    ProfilePicture = {},
    StartDate = {},
    Motivation = {},
    City = {},
    Province = [],
    TrainingName = {},
    DurationInDays = {},
    Specialties = {}
  } = fields;

  const { value: firstName } = FirstName;
  const { value: lastName = '' } = LastName;
  const { value: trainingName = '' } = TrainingName;
  const { value: durationInDays = 0 } = DurationInDays;
  const { value: startDate } = StartDate;
  const { value: city } = City;
  const { value: motivation } = Motivation;
  const { value: specialties } = Specialties;

  const provinces = Province.map(x =>
    object.getNested(x, 'fields', 'Title', 'value')
  ).filter(x => x);
  const provincesStr = provinces.reduce(
    (acc, cur, idx, arr) =>
      acc +
      (idx > 0
        ? idx < arr.length - 1
          ? ', '
          : ` ${t('internships.application.and')} `
        : '') +
      cur,
    ''
  );

  const src = object.getNested(ProfilePicture, 'value', 'src');
  const name = `${firstName} ${lastName}`;

  const durationInMonths = Math.round(durationInDays / 30);
  const formattedStartDate = date.formatDate(startDate, 'D MMMM YYYY');

  const { state: { index = -1, list = [] } = {} } = location;

  const prev = index > 0 && list[index - 1];
  const next = index < list.length - 1 && list[index + 1];

  const title = object.getNested(sitecoreContext, 'navigationData', 'TitleName');

  return (
    <>
      <Helmet>
        <title>{name} | {title}</title>
      </Helmet>
      <div className='o-interns-application__container'>
        <div className='o-interns-application_nav'>
          {prev && (
            <ReactLinkWithRoundIcon
              className='a-nav-link__prev'
              text={t('internships.application.previous')}
              iconBefore='arrow-left'
              to={{
                pathname: prev,
                state: { index: index - 1, list }
              }}
            />
          )}
          {next && (
            <ReactLinkWithRoundIcon
              className='a-nav-link__next'
              text={t('internships.application.next')}
              iconAfter='arrow-right'
              to={{
                pathname: next,
                state: { index: index + 1, list }
              }}
            />
          )}
        </div>
        <div className='o-interns-application__header'>
          <h1>{name}</h1>
          <div className='o-interns-application__summary a-text--large a-text--dark'>
            <div className='o-interns-application__training'>
              {trainingName}
            </div>
            <div className='o-interns-application__spacer'>&middot;</div>
            <div className='o-interns-application__duration'>
              {t(
                `internships.application.summary${
                  durationInMonths > 1 ? 'Plural' : 'Singular'
                }`,
                { durationInMonths, formattedStartDate }
              )}
            </div>
          </div>
          <p className='a-text--light'>
            {city}
            {city && ', '}
            {t('internships.application.province', { province: provincesStr })}
          </p>
        </div>
        <div className='o-interns-application__info-wrapper'>
          <div className='o-interns-application__action'>
            {src && (
              <div className='o-interns-application__image a-image'>
                <Image
                  classes='a-image__visual o-intern-application__image-visual'
                  ImageUrl={src}
                  EstimatedSize='3'
                  Title={name}
                  NotLazy={true}
                />
              </div>
            )}
            <Button
              fields={{
                ButtonText: t('internships.application.contactLabel'),
                ButtonIconBefore: 'send',
                ButtonIconAfter: 'send'
              }}
              type='button'
            />
          </div>

          <div className='o-interns-application__info'>
            {motivation && (
              <>
                <h4>{t('internships.application.motivation')}</h4>
                <div dangerouslySetInnerHTML={{ __html: motivation }}></div>
              </>
            )}
            {specialties && (
              <>
                <h4>{t('internships.application.specialities')}</h4>
                <div dangerouslySetInnerHTML={{ __html: specialties }}></div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const experienceEditorHint = 'Internship Application - this message only appears in Experience Editor';

export default withTranslation()(
  withHint(experienceEditorHint)(
    withSitecoreContext()(
      withRouter(withHandleNotFound()(InternshipApplication))
    )
  )
);
