import parseFilter from './parseFilter.ts'
import parseSearch from './parseSearch'

/**
 * Parse
 * =============================================================================
 */
 const parse = (search, filter, t, regionsMap) => {
  const parsed = [...parseSearch(search, t, regionsMap), ...parseFilter(filter, t)];
  return parsed;
};


/**
 * Exports
 * =============================================================================
 */
 export default parse;
