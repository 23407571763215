import React, { useContext } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import { Datepicker } from '../../../building-blocks';
import FieldLabel from '../Helpers/FieldLabel';
import moment from 'moment';
import { InputDateFormat } from '../../../lib/Constants';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import { checkConditions } from '../conditionalRendering';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const DateField = (props) => {
  const { field, value, onChange, tracker, errors, isValid, t } = props;

  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  var shouldHide = false;

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
  }

  const updateContextFieldValue = (fieldValue) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, fieldValue);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, fieldValue);
    }
  }

  return (
    <div className={`a-formfield ${!isValid ? 'is-error' : ''}  ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      <div className='a-formfield__wrapper'>
        <Datepicker
          id={field.valueField.id}
          name={field.valueField.name}
          value={formatValue(value)}
          minDate={formatValue(field.model.min)}
          maxDate={formatValue(field.model.max)}
          onChange={(name, value) => handleOnChange(field, value, onChange, t)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
          onClick={() => updateContextFieldValue(value)}
        />
      </div>
      <FieldValidationErrors {...props} />
    </div>
  );
};

/**
 * @param {string} [value]
 */
function formatValue(value) {
  // dates are serialized from the API as full date/times (i.e. 2019-01-11T00:00:00)
  // but the date input expects only the date. Since the format is predictably long,
  // we can just take a substring.

  if (!value || value.length < 10) {
    return value;
  }

  // value is either DD/MM/YYYY or YYYY-MM-DD
  const fmt = value.indexOf('/') > -1 ? 'DD/MM/YYYY' : 'YYYY-MM-DD';

  const formatted = moment(value.substring(0, 10), fmt).format(InputDateFormat);
  return formatted;
}

export default withTranslation()(DateField);
