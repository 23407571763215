/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive, Image } from '@sitecore-jss/sitecore-jss-react';
import { Link } from '../../building-blocks';

/**
 * Definition
 * =============================================================================
 */
const Author = props => {
    const {
        AuthorName,
        AuthorFunction,
        AuthorDescription,
        AuthorPicture,
        CtaText,
        CtaLink,
        LinkedInLink,
        WebsiteLink,
        FacebookLink,
        TwitterLink,
        InstagramLink
    } = props.fields;

    /**
     * Renders
     * ---------------------------------------------------------------------------
     */
    const renderAvatar = () => {
        return (
            <div class="a-avatar m-author__avatar a-avatar--large">
                <div class="a-image a-avatar__image">
                    <Image class="a-image__visual" field={AuthorPicture} alt={AuthorName.value} title={AuthorName.value} mediaUrlPrefix={/^$/}/>
                </div>
                <div class="a-avatar__content">
                    <p class="a-avatar__title"><Text field={AuthorName} /></p>

                    <p class="a-avatar__description"><Text field={AuthorFunction} /></p>
                </div>
            </div>
        );
    };

    const hasLinkInField = field => field && field.value && field.value.href;

    const renderSocial = () => {
        return (
            <ul class="m-author__socials">
                {hasLinkInField(FacebookLink) && (
                    <li class="m-author__social">
                        <a class="m-author__social-link" href={FacebookLink.value.href} aria-label="Facebook" target="_blank">
                            <span class="m-author__social-icon icon-facebook"></span>
                        </a>
                    </li>
                )}
                {hasLinkInField(LinkedInLink) && (
                    <li class="m-author__social">
                        <a class="m-author__social-link" href={LinkedInLink.value.href} aria-label="Linkedin" target="_blank">
                            <span class="m-author__social-icon icon-linkedin"></span>
                        </a>
                    </li>
                )}
                {hasLinkInField(TwitterLink) && (
                    <li class="m-author__social">
                        <a class="m-author__social-link" href={TwitterLink.value.href} aria-label="Twitter" target="_blank">
                            <span class="m-author__social-icon icon-twitter"></span>
                        </a>
                    </li>
                )}
                {hasLinkInField(WebsiteLink) && (
                    <li class="m-author__social">
                        <a class="m-author__social-link" href={WebsiteLink.value.href} aria-label="Website" target="_blank">
                            <span class="m-author__social-icon icon-user"></span>
                        </a>
                    </li>
                )}
                {hasLinkInField(InstagramLink) && (
                    <li class="m-author__social">
                        <a class="m-author__social-link" href={InstagramLink.value.href} aria-label="Instagram" target="_blank">
                            <span class="m-author__social-icon icon-instagram"></span>
                        </a>
                    </li>
                )}
            </ul>
        )
    };

    const renderCta = () => {
        return (
            <Link classes="a-link m-author__link a-link--decorated " fields={{
                LinkText: CtaText,
                LinkUrl: CtaLink
            }}>
            </Link>
        )
    }
    /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    const hasCtaText = CtaText && CtaText.value;
    const hasCtaLink = CtaLink && CtaLink.value && CtaLink.value.href;

    return (
      <article class='m-author'>
        <h3 class='m-author__title'>
          <Text field={AuthorName} />
        </h3>
        {renderAvatar()}
        <p class='m-author__description'>
          <Text field={AuthorDescription} />
        </p>
        {renderSocial()}
        {((hasCtaText && hasCtaLink) || isEditorActive()) &&
          renderCta()}
      </article>
    );
};

export default Author;
