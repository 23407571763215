/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import {
  Text,
  isEditorActive
} from '@sitecore-jss/sitecore-jss-react';
import { Button } from './../../building-blocks';
import { object, classes } from './../../utils';
import Background from '../Background';

/**
 * Definition
 * =============================================================================
 */
const Banner = props => {
  const {
    BannerImage,
    BannerImageMobile,
    BannerSubtitle,
    BannerTitle,
    BannerBackground,
    ButtonText,
    ButtonUrl,
    EnableBackgroundOverlay
  } = props.fields;

  const enableBgOverlay = object.getNested(EnableBackgroundOverlay, 'value');

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */

  const getModifiers = () => {
    let modifiers = '';
    const image = object.getNested(BannerImage, 'value', 'src');
    const imageClass = isEditorActive() && object.getNested(BannerImage, 'value', 'class');
    const hasImage = imageClass !== 'scEmptyImage' && image;
    if (!hasImage) {
      var background = object.getNested(
        BannerBackground,
        'fields',
        'Value',
        'value'
      );
      if (background) {
        modifiers += classes.compare(background, {
          'illustrative-grey': 'm-banner--illustrative-grey',
          white: ''
        });
      }
    } else {
      modifiers += 'm-banner--with-image';
    }

    return modifiers;
  };

  const backgroundImage = object.getNested(BannerImage, 'value', 'src');
  const backgroundImageMobile = object.getNested(BannerImageMobile, 'value', 'src');
  const getBackgroundImageClasses = () => {
    let bgClasses = '';
    if (enableBgOverlay) {
      bgClasses += ' m-banner__image';
    }
    return bgClasses;
  }
  

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const renderSubtitle = () => {
    return (
      <small className='m-banner__subtitle'>
        <Text field={BannerSubtitle} />
      </small>
    );
  };

  const renderTitle = () => {
    return (
      <h2 className='m-banner__title'>
        <Text field={BannerTitle} />
      </h2>
    );
  };

  const renderButton = () => {
    return (
      <div className='m-banner__action'>
        <Button {...props} />
      </div>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <section className={`m-banner ${getModifiers()}`}>
      {backgroundImage && backgroundImage != '' && (
        <Background classes={getBackgroundImageClasses()} backgroundImage={backgroundImage} backgroundImageMobile={backgroundImageMobile} />
      )}

      <div className='m-banner__container l-container'>
        <header className='m-banner__header'>
          {(isEditorActive() || BannerSubtitle) && renderSubtitle()}

          {(isEditorActive() || BannerTitle) && renderTitle()}
        </header>

        {(isEditorActive() ||
          (ButtonUrl &&
            object.getNested(ButtonUrl, 'value', 'href') &&
            ButtonText &&
            object.getNested(ButtonUrl, 'value'))) &&
          renderButton()}
      </div>
    </section>
  );
};

export default Banner;
