/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formfield from './../Formfield';
import { type } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
class Select extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || ''
    };
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChange = (e) => {
    const { onChange, name } = this.props;
    const { value } = this.state;

    this.setState({ value: e.target.value }, () => {
      if (onChange) onChange(name, value);
    });
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderPlaceholder() {
    const { placeholder } = this.props;

    return (
      <option value={placeholder} disabled>
        {placeholder}
      </option>
    );
  }


  renderOptions() {
    const { options, id } = this.props;
    const getOptionText = (option) => type.isReact(option) ? option : option.text;
    const getOptionValue = (option) => type.isReact(option) ? option : option.value;
  
    return options.map((option, index) => {
      return (
        <option
          key={`${id}-select-option-${index}`}
          value={getOptionValue(option)}
        >
          {getOptionText(option)}
        </option>
      );
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes,
      labelClasses,
      label,
      name,
      id,
      placeholder,
      options,
    } = this.props;

    const { value } = this.state;

    return (
      <Formfield
        classes={classes}
        labelClasses={labelClasses}
        label={label}
        name={id}
      >
        <div className='a-formfield__wrapper a-formfield__wrapper--with-icon-after'>
          <select
            className='a-formfield__select'
            id={id}
            name={name}
            defaultValue={value ? value : placeholder}
            onChange={this.handleChange}
          >
            {placeholder && this.renderPlaceholder()}

            {options && options.length > 0 && this.renderOptions()}
          </select>

          <span className='a-formfield__icon-after icon-arrow-down-small'></span>
        </div>
      </Formfield>
    );
  }
}

export default Select;
