import React, {useContext} from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import FieldLabel from '../Helpers/FieldLabel';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const Password = (props) => {
  const { field, value, onChange, tracker, errors, isValid, t } = props;

  var shouldHide = false;
  var shouldDisable = false;
  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const updateContextFieldValue = (e) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, e.target.value);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, e.target.value);
    }
    handleOnChange(field, e.target.value, onChange, t)
  }
  return (
    <div className={`a-formfield ${!isValid ? 'is-error' : ''}  ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      <div className='a-formfield__wrapper'>
        <input
          type='password'
          className={`a-formfield__input`}
          id={field.valueField.id}
          name={field.valueField.name}
          value={value}
          maxLength={field.model.maxLength}
          placeholder={field.model.placeholderText}
          disabled={shouldDisable}
          onChange={(e) => updateContextFieldValue(e)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
        />
      </div>
      <FieldValidationErrors {...props} />
    </div>
  );
};

export default withTranslation()(Password);
