/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Item = (props) => {
  const {
    children,
    extraClasses=''
  } = props;


  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`l-grid__col l-grid__col-12-12 l-grid__col--smaller-margins ${extraClasses}`}>
      {children}
    </div>
  )
};

export default Item;
