/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import TableWithActions from './with-actions';
import TableWithLinks from './with-links';

/**
 * Definition
 * =============================================================================
 */
const Table = (props) => {
  const { type, ...otherProps } = props;

  return (
    (type === 'with-actions' && (
      <TableWithActions  {...otherProps} />
    )) ||
    (type === 'with-links' && (
      <TableWithLinks {...otherProps} />
    )) || null
  );
};

export default Table;
