/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object, styles } from './../../utils';
import Background from '../Background';
/**
 * Definition
 * =============================================================================
 */
const Footer = ({
  props,
  classes = '',
  topSpacing,
  backgroundImage = null,
  backgroundType = null,
}) => {

  /**
	 * Helpers
	 * ---------------------------------------------------------------------------
   */
   const getMarginTopClasses = () => {
    const spacing = object.getNested(topSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return styles.getMarginTopUtil(spacing);
  };

  const getClasses = () => {
    const top = getMarginTopClasses();

    return `${top}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`l-section__footer ${classes} ${getClasses()}`}>
     <Background backgroundImage={backgroundImage} backgroundType={backgroundType} />
      <Placeholder name='section-footer' {...props} />
    </div>
  );
};

export default Footer;
