/**
 * Imports
 * =============================================================================
 */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Text, Image, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Cta from '../Cta';
import dateUtils from '../../utils/date';
import Author from '../Author';
import RelatedRecentArticles from '../RelatedRecentArticles';
import RelatedArticles from '../RelatedArticles';
import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '../../building-blocks';
import { object } from '../../utils';
import ArticleStructuredData from '../ArticleStructuredData';

const QUERY = gqlLoader('./query.graphql');
const SIDE_RELATED_ARTICLES = 3;
const BOTTOM_RELATED_ARTICLES = 3;
const TOTAL_RELATED_ARTICLES = SIDE_RELATED_ARTICLES + BOTTOM_RELATED_ARTICLES;
/**
 * Definition
 * =============================================================================
 */
const Article = (props) => {
  const {
    ArticleTitle,
    ArticleAuthor,
    ArticlePublishingDate,
    ArticleReadingTime,
    ArticlePromoCta,
  } = props.fields;
  const { value: articleDate } = ArticlePublishingDate;
  const { rendering, sitecoreContext } = props;

  const articleStructuredData = {
    articleTitle: object.getNested(ArticleTitle, 'value'),
    articleDate: object.getNested(ArticlePublishingDate, 'value'),
    authorName: object.getNested(ArticleAuthor, 'fields', 'AuthorName', 'value')
  };

  const [showShareButtons, setShowShareButtons] = useState(true);
  const [showShareOption, setShowShareOption] = useState(true);

  const { loading, data } = useQuery(QUERY, {
    variables: { id: rendering.dataSource, first: TOTAL_RELATED_ARTICLES },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useLayoutEffect(() => {
    const st = window.__sharethis__;
    if (!st) {
      setShowShareOption(false);
      return;
    }
    const stConf = st.config || {};
    st.load('inline-share-buttons', stConf['inline-share-buttons']);
    setShowShareButtons(false);
  }, [])

  const relatedArticles =
    data && data.articlepage && data.articlepage.relatedArticles.hits || [];

  const oddRelatedArticles = relatedArticles.filter((_, idx) => idx % 2 !== 0);
  const evenRelatedArticles = relatedArticles.filter((_, idx) => idx % 2 === 0);

  const theme =
    data && data.articlepage && data.articlepage.relatedArticles.theme;

  const formattedArticlePublishingDate = dateUtils.formatDateUsingSitecoreContextLanguage(sitecoreContext, articleDate, 'D MMMM YYYY');

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const renderAuthor = (author) => {
    return (
      author &&
      author.fields && (
        <div className='m-article-metadata__footer'>
          <div className='a-avatar m-article-metadata__avatar'>
            <div className='a-image a-avatar__image'>
              <Image
                className='a-image__visual'
                field={author.fields.AuthorPicture}
                alt={author.fields.AuthorName.value}
                title={author.fields.AuthorName.value}
                mediaUrlPrefix={/^$/}
              />
            </div>
            <div className='a-avatar__content'>
              <p className='a-avatar__title'>
                <Text field={author.fields.AuthorName} />
              </p>
              <p className='a-avatar__description'>
                <Text field={author.fields.AuthorFunction} />
              </p>
            </div>
          </div>
          {showShareOption && (
            <>
              <a
                className={`m-article-metadata__share ${showShareButtons ? 'm-article-metadata__share--expanded' : ''}`}
                href='#'
                aria-label='Deel dit artikel'
                onClick={() => setShowShareButtons(!showShareButtons)}
              >
                <span className='m-article-metadata__share-icon icon-share'></span>
              </a>
              <div className={`m-article-metadata__share-inline sharethis-inline-share-buttons ${!showShareButtons ? 'm-article-metadata__share-inline--hidden' : ''}`}></div>
            </>
          )}
        </div>
      )
    );
  };

  const renderArticle = () => (
    <div>
      <div className='l-container'>
        <div className='l-section u-padding-bottom-2x'>
          <h1 className='l-section__header u-margin-top-2x@below-viewport-11'>
            <Text field={ArticleTitle} />
          </h1>
          <div className='m-article-metadata '>
            <div className='m-article-metadata__timing-container'>
              <div className='m-article-metadata__timing'>
                <time className='m-article-metadata__timing-item'>
                  {formattedArticlePublishingDate}
                </time>
                <div className='a-attribute m-article-metadata__timing-item a-attribute--inherited'>
                  <span className='a-attribute__icon a-attribute__icon--before icon-stopwatch'></span>
                  <p className='a-attribute__text'>
                    <Text field={ArticleReadingTime} /> min
                  </p>
                </div>
              </div>
            </div>
            {renderAuthor(ArticleAuthor)}
          </div>
        </div>
      </div>
      <section className='l-section u-padding-top-none'>
        <Placeholder name='article-content' rendering={rendering} />
      </section>
      <ArticleStructuredData {...articleStructuredData} />
    </div>
  );

  return (
    <>
      <div class='l-grid l-grid--with-spacing o-article'>
        <div class='l-grid__col l-grid__col-8-12 o-article--main'>
          {renderArticle()}
        </div>
        <div class='l-grid__col l-grid__col-4-12 o-article--sidebar'>
          {ArticlePromoCta && ArticlePromoCta.fields && (
            <Cta fields={ArticlePromoCta.fields} />
          )}
          {ArticleAuthor && ArticleAuthor.fields && (
            <Author fields={ArticleAuthor.fields} />
          )}
          {loading && <Loader />}
          {!loading && (
            <RelatedRecentArticles
              items={evenRelatedArticles}
              theme={theme}
            />
          )}
        </div>
      </div>
      {loading && <Loader />}
      {!loading && <RelatedArticles items={oddRelatedArticles} />}
    </>
  );
};

export default withSitecoreContext()(Article);
