import React from 'react';
import InnerGroup from './Group';
import CheckboxWithHint from '../../building-blocks/CheckboxWithHint';
import { withCollapse } from '../../hoc';


const Item = ({
    handleToggleCollapse,
    setCollapseState,
    onChange,
    item,
    uncheckParent
}) => {
    const {text, id, itemId, isChecked, amount, children} = item;

    return (
    <li className={`m-toggle-group__item`}>
    <div className={`m-toggle-group_title`}>
      <CheckboxWithHint
        label={text + (amount ? ` (${amount})` : '')}
        name={id}
        id={id}
        itemId={itemId}
        onChange={onChange}
        isChecked={isChecked}
      />
      {children && children.length > 0 && (
        <div
          onClick={handleToggleCollapse}
          className={`m-toggle-group_collapse-toggle ${setCollapseState()}`}
        >
          <span className='m-toggle-group__arrow'></span>
        </div>
      )}
    </div>
    {children && children.length > 0 && (
      <div className={`m-toggle-group__inner ${setCollapseState()}`}>
        <InnerGroup
          classes='m-toggle-group--sub'
          items={children}
          onChange={onChange}
          uncheckParent={uncheckParent}
        />
      </div>
    )}
  </li>);
}

export default withCollapse()(Item);
