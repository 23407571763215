/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ children }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='m-link-list__item'>
      {children}
    </li>
  );
};

export default Item;
