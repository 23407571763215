/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { Button, Link } from './../../building-blocks';
import { object } from '../../utils';
import { Events } from '../../managers';
/**
 * Definition
 * =============================================================================
 */
const RegisterCta = (props) => {
  const {
    CtaTitle, 
    ButtonText,
    ButtonUrl,
    LinkText,
    LinkUrl
  } = props.fields;




  const renderButton = () => {
    return (
      <Button 
        classes={'a-button--secondary o-register-info__button'}
        {...props}
      />
    );
  };

  const renderLink = () => {
    
    return (
      <Link
        classes='a__link o-register-info__link'
        {...props}
      />
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  const { rendering } = props;

  return (
    <div className="l-grid l-grid--center">
      <div className="l-grid__col-6-12">
      <div className="o-register-info">
      <div className="o-register-info__background l-background l-background--illustrative-bottom l-background--illustrative-bottom-small@below-desktop-base"></div>
      <div className="o-register-info__container">
        <div className="o-register-info__content">
          <h4 className="o-register-info__title"> 
          <Text field={CtaTitle} />
          </h4>
          <Placeholder name='register-cta-content'  rendering={rendering} classes='o-register-info__list' />
          {((object.hasNested(LinkText,"value") && object.hasNested(LinkUrl,"value")) || isEditorActive()) && renderLink()}
        </div>
        {((ButtonText && ButtonUrl) || isEditorActive()) && renderButton()}
      </div>
      </div>
      </div>
    </div>
  );
};

export default RegisterCta;
