/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */
class Pagination extends React.Component {

  constructor(props) {
    super(props);

    const { page = 1 } = this.props;

    this.state = {
      page: page > 0 ? page : 1,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.total !== this.props.total) {
      this.setState({ page: 1 });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  getMax() {
    const {
      total,
      perPage,
    } = this.props;

    return Math.ceil(total/ perPage);
  }

  getEnd() {
    const { page } = this.state;
    const { perPage, total } = this.props;
    const max = this.getMax();

    return page !== max
      ? page * perPage
      : total;
  }

  getStart() {
    const { page } = this.state;
    const { perPage } = this.props;

    return page * perPage - (perPage - 1);
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handlePrev = (e) => {
    if (e) {
      e.preventDefault();
    }

    let { page } = this.state;
    const { changeCb } = this.props;

    page--;

    this.setState({ page }, () => {
      if (changeCb) {
        changeCb({
          page,
          start: this.getStart(),
          end: this.getEnd()
        });
      }
    });
  }

  handleNext = (e) => {
    if (e) {
      e.preventDefault();
    }

    let { page } = this.state;
    const { changeCb } = this.props;

    page++;

    this.setState({ page }, () => {
      if (changeCb) {
        changeCb({
          page,
          start: this.getStart(),
          end: this.getEnd()
        });
      }
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes = '',
      total,
      t,
    } = this.props;

    const { page } = this.state;
    const min = 1;
    const max = this.getMax();
    const start = this.getStart();
    const end = this.getEnd();

    return (
      <div className={`m-pagination ${classes}`}>
        <button
          className='m-pagination__button icon-arrow-left'
          onClick={this.handlePrev}
          disabled={page === min}
        />

        <p className='m-pagination__text'>
          {t(`pagination.results`, {
            start,
            end,
            total,
          })}
        </p>

        <button
          className='m-pagination__button icon-arrow-right'
          onClick={this.handleNext}
          disabled={page === max}
        />
      </div>
    );
  }
}

export default withTranslation()(Pagination);
