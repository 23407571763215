/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Disconnected from './Disconnected';
import Connected from './Connected';

/**
 * Definitions
 * =============================================================================
 */
const PageInfofiche = (props) =>
  props.rendering.dataSource !== 'available-in-connected-mode'
    ? <Connected {...props} />
    : <Disconnected {...props} />;

export default withSitecoreContext()(PageInfofiche);
