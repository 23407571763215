/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import BasketOverview from './../../pages/BasketOverview';

/**
 * Data
 * =============================================================================
 */
const data = [
  { text: "History of magic" },
  { text: "Transfiguration" },
  { text: "Transfiguration" },
];

/**
 * Definition
 * =============================================================================
 */
const Disconnected = (props) => {

  return (
    <BasketOverview
      {...props}
      data={data}
    />
  );
}

export default Disconnected;
