/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';

const getRecursiveIsChecked = (arr = []) => !!(arr.find(x => x.isChecked || getRecursiveIsChecked(x.children)));
/**
 * Definition
 * =============================================================================
 */
const Group = ({
  classes = '',
  items,
  onChange,
  uncheckParent
}) => {
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = () => {
    return items.map((i, index) => {
      const { text, id, children, isChecked } = i;
      if(typeof(text) === "undefined"){
        return null;
      }
    const recursiveIsChecked = isChecked || getRecursiveIsChecked(children);
    return (
        <React.Fragment key={`toggle-group-content-${id}-${index}`}>
          <Item onChange={onChange} item={i} isOpen={recursiveIsChecked} uncheckParent={uncheckParent}/>
        </React.Fragment>
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <div className={`m-toggle-group ${classes}`}>
      <ul className='m-toggle-group__list'>{renderItems()}</ul>
    </div>
  );
};

export default Group;
