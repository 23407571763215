/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object, styles } from './../../utils';
import Background from '../Background';
/**
 * Definition
 * =============================================================================
 */
const Root = ({
  isSection,
  children,
  classes = '',
  backgroundImage = null,
  backgroundType = null,
  topSpacing,
  bottomSpacing,
}) => {

  /**
	 * Helpers
	 * ---------------------------------------------------------------------------
   */
  const getPaddingTopClasses = () => {
    const spacing = object.getNested(topSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return styles.getPaddingTopUtil(spacing);
  };

  const getPaddingBottomClasses = () => {
    const spacing = object.getNested(bottomSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return styles.getPaddingBottomUtil(spacing);
  };

  const getClasses = () => {
    const top = getPaddingTopClasses();
    const bottom = getPaddingBottomClasses();

    return `${top} ${bottom}`;
  };
  isSection = true;
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (isEditorActive() || isSection) ? (
    <section className={`l-section ${classes} ${getClasses()}`}>
      <Background backgroundImage={backgroundImage} backgroundType={backgroundType} />
        {children}
    </section>
  ) : (
    <div className={`l-section ${classes}`}>
      {children}
    </div>
  );
}

export default Root;
