import IFilter from './IFilter';
import { extractActiveFilterItems } from './TransformData';
import { SearchPageSize } from '../../lib/Constants';

// @ts-ignore
import window from 'global/window';

const getSearchQuery = (search: any): string =>
  Object.keys(search).reduce(
    (acc, next) => (search[next] ? `${acc}&${encodeURIComponent(next)}=${encodeURIComponent(search[next])}` : acc),
    ''
  );

const getFilterQuery = (
  filter: IFilter,
  facetIdToNameMappingFn: (facetName: string, id: string) => string = (_,id) => id
): string =>
  getQueryFor(filter, 'themes', false, facetIdToNameMappingFn) +
  getQueryFor(filter, 'sectors', false, facetIdToNameMappingFn) +
  getQueryFor(filter, 'locations', false, facetIdToNameMappingFn) +
  getQueryFor(filter, 'learnMethod', false, facetIdToNameMappingFn) +
  getQueryFor(filter, 'languages', true, (_,id) => id) +
  getQueryFor(filter, 'durations', false, (_,id) => id) +
  (filter.availablePlaces ? '&availablePlaces=1' : '') +
  (filter.availableInCompany ? '&availableInCompany=1' : '');

const getQueryFor = (
  filter: any,
  name: string,
  addIfEmpty: boolean,
  facetIdToNameMappingFn: (facetName: string, id: string) => string 
): string => {
  const activeItems = extractActiveFilterItems(filter[name], (x) => {
    return facetIdToNameMappingFn(name, x.id)
  }
  );
  return activeItems && (activeItems.length > 0 || addIfEmpty)
    ? `&${name}=${JSON.stringify(activeItems)}`
    : '';
};

const generateSearchUrlQuery = (
  search: any,
  filter?: IFilter,
  loaded?: number,
  facetIdToNameMappingFn?: (facetName: string, id: string) => string
): string => {
  const params = window.location && new URLSearchParams(window.location.search);
  const wally = params.has('wally') ? '&wally' : '';
  const query =
    wally +
    getSearchQuery(search) +
    (filter ? getFilterQuery(filter, facetIdToNameMappingFn) : '') +
    (typeof loaded === 'number' && loaded > SearchPageSize ? `&loaded=${loaded}` : '');
  return query.replace(/^\&/, '?');
};

export default generateSearchUrlQuery;