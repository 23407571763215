import React, {useContext} from 'react';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';

const Section = ({ field, fieldFactory }) => {

  var shouldHide = false;
  const context = useContext(FormConditionsContext);

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
  }

  return (
    <fieldset className={`l-form__fieldset ${field.model && field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}>
      {field.fields && field.fields.map(fieldFactory)}
    </fieldset>
  );
}

export default Section;
