/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formsy from 'formsy-react';
import Input from '../../building-blocks/Input';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.state = { canSubmit: false };
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
   
    this.setState({ canSubmit: true });
  }

  submit(model) {

   /* fetch('http://example.com/', {
      method: 'post',
      body: JSON.stringify(model),
    });*/
  }
  renderSubmit(){
    const {t } = this.props;  
    return (
      <input type="submit" className="a-button" value={t('subscription.input.button')} disabled={!this.state.canSubmit} />
      
    );
  }
  render() {
    const {t } = this.props;
    return (
<section className='o-subscription'>
<div className='l-container l-container--small'>
  <header className='o-subscription__header'>
    <p className='o-subscription__subtitle u-tablet-hide'>
      {t('subscription.subtitle')}
    </p>

    <h2 className='o-subscription__title'>
      {t('subscription.title')}
    </h2>
  </header>

  <div className='o-subscription__content'>
  <Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
     
    <form className='l-form'>
      <div className='l-form__grid l-grid l-grid--with-margins'>
        <Input
          classes='l-grid__col-12-12'
          placeholder={t('subscription.input.placeholder')}
          type='email'
          validations="isEmail" 
          name='email'
          validationError="Dit is geen geldig em-mailadres" 
          required
          button={this.renderSubmit()}
        />
      </div>
    </form>
    </Formsy>
  </div>
</div>
</section>
    );
  }
}
export default withTranslation()(Subscription);


