/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import TextElement from './Text';
import { object } from '../../utils';

/**
 * Globals
 * =============================================================================
 */
const hint = `
  <p>
    The title component can either receive an icon or an image to support the
    text next to it. Select either one. <strong>If both have a value, the visual
    will not be rendered!</strong>
  </p>
`;

/**
 * Definition
 * =============================================================================
 */
const Title = (props) => {
  const {
    TitleType,
    TitleText,
    TitleIcon,
    TitleImage,
    TitleAlignment
  } = props.fields;

  const type = object.getNested(TitleType, 'fields', 'Value', 'value') || 'h1';
  const icon = object.getNested(TitleIcon, 'fields', 'Value', 'value') || '';

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderText = () => {
    return (
      <TextElement type={type}>
        <Text field={TitleText} />
      </TextElement>
    );
  };

  const renderIcon = () => {
    return <span className={`a-title__icon a-title__icon--${type} icon-${icon}`}></span>;
  };

  const renderImage = () => {
    return (
      <div className={`a-title__image a-title__image--${type}`}>
        <img
          className='a-title__visual'
          src={object.getNested(TitleImage, 'value', 'src')}
          alt={object.getNested(TitleImage, 'value', 'alt')}
        />
      </div>
    );
  };
  const getAlignClass = () => {

    const align = object.getNested(TitleAlignment, 'fields', 'Value', 'value');

    if (!align) return '';

    return 'a-title--align-'+align;
  };

  const renderVisual = () => {
    if (TitleImage && icon) return;

    return (
      ((isEditorActive() || TitleImage) && renderImage()) ||
      ((isEditorActive() || TitleIcon) && renderIcon())
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-title ${getAlignClass()}`}>
      {renderVisual()}

      {(isEditorActive() || TitleText) && renderText()}
    </div>
  );
};
export default Title;

