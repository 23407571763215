/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link } from './../../building-blocks';

/**
 * Definition
 * =============================================================================
 */
const Highlight = ({ url, text, onclick }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='a-highlight'>
      <div className='a-highlight__content'>

        {!onclick && (
          <Link
            fields={{
              LinkUrl: { href: url },
              LinkText: text
            }}
          /> 
          ) || (
            <Link
              onClick={onclick}
              fields={{
                LinkText: text
              }}
            /> 
          )
        }
        
      </div>
    </div>
  );
};

export default Highlight;

