import { OPERATOR_CONSTANTS, MATCH_TYPE_CONSTANTS, ACTION_CONSTANTS } from './constants/index';

const conditionDetailsInitial = {
    shouldHide: false,
    shouldDisable: false,
    shouldGoToPage: false,
    goToPage: ""
};

const handleActionType = (actionType, actionValue, isConditionMet) => {
    const actions = {
        [ACTION_CONSTANTS["SHOW"]]: () => {
            return { ...conditionDetailsInitial, shouldHide: !isConditionMet };
        },
        [ACTION_CONSTANTS["HIDE"]]: () => {
            return { ...conditionDetailsInitial, shouldHide: isConditionMet };
        },
        [ACTION_CONSTANTS["ENABLE"]]: () => {
            return { ...conditionDetailsInitial, shouldDisable: !isConditionMet };
        },
        [ACTION_CONSTANTS["DISABLE"]]: () => {
            return { ...conditionDetailsInitial, shouldDisable: isConditionMet };
        },
        [ACTION_CONSTANTS["GO_TO_PAGE"]]: () => {
            return { ...conditionDetailsInitial, shouldGoToPage: isConditionMet, goToPage: actionValue };
        }
    }

    return actions[actionType]();
}

const validate = (operator, value, valueAgainst) => {
    const operators = {
        [OPERATOR_CONSTANTS["CONTAINS"]]: () => {
            const regex = RegExp(`${valueAgainst}`);
            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["DOES_NOT_CONTAIN"]]: () => {
            const regex = RegExp(`^((?!${valueAgainst}).)*$`);
            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["STARTS_WITH"]]: () => {
            const regex = RegExp(`^${valueAgainst}`);
            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["DOES_NOT_START_WITH"]]: () => {
            const regex = RegExp(`^(?!${valueAgainst}).*$`);
            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["ENDS_WITH"]]: () => {
            const regex = RegExp(`${valueAgainst}$`);
            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["DOES_NOT_END_WITH"]]: () => {
            const regex = RegExp(`^(.(?!${valueAgainst}$))+$`);

            if (value === '') {
                return true;
            }

            return regex.test(value);
        },
        [OPERATOR_CONSTANTS["IS_EQUAL_TO"]]: () => {
            const compareAgainst = valueAgainst === 'true' ? true : valueAgainst;
            return value === compareAgainst;
        },
        [OPERATOR_CONSTANTS["IS_NOT_EQUAL_TO"]]: () => {
            return value !== valueAgainst;
        },
        [OPERATOR_CONSTANTS["IS_GREATER_THAN"]]: () => {
            return value > valueAgainst;
        },
        [OPERATOR_CONSTANTS["IS_GREATER_THAN_OR_EQUAL_TO"]]: () => {
            return value >= valueAgainst;
        },
        [OPERATOR_CONSTANTS["IS_LESS_THAN"]]: () => {
            return value < valueAgainst;
        },
        [OPERATOR_CONSTANTS["IS_LESS_THAN_OR_EQUAL_TO"]]: () => {
            return value <= valueAgainst;
        }
    };

    if (typeof operators[operator] === 'undefined') {
        return false;
    }

    return operators[operator]();
};

const matchingTypes = (fieldValues, item) => {
    const matches = {
        [MATCH_TYPE_CONSTANTS["ANY"]]: (fieldValues, item) => {
            let isConditionMet;

            for (let i = 0; i < item.conditions.length; i += 1) {
                const value = fieldValues[item.conditions[i].fieldId],
                    operator = item.conditions[i].operatorId,
                    valueAgainst = item.conditions[i].value;

                isConditionMet = validate(operator, value, valueAgainst);

                if (isConditionMet) {
                    break;
                }
            }

            return isConditionMet;
        },
        [MATCH_TYPE_CONSTANTS["ALL"]]: (fieldValues, item) => {
            let isConditionMet;

            for (let i = 0; i < item.conditions.length; i += 1) {
                const value = fieldValues[item.conditions[i].fieldId],
                    operator = item.conditions[i].operatorId,
                    valueAgainst = item.conditions[i].value;

                isConditionMet = validate(operator, value, valueAgainst);

                if (!isConditionMet) {
                    break;
                }
            }

            return isConditionMet;
        }
    },
        result = matches[item.matchTypeId](fieldValues, item);

    return handleActionType(item.actionTypeId, item.actionValue, result);
};

export const checkConditions = (fieldValues, conditions) => {
    let conditionDetails = conditionDetailsInitial;

    for (let i = 0; i < conditions.length; i++) {
        conditionDetails = matchingTypes(fieldValues, conditions[i]);
        if (conditionDetails !== conditionDetailsInitial) {
            break;
        }
    }

    return conditionDetails;
};
