import flatten from 'array-flatten';
import { getLocalized } from '../pages/SearchResults/TransformData';
import { window } from 'global/window';
import gql from 'graphql-tag';


const extractBase64Values = (array) => {
  return (flatten(array || [])
    .filter(x => x)
    .map(x => x.split('/'))
    .filter(x => x.length > 0)
    .map(x => getLocalized(x[x.length - 1], 'en')));
}

const getGtmObj = (hit) => {
  const gtmObj = {
    item_id: hit.referenceOld?.toUpperCase(),
    item_name: hit.title,
    item_brand: 'Cevora',
    price: 1.0,
    quantity: 1,
    item_language: hit.trainingLang?.toUpperCase()
  };
  const isOnline = ((hit.trainingFormModality && hit.trainingFormModality.toLowerCase().includes('online'))
    || !!(hit.searchResultIcons && hit.searchResultIcons.find(x => x.toLowerCase() === 'computer'))
    || !!(hit.isWebinar));
  const locations = extractBase64Values(hit.locations).filter(onlyUnique);
  if (locations.length > 0) { gtmObj['item_location'] = locations[0]; }
  if (isOnline) { gtmObj['item_location'] = 'Online'; }
  const trainingForm = extractBase64Values([hit.trainingForm]);
  const themes = extractBase64Values(hit.themeHierarchy).filter(onlyUnique);
  if (themes.length > 0) { gtmObj['item_category'] = themes[0]; }
  if (themes.length > 1) { gtmObj['item_category2'] = themes[1]; }
  if (hit.inCompany) { gtmObj['item_category3'] = 'In-company'; }
  else { gtmObj['item_category3'] = 'Classroom'; }
  if (hit.durations && hit.durations.length > 0) { gtmObj['item_category4'] = hit.durations[0]; }
  if (trainingForm.length > 0) { gtmObj['item_category5'] = trainingForm[0]; }
  return (gtmObj);
}

const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
}

const convertSearchResultToGtm = (result, persona, position, listName, listId) => {
  return { ...getGtmObj(result), item_variant: result.persona === "JS" ? result.persona : persona, index: position, item_list_name: listName, item_list_id: listId };
};

const dataLayerPush = (args, redirect) => {
  const tagManagerArgs = { ...args };

  if (redirect) {
    tagManagerArgs.eventCallback = redirect;
  }

  if (
    redirect &&
    window &&
    Array.isArray(window.dataLayer) &&
    !window.dataLayer.find(x => x.event === 'gtm.load')
  ) {
    // if dataLayer is not loaded, we should redirect ourselves!
    setTimeout(redirect, 500);
  }

  if (window && window.dataLayer) {
    console.log("dataLayerPush", JSON.stringify(tagManagerArgs, null, 2));
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push(tagManagerArgs);
  }
};


const impressionSearchResults = (hits, offset, persona) => {
  const dataLayer = {
    event: 'view_item_list',
    ecommerce: {
      item_list_name: 'Search Results',
      item_list_id: 'search_results',
      items: hits.map((x, idx) => ({
        ...convertSearchResultToGtm(x, persona, idx + offset, 'Search Results', 'search_results'),
      }))
    }
  };
  dataLayerPush(dataLayer);
};

const clickSearchResults = (hit, index, persona) => {
  const tagManagerArgs = {
    event: 'select_item',
    ecommerce: {
      item_list_name: 'Search Results',
      item_list_id: 'search_results',
      items: [convertSearchResultToGtm(hit, persona, 0)]
    }
  };
  dataLayerPush(tagManagerArgs);
};

const GET_COURSE = gql`
query GET_COURSE($cdb2CourseIds: [String])
{        
  search{
    courses(cdb2CourseIds: $cdb2CourseIds){
      hits{
        courseId
        cdb2CourseId
        courseUrl
        description
        highlightCode
        displayName
        educationalPath
        locations
        objective
        overview
        persona
        title
        trainingForm
        trainingFormCode
        trainingFormModality
        trainingLang
        referenceOld
        isVirtual
        isWebinar
        themeHierarchy
        sessions{
          startDateTime
          endDateTime
          registrationCount
          registrationMax
          sequences{
            startDateTime
            endDateTime
            locationRegion
            locationCity{
              cityName
              language
            }
          }
        }
      }
    }
  }
}

`;

const pushEvent = (eventName, courseId, client, persona) => {
  client.query({
    query: GET_COURSE,
    variables: { cdb2CourseIds: [courseId] }
  }).then(results => {
    if (!(results && results.data && results.data.search && results.data.search.courses && results.data.search.courses[0].hits.length > 0)) {
      return;
    }
    const hit = results.data.search.courses[0].hits[0];
    const tagManagerArgs = {
      event: eventName,
      ecommerce: {
        currency: 'EUR',
        value: 1.0,
        items: [
          convertSearchResultToGtm(hit, persona, 0)
        ]
      }
    };
    dataLayerPush(tagManagerArgs);
  });
}

const submitForm = (name, language) => {
  const tagManagerArgs = {
    event: 'form-submit',
    eventCategory: 'Form',
    eventAction: name,
    eventLabel: language
  };
  dataLayerPush(tagManagerArgs);
}

const addToBasket = (courseId, client, persona) => {
  if (courseId && client) {
    pushEvent('add_to_cart', courseId, client, persona);
  }
};

const basketView = (client, basketItems = [], persona = "") => {
  if (basketItems.length === 0) { return; }
  client.query({
    query: GET_COURSE,
    variables: { cdb2CourseIds: basketItems.map(x => x.courseId) }
  }).then(results => {
    if (!(results && results.data && results.data.search && results.data.search.courses && results.data.search.courses[0].hits.length > 0)) {
      return;
    }
    const products = results.data.search.courses[0].hits.map(x => convertSearchResultToGtm(x, persona));
    const tagManagerArgs = {
      event: 'view_cart',
      ecommerce: {
        currency: 'EUR',
        value: products.length * 1.0,
        items: products
      }
    };
    dataLayerPush(tagManagerArgs);
  }).catch(c => {
    console.error("Error retrieving courses for gtm push view cart", c);
  });
}

const removeFromBasket = (courseId, client, persona) => {
  if (courseId && client) {
    pushEvent('remove_from_cart', courseId, client, persona);
  }
};

const productView = (courseId, client, persona) => {
  if (courseId && client) {
    pushEvent('view_item', courseId, client, persona);
  }
}

const redirectToCheckout = (checkoutUrl, client, basketItems = [], persona = "") => {
  const redirect = () => window.open(checkoutUrl, "_self");
  client.query({
    query: GET_COURSE,
    variables: { cdb2CourseIds: basketItems.map(x => x.courseId) }
  }).then(results => {
    if (!(results && results.data && results.data.search && results.data.search.courses && results.data.search.courses[0].hits.length > 0)) {
      redirect();
      return;
    }
    const products = results.data.search.courses[0].hits.map(x => convertSearchResultToGtm(x, persona));
    const tagManagerArgs = {
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: products.length * 1.0,
        items: products
      }
    };
    dataLayerPush(tagManagerArgs);

    const uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
    const tagManagerArgsPurchase = {
      event: 'purchase',
      ecommerce: {
        transaction_id: uniqueId,
        currency: 'EUR',
        value: products.length * 1.0,
        items: products
      }
    };
    dataLayerPush(tagManagerArgsPurchase, redirect);

  }).catch(c => {
    console.error("Error retrieving courses for gtm push checkout", c);
    redirect();
  });
}

const calculatePersona = (auth0, basketData, defaultPersona) => {
  const { userPesona: auth0Persona } = auth0 || {};
  const { persona: basketPersona } = basketData || {};
  return auth0Persona || basketPersona || defaultPersona;
}

export {
  impressionSearchResults,
  convertSearchResultToGtm,
  clickSearchResults,
  addToBasket,
  removeFromBasket,
  redirectToCheckout,
  calculatePersona,
  productView,
  submitForm,
  basketView
};
