import React from 'react';
import TextWithTooltip from './TextWithTooltip';

const FieldLabel = ({ field }) => (
    field.model.title && (
        <div className='a-formfield__header'>
          <label className='a-formfield__label' htmlFor={field.valueField.id}>
            <TextWithTooltip text={field.model.title} />
          </label>
        </div>
      )
)

export default FieldLabel;