/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { IconButton,BodyEnd } from './../../building-blocks';
import { Events, Body } from '../../managers';
import document from 'global/document';
/**
 * Definition
 * =============================================================================
 */
class Modal extends React.Component {

  constructor(props) {
    super(props);
     this.state = {
      isOpen: props.isOpen==undefined?false:props.isOpen,
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentWillReceiveProps(nextProps){
   
    if (
      nextProps.isOpen!= undefined && nextProps.isOpen !== this.state.isOpen 
    ) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if(document.body){
      if (this.state.isOpen) {
        Body.handleAddClass('has-modal-open');
      } else {
        Body.handleRemoveClass('has-modal-open');
      }
    }
  }

  componentDidMount() {
    const { id } = this.props;
   
    Events.emitter.on(`modal-trigger-close:${id}`, this.handleClose);
    Events.emitter.on(`modal-trigger-open:${id}`, this.handleOpen);
    Events.emitter.on(`modal-trigger-toggle:${id}`, this.handleToggle);
  }

  componentWillUnmount() {
    const { id } = this.props;

    Events.emitter.removeListener(`modal-trigger-close:${id}`, this.handleClose);
    Events.emitter.removeListener(`modal-trigger-open:${id}`, this.handleOpen);
    Events.emitter.removeListener(`modal-trigger-toggle:${id}`, this.handleToggle);
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setOpenState() {
    const { isOpen } = this.state;
    const {id} = this.props;
    return isOpen ? 'is-open' : '';
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleToggle = () => {
    
   
    const isOpen = !this.state.isOpen;
   
    if (isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen = (e = null) => {
    if(e!=null)e.preventDefault();
    const { onOpen } = this.props;
    this.setState({ isOpen: true }, () => {
      if (onOpen) onOpen();

      Body.handleAddClass('has-modal-open');
    });
  };

  handleClose = (e) => {
    if(e!=null)e.preventDefault();
    const { onClose } = this.props;

    this.setState({ isOpen: false }, () => {
      if (onClose) onClose();

      Body.handleRemoveClass('has-modal-open');
     });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
   
    const {
      classes = '',
      contentClasses = '',
      footerClasses = '',
      title,
      content,
      footer,
      id
    } = this.props;
   return (
    <BodyEnd>
      <div className={`l-modal ${classes} ${this.setOpenState()}`} role="dialog">
        <div className='l-modal__backdrop' onClick={this.handleClose}></div>

        <div className='l-modal__container'>
          <header className='l-modal__header'>
            {title && (
              <h5 className='l-modal__title' id={`l-modal-title-${id}`}>{title}</h5>
            )}

            <IconButton
              classes='a-icon-button--animated l-modal__close'
              icon='icon-cancel'
              title='close'
              onClick={this.handleClose}
            />
          </header>

          <div className={`l-modal__content ${contentClasses}`} id={`l-modal-content-${id}`}>
            {content}
          </div>

          {footer && (
            <footer className={`l-modal__footer ${footerClasses}`}>
              {footer}
            </footer>
          )}
        </div>
      </div>
      </BodyEnd>
    );
  }
}

export default Modal;
