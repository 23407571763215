/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Badge from './../Badge';
import TooltipWrapper from '../TooltipWrapper';

/**
 * Definition
 * =============================================================================
 */
const Tooltip = (props) => {

  const {text, content} = props;

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <TooltipWrapper {...props} label={text} tooltip={content}>
      <Badge classes='a-badge--theme-purple' text='?' />
    </TooltipWrapper>
  );
};

export default Tooltip;
