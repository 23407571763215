/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { image,object } from '../../utils';
import Image from "../Image";
import window from 'global/window';
import LazyLoad from "vanilla-lazyload";
/**
 * Definition
 * =============================================================================
 */
class ResponsiveImage extends React.Component {
 
  constructor(props) {
    super(props);
    this.decorationTypeClass =  object.getNested(this.props,'fields','DecorationType','fields','Value','value');
    this.decorationContainedClass = object.getNested(this.props,'fields','DecorationOverflow','value')?'':'a-image--decoration-contained';
    this.decorationAlignClass = object.getNested(this.props,'fields','DecorationAlignRight','value')?'a-image--decoration-right':'a-image--decoration-left';  
  }
 
 
render(){
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-image  ${this.decorationTypeClass} ${this.decorationContainedClass} ${this.decorationAlignClass}`}>
    <Image classes={`a-image__visual`}
   {...this.props} />
   </div>);


}
}

export default ResponsiveImage;
