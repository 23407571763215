/**
 * Debounce
 * =============================================================================
 */

 function debounce(func, delay) {
  let timeout;

  return function(...args) {
    const ctx = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(ctx, args), delay);
  }
}

/**
 * Exports
 * =============================================================================
 */
export default {
  debounce
};
