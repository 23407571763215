import React, { useMemo } from 'react';
import SearchResults from './SearchResults';
import { gql } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { object } from '../../utils';
import { Loader } from '../../building-blocks';
import { getLocalized } from '../../pages/SearchResults/TransformData.ts';

const Query = gql`
  query SearchFacets {
    search {
      courses(first: 0, offset: 0) {
        facets {
          name
          values {
            aggregateCount
            name
          }
          foldedByDefault
          sortOrder
        }
      }
    }
  }
`;

const getPathLocalized = (base64, propertyName) => {
  const pathItems = base64.split('/');
  const names = pathItems.map((x) => getLocalized(x, propertyName));
  return names.join('/');
};

const facetToFilterMapping = {
  sessionslocations: 'locations',
  trainingformhierarchy: 'learnMethod',
  durations: 'durations',
  themehierarchytitle: 'themes',
  sectorhierarchytitle: 'sectors',
  traininglang: 'languages',
  hassessionsfreeplaces: 'options',
};

const mapFacets = (values) => {
  const namesMap = new Map();
  const idsMap = new Map();
  values.forEach((x) => {
    const id = x.name;
    const name = getPathLocalized(id, 'name');
    namesMap.set(name, id);
    idsMap.set(id, name);
  });
  return { namesMap, idsMap };
};

const SearchResultsWithMapping = (props) => {
  const { data } = useQuery(Query);
  const memoizedFacetsMap = useMemo(() => {
    const courses = object.getNested(data, 'search', 'courses') || [];
    const facets =
      courses.length > 0 ? [...courses[0].facets, ...courses[1].facets] : [];
    const facetsMap = new Map();
    facets.forEach((x) => {
      const mappedFacets = mapFacets(x.values);
      const facetSettings = { foldedByDefault: x.foldedByDefault, sortOrder: x.sortOrder };
      facetsMap.set(x.name, {...mappedFacets, facetSettings: facetSettings});
      // The naming for the facets and filters is not always consistant,
      // so to avoid possible mistakes lets add names for filters in the map.
      // That way we can be sure that the same function will work both on filter and facet names.
      const alternativeName = facetToFilterMapping[x.name];
      alternativeName && facetsMap.set(alternativeName, {...mappedFacets, facetSettings: facetSettings});
    });
    return facetsMap;
  }, [data]);

  const getFacetSettings = (facetName) => 
  memoizedFacetsMap.get(facetName).facetSettings || {foldedByDefault: false, sortOrder: 0};

  // Create 2 functions to map one or other direction.
  const nameToId = (facetName, name) =>
    memoizedFacetsMap.get(facetName).namesMap.get(name);
  const idToName = (facetName, id) =>
    memoizedFacetsMap.get(facetName).idsMap.get(id);

  return memoizedFacetsMap.size > 0 ? (
    <>
      <SearchResults
        {...props}
        facetNameToId={nameToId}
        facetIdToName={idToName}
        getFacetSettings={getFacetSettings}
      />
    </>
  ) : (
    <Loader />
  );
};

export default SearchResultsWithMapping;
