/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Pill = ({
  classes = '',
  text,
  onClick,
  id
}) => {

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClick = () => {
    if (onClick) onClick(id);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-pill ${classes}`}>
      <span className='a-pill__text'>{text}</span>

      <button
        className='a-pill__button'
        onClick={handleClick}
      />
    </div>
  );
};

export default Pill;
