import React, { useState } from 'react';
import { isEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import flatten from 'array-flatten';

import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Button, Loader } from '../../building-blocks';

import Filter from './Filter';
import { withTranslation } from '../../hoc/withTranslation';

import window from 'global/window';
import Cta from '../Cta';

const QUERY = gqlLoader('./query.graphql');

const PAGE_SIZE = 18;
const SESSION_STORAGE_KEY = (lng) => `Cevora:ImpulsePartnersFilter:${lng}`;

const getUniqueCategories = (arr, field) => {
  const result = flatten(
    arr.map((x) =>
      x[field].targetItems.map((y) => ({
        text: y.title.value,
        value: y.id,
        id: y.id,
      }))
    )
  ).reduce(
    (acc, cur) => (acc.find((x) => x.id === cur.id) ? acc : [...acc, cur]),
    []
  );
  result.sort((a, b) => (a < b ? 1 : -1));
  return result;
};

const ImpulsePartnersList = ({ sitecoreContext, t, lng }) => {
  const { sessionStorage = { getItem: () => {}, setItem: () => {} } } = window;
  const sessionFilter = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEY(lng)) || 'false'
  ) || { sector: {}, theme: {}, location: {} };

  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      lang: lng,
    },
  });
  const [top, setTop] = useState(PAGE_SIZE);
  const [filter, setFilter] = useState(sessionFilter);

  if (error) {
    console.error('Error loading impulse partners', error);
    return <></>;
  }

  const handleFilterChange = (f) => {
    setFilter(f);
    sessionStorage.setItem(SESSION_STORAGE_KEY(lng), JSON.stringify(f));
    setTop(PAGE_SIZE);
  };

  const allPartners = (data && data.item && data.item.children) || [];
  const partners = allPartners.filter((x) => x.versions.length > 0);

  const sectors = getUniqueCategories(partners, 'sector');
  const themes = getUniqueCategories(partners, 'theme');
  const locations = getUniqueCategories(partners, 'location');


  const isAllMandatoryFieldsFilledIn = (x) =>
    x.title.value &&
    x.buttonUrl.url &&
    x.description.value &&
    x.logo.src &&
    x.promo.targetItem &&
    x.location.targetItems &&
    x.location.targetItems.length > 0 &&
    x.theme.targetItems &&
    x.theme.targetItems.length > 0;

  const partnersWithAllFieldsFilledIn = isEditorActive()
    ? partners
    : partners.filter(isAllMandatoryFieldsFilledIn)

  const filteredPartners = partnersWithAllFieldsFilledIn
    .filter(
      (x) =>
        !filter.sector.value ||
        x.sector.targetItems.find((y) => y.id === filter.sector.value)
    )
    .filter(
      (x) =>
        !filter.location.value ||
        x.location.targetItems.find((y) => y.id === filter.location.value)
    )
    .filter(
      (x) =>
        !filter.theme.value ||
        x.theme.targetItems.find((y) => y.id === filter.theme.value)
    );

  const noResults = (
    <div className='a-highlight '>
      <div className='a-highlight__content'>
        <div className='a-title '>
          <h5 className='a-title__text'>{t('impulsePlus.noResults')}</h5>
        </div>
      </div>
    </div>
  );

  const hasMore = top < filteredPartners.length;
  const handleMoreClick = () => {
    setTop(999999);
  };

  return (
    <>
      <div className='o-impulse-plus-filter'>
        <Filter
          filter={filter}
          themes={themes}
          sectors={sectors}
          locations={locations}
          onChange={handleFilterChange}
        />
      </div>
      {loading && <Loader />}
      {filteredPartners.length > 0 ? (
        <div className='o-card-group'>
          <ul class='o-card-group__list l-grid l-grid--with-margins o-card-group__list--align-center'>
            {filteredPartners.slice(0, top).map((x, idx) => (
              <li class='o-card-group__item l-grid__col-4-12 '>
                <Cta
                  key={x.id}
                  fields={{
                    ButtonText: t('impulsePlus.buttonText', {
                      title: x.title.value,
                    }),
                    ButtonUrl: x.buttonUrl.jss,
                    CtaTitle: x.title.jss,
                    CtaDescription: x.description.jss,
                    CtaMiniImageUrl: x.logo.jss,
                    CtaTagText:
                      x.promo.targetItem && x.promo.targetItem.title.jss,
                    CtaTheme: {
                      fields: { Value: { value: 'm-cta--secondary-purple' } },
                    },
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      ) : (
        noResults
      )}
      {hasMore && (
        <div className='o-impulse-plus-group__action'>
          <Button
            type='button'
            classes='a-button--outline'
            onClick={handleMoreClick}
            fields={{ ButtonText: t('impulsePlus.showMore') }}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(withSitecoreContext()(ImpulsePartnersList));
