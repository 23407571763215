/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Title from '../Table/components/Title';
import Mobile from '../Table/components/Mobile';
import Row from './Row';
import { Attribute } from './../../building-blocks';

/**
 * Definition
 * =============================================================================
 */
const Table = ({ classes, header, body, id }) => {
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderHeader = () => {
    return header.map((h, index) => (
      <Title key={`table-header-row-${index}`} {...h} />
    ));
  };

  const renderBody = () => {
    const alert = body.find(
      (x) => x.columns[2].text && x.columns[2].text != ''
    );
    return body.map((b, index) => (
      <Row
        key={`table-body-row-${index}`}
        index={index}
        alert={alert}
        id={id}
        data={b.columns}
        linkUrl={b.linkUrl}
      />
    ));
  };

  const renderMobile = () => {
    return body.map((d, index) => (
      <Mobile
        key={`table-action-mobile-${index}`}
        url={d.linkUrl}
        icon={'icon-arrow-right'}
        tableId={id}
        index={index}
        {...d}
      />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`o-table o-table--custom o-table--with-links ${classes}`}>
      <div className='o-table__mobile-actions u-desktop-hide'>
        <div className='o-table__mobile-header-row'></div>

        {renderMobile()}
      </div>

      <div className='o-table__wrapper'>
        <div className='o-table__content'>
          <div className='o-table__header-row'>{renderHeader()}</div>

          <div className='o-table__body'>{renderBody()}</div>
        </div>
      </div>
    </div>
  );
};

export default Table;
