import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const SCROLL_BACK_TO_BEGINNING_OF_FILTER_THRESHOLD = -250;

const recursiveLength = (arrayWithChildren = []) =>
  arrayWithChildren.length +
  arrayWithChildren.reduce(
    (acc, cur) => acc + recursiveLength(cur.children || []),
    0
  );

const withMore = (Component) => (props) => {
  const topEl = useRef(null);

  const { i18n } = useTranslation();
  const t = i18n.t.bind(i18n);

  const scrollToFilter = (prevIsFolded) => {
    if (!prevIsFolded && window && topEl && topEl.current) {
      const rect = topEl.current.getBoundingClientRect();
      if (rect.top < SCROLL_BACK_TO_BEGINNING_OF_FILTER_THRESHOLD) {
        topEl.current.scrollIntoView();
      }
    }
  }
  
  const handleToggleMore = () => {
    setIsFolded((prevIsFolded) => {
      scrollToFilter(prevIsFolded);
      return !prevIsFolded;
    });
  };

  const { data = [], loadMore = {} } = props;
  const { limit } = loadMore;

  const [isFolded, setIsFolded] = useState(limit > 0);

  let limitedData = data;
  const totalLength = recursiveLength(data);

  if (limit) {
    let superIdx = -1;
    const indexMe = (arr, lvl) =>
      arr.map((x) => ({
        ...x,
        idx: lvl > 0 ? superIdx : ++superIdx,
        children: indexMe(x.children || [], lvl+1),
      }));
    const indexedArray = indexMe(data, 0);
    const hasChecked = (arr = []) =>
      !!arr.find((x) => x.isChecked || hasChecked(x.children));

    const hierarchicalSlice = (arr, lim) => arr
         .filter((x) => x.idx < lim || x.isChecked || hasChecked(x.children))
         .map((x) => ({ ...x, children: hierarchicalSlice(x.children, lim) }));

    limitedData = hierarchicalSlice(indexedArray, isFolded ? limit : 100500);
  }

  return (
    <>
      <div ref={topEl} />
      <Component {...props} limitedData={limitedData} />
      {limit && totalLength > limit && (
        <a
          className='a-link a-link--orange a-link'
          onClick={(e) => {
            if (e) e.preventDefault();
            handleToggleMore();
          }}
        >
          <span className='a-link__text'>
            {isFolded ? t('misc.more') : t('misc.less')}
          </span>
          <span
            className={`a-link__icon icon-arrow-${isFolded ? 'down' : 'up'}`}
          ></span>
        </a>
      )}
    </>
  );
};

export default withMore;
