/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react'
import Connected from './Connected';
import Disconnected from './Disconnected';
import { object } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const SearchBar = (props) => {
  const { rendering } = props;

  return rendering && object.getNested(rendering, 'dataSource') !== "available-in-connected-mode" ? (
    <Connected {...props} />
  ) : (
    <Disconnected {...props} />
  );
};

export default withSitecoreContext()(SearchBar);
