/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Item = ({
  id,
  name,
  text,
  value,
  onChange,
  isActive = false
}) => { 

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const handleChange = () => {
    if (onChange) onChange(text, value, id);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='m-choice-list__item'>
      <input
        className='m-choice-list__input'
        type='radio'
        id={id}
        name={name}
        onChange={handleChange}
        checked={isActive}
      />

      <span className='m-choice-list__icon'></span>

      <label className='m-choice-list__label' htmlFor={id}>
        {text}
      </label>
    </li>
  );
};

export default Item;
