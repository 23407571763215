const daysInWeek = 5;
const daysInMonth = 20.9;
const localizedUnits = {
    "en": [
        { singular: "day", plural: "days"}, 
        { singular: "week", plural: "weeks"}, 
        { singular: "month", plural: "months"}, 
    ],
    "fr": [
        { singular: "jour", plural: "jours"}, 
        { singular: "semaine", plural: "semaine"}, 
        { singular: "mois", plural: "mois"}, 
    ],
    "nl": [
        { singular: "dag", plural: "dagen"}, 
        { singular: "week", plural: "weken"}, 
        { singular: "maand", plural: "maanden"}, 
    ],
    "de": [
        { singular: "Tag", plural: "Tage"}, 
        { singular: "Woche", plural: "Wochen"}, 
        { singular: "Monat", plural: "Monate"}, 
    ]
  };

const FormatInt = (days, lang, level) => {
    const roundDays = Math.round(days);
    const translation = localizedUnits[lang][level]; 
    const str = roundDays > 1 ? translation.plural : translation.singular;
    return `${roundDays} ${str}`;
}

const Level = { days: 1, weeks: 2, months: 3 };
const Format = (days, lang, level) => {
    if(typeof(days) === "string" && days.length === 0) return "";
    if(level < Level.weeks || days < daysInWeek) return FormatInt(days, lang, 0);
    if(level < Level.months || days < daysInMonth) return FormatInt(days / daysInWeek, lang, 1);
    return FormatInt(days / daysInMonth, lang, 2);
}

export { Level, Format };