/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Link from '../../building-blocks/SimpleLink';


/**
 * Definition
 * =============================================================================
 */
const Item = ({ title, link, children }) => {
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderLink = () => {
    return (
      <Link
        to={link}
        className='o-nav__item-link'
      >
        {title}
      </Link>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='o-nav__item'>
      {!children ? renderLink() : children}
    </li>
  );
};

export default Item;
