/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { object } from '../../utils';
import SearchResults from '../../pages/SearchResults';
import moment from 'moment';
import {
  InputDateFormat,
  TaxonomySectorsRoot,
  TaxonomyThemesRoot,
  TrainingFormsRoot
} from '../../lib/Constants';

import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '../../building-blocks';
import { getLocalized } from '../../pages/SearchResults/TransformData';
import { withTranslation } from '../../hoc/withTranslation';

const Query = gqlLoader('./query.gql');

/**
 * Definition
 * =============================================================================
 */
const PredefinedSearchResults = props => {
  const { fields, lng, t } = props;

  const fieldValues = {
    keywords: object.getNested(fields, 'Keywords', 'value'),
    dateFrom: object.getNested(fields, 'DateFrom', 'value'),
    dateFromUseCurrentDate: object.getNested(
      fields,
      'DateFromUseCurrentDate',
      'value'
    ),
    dateTo: object.getNested(fields, 'DateTo', 'value'),
    trainingAudienceType: object.getNested(fields, 'TrainingAudienceType'),
    trainingForms: object.getNested(fields, 'TrainingForms'),
    taxonomy: object.getNested(fields, 'Taxonomy'),
    trainingLanguages: object.getNested(fields, 'TrainingLanguages'),
    resultsFilteredOn: object.getNested(fields, 'ResultsFilteredOn'),
    showOnlyAvailable: object.getNested(fields, 'ShowOnlyAvailable', 'value'),
    showOnlyInCompany: object.getNested(fields, 'ShowOnlyInCompany', 'value'),
    orderResultsBy: object.getNested(fields, 'OrderResultsBy'),
    sortByCourseName: object.getNested(fields, 'SortByCourseName', 'value'),
    hideSortingOption: object.getNested(fields, 'HideSortingOption', 'value'),
    hideFilters: object.getNested(fields, 'HideFilter', 'value'),
    durations: object.getNested(fields, 'Durations'),
    locations: object.getNested(fields, 'Locations'),
    trainingTypeCode: object.getNested(
      fields,
      'TrainingType',
      'fields',
      'Code',
      'value'
    )
  };

  const dateFrom =
    fieldValues.dateFrom === '0001-01-01T00:00:00Z'
      ? fieldValues.dateFromUseCurrentDate
        ? new Date().toISOString()
        : undefined
      : fieldValues.dateFrom;
  const dateTo =
    fieldValues.dateTo === '0001-01-01T00:00:00Z'
      ? undefined
      : fieldValues.dateTo;
  const persona =
    fieldValues.trainingAudienceType &&
    fieldValues.trainingAudienceType.fields.Code.value;

  const query = useQuery(Query, {
    variables: {
      themesPath: TaxonomyThemesRoot,
      sectorsPath: TaxonomySectorsRoot,
      trainingFormsPath: TrainingFormsRoot
    }
  });
  const { error, loading, data } = query;

  if (error) {
    console.error('Error retrieving data', error);
    return <pre>{JSON.stringify(data, null, 2)}</pre>;
  }

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return null;
  }

  const { themes, sectors, learnMethods } = data;

  const activeTaxonomyIds = (fieldValues.taxonomy || []).map(x => x.id);
  const activeTrainingFormIds = (fieldValues.trainingForms || []).map(
    x => x.id
  );

  const initial = {
    term: fieldValues.keywords,
    from: dateFrom ? moment(dateFrom).format(InputDateFormat) : undefined,
    to: dateTo ? moment(dateTo).format(InputDateFormat) : undefined,
    searchAs: persona,
    trainingTypeCode: fieldValues.trainingTypeCode,
    alwaysSearch: true,
    hideSearchBar: true,
    hideFilters: fieldValues.hideFilters,

    languages: fieldValues.trainingLanguages
      .map(item => item.fields.Iso.value)
      .map(x => ({ id: x, text:  t('language.' + x), isChecked: true })),
    availablePlaces: fieldValues.showOnlyAvailable,
    availableInCompany: fieldValues.showOnlyInCompany,
    themes:
      themes && Array.isArray(themes.children) &&
      themes.children.map(mapThemesToFilter(activeTaxonomyIds, lng)),
    sectors:
      sectors && Array.isArray(sectors.children) &&
      sectors.children.map(mapThemesToFilter(activeTaxonomyIds, lng)),
    learnMethod:
      learnMethods && Array.isArray(learnMethods.children) &&
      learnMethods.children.map(mapThemesToFilter(activeTrainingFormIds, lng)),
    resultFilters: fieldValues.resultsFilteredOn.map(
      item => item.fields.Code.value
    ),
    locations: fieldValues.locations,
    durations: fieldValues.durations.map(item => object.getNested(item, 'fields', 'Value', 'value')),
    sorting:
      fieldValues.orderResultsBy &&
      fieldValues.orderResultsBy.fields.Code.value,
    sortByCourseName: fieldValues.sortByCourseName,
    hideSortingOption: fieldValues.hideSortingOption
  };
  return <SearchResults initial={initial} />;
};

const mapThemesToFilter = (activeIds, lng, parentId = '') => item => {
  const localizedId = item.localizedFilterId;
  const id = `${parentId}/${localizedId}`;
  const isChecked = !!activeIds.find(x => convertToId(x) === convertToId(item.id));
  const children = item.children &&
    item.children.map(mapThemesToFilter(activeIds, lng, id));
  const text = isChecked || (children && children.find(x => x.isChecked)) ? getLocalized(localizedId, lng) : undefined;
  return {
    id,
    isChecked,
    text,
    children
  };
};

const convertToId = guid => {
  let i = 0;
  const compressedGuid = (guid || "").replace(/[^0-9a-z]/gi, '');
  return '{11111111-1111-1111-1111-111111111111}'
    .replace(/[0-9a-z]/gi, () => compressedGuid[i++])
    .toUpperCase();
};

export default withTranslation()(PredefinedSearchResults);
