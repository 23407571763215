/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import  ProgressBar  from '../ProgressBar';
import object from '../../utils/object';
import { Events } from './../../managers';
import { withTranslation } from '../../hoc/withTranslation';
/**
 * Definition
 * =============================================================================
 */
class MultiPane extends React.Component {
  constructor(props) {
    super(props);

    const {
      ShowTabs,
      ShowProgress,
      ShowButtons,
      AllEnabled
    } = props.fields;
    
    const {
      t
    } = props
    this.state = {active:1};
 
  }
  componentDidMount(){

   // Events.emitter.on('multipane-enable-pane', this.handleEnablePane);
   // Events.emitter.on('multipane-disable-pane', this.handleDisablePane);
    this.setState({active:this.items.length});
  
  }
  componentWillUnmount() {
    //Events.emitter.removeListener('multipane-enable-pane', this.handleEnablePane);
    //Events.emitter.removeListener('multipane-disable-pane', this.handleDisablePane);
  }

  handlDisablePane = ({ index }) => {
    
  }
  handleEnablePane = ({ index }) => {
   
  }

  static getActive(component,activeitem){
    return JSON.stringify(component.props.fields)==JSON.stringify(activeitem.fields)?'is-active':'';
  
  }
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderItem (component, index) {
    const {active,items} = component.props;
    
   var activeClass = MultiPane.getActive(component,items[active-1]);
    return (
    <div className={`o-multipane__pane ${activeClass}`}
        key={`multipane-item-${index}`}
      > {component}
      </div>
    );
  };
  getItems(){
   

    if(!object.hasNested(this.props,'rendering','placeholders','multipane'))return;
    var items = this.props.rendering.placeholders.multipane.filter(x=>x.componentName=="MultiPaneItem");
    
    return items;
  }

  handleTabClick(e,item,index){
    e.preventDefault();

    this.setState({active:index+1});
  }

  handleNextClick(e){
    this.setState({active:this.state.active+1});
  }
  handlePreviousClick(e){
    this.setState({active:this.state.active-1});
  }

  getNextButton(t){
    return object.hasNested(this.getCurrentItem(),'fields','NextButton','value') && object.getNested(this.getCurrentItem(),'fields','NextButton','value')!=''? this.getCurrentItem().fields.NextButton.value:t('multipane.next');
  }

  getPreviousButton(t){

    return object.hasNested(this.getCurrentItem(),'fields','PreviousButton','value') && object.getNested(this.getCurrentItem(),'fields','PreviousButton','value')!=''? this.getCurrentItem().fields.PreviousButton.value:t('multipane.previous');
  }

  getCurrentItem(){
    return this.items[this.state.active-1];
  }
  render() {
    const {
      ShowTabs,
      ShowProgress,
      ShowButtons,
      AllEnabled 
    } = this.props.fields;
    const {rendering,t} = this.props;

    this.items = this.getItems();
   
const tabs = this.items.map((item,index) =>
<li key={`tab-item-${index}`} className={`o-tabs__item ${(this.state.active==index+1)?'is-active':''}`}>
<a onClick={e => this.handleTabClick(e,item,index)} className="o-tabs__link">{item.fields.Name.value}</a>
</li>
);
  return (
    <div className='o-multipane'>
      <div className="o-multipane__tabs o-tabs js-tabs ">
	{(ShowTabs.value==true && <ul className="o-tabs__list">
  {tabs}
	</ul>)}
</div>
      <div className={`o-multipane__content`}>
        <Placeholder
          rendering={rendering}
          active={this.state.active}
          items={this.items}
                   name='multipane'
          renderEach={this.renderItem}
        />
      </div>
      <div className="o-multipane__nav">
		{(ShowButtons.value==true && <button disabled={this.state.active==1} onClick={this.handlePreviousClick} className={`a-button a-button--outline o-multipane__prev ${this.state.active==1?'a-button--hidden':''}`}>
			<span className="a-button__text">{this.getPreviousButton(t)}</span>
		</button>)}
		{(ShowProgress.value==true && <ProgressBar classes="o-multipane__progress" steps={this.items.length} active={this.state.active}/>
    )}
		{(ShowButtons.value==true &&  <button className="a-button o-multipane__next" onClick={this.handleNextClick}>
			<span className="a-button__text">{this.getNextButton(t)}</span>
		</button>)}
	</div>
    </div>
  );
      }
}

export default withTranslation()(MultiPane);
