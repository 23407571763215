/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Multilink = (props) => {
  const {
    MultilinkTitle,
    MultilinkSpacing,
    MultilinkAlignment
  } = props.fields;

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
   const getSpacingClasses = () => {
    const spacing = object.getNested(MultilinkSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return classes.compare(spacing, {
      'large': 'm-multilink--spacing-large'
    });
  };

  const getAlignmentClasses = () => {
    const alignment = object.getNested(MultilinkAlignment, 'fields', 'Value', 'value');

    if (!alignment) return '';

    return classes.compare(alignment, {
      'center': 'm-multilink--center'
    });
  };

  const getClasses = () => {
    const spacing = getSpacingClasses();
    const alignment = getAlignmentClasses();

    return `${spacing} ${alignment}`;
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderTitle = () => {
    return (
      <h4 className='m-multilink__title'>
        <Text field={MultilinkTitle} />
      </h4>
    );
  };

  const renderItem = (component, index) => {
    return (
      <li
        key={`multilink-action-${index}`}
        className='m-multilink__action'
      >
        {component}
      </li>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <article className={`m-multilink ${getClasses()}`}>
      <header className='m-multilink__header'>
        {(MultilinkTitle || isEditorActive()) && renderTitle()}
      </header>

      <ul className='m-multilink__actions'>
        <Placeholder
          {...props}
          name='multilink-actions'
          renderEach={renderItem}
        />
      </ul>
    </article>
  );
};

export default Multilink;
