/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import withFlyout from './withFlyout';
import { Body } from '../managers';

/**
 * Definition
 * =============================================================================
 */
const withInputFlyout = () => (WrappedComponent) => {
  class WithInputFlyout extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        isMouseOverContent: props.isMouseOverContent || false,
        isFocused: props.isFocused || false
      };
    }

    /**
     * Handlers
     * ---------------------------------------------------------------------------
     */
    handleInputFlyoutMouseEnter = () => {
      this.setState({ isMouseOverContent: true });
    }

    handleInputFlyoutMouseLeave = () => {
      const { handleCloseFlyout } = this.props;
      const { isFocused } = this.state;

      this.setState({ isMouseOverContent: false }, () => {
        if (!isFocused) handleCloseFlyout();
      });
    }

    
    handleInputFlyoutBlur = () => {
      const { isMouseOverContent } = this.state;
      const { handleCloseFlyout } = this.props;

      this.setState({ isFocused: false }, () => {
        if (!isMouseOverContent) handleCloseFlyout();
      });
    }

    handleInputFlyoutFocus = () => {
      const { handleOpenFlyout } = this.props;

      this.setState({ isFocused: true }, () => {
        handleOpenFlyout();
      });
    }

    /**
     * Main render
     * -------------------------------------------------------------------------
     */
    render() {
      const { isMouseOverContent, isFocused } = this.state;

      return (
        <WrappedComponent
          handleInputFlyoutMouseEnter={this.handleInputFlyoutMouseEnter}
          handleInputFlyoutMouseLeave={this.handleInputFlyoutMouseLeave}
          handleInputFlyoutBlur={this.handleInputFlyoutBlur}
          handleInputFlyoutFocus={this.handleInputFlyoutFocus}
          isMouseOverContent={isMouseOverContent}
          isFocused={isFocused}
          {...this.props}
        />
      );
    }
  }

  return withFlyout()(WithInputFlyout);
}

export default withInputFlyout;
