import * as React from 'react';
import GraphQLClientContext from '../contexts/GraphQLClientContext'

const GraphQLClientProvider = (props) => {
    const {graphQLClient} = props;
    return (
    <GraphQLClientContext.Provider value={graphQLClient}>
        {props.children}
    </GraphQLClientContext.Provider>);
};

export default GraphQLClientProvider;
