import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React, {useState,useEffect} from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import fixGlobalThis from '../../../lib/fixGlobalThis';
import Turnstile from 'react-turnstile';

const TurnstileField = (props) => {
  const {
    field,
    value,
    onChange,
    isValid,
    sitecoreContext,
  } = props;
  const { TurnstileSiteKey: siteKey } = sitecoreContext;

  const [token, setToken] = useState(null);
  useEffect(() => {
    if(token !== value){
      onChange(field.valueField.name, token, true, []);
    }
  }, [token, value]);

  return (
    <>
      <div
        className={`a-formfield ${!isValid ? 'is-error' : ''}  ${
          field.model.cssClass
        }`}
      >
        <input
          hidden
          type='text'
          id={field.valueField.id}
          name={field.valueField.name}
          value={value}
        />
        <Turnstile
          sitekey={siteKey}
          onVerify={(token) => {
            setToken(token);
          }}
          responseFieldName={field.valueField.name}
          responseField={true}
          theme="light"
        />
        <FieldValidationErrors {...props} />
      </div>
    </>
  );
};
export default withSitecoreContext()(TurnstileField);
