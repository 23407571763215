/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */
const HighlightList = ({
  classes = '',
  items,
  highlight,
  onClick,
  t
}) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getIsMatched = (text) => {
    let isMatched = true;
    const split = text
      .toLowerCase()
      .split(highlight.toLowerCase());

    if (split.length === 1 && split[0] === text.toLowerCase()) {
      isMatched = false;
    }

    return isMatched;
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = (collection = items) => {
    return collection.map((i, index) => (
      <Item
        key={`highlight-list-item-${index}`}
        highlight={highlight}
        onClick={onClick}
        {...i}
      />
    ));
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  items = items.filter((i) => {
    const { text = '' } = i;
    const isMatched = getIsMatched(text);

    return isMatched;
  });

  return items.length > 0
    ? (
      <ul className={`m-highlight-list ${classes}`}>
        {renderItems(items)}
      </ul>
    )
    : <p>{t('highlightList.noMatches', { text: highlight })}</p>;
};

export default withTranslation()(HighlightList);
