import * as React from 'react';
import { Events } from './../managers';
import BasketContext from '../contexts/BasketContext';
import { addToBasket, removeFromBasket, calculatePersona } from '../lib/Gtm';
import { withAuth0 } from '../hoc';
import { CourseBasketProvider } from '../providers';

class BasketProvider extends React.Component {
  constructor(props) {
    super(props);


    this.dataProvider = new CourseBasketProvider(props.graphQLClient, props.auth0);

    this.state = {
      isLoading: false,
      items: [],
      persona: '',
      graphQLClient: props.graphQLClient,
      onCheckOut: this.onCheckOut,
      onPersonaSet: this.onPersonaSet,
      onAllItemsDeleted: this.onAllItemsDeleted,
      onItemAdded: this.onItemAdded,
      onItemDeleted: this.onItemDeleted,
      sendAddToCardGtm: this.sendAddToCardGtm,
    };

    this.loadData = this.loadData.bind(this);
  }

  loadData() {

    this.setState({ isLoading: true }, () => {
      this.dataProvider.getInitialData().then(items => {
        this.dataProvider.getPersonaData().then(persona => {
          this.setState({ items, persona, isLoading: false });
        });
      });
    });
  }



  componentDidMount() {
    const { isIdentified } = this.props;
    this.dataProvider.registerToChanges(items => {
      this.setState({ items });
    });
    if (!isIdentified) {
      Events.emitter.on('user-init', this.loadData);
    }

    this.loadData();
  }

  onCheckOut = (courseType) => {
    this.setState({ isLoading: true }, () => {
      this.dataProvider.onCheckOut(courseType);
    });
  }

  onPersonaSet = (persona, disableLoadingSpinner) => {
    this.setState({ isLoading: true, persona }, () => {
      this.dataProvider.onPersonaSet(persona, disableLoadingSpinner).then(p => {
        this.setState({ persona: p, isLoading: false });
      });
    });
  };

  onAllItemsDeleted = () => {
    this.setState({ isLoading: true }, () => {
      this.dataProvider.onAllItemsDeleted().then(items => {
        this.setState({ items, isLoading: false });
      });
    });
  };

  onItemAdded = id => {
    this.setState({ isLoading: true }, () => {
      this.sendAddToCardGtm(id);
      this.dataProvider
        .onItemAdded(id)
        .then(items => {
          this.setState({ items, isLoading: false });
        })
        .catch(error => {
          console.error('Error adding item to basket', error, id);
        });
    });
  };

  sendAddToCardGtm = (id) => {
    addToBasket(
      id.courseId,
      this.props.graphQLClient,
      calculatePersona(this.props.auth0, this.state)
    );
  };

  onItemDeleted = index => {
    this.setState({ isLoading: true }, () => {
      const course = this.state.items.find(x => x.id === index);
      if (course) {
        removeFromBasket(course.courseId, this.props.graphQLClient, calculatePersona(this.props.auth0, this.state));
      }
      this.dataProvider.onItemDeleted(index).then(items => {
        this.setState({ items, isLoading: false });

      });
    });
  };

  render() {
    return (
      <BasketContext.Provider value={this.state}>
        {this.props.children}
      </BasketContext.Provider>
    );
  }
}

export default withAuth0(BasketProvider);
