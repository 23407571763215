/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withCollapse } from './../../hoc';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object, type } from './../../utils';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
const SectionCollapse = ({
  fields,
  children,
  sectionId,
  setCollapseState,
  handleToggleCollapse
}) => {
  const {
    SectionCollapseTitle,
    SectionCollapseMainContent,
    SectionCollapseDesktopOnly,
    SectionCollapseBackgroundColor
  } = fields;
  var aboveDesktop = '';
  if (SectionCollapseDesktopOnly) {
    aboveDesktop = '@below-desktop-base';
  }
  
  const isNotSupportedAnymore = () => {
    const sectionCollapseBackgroundColor = object.getNested(SectionCollapseBackgroundColor, 'fields', 'Value', 'value');
    return ['#000000', '#616365', '#ff7900', '#d71f85', '#ed910f'].includes(sectionCollapseBackgroundColor);
  };

  const getBackgroundHexColor = () => {
    const sectionCollapseBackgroundColor = object.getNested(SectionCollapseBackgroundColor, 'fields', 'Value', 'value');
    if (!sectionCollapseBackgroundColor || isNotSupportedAnymore()) return { backgroundColor: '#fff' };
    return { backgroundColor: `${sectionCollapseBackgroundColor}` };
  };

  const isDark = () => {
    const sectionCollapseBackgroundColor = object.getNested(SectionCollapseBackgroundColor, 'fields', 'Value', 'value');
    return ['#0094a8', '#4b92db', '#434294', '#b5b875', '#ac98db', '#a70240', '#1f145d'].includes(sectionCollapseBackgroundColor);
  };

  var darkBackground = '';
  if (isDark()) {
    darkBackground = 'l-section-collapse--dark';
  }

  /**
 * Renders
 * ---------------------------------------------------------------------------
 */
  const renderTitle = () => {
    return (
      <h4 className='l-section-collapse__title'>
        {(type.isReact(SectionCollapseTitle))
          ? SectionCollapseTitle
          : <Text field={SectionCollapseTitle} />
        }
      </h4>
    );
  };

  const renderContent = () => {
    return (
      <RichText field={SectionCollapseMainContent} />
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <section id={sectionId} className={`l-section-collapse${aboveDesktop} ${setCollapseState()} ${darkBackground}`} style={getBackgroundHexColor()} >
      <div className='l-section-collapse__container l-container'>
        <header
          className='l-section-collapse__header'
          onClick={handleToggleCollapse}
        >
          {(isEditorActive() || SectionCollapseTitle) && renderTitle()}

          <button className='l-section-collapse__trigger'></button>
        </header>

        <div className='l-section-collapse__content'>
          <div className='l-section-collapse__content-container'>
            {(isEditorActive() ||
              type.isReact(SectionCollapseMainContent) ||
              object.getNested(SectionCollapseMainContent, 'value')) && renderContent()}
            {(children)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withCollapse()(SectionCollapse);
