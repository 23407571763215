import document from 'global/document';
import { url, image } from '../utils';

function getMetaImageUrl(imagelink, type) {
  if (imagelink == '' || imagelink == undefined || imagelink == null) return null;
  return createTypeUrl(imagelink, type);
}

function removeExtension(imagepath) {
  return imagepath.replace(/\.(ashx|png|jpg|jpeg|gif|webp)/gi, '');
}

function removeQuerystring(imagepath) {
  return imagepath.split("?")[0];
}

function stripImagePath(imagelink) {
  var parsedurl = url.parseURL(imagelink);
  if (parsedurl.protocol == undefined) return removeQuerystring(removeExtension(imagelink));
  if (parsedurl.pathname.indexOf('/') != 0) parsedurl.pathname = "/" + parsedurl.pathname;
  var imagepath = parsedurl.protocol + "//" + parsedurl.host + parsedurl.pathname;
  return removeExtension(imagepath);
}

function createTypeUrl(imagepath, type) {
  switch (type) {
    case 'opengraph':
      return createImageUrl(imagepath, 1200, 630, 65, image.getExtension(imagepath));
    case 'twitter':
      return createImageUrl(imagepath, 260, 260, 65, image.getExtension(imagepath));
  }

}
function getMediaTag(platform) {
  var mobilebase = 480;
  var tabletbase = 720;
  var desktopbase = 960;
  var desktoplargebase = 1140;
  var desktopmaxbase = 2000;
  var quality = 65;
  switch (platform) {
    case "mobile":
      return "(min-width: 1px)";
    case "tablet":
      return "(min-width: " + mobilebase + "px)";
    case "desktop":
      return "(min-width: " + tabletbase + "px)";
    case "desktoplarge":
      return "(min-width: " + desktopbase + "px)";
  }

}
function getImageUrlByColumnSize(imagepath, columnSize = "12", platform, format, dp = 1) {
  if (imagepath == '' || imagepath == undefined || imagepath == null) return null;
  var mobilebase = 480;
  var tabletbase = 720;
  var desktopbase = 960;
  var desktoplargebase = 1253;
  var desktopmaxbase = 2000;
  var quality = 65;

  var columnSizeParsed = parseInt(columnSize);
  var outherSize = columnSizeParsed > 12;
  if (columnSizeParsed === NaN || columnSizeParsed > 12) {
    columnSizeParsed = 12;
  }

  switch (platform) {
    case "mobile":
      switch (columnSizeParsed) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return createImageUrl(imagepath, mobilebase * (12 / 6) * dp, undefined, quality, format);

        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
          return createImageUrl(imagepath, mobilebase * dp, undefined, quality, format);
      }
      break;

    case "tablet":
      switch (columnSizeParsed) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
          return createImageUrl(imagepath, tabletbase * (6 / 12) * dp, undefined, quality, format);

        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
          return createImageUrl(imagepath, tabletbase * dp, undefined, quality, format);
      }

    case "desktop":
      return createImageUrl(imagepath, Math.round(desktopbase * columnSizeParsed / 12) * dp, undefined, quality, format);

    case "desktoplarge":
      if (outherSize)
        return createImageUrl(imagepath, Math.round(desktopmaxbase * columnSizeParsed / 12) * dp, undefined, quality, format);
      else
        return createImageUrl(imagepath, Math.round(desktoplargebase * columnSizeParsed / 12) * dp, undefined, quality, format);

  }


}

function createImageUrl(imagepath, width, height, quality, format) {
  imagepath = stripImagePath(imagepath) + "." + format;
  var imgParams = { w: width, h: height, q: quality, f: format };
  var path = imagepath + url.createURLParameters(imgParams);
  return path;
}

function getExtension(imageUrl) {
  return typeof imageUrl === 'string'
    ? imageUrl.split(/[#?]/)[0].split('.').pop().trim()
    : '';
}

/**
 * Exports
 * =============================================================================
 */
export default {
  getMetaImageUrl, getImageUrlByColumnSize, getMediaTag, getExtension
};
