/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Button as BBButton } from './../../building-blocks';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Button = (props) => {
  


  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <BBButton
      {...props}
    />
  );
};

export default Button;
