/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Input } from '../../building-blocks';
import { withInputFlyout } from '../../hoc';

/**
 * Definition
 * =============================================================================
 */
const InputFlyout = ({
  classes = '',
  formfieldClasses = '',
  iconAfterClasses = '',
  label,
  placeholder,
  iconAfter = '',
  onIconAfterClick,
  iconBefore = '',
  id,
  name,
  value,
  children,
  tooltip,
  readonly = false,
  setFlyoutState,
  onFocus,
  handleInputFlyoutFocus,
  handleInputFlyoutBlur,
  handleInputFlyoutMouseEnter,
  handleInputFlyoutMouseLeave,
  onChange,
  onKeyPress,
  message,
  contentStyle = {},
  reference
}) => {

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleFocus = () => {
    if (handleInputFlyoutFocus) handleInputFlyoutFocus();
    if (onFocus) onFocus();
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div
      ref={reference}
      className={`m-formfield-flyout ${classes} ${setFlyoutState()}`}
    >
      <Input
        classes={`m-formfield-flyout__trigger ${formfieldClasses}`}
        label={label}
        placeholder={placeholder}
        id={id}
        name={name}
        iconAfter={iconAfter}
        iconAfterClasses={iconAfterClasses}
        onIconAfterClick={onIconAfterClick}
        iconBefore={iconBefore}
        readonly={readonly}
        onFocus={handleFocus}
        onBlur={handleInputFlyoutBlur}
        onChange={onChange}
        onKeyDown={onKeyPress}
        tooltip={tooltip}
        autocomplete="off"
        value={value}
      />

      {(children || message) && (
        <div
          className='m-formfield-flyout__content'
          style={contentStyle}
          onMouseEnter={handleInputFlyoutMouseEnter}
          onMouseLeave={handleInputFlyoutMouseLeave}
        >
          {message && (
            <p className='m-formfield-flyout__message'>{message}</p>
          )}

          <div className='m-formfield-flyout__content-container'>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default withInputFlyout()(InputFlyout);
