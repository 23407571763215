/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';

/**
 * Definition
 * =============================================================================
 */
const OptionList = ({
  classes = '',
  items = []
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = () => {
    return items.map((i, index) => (
      <Item
        key={`option-list-item-${index}`}
        {...i}
      />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`m-option-list ${classes}`}>
      <ul className='m-option-list__items'>
        {renderItems()}
      </ul>
    </div>
  );
};

export default OptionList;
