/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const SimpleFooter = () => {
  const currentYear = new Date().getFullYear();

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <footer className='o-footer o-footer--simplified'>
      <nav className='o-footer__content l-container'>
        <span className='o-footer__copyright'>&copy;&nbsp;{currentYear}</span>
      </nav>
    </footer>
  );
};

export default SimpleFooter;
