import React, {useContext} from 'react';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import { checkConditions } from '../conditionalRendering';

// NOTE: onButtonClick is not a submit handler;
// it signals to the parent form which button invoked the submit action
// (which is important for multi-step forms where multiple submits can occur i.e. back/forward)

const Button = ({ field, onButtonClick }) => {
  const context = useContext(FormConditionsContext);

  var shouldHide = false;
  var shouldDisable = false;
  var shouldGoToPage = false;
  var goToPage = false;

  if(context?.conditions[field.model.conditionSettings.fieldKey]){
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
    shouldGoToPage = conditionDetails.shouldGoToPage;
    goToPage = conditionDetails.goToPage;
  }

  return (
    <button
      type='submit'
      className={`a-button ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}
      value={field.model.title}
      name={field.buttonField.name}
      id={field.buttonField.id}
      disabled={shouldDisable}
      onClick={() => {
        onButtonClick(field.buttonField.name)
      }}
    >
      <span className='a-button__text'>{field.model.title}</span>
    </button>
  );
}
export default Button;
