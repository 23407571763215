/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import TeamMembersList from '../../pages/TeamMembersList';

/**
 * Definition
 * =============================================================================
 */
const Connected = () => <TeamMembersList /> 

export default Connected;
