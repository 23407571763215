/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */
const RelatedArticles = (props) => {
  const { t, items } = props;

  const renderItems = () => {
    return items.map((item) => item && <Item key={item.articleId} item={item} />);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return items.length > 0 && (
    <div>
      <header className='l-section__header u-text-align-center u-margin-bottom-6x@above-viewport-9'>
        <h6 className='u-margin-bottom-3x@above-viewport-11'>
          {t('relatedArticles.title')}
        </h6>
        <h2 className='l-section__title'>
          {t('relatedArticles.sectionTitle')}
        </h2>
      </header>
      <div className='l-grid l-grid__row l-grid--with-spacing'>
        {renderItems()}
      </div>
    </div>
  );
};

export default withTranslation()(RelatedArticles);
