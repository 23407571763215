export const WildcardUrlPattern = ",-w-,";
export const Sorting = {NoSorting: "NO_SORTING", OnlineFirst: "ONLINE_FIRST", MostRelevant: "MOST_RELEVANT", FirstSession: "FIRST_SESSION", NewCourses: "NEW_COURSES", AdditionalDay: "ADDITIONAL_DAY", HighlightedAsNew: "HIGHLIGHT_NEW", HighlightedAsPopular: "HIGHLIGHT_POPULAR", HighlightedAsSpotlight: "HIGHLIGHT_SPOTLIGHT"};
export const DefaultSorting = Sorting.FirstSession;
export const FilterLoadMoreLimit = 6;
export const InputDateFormat = "DD/MM/YYYY";
export const QueryDateFormat = "YYYY-MM-DD";
export const ShowPlacesLeftWarningFrom = 2;
export const AutocompleteMinimumLength = 4;
export const SearchPageSize = 20;

export const TaxonomyThemesRoot = '/sitecore/content/cevora-app/Data/Taxonomy/Themes'
export const TaxonomySectorsRoot = '/sitecore/content/cevora-app/Data/Taxonomy/Sectors'
export const TrainingFormsRoot = '/sitecore/content/cevora-app/Data/TrainingForms'

