/**
 * Imports
 * =============================================================================
 */

import React, { useState } from 'react';
import { object } from '../../utils';
import { Button } from '../../building-blocks';
import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { withTranslation } from '../../hoc/withTranslation';
import { getIsMobile } from '../../utils/mobile';
import PredefinedArticle from './PredefinedArticle';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Events } from '../../managers';

const QUERY = gqlLoader('./query.graphql');

/**
 * Definition
 * =============================================================================
 */

const PredefinedArticles = (props) => {
  const { fields, rendering, t, lng } = props;

  const fieldValues = {
    displayAmount: object.getNested(fields, 'DisplayAmount', 'value'),
    dateFrom: object.getNested(fields, 'DateFrom', 'value'),
    dateTo: object.getNested(fields, 'DateTo', 'value'),
    author: object.getNested(fields, 'Author', 'id'),
    blogThemes: object.getNested(fields, 'BlogThemes'),
  };

  const dateFrom =
    fieldValues.dateFrom === '0001-01-01T00:00:00Z'
      ? undefined
      : fieldValues.dateFrom;
  const dateTo =
    fieldValues.dateTo === '0001-01-01T00:00:00Z'
      ? undefined
      : fieldValues.dateTo;
  const authorId = fieldValues.author || undefined;
  const blogThemeIds = (fieldValues.blogThemes || []).map((x) => x.id);
  const fetchAmount = fieldValues.displayAmount ?? 6;
  const [hideLoadMore, setHideLoadMore] = useState(false);

  const { data, loading, fetchMore } = useQuery(QUERY, {
    variables: {
      fetchAmount: fetchAmount,
      fetchCursor: 0,
      dateFrom: dateFrom,
      dateTo: dateTo,
      authorId: authorId,
      blogThemeIds: blogThemeIds,
      language: lng,
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: () => Events.emitter.emit('stop-loading'),
  });

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    if (
      fetchMoreResult &&
      fetchMoreResult.articlepage.predefinedArticles.hits.length > 0
    ) {
      fetchMoreResult.articlepage.predefinedArticles.hits = [
        ...previousResult.articlepage.predefinedArticles.hits,
        ...fetchMoreResult.articlepage.predefinedArticles.hits,
      ];

      if (
        fetchMoreResult.articlepage.predefinedArticles.hits.length >=
        fetchMoreResult.articlepage.predefinedArticles.pagination.totalPages
      ) {
        setHideLoadMore(true);
      }
      return fetchMoreResult;
    }
    setHideLoadMore(true);
    return previousResult;
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        fetchCursor: data.articlepage.predefinedArticles.hits.length,
      },
      updateQuery: updateQuery,
    });
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const noArticlesFound = isEditorActive() && (
    <div className='l-grid__col l-grid__col-12-12'>
      Predefined Articles component: No articles were found
    </div>
  );

  const renderPredefinedArticles = () => {
    return data.articlepage.predefinedArticles.hits.map((predefinedArticle) => {
      return (
        <div className='l-grid__col l-grid__col-6-12'>
          <PredefinedArticle
            key={predefinedArticle.articleId}
            predefinedArticle={predefinedArticle}
          />
        </div>
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  if (loading) {
    Events.emitter.emit('start-loading');
  }

  return (
    rendering && (
      <div className='l-grid l-grid__row'>
        {data &&
        data.articlepage.predefinedArticles.hits &&
        data.articlepage.predefinedArticles.hits.length > 0
          ? renderPredefinedArticles()
          : noArticlesFound}

        {data &&
          data.articlepage.predefinedArticles.hits &&
          !hideLoadMore &&
          data.articlepage.predefinedArticles.hits.length >= fetchAmount && (
            <div
              className='l-grid__col l-grid__col-12-12'
              style={{ textAlign: `center`, width: '100%' }}
            >
              <Button
                type='clickButton'
                classes='a-button--secondary a-button--outline m-search-results__load-more'
                fields={{
                  ButtonText: t('blog.predefinedArticles.loadMore'),
                }}
                onClick={() => handleLoadMore()}
              />
            </div>
          )}
      </div>
    )
  );
};

export default withTranslation()(PredefinedArticles);
