import { Events } from './../managers';
import env from './../env';
import { redirectToCheckout, calculatePersona } from '../lib/Gtm';
class CourseBasketProvider {

  constructor(graphQLClient, auth0){
    this.graphQLClient = graphQLClient;
    this.auth0 = auth0;
  }

  registerToChanges(callback) {
    // You can call callback functions if you socket.io/pusher or something like it.
  }

  items = [];

  CHECKOUT_INTERVAL = 1000;
  MAX_CHECKOUT_COUNT = 40;

  getInitialData() {
    Events.emitter.emit('start-loading');
    return new Promise((resolve, reject) => {
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/BasketApi/GET?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(res => res.json())
        .then(data => {
          this.items = [];
          if (data.lineItems) {
            Events.emitter.emit('basket-inited');
            this.items = data.lineItems;
            resolve(this.items);
          } else {
            if (data && data.message)
              console.log('Failed to initialize basket:', data.message);
            Events.emitter.emit(
              'modal-alert-open',
              'alert.basket.initialize.content'
            );
          }

          Events.emitter.emit('stop-loading');
        })
        .catch(error => {
          reject(error);
          this.items = [];
          console.log('Failed to initialize basket:', error);

          Events.emitter.emit('stop-loading');
        });
    });
  }
  getPersonaData() {
    Events.emitter.emit('start-loading');
    return new Promise((resolve, reject) => {
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/PersonaApi/GET?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(response => {
          if (!response.ok) {
            console.log('Failed to initialize persona:', response.statusText);
          }
          return response;
        })
        .then(res => res.json())
        .then(data => {
          Events.emitter.emit('persona-inited');
          this.persona = null;
          if (data && data.personaType) {
            this.persona = data.personaType;
          }
          resolve(this.persona);
          Events.emitter.emit('stop-loading');
        })
        .catch(error => {
          reject(error);
          this.persona = null;
          console.log('Failed to initialize persona:', error);

          Events.emitter.emit('stop-loading');
        });
    });
  }

  onCheckOut(courseType) {
    Events.emitter.emit('start-loading', true);
    fetch(
      `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/CheckoutApi/Start?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ checkoutType: courseType })
      }
    )
      .then(async data => {
        const processHasStarted = await data.json();
        if (data.ok && processHasStarted) {
          var me = this;
          this.checkoutCount = 0;
          this.checkoutIntervalId = setTimeout(() => {
            me.getCheckoutStatus();
          }, this.CHECKOUT_INTERVAL);
        } else {
          console.log('Failed checking out: ', data);
          Events.emitter.emit(
            'modal-alert-open',
            'alert.basket.checkOut.content'
          );
          Events.emitter.emit('stop-loading');
        }
      })
      .catch(error => {
        console.log('Failed checking out: ', error);
        Events.emitter.emit(
          'modal-alert-open',
          'alert.basket.checkOut.content'
        );
        Events.emitter.emit('stop-loading');
      });
  }

  getCheckoutStatus() {
    if (this.checkoutCount > this.MAX_CHECKOUT_COUNT) {
      Events.emitter.emit(
        'modal-alert-open',
        'alert.basket.checkOutStatus.content'
      );
      Events.emitter.emit('stop-loading');
      return;
    }

    this.checkoutCount++;
    fetch(
      `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/CheckoutApi/Status?sc_apikey=${env.REACT_APP_JSS_API_KEY}`
    )
      .then(this.handleErrors)
      .then(res => res.json())
      .then(data => {
        if (data && data.hasFailed) {
          console.log('Failed checking status: ', data);
          Events.emitter.emit(
            'modal-alert-open',
            'alert.basket.checkOutStatus.content'
          );
          Events.emitter.emit('stop-loading');
        } else {
          if (data && data.hasCompleted) {
            redirectToCheckout(data.checkoutUrl, this.graphQLClient, this.items, calculatePersona(this.auth0, this));
            //Events.emitter.emit("basket-go-to-checkout",data.checkoutUrl);
          } else {
            var me = this;
            this.checkoutIntervalId = setTimeout(() => {
              me.getCheckoutStatus();
            }, this.CHECKOUT_INTERVAL);
          }
        }
      })
      .catch(error => {
        console.log('Failed checking status: ', error);
        Events.emitter.emit(
          'modal-alert-open',
          'alert.basket.checkOutStatus.content'
        );
        Events.emitter.emit('stop-loading');
        //reject(error);
      });
  }

  onAllItemsDeleted() {
    Events.emitter.emit('start-loading');
    return new Promise((resolve, reject) => {
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/BasketApi/Clear?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'DELETE'
        }
      ).then(data => {
        Events.emitter.emit('stop-loading');
        resolve();
      });
    });
  }

  onPersonaSet(persona, disableLoadingSpinner) {
    this.persona = persona;
    if(!disableLoadingSpinner) {
      Events.emitter.emit('start-loading');
    }
    return new Promise((resolve, reject) => {
      //add to field
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/PersonaApi/Post?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ personaType: persona, force: true })
        }
      )
        .then(res => res.json())
        .then(data => {
          if(!disableLoadingSpinner) {
            Events.emitter.emit('stop-loading');
          }
          if (data && data.personaType) {
            this.persona = data.personaType;
            Events.emitter.emit('persona-set', this.persona);
            resolve(this.persona);
          } else {
            if (data && data.message)
              console.log('Failed setting persona', data.message);
            else console.log('Failed setting persona');
            Events.emitter.emit(
              'modal-alert-open',
              'alert.basket.setPersona.content'
            );
            reject(data);
          }
        });
    });
  }

  onItemAdded(item) {
    Events.emitter.emit('start-loading');
    return new Promise((resolve, reject) => {
      //add to field
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/BasketApi/Post?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(item)
        }
      )
        .then(res => res.json())
        .then(data => {
          if (data.lineItems) {
            this.items = data.lineItems;
            Events.emitter.emit('basket-item-added', item);
            resolve(this.items);
          } else {
            if (data && data.message)
              console.log('Failed adding item', data.message);
            else console.log('Failed adding item');

            Events.emitter.emit(
              'modal-alert-open',
              'alert.basket.addItem.content'
            );
          }
          Events.emitter.emit('stop-loading');
        });
    });
  }

  onItemDeleted(lineItemId) {
    Events.emitter.emit('start-loading');

    return new Promise((resolve, reject) => {
      fetch(
        `${env.REACT_APP_JSS_API_HOST}/api/cevora/session/write/BasketApi/Delete?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ lineItemId: lineItemId })
        }
      )
        .then(this.handleErrors)
        .then(res => res.json())
        .then(data => {
          if (data.lineItems) {
            this.items = data.lineItems;
            Events.emitter.emit('basket-item-removed', this.items);
            resolve(this.items);
          } else {
            if (data && data.message)
              console.log('Failed removing item', data.message);
            else console.log('Failed removing item');

            Events.emitter.emit(
              'modal-alert-open',
              'alert.basket.removedItem.content'
            );
          }
          Events.emitter.emit('stop-loading');
        })
        .catch(error => {
          console.log('Failed removing item', error);

          Events.emitter.emit(
            'modal-alert-open',
            'alert.basket.removedItem.content'
          );
          Events.emitter.emit('stop-loading');
          reject(error);
        });
    });
  }
}

export default CourseBasketProvider;
