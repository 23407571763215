/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';

/**
 * Definition
 * =============================================================================
 */

class Breadcrumbs  extends React.Component {

  constructor(props) {
    super(props);

    const { data = [] } = props;

    this.state = {
      data,
    };
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderItems = () => {
    const { data } = this.state;
    return data.map((item, index) => {
      return (
        <Item
          key={`breadcrumb-item-${index}`}
          text={item.text}
          url={item.url}
          isLast={index === data.length - 1}
        />
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render = () => {
    const {
     classes
    } = this.props;

    const { data } = this.state;
  
  return data && data.length > 0
    ? (
      <nav className={`m-breadcrumbs ${classes}`}>
        <ul className='m-breadcrumbs__list'>

          {this.renderItems()}

        </ul>
      </nav>
    )
    : null;
  }
};

export default Breadcrumbs;
