/**
 * Imports
 * =============================================================================
 */
import React from "react";
import Header from "./Header";

/**
 * Definition
 * =============================================================================
 */
const Disconnected = props => {
  const mapMenuItem = item => ({
    title: item.fields.find(x => x.name === "Title"),
    link: item.fields.find(x => x.name === "Page"),
    children: item.children && item.children.map(mapMenuItem)
  });

  props.isAuthenticated = false;
  props.loginWithRedirect = () => {};

  const currentLanguage = "EN";
  const allLanguages = [
    {
      language: "FR",
      url: "/fr/"
    },
    {
      language: "NL",
      url: "/nl/"
    }
  ];

  const menu = props.fields.HeaderRoot.value.children.map(mapMenuItem);
  const language = {
    languages: allLanguages,
    current: currentLanguage
  };

  const basket = [{ text: "History of magic" }, { text: "Transfiguration" }];

  return <Header {...props} menu={menu} language={language} basket={basket} />;
};

export default Disconnected;
