/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Root from './Root';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import { object, classes } from './../../utils';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';

/**
 * Definition
 * =============================================================================
 */
const Section = (props) => {
  if (props.fields == undefined) return '';
  const {
    SectionTitle,
    SectionMainContent,
    SectionBackground,
    SectionElevated,
    SectionCustomBackground,
    SectionBackgroundCoverage,
    SectionTopSpacing,
    SectionBottomSpacing,
    SectionHeaderSpacing,
    SectionContentSpacing,
    SectionFooterSpacing,
    SectionHeaderAlignment,
  } = props.fields;
  const backgroundType = object.getNested(SectionBackground, 'fields', 'Value', 'value');
  const hasExperienceEditorEmptyBackground = (isEditorActive() && object.getNested(SectionCustomBackground, 'value', 'class') == 'scEmptyImage');

  const getBackgroundClasses = () => {

    if (object.hasNested(SectionCustomBackground, 'value', 'src') && !hasExperienceEditorEmptyBackground) {
      return 'l-background l-background--with-image';
    }

    const backgroundType = object.getNested(SectionBackground, 'fields', 'Value', 'value');

    if (!backgroundType) return '';

    const modifier = classes.compare(backgroundType, {
      'dots': 'l-background--dots',
      'lines': 'l-background--lines',
      'dots-faded': 'l-background--dots l-background--fades',
      'lines-faded': 'l-background--lines l-background--fades',
      'orange': 'l-background--orange',
      'purple': 'l-background--purple',
      'grey': 'l-background--grey',
      'lightblue': 'l-background--light-blue',
      'lightorange': 'l-background--light-orange',
      'lightorange-faded-dots': 'l-background--light-orange l-background--dots l-background--fades',
      'illustrative': 'l-background--illustrative',
      'illustrative-grey': 'l-background--illustrative-grey',
      'illustrative-bottom': 'l-background--illustrative-bottom',
    });

    return `l-background ${modifier}`;
  };
  const getSectionClasses = () => {

    const elevated = object.getNested(SectionElevated, 'value');
    if (!elevated) return '';
    return ' l-container@above-tablet-base l-box@above-tablet-base l-full-height__content';
  }
  const getSectionContainerClass = () => {

    const elevated = object.getNested(SectionElevated, 'value');
    if (!elevated) return '';
    return 'l-section__container--elevated';
  }

  const coverage = object.getNested(SectionBackgroundCoverage, 'fields', 'Value', 'value') || "root";
  const backgroundImage = hasExperienceEditorEmptyBackground || (!object.hasNested(SectionCustomBackground, 'value', 'src')) ?
    null : object.getNested(SectionCustomBackground, 'value', 'src');

  const backgroundImageStyle = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})` }
    : {};
  const hasBackgroudImage = object.hasNested(SectionCustomBackground, 'value', 'src') && !hasExperienceEditorEmptyBackground;
  let backgroundClasses = getBackgroundClasses();
  /*
      backgroundClasses = !backgroundClasses && backgroundImage
      ? 'l-background--dots'
      : backgroundClasses;
  */
  let sectionClasses = getSectionClasses();

  backgroundClasses = backgroundClasses + sectionClasses;
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Root
      isSection={object.hasNested(SectionTitle, 'value')}
      topSpacing={SectionTopSpacing}
      bottomSpacing={SectionBottomSpacing}
      backgroundImage={coverage === "root" ? backgroundImage : null}
      backgroundType={coverage === "root" ? backgroundType : null}
    >
      <div className={`l-section__container l-container ${getSectionContainerClass()}`}>
        {(object.getNested(props, 'rendering', 'placeholders', 'section-header').length != 0 &&
          <Header
            classes={(coverage === "header" && hasBackgroudImage) ? 'l-section__header--with-image' : ''}
            title={SectionTitle}
            props={props}
            bottomSpacing={SectionHeaderSpacing}
            alignment={SectionHeaderAlignment}
            backgroundImage={coverage === "header" ? backgroundImage : null}
            backgroundType={coverage === "header" ? backgroundType : null}

          />)}

        <Content
          classes={(coverage === "content" && hasBackgroudImage) ? 'l-section__content--with-image' : ''}
          content={SectionMainContent}
          props={props}
          itemSpacing={SectionContentSpacing}
          backgroundImage={coverage === "content" ? backgroundImage : null}
          backgroundType={coverage === "content" ? backgroundType : null}
        />

        {(object.getNested(props, 'rendering', 'placeholders', 'section-footer').length != 0 && <Footer
          classes={(coverage === "footer" && hasBackgroudImage) ? 'l-section__footer--with-image' : ''}
          backgroundImage={coverage === "footer" ? backgroundImage : null}
          backgroundType={coverage === "footer" ? backgroundType : null}
          props={props}
          topSpacing={SectionFooterSpacing}
        />)}
      </div>
    </Root>
  );
};

export default Section;
