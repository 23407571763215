import React, { useContext } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import FieldLabel from '../Helpers/FieldLabel';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const RadioButtonList = (props) => {
  const {
    field,
    field: {
      model: { items, cssClass },
    },
    value,
    onChange,
    tracker,
    errors,
    t,
  } = props;
  const selectedValue = typeof value !== 'string' ? value[0] : value;

  var shouldHide = false;
  var shouldDisable = false;
  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const isDependent = field.model.dependentField;
  const dependsOnValue = dependentFieldsContext.fieldValues[field.model.dependentField];
  var radioButtonItems = items;

  const { dependentItems } = field.model;
  if (isDependent && dependentItems && dependsOnValue) {
    Object.entries(dependentItems).forEach(entry => {
      const [key, value] = entry;
      if(key.toLowerCase() === dependsOnValue.toLowerCase()){
        radioButtonItems = value;
      }
    });
  }

  const updateContextsFieldsValues = (e) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, e.target.value);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, e.target.value);
    }
    handleOnChange(field, e.target.value, onChange, t)
  }

  const shouldRender = (isDependent && items !== radioButtonItems) || (!isDependent);

  if(!shouldRender){
    console.log(`${field.model.name} is not shown. The dependent list is empty, because there are no corresponding values.`)
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const renderItems = (itemsArray) => {
    return (
      <div className='a-formfield__radioButtonList--wrapper'>
        {itemsArray && itemsArray.map((item, index) => {
          const itemId = field.valueField.id + index;
          return (
            <div className='a-toggle' key={itemId}>
              <label className='a-toggle__container' htmlFor={itemId}>
                <div className='a-toggle__wrapper'>
                  <input
                    className='a-toggle__input'
                    type='radio'
                    id={itemId}
                    name={field.valueField.name}
                    value={item.value}
                    checked={selectedValue === item.value}
                    disabled={shouldDisable}
                    onChange={(e) =>
                      updateContextsFieldsValues(e)
                    }
                    onFocus={() => tracker.onFocusField(field, value)}
                    onBlur={() => tracker.onBlurField(field, value, errors)}
                  />
                  <span className='a-toggle__icon a-toggle__icon--off icon-radio-hollow'></span>
                  <span className='a-toggle__icon a-toggle__icon--on icon-radio-filled'></span>
                </div>
                <span className='a-toggle__label '>{item.text}</span>
              </label>
            </div>
          );
        })}
      </div>);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    shouldRender &&
    <div className={`a-formfield__radioButtonList ${cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      {isDependent && renderItems(radioButtonItems)}
      {!isDependent && renderItems(items)}
      <FieldValidationErrors {...props} />
    </div>
  );
};

export default withTranslation()(RadioButtonList);
