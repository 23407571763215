/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive, Image as RImage, Link } from '@sitecore-jss/sitecore-jss-react';
import { Button } from '../../building-blocks';
import { object } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const WhitePaper = props => {
    const {
        Image,
        ImageAltText,
        Intro,
        MainTitle,
        IsLarge,
        ButtonText,
        ButtonUrl,
        ButtonIconBefore,
        ButtonIconAfter
    } = props.fields;
  
    const isLarge = IsLarge.value;
    const iconBeforeClass = object.getNested(ButtonIconBefore, 'fields', 'Value', 'value')
    const iconAfterClass = object.getNested(ButtonIconAfter, 'fields', 'Value', 'value')

    /**
     * Helpers
     * ---------------------------------------------------------------------------
     */

    const getWhitePaperArticleClass = () => {
      let className = 'm-whitepaper ';
      if (isLarge) {
        className += 'm-whitepaper--large ';
      }
      return className;
    };

    /**
     * Renders
     * ---------------------------------------------------------------------------
     */

    const renderCta = () => {
      return (
          <Button
              classes='a-button m-whitepaper__button a-button--animated '
              fields={{
                ButtonText: ButtonText,
                ButtonUrl: ButtonUrl,
                ButtonIconBefore: iconBeforeClass,
                ButtonIconAfter: iconAfterClass
              }}
            />
       )
    }

    /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    return (
      <article className={getWhitePaperArticleClass()}>
        <div className="m-whitepaper__image-container">
          <RImage class="m-whitepaper__image" field={Image} alt={ImageAltText.value} title={ImageAltText.value} />
        </div>
        <div className="m-whitepaper__content">
          <h3 className="m-whitepaper__title"><Text field={Intro}/></h3>
          <p className="m-whitepaper__description"><Text field={MainTitle}/></p>
          {((ButtonText && ButtonUrl) || isEditorActive()) && renderCta()}
        </div>
      </article>
    );
};

export default WhitePaper;
