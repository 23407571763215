import React, { useEffect, useState, useContext } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import FieldLabel from '../Helpers/FieldLabel';
import { Input } from '../../../building-blocks';
import ChoiceList from '../../ChoiceList';
import { checkConditions } from '../conditionalRendering'; 
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const DropdownList = (props) => {
  const {
    field,
    field: {
      model: { items, cssClass },
    },
    value,
    onChange,
    tracker,
    errors,
    t,
  } = props;

  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  var shouldHide = false;
  var shouldDisable = false;

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const updateContextFieldValue = (fieldValue) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, fieldValue);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, fieldValue);
    }
  }

  const choices = items.map((item) => ({
    id: item.itemId,
    text: item.text,
    value: item.value,
  }));

  if (field.model.showEmptyItem) {
    choices.unshift({
      id: ' ',
      text: ' ',
      value: ' ',
    });
  }

  const selectedValue = typeof value !== 'string' ? value[0] : value;
  const selectedText = (items.find((x) => x.value === selectedValue) || { text: ' ' })
    .text;

  const [text, setText] = useState(selectedText);
  useEffect(() => {
    setText(selectedText);
  }, [value]);

  const handleChange = (name, value, text) => {
    setText(text);
    handleOnChange(field, value, onChange, t);
    updateContextFieldValue(value);
  };

  return (
    <div className={`a-formfield ${cssClass} ${shouldHide ? "u-hide" : ""} `}>
      <FieldLabel field={field} />
      <Input
        classes='a-formfield--choice'
        iconAfter='icon-arrow-down'
        id={field.valueField.id}
        name={field.valueField.name}
        value={text}
        readonly={true}
        disabled={shouldDisable}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      >
        <ChoiceList
          name={field.valueField.name}
          onChange={handleChange}
          items={choices}
        />
      </Input>
      <FieldValidationErrors {...props} />
    </div>
  );
};

export default withTranslation()(DropdownList);
