/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Feedback = ({ text }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='a-formfield__feedback'>
      <p className='a-formfield__message'>
        {text}
      </p>
    </div>
  );
};

export default Feedback;
