import * as React from 'react';
import BasketContext from '../contexts/BasketContext';

const withBasketData = () => (WrappedComponent) => {
  return class extends React.Component {

   
    render() {
      return (
        <BasketContext.Consumer>
        {(basketData) => (
          <WrappedComponent {...this.props} basketData={basketData} />
        )}
      </BasketContext.Consumer>
      );
    }
  }
}


export default withBasketData; 