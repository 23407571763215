/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Supertitle = ({
  classes = '',
  text,
  children,
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`m-highlighted-session ${classes}`}>
      <p className='m-highlighted-session__description'>{text}</p>

      <div className='m-highlighted-session__content'>
        {children}
      </div>
    </div>
  );
};

export default Supertitle;
