/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withCollapse } from './../../hoc';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Checkbox } from './../../building-blocks';
import { Events } from './../../managers';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
const Collapse = ({
  title,
  content,
  setCollapseState,
  handleToggleCollapse,
  isActive,
  id,
  name,
  hasToggle = false,
  onClickCollapse,
  hasApproved
}) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const setActiveState = () => {
    return isActive ? 'is-active' : '';
  };

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClickCollapse = () => {
    handleToggleCollapse();

    if (onClickCollapse) onClickCollapse(id);
  };

  const handleToggleCheckbox = (isChecked) => {
    if (isChecked) {
      Events.emitter.emit('cookie-settings-enable', { name });
    } else {
      Events.emitter.emit('cookie-settings-disable', { name });
    }
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderCheckbox = () => {
    return (
      <Checkbox
        classes='a-toggle--fit-space a-toggle--inverse-order'
        name={name}
        id={name}
        label={title}
        labelClasses='a-toggle__label--bold'
        isChecked={hasApproved}
        onChange={handleToggleCheckbox}
      />
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`o-cookie-settings__item ${setActiveState()} ${setCollapseState()}`}>
      <div className='o-cookie-settings__header u-tablet-show'>
        <h5 className='o-cookie-settings__title'>{title}</h5>

        <button className='o-cookie-settings__toggle' onClick={handleClickCollapse}></button>
      </div>

      <div className='o-cookie-settings__content'>
        <div className='o-cookie-settings__content-wrapper'>
          <div className='o-cookie-settings__content-header'>
            {hasToggle
              ? renderCheckbox()
              : <span className='o-cookie-settings__content-title'>{title}</span>
            }
          </div>
          {(content || isEditorActive()) && (
            <RichText className='s-jss-rte-cookie-settings' field={content} />
          )}
        </div>
      </div>
    </div>
  );
};

export default withCollapse()(Collapse);
