/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */
const RelatedRecentArticles = (props) => {
  const { t, items, theme } = props;

  const renderItems = () => {
    return (
      items && items.map((item) => item && <Item key={item.articleId} item={item} />)
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return items.length > 0 && (
    <aside class='l-section'>
      <h6 class='u-margin-bottom-2x'>
        {t('relatedRecentArticles.title', { theme })}
      </h6>

      <div>{renderItems()}</div>
    </aside>
  );
};

export default withTranslation()(RelatedRecentArticles);
