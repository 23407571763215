import * as React from 'react';
import GraphQLClientContext from '../contexts/GraphQLClientContext';

const withGraphQLClient = (WrappedComponent) => (props) => {
      return (
        <GraphQLClientContext.Consumer>
        {(graphQLClient) => (
          <WrappedComponent {...props} graphQLClient={graphQLClient} />
        )}
      </GraphQLClientContext.Consumer>
      );
}


export default withGraphQLClient; 