/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import { withFlyout } from './../../hoc';

/**
 * Definition
 * =============================================================================
 */
const Languages = ({
  classes = '',
  active,
  items,
  setFlyoutState,
  handleToggleFlyout,
  handleOpenFlyout,
  handleCloseFlyout,
  isMobile,
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = () => {
    return items.map((l, index) => {
      return (
        <Item
          key={`languages-item-${index}`}
          text={l.language}
          url={l.url}
        />
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div
      className={`m-language ${classes} ${setFlyoutState()}`}
      onMouseEnter={() => !isMobile && items.length != 0 && handleOpenFlyout()}
      onMouseLeave={() => !isMobile && items.length != 0 && handleCloseFlyout()}
    >
      {(items.length != 0) && (
        <button
          className={`m-language__active`}
          onClick={handleToggleFlyout}
        >
          {active}
        </button>
      )} {(items.length == 0) && (
        <div>
          {active}</div>
      )}
      {(items.length != 0) && (
        <ul className='m-language__list'>
          {renderItems()}
        </ul>)}
    </div>
  );
};

export default withFlyout()(Languages);
