/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Link } from './../../building-blocks';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
const CardSecondaryItem = (props) => {
    const {
        ItemTitle,
        ItemDescription,
        LinkText,
        LinkUrl,
        RawLinkUrl
    } = props.fields;

    /**
     * Renders
     * ---------------------------------------------------------------------------
     */
    const renderTitle = () => {
        return (
            <h5 className="a-icon-label__title">
                <Text field={ItemTitle} />
            </h5>
        );
    };

    const renderLink = () => {
        return (
            <Link
                classes='a-icon-label__emphasis'
                {...props}
            />
        );
    };

    /**
     * Main render
     * ---------------------------------------------------------------------------
     */
    return (
            <div className="a-icon-label ">
                <span className="a-icon-label__icon icon-alert"></span>
                <div className="a-icon-label__content">
                    {((ItemTitle && (ItemTitle.value.length > 0)) || isEditorActive()) && renderTitle()}
                    <div className="a-icon-label__footer">
                        <div className="a-icon-label__description">
                            <RichText className='s-jss-rte' field={ItemDescription} />
                        </div>
                        {(isEditorActive() || (LinkText && (LinkText.value.length > 0) && (LinkUrl || RawLinkUrl))) && renderLink()}
                    </div>
                </div>
            </div>
    );
};

export default CardSecondaryItem;
