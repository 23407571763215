/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Group from './Group';
import withMore from './withMore';

/**
 * Definition
 * =============================================================================
 */
class ToggleGroup extends React.Component {
  constructor(props) {
    super(props);

    const { data = [] } = props;

    this.state = {
      data: data
    };
  }

  componentWillReceiveProps(nextProps) {
    const mapping = x => ({
      id: x.id,
      text: x.text,
      itemId: x.itemId,
      hint: x.hint,
      amount: x.amount,
      children: x.children ? x.children.map(mapping) : []
    });
    const currentProps = this.props.data.map(mapping);
    const futureProps = nextProps.data.map(mapping);
    if (Array.isArray(currentProps)) {
      currentProps.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
    }
    if (Array.isArray(futureProps)) {
      futureProps.sort((a, b) => (a.id < b.id ? -1 : a.id > b.id ? 1 : 0));
    }
    if (JSON.stringify(currentProps) !== JSON.stringify(futureProps)) {
      this.setState({
        data: JSON.parse(JSON.stringify(nextProps.data))
      });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setActive(data, { id, isChecked }) {
    const { uncheckParent = false } = this.props;
    for (let i = 0; i < data.length; i++) {
      const targetId = data[i].id;
      data[i].children = data[i].children || [];

      if (data[i].children.length > 0) {
        this.setActive(data[i].children, {
          id,
          isChecked
        });
      }

      if(uncheckParent && 
        data[i].children.find(x => x.isChecked && x.id === id) &&
        data[i].isChecked
        ) 
      {
        data[i].isChecked = false;
      }

      if (targetId === id) {
        data[i].isChecked = isChecked;
      }

      data[i].isChecked = data[i].isChecked || false;
    }

    return data;
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChangeCheckbox = (id, isChecked) => {
    const { onChange } = this.props;

    this.setState(
      prevState => ({
        data: this.setActive(prevState.data, { id, isChecked })
      }),
      () => {
        if (onChange) {
          onChange(this.state.data);
        }
      }
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes = '', limitedData } = this.props;

    return (
      <Group
        classes={classes}
        items={limitedData}
        onChange={this.handleChangeCheckbox}
      />
    );
  }
}

export default withMore(ToggleGroup);
 