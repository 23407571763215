/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, Link as JSSLink } from '@sitecore-jss/sitecore-jss-react';
import ReactLink from '../../building-blocks/SimpleLink';
import { type } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ title, link }) => {
  const linkClassName = 'o-nav__sub-link';

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderLink = () => {
    return type.isString(link) ? (
      <ReactLink
        className={linkClassName}
        to={link || '/'}
      >
        {title}
      </ReactLink>
    ) : (
      <JSSLink className={linkClassName} field={link}>
        <Text field={title} />
      </JSSLink>
    )
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='o-nav__sub-item'>
      {renderLink()}
    </li>
  );
};

export default Item;
