/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formfield from './../Formfield';
import CharacterLimit from './CharacterLimit';

/**
 * Definition
 * =============================================================================
 */
class Textarea extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      characterAmount: (props.value && props.value.length) || 0
    };
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChange = (e) => {
    const { value } = e.target;

    this.setState({
      value: value,
      characterAmount: value.length
    });
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderCharacterLimit() {
    const { characterLimit } = this.props;
    const { characterAmount } = this.state;

    return (
      <CharacterLimit
        limit={characterLimit}
        amount={characterAmount}
      />
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      label,
      name,
      id,
      placeholder,
      icon,
      cols,
      rows,
      characterLimit
    } = this.props;

    const { value } = this.state;

    return (
      <Formfield
        classes='a-formfield--textarea'
        label={label}
        name={id}
      >
        <div className='a-formfield__wrapper'>
          <textarea
            className='a-formfield__textarea'
            cols={cols || 42}
            rows={rows || 5}
            name={name}
            id={id}
            placeholder={placeholder}
            onChange={this.handleChange}
            defaultValue={value}
          />

          {icon && (
            <span className={`a-formfield__icon-after ${icon}`}></span>
          )}
        </div>

        {characterLimit && this.renderCharacterLimit()}
      </Formfield>
    );
  }
}

export default Textarea;
