/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Icon = ({ classes, name }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <span className={`a-attribute__icon ${classes} ${name}`}></span>
  );
};

export default Icon;
