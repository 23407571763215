/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Item = (props) => {
  const {
    children,
    column,
    extraClasses=''
  } = props;

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
   const getColumnClasses = () => {
    const col = object.getNested(column, 'fields', 'Value', 'value');

    if (!col) return 'l-grid__col-12-12';

    return classes.compare(col, {
      'col-3': 'l-grid__col-3-12',
      'col-4': 'l-grid__col-4-12',
      'col-6': 'l-grid__col-6-12'
    });
  };

  const getClasses = () => {
    const col = getColumnClasses();

    return `${col}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className={`o-card-group__item ${getClasses()} ${extraClasses}`}>
      {children}
    </li>
  )
};

export default Item;
