import React, { useState } from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { withTranslation } from '../../hoc/withTranslation';

import { object } from '../../utils';
import { Link, Button, Loader } from '../../building-blocks';
import Image from '../../components/Image';
const ITEMS_PER_LOAD = 24;

const Query = gqlLoader('./query.graphql');

const TeamMembersList = props => {
  const { t, lng } = props;
  const [teamMembers, setTeamMembers] = useState([]);
  const [memberIndex, setMemberIndex] = useState(ITEMS_PER_LOAD);
  const [language, setLanguage] = useState(lng);

  const query = useQuery(Query, {
    variables: { path: '/sitecore/content/cevora-app/Data/TeamMembers' },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => 
      data && data.item && setTeamMembers([...data.item.children].sort(() => Math.random() - 0.5))
  });

  const { data, error, loading, refetch } = query;

  if(lng != language && refetch){
    refetch();
    setTeamMembers([]);
    setLanguage(lng);
  }

  const handleMoreClick = () => {
    setMemberIndex(memberIndex + ITEMS_PER_LOAD);
  };

  if (error) console.log('Error while searching: ' + error);

  return (
    <div className='o-team-member-card-group'>
      {query.loading && (
        <div className='o-team-member-card-group__loader'>
          <Loader />
        </div>
      )}
      {!query.loading && data.item && (
        <RenderResults
          members={teamMembers}
          t={props.t}
          memberIndex={memberIndex}
        />
      )}
      {object.hasNested(data, 'item', 'children') &&
        memberIndex < data.item.children.length && (
          <div className='o-team-member-card-group__action'>
            <Button
              type='button'
              classes='a-button--outline'
              onClick={handleMoreClick}
              fields={{ ButtonText: t('teamMembers.showMore') }}
            />
          </div>
        )}
    </div>
  );
};
const renderMembers = (members, t, memberIndex) => {
  return members.map((member, index) => {
    return renderMember(index, member, t, memberIndex);
  });
};

const renderMember = (index, member, t, memberIndex) => {
  const hiddenClass = index < memberIndex ? '' : 'm-team-member-card--hidden';
  return (
    <li
      key={`member` + index}
      className={`m-team-member-card o-team-member-card-group__item l-grid__col-3-12 ${hiddenClass}`}
    >
      <div className='m-team-member-card__picture a-image'>
        <Image
          classes='a-image__visual m-team-member-card__visual m-team-member-card__visual--is-normal'
          ImageUrl={member.picture.jss.value.src}
          EstimatedSize='3'
          Title={`${member.firstName.value} ${member.lastName.value}`}
        />
        <Image
          classes='a-image__visual m-team-member-card__visual m-team-member-card__visual--is-hover'
          ImageUrl={member.pictureOnHover.jss.value.src}
          EstimatedSize='3'
          Title={`${member.firstName.value} ${member.lastName.value}`}
        />
      </div>
      <div className='m-team-member-card__title'>
        <h5>
          {member.firstName.value} {member.lastName.value}
        </h5>
      </div>
      {object.hasNested(member, 'jobTitle', 'value') &&
        member.jobTitle.value != '' && (
          <div className='m-team-member-card__function'>
            {member.jobTitle.value}
          </div>
        )}
      {object.hasNested(member, 'department', 'targetItem', 'title') && (
        <div className='m-team-member-card__department'>
          {member.department.targetItem.title.value}
        </div>
      )}
      {object.hasNested(member, 'linkedIn', 'value') &&
        member.linkedIn.value != '' && (
          <div className='m-team-member-card__action'>
            <Link
              classes='a-link--decorated'
              fields={{
                LinkText: t('teamMembers.viewProfile'),
                LinkUrl: member.linkedIn.value
              }}
            />
          </div>
        )}
    </li>
  );
};
const RenderResults = ({ members, t, memberIndex }) => {
  return (
    <ul className='o-team-member-card-group__list l-grid l-grid--with-margins'>
      {renderMembers(members, t, memberIndex)}
    </ul>
  );
};
export default withTranslation()(TeamMembersList);
