/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ isLast, text, url }) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderActive = () => {
    return (
      <React.Fragment>
        <Link className='m-breadcrumbs__active u-desktop-show' to={`${url}`}>
          {text}
        </Link>

        <Link className='m-breadcrumbs__active u-desktop-hide' to={`${url || '/'}`}>
          {text}
        </Link>
      </React.Fragment>
    );
  };

  const renderDefault = () => {
    return (
      <Link className='m-breadcrumbs__link' to={`${url || '/'}`}>
        {text}
      </Link>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className={`m-breadcrumbs__item ${!isLast ? 'u-desktop-show' : ''}`}>
      {isLast ? renderActive() : renderDefault()}
    </li>
  );
};

export default Item;
