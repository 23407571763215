/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import CookieBar from './../CookieBar';
import CookieSettings from './../CookieSettings';
import Modal from './../Modal';
import { withTranslation } from '../../hoc/withTranslation';
import { object } from '../../utils';
import ActionGroup from './../ActionGroup';
import { Button, Link } from '../../building-blocks';
import linkParsers from './parsers/Link';
import { Events } from './../../managers';
import { withCookies } from 'react-cookie';
import ModalTrigger from './../Modal/Trigger';

/**
 * Definition
 * =============================================================================
 */
class Cookies extends React.Component {

  constructor(props) {
    super(props);

    const { fields, cookies, t } = props;

    this.cookiesData = cookies.get('cevora-cookies') || {};
    const hasCookies = this.cookiesData && Object.keys(this.cookiesData).length > 0;

    this.initialRender = true;
    this.hasAcceptedOnLoad = hasCookies;

    this.state = {
      hasAccepted: this.hasAccepted,
      settings: {
        privacy: {
          title: t('cookieSettings.privacy'),
          content: object.getNested(fields, 'CookiesSettingsPrivacyText'),
          hasApproved: hasCookies ? this.cookiesData.privacy : true,
        },
        strict: {
          title: t('cookieSettings.strict'),
          content: object.getNested(fields, 'CookiesSettingsStrictText'),
          hasApproved: hasCookies ? this.cookiesData.strict : true,
        },
        analytical: {
          title: t('cookieSettings.analytical'),
          content: object.getNested(fields, 'CookiesSettingsAnalyticalText'),
          hasApproved: hasCookies ? this.cookiesData.analytical : true,
          hasToggle: true
        },
        functional: {
          title: t('cookieSettings.functional'),
          content: object.getNested(fields, 'CookiesSettingsFunctionalText'),
          hasApproved: hasCookies ? this.cookiesData.functional : true,
          hasToggle: true
        },
        advertisement: {
          title: t('cookieSettings.advertisement'),
          content: object.getNested(fields, 'CookiesSettingsAdvertisementText'),
          hasApproved: hasCookies ? this.cookiesData.advertisement : true,
          hasToggle: true
        }
      }
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    Events.emitter.on('cookie-settings-enable', this.handleAddCookie);
    Events.emitter.on('cookie-settings-disable', this.handleRemoveCookie);
    Events.emitter.on('cookie-bar-approve', this.handleAcceptCookies);

    this.initialRender = false;
  }

  componentWillUnmount() {
    Events.emitter.removeListener('cookie-settings-enable', this.handleAddCookie);
    Events.emitter.removeListener('cookie-settings-disable', this.handleRemoveCookie);
    Events.emitter.removeListener('cookie-bar-approve', this.handleAcceptCookies);
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleAcceptCookies = () => {
    const { cookies } = this.props;
    const { settings } = this.state;
    const keys = Object.keys(settings);
    let result = {};

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      result[key] = settings[key].hasApproved;
    }

    cookies.set('cevora-cookies', result, { path: '/' });

    this.setState({ hasAccepted: true });
  }

  handleAddCookie = ({ name }) => {
    this.setState((prevState) => {
      const { settings } = prevState;

      settings[name].hasApproved = true;

      return { settings };
    });
  }

  handleRemoveCookie = ({ name }) => {
    this.setState((prevState) => {
      const { settings } = prevState;

      settings[name].hasApproved = false;

      return { settings };
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { t, fields } = this.props;
    const { settings, hasAccepted } = this.state;

    return !this.hasAcceptedOnLoad && (
      <div className='o-cookies'>
        <CookieBar
          classes='o-cookies__bar'
          text={object.getNested(fields, 'CookiesBarText')}
          isOpen={!hasAccepted}
        />

        <Modal
          id='cookie-settings-modal'
          classes='o-cookies__settings'
          contentClasses='u-padding-none'
          footerClasses='l-modal__footer--border-top'
          title={t('cookieSettings.title')}
          content={(
            <CookieSettings data={settings} />
          )}
          footer={(
            <ActionGroup classes='m-action-group--horizontal-center'>
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='cookie-settings-modal'
                onClick={this.handleAcceptCookies}
                type='button'
                fields={{
                  ButtonText: t('cookieSettings.save')
                }}
              />

              <Link
                classes='a-link--decorated'
                fields={{
                  LinkText: t('cookieSettings.info'),
                  ...linkParsers(fields)
                }}
              />
            </ActionGroup>
          )}
        />
      </div>
    );
  }
}

export default withCookies(withTranslation()(Cookies));

