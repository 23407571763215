/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Badge from './../Badge';

/**
 * Definition
 * =============================================================================
 */
const AmountIndicator = ({
  classes,
  icon,
  amount = 0,
}) => {

 /**
  * Main render
  * ----------------------------------------------------------------------------
  */
  return (
    <div className={`a-amount-indicator ${classes}`}>
      <span className={`a-amount-indicator__icon ${icon}`}></span>

      <Badge
        classes='a-amount-indicator__badge'
        text={amount}
      />
    </div>
  );
};

export default AmountIndicator;
