/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import object from '../../utils/object'
import { url as urlUtils} from '../../utils'
import { useLocation } from "react-router-dom";
import { WildcardUrlPattern } from '../../lib/Constants.ts';

/**
 * Definition
 * =============================================================================
 */
const BreadcrumbsStructuredData = ({ breadcrumbs, sitecoreContext }) => {

    const pageUrlWithPort = object.getNested(sitecoreContext, 'navigationData', 'pageHost');
    const pageUrl = urlUtils.urlWithoutPort(pageUrlWithPort);
    // Wildcard page - Infofiche breadcrumbs fix
    const location = useLocation();
    const currentPageUrl = location.pathname || '';
    const currentPageNameFromUrl = currentPageUrl.split('/').pop();
    const pageTitle = object.getNested(sitecoreContext, 'pageMetadata', 'pageTitle');

    const breadcrumbsStructData = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: breadcrumbs.map(
            ({ url, text }, idx) => {
                const absoluteItemUrl = urlUtils.isRelative(url) ? pageUrl + url : url;
                const transformedItemUrl = absoluteItemUrl.replace(WildcardUrlPattern, currentPageNameFromUrl);
                return {
                    '@type': 'ListItem',
                    'position': idx + 1,
                    'name': text === '*' ? pageTitle : text,
                    'item': transformedItemUrl
                }
            }
        )
    };
    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsStructData) }}></script>
    );
}
export default BreadcrumbsStructuredData;