/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import useLazyLoad from '../hooks/useLazyLoad';

/**
 * Definition
 * =============================================================================
 */
const withLazyload = (Component) => (props) => {
  useLazyLoad();
  return <Component  {...props}/>;
}

export default withLazyload;
