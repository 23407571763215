/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';

/**
 * Definition
 * =============================================================================
 */
const Slogan = (props) => {
  const {
    SloganPrimary,
    SloganSecondary
  } = props.fields;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderPrimary = () => {
    return (
      <span className='a-slogan__part a-slogan__part--primary'>
        <Text field={SloganPrimary} />
      </span>
    );
  };

  const renderSecondary = () => {
    return (
      <span className='a-slogan__part a-slogan__part--secondary'>
        <Text field={SloganSecondary} />
      </span>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='a-slogan'>
      <h1 className='a-slogan__container'>
        {(SloganPrimary || isEditorActive()) && renderPrimary()}

        {(SloganSecondary || isEditorActive()) && renderSecondary()}
      </h1>
    </div>
  )
};

export default Slogan;
