/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import ReactDOM from "react-dom";
import { Loader  } from './../../building-blocks';
import { Events } from './../../managers';
/**
 * Definition
 * =============================================================================
 */
class MainLoader  extends React.PureComponent {
 
  constructor(props) {
    super(props);
    this.state = {isLoading:props.isLoading};
    this.hideTimeout=-1;
    this.loadTimeout=-1;
    this.handleStartLoading= this.handleStartLoading.bind(this);
    this.handleStopLoading= this.handleStopLoading.bind(this);
  }

/**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {

    Events.emitter.on('start-loading', this.handleStartLoading);
    Events.emitter.on('stop-loading', this.handleStopLoading);
   
    this.node = ReactDOM.findDOMNode(this);
    this.node.style.display = 'none';
    this.node.classList.add("m-main-loader--is-hidden");
  }

  componentWillUnmount() {
 
    Events.emitter.removeListener('start-loading', this.handleStartLoading);
    Events.emitter.removeListener('stop-loading', this.handleStopLoading);
   
  }

  handleStartLoading(persistent=false){
    const { isLoading } = this.state;
    if(isLoading)return;
    if(this.hideTimeout!=-1)
    clearTimeout(this.hideTimeout);
    this.hideTimeout=-1;
   
    this.setState({isLoading:true,persistent:persistent});
    this.loadTimeout=setTimeout(
     ()=>{
      this.loadTimeout=-1;
      this.node = ReactDOM.findDOMNode(this);
      this.node.style.display = '';
      this.node.classList.remove("m-main-loader--is-hidden");
     
  

  },300);
  }
  handleStopLoading(){
    
    const { isLoading } = this.state;
    if(!isLoading)return;
    if(this.loadTimeout!=-1)
    clearTimeout(this.loadTimeout);
    this.loadTimeout=-1;
   
    
    this.node = ReactDOM.findDOMNode(this);
    
    this.node.classList.add("m-main-loader--is-hidden");
    this.setState({isLoading:false});

    this.hideTimeout=setTimeout(()=>{
      this.hideTimeout=-1;
      this.node.style.display = 'none';
    }, 300)
  }
  
/**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { isLoading,persistent } = this.state;
    const persistentClass = persistent?'':'m-main-loader--no-persistent';
    return (
      <div className={`m-main-loader ${persistentClass}`}>
    <Loader 
    />
    </div>
    );
  }
}
 

export default MainLoader;
