/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import AnchorLink from 'react-anchor-link-smooth-scroll'

/**
 * Definition
 * =============================================================================
 */
const SessionsSeen = ({ amount, t }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <p>
      {t('sessionCard.sessionsSeen.amount', { amount })}

      <a href='#session-table-section'>{t('sessionCard.sessionsSeen.action')}</a>&nbsp;

      {t('sessionCard.sessionsSeen.location')}
    </p>
  );
};

export default withTranslation()(SessionsSeen);
