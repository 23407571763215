import moment from 'moment-timezone';
import React from 'react';

const formatDate = (date, fmt = 'DD/MM/YYYY') => {
  var mdate = moment(date);
  return mdate.format(fmt);
};

const hasTimePart = (momentDate) => {
  const utcDate = moment(momentDate).utc();
  return (
    utcDate.hour() !== 0 || utcDate.minute() !== 0 || utcDate.second() !== 0
  );
};

const formatDateUsingSitecoreContextLanguage = (sitecoreContext, articleDate, fmt) => {
  if (sitecoreContext) {
    moment.locale(sitecoreContext.language.toLowerCase());
    var mdate = moment(articleDate);
    return mdate.format(fmt);
  }
  else {
    formatDate(articleDate, fmt)
  }
};

const formatDateIfHourReact = (
  startDate,
  endDate,
  dateFormat = "dd D MMM 'YY",
  hourFormat = 'HH:mm'
) => {
  var start = moment.utc(startDate).tz('Europe/Brussels');
  var end = moment.utc(endDate).tz('Europe/Brussels');
  let date;
  if (start.isSame(end, 'day')) {
    date = (
      <div className='dateTime sameDay'>
        <div className='date'>
          {start.format(dateFormat + ' ').replace(/\./g, '')}
        </div>
        <div className='hour'>
          {hasTimePart(start) &&
            hasTimePart(end) &&
            start.format(hourFormat) + ' - ' + end.format(hourFormat)}
        </div>
      </div>
    );
  } else {
    date = (
      <div className='dateTime'>
        <div className='startDate'>
          {hasTimePart(start)
            ? start.format(dateFormat).replace(/\./g, '')
            : start
                .format(dateFormat + ' ' + hourFormat)
                .replace(/\./g, '')}{' '}
        </div>
        <div className='separator'>{'-'}</div>
        <div className='endDate'>
          {hasTimePart(end)
            ? end.format(dateFormat).replace(/\./g, '')
            : end.format(dateFormat + ' ' + hourFormat).replace(/\./g, '')}
        </div>
      </div>
    );
  }
  return date;
};
/**
 * Exports
 * =============================================================================
 */
export default {
  formatDateIfHourReact,
  formatDate,
  formatDateUsingSitecoreContextLanguage
};
