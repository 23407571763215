/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import Flyout from './Flyout';
import Collapse from './Collapse';
import Sub from './Sub';
import { Button, Link } from './../../building-blocks';
import { withTranslation } from '../../hoc/withTranslation';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Events } from '../../managers';
import { object } from './../../utils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import UserActions from '../UserMenu/UserActions';
/**
 * Definition
 * =============================================================================
 */
const Navigation = ({ classes, items, isOpen, t, sitecoreContext, onGoToLogin }) => {
  const { isAuthenticated } = useAuth0();
  const setOpenState = () => {
    return isOpen ? 'is-open' : '';
  };

  const handleLogout = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    }
    Events.emitter.emit('go-to-logout');
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = (Component = Flyout) => {
    return items.map((i, index) => {
      const { children, ...data } = i;

      return (
        <Item key={`navigation-items-${index}`} {...data}>
          {children && children.length > 0 ? (
            <Component
              classes={
                index === 0 &&
                Component === Flyout &&
                'o-nav__flyout--align-left'
              }
              {...data}
            >
              <Sub items={children} isMobile={Component !== Flyout} />
            </Component>
          ) : null}
        </Item>
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <nav className={`o-nav ${classes} ${setOpenState()}`}>
      <ul className='o-nav__list u-desktop-show'>{renderItems(Flyout)}</ul>

      <div className='o-nav__mobile u-desktop-hide'>
        <ul className='o-nav__list'>{renderItems(Collapse)}</ul>

        {!isAuthenticated && (
          <div className='o-nav__register'>
            <span className='o-nav__register-option'>
              <Link
                fields={{
                  LinkText: t('nav.register.action'),
                  LinkUrl: object.getNested(
                    sitecoreContext,
                    'navigationData',
                    'Register'
                  )
                }}
              />

              {t('header.register.separator')}
            </span>

            <Button
              classes='o-nav__register-option'
              onClick={onGoToLogin}
              type='button'
              fields={{
                ButtonText: t('header.register.login')
              }}
            />
          </div>
        )}
        {isAuthenticated && (
          <div className='o-nav__register'>
            <UserActions onLogout={handleLogout} wrapperCssClass='o-nav__register-option'/>
          </div>
        )}
      </div>
    </nav>
  );
};

export default withSitecoreContext()(withTranslation()(Navigation));
