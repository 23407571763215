import React, { Fragment } from 'react';

const FieldValidationErrors = (props) => {
  if (props.fieldValidationErrorsComponent) {
    const CustomError = props.fieldValidationErrorsComponent;

    // strip the errors component from the custom component props
    // (prevents infinite loop rendering if someone reuses this component as a custom error component)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { fieldValidationErrorsComponent, ...errorsComponentProps } = props;

    return <CustomError {...errorsComponentProps} />;
  }
  const { errors, field:{model:{
    name,
    title
  }} } = props;

  if (!errors || errors.length === 0) {
    return null;
  }
  return (
    <small className='a-formfield__countdown is-error'>
      {errors.map((error, index) => (
        <Fragment key={index}>
          {error}
          <br />
        </Fragment>
      ))}
    </small>
  );
};

export { FieldValidationErrors };
