/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { type } from './../../utils';
import Events from '../../managers/Events';

/**
 * Definition
 * =============================================================================
 */
class Trigger extends React.PureComponent {

  /**
   * Handler
   * ---------------------------------------------------------------------------
   */
  handleClick = (e) => {
    e.preventDefault();
    let { targets, triggerType } = this.props;

    if (type.isString(targets)) targets = [targets];

    for (let i = 0; i < targets.length; i++) {
      const target = targets[i];

      if (triggerType === 'close') {
        Events.emitter.emit(`modal-trigger-close:${target}`);
      } else if (triggerType === 'open') {
        Events.emitter.emit(`modal-trigger-open:${target}`);
      } else {
        Events.emitter.emit(`modal-trigger-toggle:${target}`);
      }
    }
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { triggerComponent:Component, ...componentProps } = this.props;

    return (
      <React.Fragment>
        <Component
          handleModalTriggerClick={this.handleClick}
          {...componentProps}
        />
      </React.Fragment>
    );
  }
}

export default Trigger;
