/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import { Attribute } from './../../building-blocks';
import { ShowPlacesLeftWarningFrom } from '../../lib/Constants.ts';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ classes = '', location, region, placesLeft, children, t, showRegion, isVirtual, remainingDaysForRegistration }) => {
  region = showRegion ? region : undefined;
  const hasAttributes = location || region || placesLeft || remainingDaysForRegistration >= 0;

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className={`m-session__slide glide__slide ${classes}`}>
      <div className='m-session__slide-container s-jss-rte-session'>
        {children}
      </div>

      {hasAttributes && (
        <ul className='m-session__attributes'>
          {placesLeft > 0 && placesLeft <= ShowPlacesLeftWarningFrom && (
            <li className='m-session__attribute'>
              <Attribute
                classes='a-attribute--error'
                iconBefore='icon-error'
                text={t('sessionCard.seatsLeft', { amount: placesLeft })}
              />
            </li>
          )}

          {placesLeft <= 0 && (
            <li className='m-session__attribute'>
              <Attribute
                classes='a-attribute--error'
                iconBefore='icon-error'
                text={t('sessionCard.sessionIsFullCard')}
              />
            </li>
          )}

          {!isVirtual ? (
            <li className='m-session__attribute'>
              <Attribute
                classes='a-attribute--location'
                iconBefore='icon-location'
                text={
                  (location && region && `${location}, ${region}`) ||
                  location ||
                  region
                }
              />
            </li>
          ) : null}

          {!isVirtual && remainingDaysForRegistration >= 0 && placesLeft > 0 && (
            <li className='m-session__attribute'>
              <Attribute
                text={t(
                  {
                    0: 'infofiche.daysLeftForRegistration.zero',
                    1: 'infofiche.daysLeftForRegistration.singular'
                  }[remainingDaysForRegistration] ||
                    'infofiche.daysLeftForRegistration.plural',
                  { remainingDaysForRegistration }
                )}
              />
            </li>
          )}
        </ul>
      )}
    </li>
  );
};

export default withTranslation()(Item);
