/**
 * Get nested
 * =============================================================================
 */
const getNested = (obj, level, ...other) => {
  if (!obj) return false;

  if (other.length === 0 && obj.hasOwnProperty(level)) return obj[level];

  return getNested(obj[level], ...other);
};

/**
 * Has nested
 * =============================================================================
 */
const hasNested = (obj, level, ...other) => {
  if (!obj) return false;

  if (other.length === 0 && obj.hasOwnProperty(level)) return true;

  return hasNested(obj[level], ...other);
};

/**
 * Has nested
 * =============================================================================
 */
const hasValue = (obj) => {
  if (!obj || obj==null || obj=='') return false;
  return true;
  
};

/**
 * Rename key
 * =============================================================================
 */
const renameKey = (oldKey, newKey, { [oldKey]: oldValue, ...rest }) => ({
  [newKey]: oldValue,
  ...rest
});

/**
 * Itterates through nested object properties
 * =============================================================================
 */

const forEachNested = (obj, callbackFunction, currentItem) => {
  obj = [ obj ];
  while (obj.length)
      if(
         !callbackFunction( currentItem = obj.pop() ) &&
         currentItem instanceof Object && 
         [Object, Array].includes(currentItem.constructor)
      ) obj.push.apply(obj, Object.values(currentItem));
}

/**
 * Exports
 * =============================================================================
 */
 export default {
  getNested,
  hasNested,
  renameKey,
  hasValue,
  forEachNested
};
