/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Supertitle = ({
  classes,
  text,
  hasImageBefore = true,
  hasImageAfter = true
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderText = () => {
    return <p className='a-supertitle__text'>{text}</p>;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-supertitle ${classes}`}>
      {hasImageBefore && (
        <span className='a-supertitle__before'></span>
      )}

      {text && renderText()}

      {hasImageAfter && (
        <span className='a-supertitle__after'></span>
      )}
    </div>
  );
};

export default Supertitle;
