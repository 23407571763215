/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Footer from './Footer';
import SimpleFooter from './SimpleFooter';
import { object } from '../../utils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * Definition
 * =============================================================================
 */
const Connected = props => {
  const { sitecoreContext } = props;
  const menu = sitecoreContext.navigationData.footerMenu;

  var footerType =
    object.getNested(
      sitecoreContext,
      'route',
      'fields',
      'FooterType',
      'fields',
      'Value',
      'value'
    ) || 'normal-footer';

  return (
    <React.Fragment>
      {footerType === 'normal-footer' && <Footer {...props} menu={menu} />}
      {footerType === 'simple-footer' && <SimpleFooter {...props} menu={menu} />}
    </React.Fragment>
  );
};

export default withSitecoreContext()(Connected);
