import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms';

/**
 * @param {ValueFormField} field
 * @param {string} fieldValue
 * @param {FieldChangeCallback} callback
 * @param {function} t
 */
 function handleOnChange(field, fieldValue, callback, t) {
    let valid = true;
    const errorMessages = [];
    callback(field.valueField.name, fieldValue, valid, errorMessages);
  }
  
  export default handleOnChange;