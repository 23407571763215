/**
 * Compare
 * =============================================================================
 */
const compare = (value, options) => {
  const arr = Object.keys(options);

  for (let i = 0; i < arr.length; i++) {
    const option = arr[i];

    if (value !== option) continue;

    return options[option];
  }
};

/**
 * Exports
 * =============================================================================
 */
 export default {
  compare,
};
