/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { event } from './../utils';
import window from 'global/window';
import { Body } from '../managers';
import { getIsMobile } from './../utils/mobile';
/**
 * Definition
 * =============================================================================
 */
const withFlyout = () => (WrappedComponent) => {
  return class extends React.Component {

    constructor(props) {
      super(props);

      this.isDisabled = false;
      
      this.state = {
        isOpen: props.isOpen || false,
        isMobile: getIsMobile()
      };
    }

    /**
     * Life cycle
     * -------------------------------------------------------------------------
     */
    componentDidMount() {
      window.addEventListener && window.addEventListener('resize', event.debounce(this.handleResize, 250));
     
    }

    componentWillUnmount() {
      clearTimeout(this.disableTimeout);
      this.disableTimeout = null;

      window.removeEventListener && window.removeEventListener('resize', this.handleResize);
    }

    componentWillReceiveProps(nextProps){
      if(this.props.isOpen !== nextProps.isOpen){
        this.setState({isOpen: nextProps.isOpen});
      }
    }

    /**
     * Helpers
     * -------------------------------------------------------------------------
     */
    setFlyoutState = () => {
      const { isOpen } = this.state;
      return isOpen ? 'is-open' : '';
    }

    getMobileState = () => getIsMobile()

    /**
     * Handlers
     * -------------------------------------------------------------------------
     */
    handleToggleFlyout = () => {
      if (this.isDisabled) return;

      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    handleOpenFlyout = () => {
      if (this.state.isOpen) return;

      this.isDisabled = true;

      this.setState({ isOpen: true }, () => {
        this.disableTimeout = setTimeout(() => this.isDisabled = false, 500);
      });

     
    }

    handleOutsideFlyoutClick = (e) =>{
      if (this.isDisabled) return;
      if (!this.state.isOpen) return;
      this.isDisabled = true;
      this.handleCloseFlyout();
      Body.removeOnClick(this.handleOutsideFlyoutClick);
    }

    handleCloseFlyout = () => {
      
      if (!this.state.isOpen) return;
    
      this.setState({ isOpen: false }, () => {
        this.isDisabled = false;
      });
    }

    handleResize = () => {
      const { isMobile } = this.state;

      if (getIsMobile() && !isMobile) {
        this.setState({ isMobile: true });
      } else if (!getIsMobile() && isMobile) {
        this.setState({ isMobile: false });
      }
    }

    /**
     * Main render
     * -------------------------------------------------------------------------
     */
    render() {
      const { isOpen, isMobile } = this.state;
      if(isOpen)setTimeout(()=>{ Body.addOnClick(this.handleOutsideFlyoutClick);},100);
      return (
        <WrappedComponent
          setFlyoutState={this.setFlyoutState}
          handleToggleFlyout={this.handleToggleFlyout}
          handleOpenFlyout={this.handleOpenFlyout}
          handleCloseFlyout={this.handleCloseFlyout}
          isOpen={isOpen}
          isMobile={isMobile}
          {...this.props}
        />
      );
    }
  }
}

export default withFlyout;
