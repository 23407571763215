/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import NavItem from './NavItem';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {object } from '../../utils';

/**
 * Definition
 * =============================================================================
 */
const Footer = ({ menu,sitecoreContext }) => {
  const currentYear = new Date().getFullYear();

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderNav = () => {
    return (menu || []).map((item, index) => {
      return (
        <NavItem
          key={`footer-nav-item-${index}`}
          {...item}
        />
      );
    });
  };

 const twitterLink = object.getNested(sitecoreContext,"navigationData","Twitter");
 const facebookLink = object.getNested(sitecoreContext,"navigationData","Facebook");
 const linkedinLink = object.getNested(sitecoreContext,"navigationData","LinkedIn");
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <footer className='o-footer'>  
      <nav className='o-footer__content l-container'>
        {menu && (
          <ul className='o-footer__nav'> 
            {renderNav()}
            
          </ul>
        )}
         <ul className="o-footer__nav-social">
                {twitterLink && <li><a href={twitterLink} target="_blank" rel="noopener" title="Twitter"><span className="o-footer__nav-social-link icon-twitter"></span></a></li>}
                {facebookLink && <li><a href={facebookLink} target="_blank" rel="noopener" title="Facebook"><span className="o-footer__nav-social-link icon-facebook"></span></a></li>}
                {linkedinLink && <li><a href={linkedinLink} target="_blank" rel="noopener" title="LinkedIn"><span className="o-footer__nav-social-link icon-linkedin"></span></a></li>}
              </ul>
        <span className='o-footer__copyright'>&copy;&nbsp;{currentYear}</span>
      </nav>
    </footer>
  )
};

export default withSitecoreContext()(Footer);
