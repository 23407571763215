import React, { useEffect } from 'react';

const useHashScrollWorkaround = () => {
  useEffect(() => {
    const hash = window.location && window.location.hash.replace(/^#/, '');
    if (!hash) {
      return;
    }

    const observer = observe(hash);

    return () => {
      observer.disconnect();
    };
  });

  return <></>;
};

function observe(hash) {
  // Select the node that will be observed for mutations
  const targetNode = document.getElementById('root');

  // Options for the observer (which mutations to observe)
  const config = { childList: true, subtree: true };

  // Callback function to execute when mutations are observed
  const callback = () => {
    const scrolledToHash = window.scrolledToHash;
    if (!hash || hash === scrolledToHash) {
      return;
    }

    const el = document.getElementById(hash);
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth', // smooth scroll
        block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
      });
    }
    window.scrolledToHash = hash;
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  observer.observe(targetNode, config);

  return observer;
}

export default useHashScrollWorkaround;
