/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import { Supertitle, Button } from '../../building-blocks';
import Filter from './Filter/index';
import { object } from '../../utils';
import PillList from '../../components/PillList';
import pillListParsers from './parsers/pillList';
import Overlay from '../../components/Overlay';
import OverlayTrigger from '../../components/Overlay/Trigger';
import SearchBar from '../../components/SearchBar/SearchBar';
import SearchResultsList from './SearchResultsList';
import { DefaultSorting } from '../../lib/Constants.ts';
import {
  transformFacets,
  mapFilterToVariables,
  mapSearchToVariables,
  splitTerms,
} from './transform';
import { setFilterActiveState, setIsCheckedDeep } from './TransformData.ts';
import { withRouter } from 'react-router';
import isSearchBarDisabled from '../../components/SearchBar/isSearchBarDisabled';
import SearchBarDisabled from './SearchBarDisabled';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import generateSearchUrlQuery from './generateSearchUrlQuery';
import TermPopup from './TermPopup';
/**
 * Definition
 * =============================================================================
 */
class SearchResults extends React.Component {
  constructor(props) {
    super(props);

    const { initial } = props;
    const params = new URLSearchParams(this.props.location.search || '');

    const defaultSearch =
      initial ||
      (params && {
        term: params.get('term'),
        from: params.get('from'),
        to: params.get('to'),
        closeTo: params.get('closeTo'),
        searchAs: params.get('searchAs'),
        trainingTypeCode: params.get('trainingTypeCode'),
      }) ||
      {};

    const { t, lng, facetNameToId } = props;
    const getFilterFromParams = (filterName) =>
      params.get(filterName) &&
      JSON.parse(params.get(filterName)).map((x) => ({
        id: facetNameToId(filterName, x),
        name: x,
        isChecked: true,
      }));

    const getInitialLanguageSelection = () => {
      const initialLang = [{
          text: t('language.' + lng),
          id: `${lng}`,
          amount: 0,
          isChecked: true,
        }]
      return (lng !== 'en' ? 
        initialLang.concat({
          text: t('language.en'),
          id: 'en',
          amount: 0,
          isChecked: true,
        }) : initialLang)
    }

    const getInitialDurations = () => {
      return initial.durations.map(item => 
        ({
          text: t('search.facets.durations.' + item),
          id: item,
          amount: 0,
          isChecked: true,
        }));
    }

    const getInitialLocations = () => {
      const locationsMapped = [];
      initial.locations.forEach(item => {
        let mappedId = facetNameToId('sessionslocations', `/${item.name}`);
        if(mappedId){
          locationsMapped.push({
            text: item.name,
            id: facetNameToId('sessionslocations', `/${item.name}`),
            name: facetNameToId('sessionslocations', `/${item.name}`),
            isChecked: true
          });
        }
      });
      return locationsMapped;
    }

    const defaultFilter = {
      languages:
        (initial && initial.languages) ||
        (params.get('languages')
          ? getFilterFromParams('languages')
          : getInitialLanguageSelection()),
      locations: (initial && initial.locations) ? getInitialLocations() : getFilterFromParams('locations'),
      themes: (initial && initial.themes) || getFilterFromParams('themes'),
      learnMethod:
        (initial && initial.learnMethod) || getFilterFromParams('learnMethod'),
      sectors: (initial && initial.sectors) || getFilterFromParams('sectors'),
      durations: (initial && initial.durations) ? getInitialDurations() : getFilterFromParams('durations'),
      availablePlaces:
        (initial && initial.availablePlaces) ||
        params.get('availablePlaces') === '1',
      availableInCompany:
        (initial && initial.availableInCompany) ||
        params.get('availableInCompany') === '1',
    };

    this.state = {
      search: defaultSearch,
      filter: defaultFilter,
      itemsLoaded: 0,
      loading: false,
      total: 0,
      sorting: (initial && initial.sorting) || DefaultSorting,
      initialPageSize: parseInt(params && params.get('loaded')),
    };
  }


  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleUpdateTags = (tag) => {
    const { key, text } = tag;
    const removeTerm = (terms, excluded) =>
      splitTerms(terms)
        .filter((x) => x !== excluded)
        .join(', ');
    let obj = {};

    switch (key) {
      case 'term':
        this.setState((prevState) => ({
          search: {
            ...prevState.search,
            term: removeTerm(prevState.search.term, text),
          },
        }));
        return;
      case 'closeTo':
      case 'from':
      case 'to':
      case 'searchAs':
        obj[key] = undefined;
        this.setState((prevState) => ({
          search: { ...prevState.search, ...obj },
        }));
        return;
    }

    const { filter } = this.state;
    if (filter && filter[key]) {
      obj[key] = setIsCheckedDeep(filter[key], false);
      this.setState({ filter: { ...filter, ...obj } });
    }
  };

  handleFilterSubmit = (filter) => {
    this.setState({ filter });
  };

  handleSearchSubmit = (search) => {
    //if(JSON.stringify(this.state.search) !== JSON.stringify(search)){
    this.setState({ search: { ...search }, loading: true });
    //}
  };

  getLanguagelessPathname = (pathname) =>
    pathname.replace(/^\/(en|de|fr|nl)\//i, '/').toLowerCase();

  componentDidMount() {
    this.setState({
      pathname: this.getLanguagelessPathname(this.props.location.pathname),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.initial) {
      return;
    }
    const { facetIdToName } = this.props;
    const currentUrl = this.props.location.search;
    const stateUrl = generateSearchUrlQuery(
      this.state.search,
      this.state.filter,
      this.state.itemsLoaded,
      this.props.facetIdToName
    );
    if (
      this.getLanguagelessPathname(this.props.location.pathname) ===
        this.state.pathname &&
      currentUrl !== stateUrl
    ) {
      this.props.history.replace({
        search: stateUrl,
        hash: this.props.location.hash,
      });
    }
  }

  handleChangeSort = (name, value) => {
    this.setState({ sorting: value });
    // End point for handling the sorting
  };

  handleQueryCompleted = (data, total, loaded) => {
    const { lng, t } = this.props;
    const filter = transformFacets(data.search.courses.facets, lng, t);
    if (!this.props.initial || loaded > 0) {
      this.setState((prevState) => {
        const updatedFilter = setFilterActiveState(filter, prevState.filter);
        return {
          filter: updatedFilter,
          itemsTotal: total,
          itemsLoaded: loaded,
          loading: false,
        };
      });
    }

    const scroll = parseInt((this.props.location.hash || '').substr(1));
    if (scroll && scroll >= window.innerHeight) {
      this.props.history.replace({
        hash: '',
      });
      setTimeout(() => {
        window.scroll({
          top: scroll,
          left: 0,
          behavior: 'instant',
        });
      }, 200);
    }
  };

  mapPredefinedSearchVairables = () => {
    const { initial = {} } = this.props;
    const { resultFilters, sortByCourseName } = initial;
    const psrVars = {
      ...(Array.isArray(resultFilters) &&
        resultFilters.length > 0 && { resultFilters }),
      ...(typeof sortByCourseName === 'boolean' && { sortByCourseName }),
    };
    return psrVars;
  };

  getQueryVariables = () => ({
      ...mapSearchToVariables(this.state.search),
      ...mapFilterToVariables(this.state.filter),
      ...this.mapPredefinedSearchVairables(),
      sorting: this.state.sorting
    });
    

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { t, sitecoreContext, initial = {}, getFacetSettings } = this.props;
    const regionsMap = object.getNested(sitecoreContext, 'regions');
    const { search, filter, loading } = this.state;
    const { term: searchTerm } = search;

    const searchResults = (
      <SearchResultsList
        {...this.props}
        variables={this.getQueryVariables()}
        loading={loading}
        onCompleted={this.handleQueryCompleted}
        initialPageSize={this.state.initialPageSize}
        hideSortingOption={
          this.props.initial && this.props.initial.hideSortingOption
        }
      />
    );

    return (
      <React.Fragment>
        {initial && initial.hideSearchBar ? null : (
          <section className='l-section u-padding-top-none@below-desktop-base u-padding-bottom-none'>
            <div className='l-section__content-container l-container@above-desktop-base'>
              <header className='l-section__header l-section__header--align-center u-desktop-show'>
                <div className='l-section__results-title'>
                  <Supertitle
                    classes='l-section__super-title'
                    text={t('searchResultsPage.title')}
                    hasImageBefore={true}
                    hasImageAfter={true}
                  />

                  {searchTerm && (
                    <h1 className='l-section__title '>
                      &ldquo;{searchTerm}&rdquo;
                    </h1>
                  )}
                </div>
              </header>

             <TermPopup term={searchTerm}/>

              <div className='l-section__content'>
                <SearchBar
                  formData={this.state.search}
                  onSubmit={this.handleSearchSubmit}
                />

                {filter && !isSearchBarDisabled(this.state.search) && (
                  <div className='l-container@below-desktop-base'>
                    <PillList
                      classes='u-margin-top-1x'
                      items={pillListParsers(search, filter, t, regionsMap)}
                      onRemoveItem={this.handleUpdateTags}
                    />
                  </div>
                )}
              </div>
            </div>
          </section>
        )}

        {/* ------------------------------------------------------------------ */}
        {/* Content */}
        {/* ------------------------------------------------------------------ */}
        <div className='l-aside l-aside--spacing-bottom-large'>
          <div className='l-aside__container l-container'>
            {isSearchBarDisabled(search) ? (
              <SearchBarDisabled />
            ) : initial && initial.hideFilters ? (
              searchResults
            ) : (
              <div className='l-aside__wrapper'>
                <aside className='l-aside__body'>
                  <div className='l-aside__header'>
                    <div className='l-aside__header-left'>
                      <h6 className='l-aside__title u-desktop-show'>
                        {t('searchResultsPage.filter.title')}
                      </h6>
                    </div>
                  </div>
                  <OverlayTrigger
                    targets='filter-overlay'
                    triggerComponent={Button}
                    triggerType='open'
                    type='button'
                    classes='a-button--outline a-button--secondary a-button--fill-space a-button--small u-desktop-hide'
                    fields={{
                      ButtonUrl: { href: '/' },
                      ButtonText: t('searchResultsPage.filter.mobileButton'),
                    }}
                  />
                  <Overlay
                    id='filter-overlay'
                    suffix='@below-desktop-base'
                    close={{
                      component: Button,
                      props: {
                        classes:
                          'a-button--outline a-button--secondary a-button--fill-space',
                        type: 'submit',
                        fields: {
                          ButtonText: t('searchResultsPage.showResults'),
                        },
                      },
                    }}
                  >
                    <Filter data={filter} getFacetSettings={getFacetSettings} onChange={this.handleFilterSubmit} />
                  </Overlay>
                </aside>
                <div className='l-aside__content'>{searchResults}</div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withSitecoreContext()(
  withTranslation()(withRouter(SearchResults))
);
