import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Helmet from 'react-helmet';
import { object, image, url } from '../../utils';

const MetaOgImage = ({sitecoreContext, imageUrl}) => {
    const pageUrl = object.getNested(sitecoreContext, 'navigationData', 'pageHost');
    const absoluteImageUrl = url.isRelative(imageUrl) ? pageUrl + imageUrl : imageUrl;
    const ogImageUrl = image.getMetaImageUrl(absoluteImageUrl, 'opengraph');
    return imageUrl && (
      <Helmet>
        <meta property='og:image' content={ogImageUrl} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
    );
}

export default withSitecoreContext()(MetaOgImage);