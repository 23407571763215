/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Icon from './Icon';

/**
 * Definition
 * =============================================================================
 */
const Attribute = ({
  classes,
  iconBefore,
  iconAfter,
  text,
  url
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderText = () => {
    return <p className='a-attribute__text'>{text}</p>;
  };

  const renderLink = () => {
    return <a className='a-attribute__link' href={url}>{text}</a>
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-attribute ${classes}`}>
      {iconBefore && (
        <Icon classes='a-attribute__icon--before' name={iconBefore} />
      )} 

      {url ? renderLink() : renderText()}

      {iconAfter && (
        <Icon classes='a-attribute__icon--after' name={iconAfter} />
      )}
    </div>
  );
};

export default Attribute;
