const mergeSearchResults = data => {
  const strictData = data.search.courses[0];
  const looseData =
    data.search.courses.length > 1 && data.search.courses[1]
      ? data.search.courses[1]
      : { totalCount: 0, structuredDataCount: 0, hits: [], facets: [] };

  const mergedData = {
    totalCount: strictData.totalCount + looseData.totalCount,
    hits: [
      ...strictData.hits.map(x => ({ ...x, strict: true })),
      ...looseData.hits.map(x => ({ ...x, strict: false }))
    ],
    facets: mergeFacets([...strictData.facets, ...looseData.facets]),
    structuredDataCount: strictData.structuredDataCount
  };

  return { search: { courses: mergedData } };
};

const mergeFacets = facets => {
  const dict = facets.reduce((cur, next) => {
    if (cur[next.name]) {
      cur[next.name].values = [...cur[next.name].values, ...next.values];
    } else {
      cur[next.name] = { ...next };
    }
    return cur;
  }, {});

  return Object.values(dict).map(x => ({
    ...x,
    values: mergeValues(x.values)
  }));
};

const mergeValues = values => {
  const dict = values.reduce((cur, next) => {
    if (cur[next.name]) {
      cur[next.name].aggregateCount += next.aggregateCount;
    } else {
      cur[next.name] = { ...next };
    }
    return cur;
  }, {});

  return Object.values(dict);
};

export { mergeSearchResults };
