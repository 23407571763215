import { ValidationDataModels } from '@sitecore-jss/sitecore-jss-react-forms';
import React, { Component, createRef } from 'react';
import { withTranslation } from '../../../hoc/withTranslation';
import { FieldValidationErrors } from './FieldValidationErrors';
import FieldLabel from '../Helpers/FieldLabel';

class FileUpload extends Component {
  fileInputRef = createRef();
  fileRef = createRef();
  SIZE_UNITS = {
    1: 'Bytes',
    1024: 'KB',
    1048576: 'MB',
    1073741824: 'GB',
  };

  componentDidUpdate(prevProps) {
    if (prevProps.value && !this.props.value && this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
    }
  }

  getEnabledValidation(itemId) {
    return this.props.field.model.validationDataModels.find(
      (validation) => validation.itemId === itemId
    );
  }

  getFileSizeUnitName(value) {
    return this.SIZE_UNITS[value];
  }
  setFieldValue = () => {
    const files = Array.from(this.fileRef.current.files);
    const names =
      files && files.length ? files.map((file) => file.name).join(', ') : '';
    this.fileInputRef.current.value = names;
  };
  onChangeField = (files, field, callback) => {
    const fileSizeValidator = this.getEnabledValidation(
      ValidationDataModels.FileSizeValidator
    );
    const fileCountValidator = this.getEnabledValidation(
      ValidationDataModels.FileCountValidator
    );
    const fileTypeValidator = this.getEnabledValidation(
      ValidationDataModels.FileTypeValidator
    );

    const list = [];
    const errorMessages = [];
    let valid = true;

    if (files) {
      Array(files.length)
        .fill(null)
        .forEach((_, idx) => {
          const fileSize = files[idx].size / field.model.fileSizeUnit;
          if (
            valid &&
            fileSizeValidator &&
            fileSize > field.model.maxFileSize
          ) {
            const msg = fileSizeValidator.message
              .replace('{0}', field.model.maxFileSize.toString())
              .replace(
                '{1}',
                this.getFileSizeUnitName(field.model.fileSizeUnit)
              );

            errorMessages.push(msg);
            valid = false;
          }

          list.push(files[idx]);
        });
    }

    if (fileTypeValidator) {
      list.some((file) => {
        const ext = file.name.split('.').pop() || '';

        if (field.model.allowedContentTypes.indexOf(ext) !== -1) {
          return false;
        }

        const msg = fileTypeValidator.message.replace(
          '{0}',
          field.model.allowedContentTypes
        );
        errorMessages.push(msg);

        return true;
      });
    }

    if (fileCountValidator && list.length > field.model.maxFileCount) {
      const msg = fileCountValidator.message
        .replace('{0}', field.model.maxFileCount.toString())
        .replace('{1}', field.model.title);

      errorMessages.push(msg);
      valid = false;
    }

    if (field.model.required && !list.length) {
      valid = false;
      const { t } = this.props;
      errorMessages.push(t('form.fieldIsRequired', { field: field.model.title }));
    }

    callback(field.valueField.name, list, valid, errorMessages);
  };

  render() {
    const { field, value, onChange, errors, tracker, t } = this.props;
    const fileTypeValidator = this.getEnabledValidation(
      ValidationDataModels.FileTypeValidator
    );

    return (
      <div className={`a-formfield ${field.model.cssClass}`}>
        <FieldLabel field={field} />
        <div className='a-formfield__wrapper a-formfield__wrapper--with-button '>
          <input
            className='a-formfield__input'
            type='text'
            ref={this.fileInputRef}
            name={field.valueField.name + '_i'}
            id={field.valueField.id + '_i'}
            readOnly
            placeholder={t('form.fileupload.noFileSelected')}
          />
          <input
            className='a-formfield__file'
            type='file'
            ref={this.fileRef}
            id={field.valueField.id}
            accept={
              fileTypeValidator ? field.model.allowedContentTypes : undefined
            }
            multiple={field.model.isMultiple}
            onChange={(e) => {
              this.setFieldValue();
              this.onChangeField(e.target.files, field, onChange);
            }}
            onFocus={() => tracker.onFocusField(field, value)}
            onBlur={() => tracker.onBlurField(field, value, errors)}
          />
          <div className='a-formfield__button'>
            <label
              className='a-button a-button--no-wrap'
              htmlFor={field.valueField.id}
            >
              {t('form.fileupload.chooseFile')}
            </label>
          </div>
        </div>
        <FieldValidationErrors {...this.props} />
      </div>
    );
  }
}

export default withTranslation()(FileUpload);
