/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import { event } from './../../utils';
import window from 'global/window';
import document from 'global/document';
import TermWithSuggest from './TermWithSuggest';
/**
 * Definition
 * =============================================================================
 */
class Term extends React.Component {

  constructor(props) {
    super(props);

    this.suggestEl = React.createRef();
    this.mobileBreakpoint = 960;

    this.state = {
      width: null
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
   componentDidMount() {
    window.addEventListener('resize', event.debounce(this.handleResize, 250));

    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  getWidth(el) {
    return (el && el.getBoundingClientRect().width) || 0
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleResize = () => {
    const { width } = this.state;

    if (window.innerWidth < this.mobileBreakpoint) {
      if (width) this.setState({ width: null })

      return;
    }

    /**
     * There is no other way to get these elements within this component,
     * without passing them through the parent, but becasue this component is
     * rendered first, it does not know about its siblings.
     */
    const fields = document.querySelector(".o-search__fields");
    const { current:suggest } = this.suggestEl;
    const closeTo = fields.children[0];
    const from = fields.children[1];
    const to = fields.children[2];

    /**
     * Offset determined in the slicing of the search component where the left
     * side is pushed a little bit more to the right
     */
    const offset = 25;
    let newWidth = -offset +
      this.getWidth(suggest) +
      this.getWidth(closeTo) +
      this.getWidth(from) +
      this.getWidth(to);

    if (newWidth === width) return;

    this.setState({ width: newWidth });
  }

  handleChange = (name, value) => {
    const { onChange, onDelayedChange } = this.props;

    if (this.inputChangeTimeout) {
      clearTimeout(this.inputChangeTimeout);
      this.inputChangeTimeout = null;
    }

    this.inputChangeTimeout = setTimeout(() => {
      if (onDelayedChange) onDelayedChange(value);

      clearTimeout(this.inputChangeTimeout);
      this.inputChangeTimeout = null;
    }, 1000);

    if (onChange) onChange(name, value);
  }

  handleClearInput = (name) => {
    const { onChange } = this.props;

    if (onChange) onChange(name, '');
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      t,
      value = ''
    } = this.props;

    const { width } = this.state;
    return (
      <TermWithSuggest
        reference={this.suggestEl}
        classes='o-search__suggest m-formfield-flyout--align-left'
        label={t('searchResultsSearch.term.label')}
        placeholder={t('searchResultsSearch.term.placeholder')}
        iconAfter='icon-cancel-circle'
        iconAfterClasses={value ? 'is-active' : ''}
        onIconAfterClick={this.handleClearInput}
        id='term'
        name='term'
        value={value}
        hideSuggestions={this.props.hideSuggestions}
        onSuggestionsHidden={this.props.onSuggestionsHidden}
        audience={this.props.audience}
      
        contentStyle={width ? { width } : {}}
        onChange={this.handleChange}
        onKeyPress={this.props.onKeyPress}
      />
    );
  }
}

export default withTranslation()(Term);
