import document from 'global/document';

/**
 * parseURL
 * =============================================================================
 */
function parseURL(url) {
  var parser = document.createElement('a'),
    searchObject = {},
    queries,
    split,
    i;

  // Let the browser do the work
  parser.href = url;

  // Convert query string to object
  if (parser.search != undefined) {
    queries = parser.search.replace(/^\?/, '').split('&');
    for (i = 0; i < queries.length; i++) {
      split = queries[i].split('=');
      searchObject[split[0]] = split[1];
    }
  }

  return {
    protocol: parser.protocol,
    host: convertHost(parser.host),
    hostname: convertHostName(parser.hostname),
    port: parser.port,
    pathname:
      parser.pathname && parser.pathname.indexOf('/') != 0
        ? '/' + parser.pathname
        : parser.pathname,
    search: parser.search,
    searchObject: searchObject,
    hash: parser.hash,
    href: parser.href
  };
}

function urlWithoutPort(url) {
  if (url == null || url == undefined || url == '') return '';

  var purl = parseURL(url);
  if (purl.host == null) return url;

  return purl.protocol + "//" + purl.hostname + ((purl.pathname == '/') ? '' : purl.pathname);
}

function relativePath(url) {
  if (url == null || url == undefined || url == '') return '/';

  var purl = parseURL(url);
  if (purl.host == null) return url;

  return purl.pathname + purl.hash + purl.search;
}

function convertHost(host) {
  if (host == 'localhost:3000') return 'cevora.sc';
  return host;
}

function replaceURL(url, data) {
  for (var key in data) {
    url = url.replace('${' + key + '}', data[key]);
  }
  return url;
}

function convertHostName(hostname) {
  if (hostname == 'localhost') return 'cevora.sc';
  return hostname;
}

function getParametersObjectFromUrl(url) {
  if (url.search.length < 2) return null;
  var query = url.search.substr(1);
  var result = {};
  var params = query.split('&');
  for (var nr = 0; nr < params.length; nr++) {
    var part = params[nr];
    var item = part.split('=');
    if (item[1] != '') result[item[0]] = decodeURIComponent(item[1]);
  }
  return result;
}

function isRelative(url) {
  if (!url) return false;
  var relative = !(url.indexOf('http://') == 0 || url.indexOf('https://') == 0);
  return relative;
}

function createURLParameters(data) {
  if (data == null) return '';

  return (
    '?' +
    Object.keys(data)
      .filter(key => data[key] !== undefined)
      .map(key => `${key}=${encodeURIComponent(data[key])}`)
      .join('&')
  );
}

function getCurrentPath() {
  return (
    document.location.protocol +
    '//' +
    document.location.host +
    document.location.pathname
  );
}

function getCurrentRelativePath() {
  return document.location.pathname.indexOf('/') != 0
    ? '/' + document.location.pathname
    : document.location.pathname;
}

function removeHostName(s) {
  return s.replace(/^\:\/\/.+?\//, '');
}

/**
 * Exports
 * =============================================================================
 */
export default {
  parseURL,
  getParametersObjectFromUrl,
  createURLParameters,
  relativePath,
  isRelative,
  replaceURL,
  getCurrentPath,
  getCurrentRelativePath,
  removeHostName,
  urlWithoutPort
};
