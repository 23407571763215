/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button, Input } from './../../building-blocks';

/**
 * Definition
 * =============================================================================
 */
class ExmPoc extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messageId: "{DCB44897-D3BE-4FA2-AA1B-96A59AAC2350}",
      // email: "max.mayorov@boondoggle.eu",
      firstName: "Max",
      lastName: "Mayorov",
      amount: 100500,
      courseName: "Sending emails with EXM"
    };

    this.sendEmail = this.sendEmail.bind(this);
  }

  sendEmail() {
    fetch("/api/mail/send", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state),
    })
      .then((result) => alert("Email sent"))
      .catch((e) => {
        console.error(e);
      });
  }

  render() {
    const props = this.props;

    return (
      <div>
        <Text field={props.fields.heading} />
        <div className='l-grid__row'>
          <div className='l-grid__col-2-12'>
            <Input
              placeholder='First name'
              value={this.state.firstName}
              onChange={(value) => this.setState({ firstName: value })}
            />
          </div>
          <div className='l-grid__col-2-12'>
            <Input
              placeholder='Last name'
              value={this.state.lastName}
              onChange={(value) => this.setState({ lastName: value })}
            />
          </div>
          <div className='l-grid__col-2-12'>
            <Input
              placeholder='Email'
              value={this.state.email}
              onChange={(value) => this.setState({ email: value })}
            />
          </div>
          <div className='l-grid__col-2-12'>
            <Input
              placeholder='Amount'
              value={this.state.amount}
              onChange={(value) => this.setState({ amount: Number(value) })}
            />
          </div>
          <div className='l-grid__col-2-12'>
            <Input
              placeholder='Training'
              value={this.state.courseName}
              onChange={(value) => this.setState({ courseName: Number(value) })}
            />
          </div>
        </div>
        <div className='l-grid__row'>
          <div className='l-grid__col-3-12'>
            <Button
              type='button'
              onClick={this.sendEmail}
              fields={{
                ButtonText: 'Send'
              }}
            />
          </div>
        </div>
      </div>);
  }
}

export default ExmPoc;
