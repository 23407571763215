/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Item from './Item';
import object from '../../utils/object'

/**
 * Definition
 * =============================================================================
 */
const LinkList = (props) => {
  const {
    children,
    classes = ''
  } = props;
  const {
    Direction,
    Aligment,
    HalfSize,
    WithMargin
  } = props.fields;
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItem = (component, index) => {
    return (
      <Item key={`link-list-item-${index}`}>
        {component}
      </Item>
    );
  };

  const renderItems = () => {
    return (
      <Placeholder
        {...props}
        name='link-list'
        renderEach={renderItem}
      />
    );
  };

  const getAlignClass = () => {

    const align = object.getNested(Aligment, 'fields', 'Value', 'value');

    if (!align) return '';

    return 'm-link-list--align-'+align;
  };
  
  const getDirectionClass = () => {

    const direction = object.getNested(Direction, 'fields', 'Value', 'value');

    if (!direction) return '';

    return 'm-link-list--direction-'+direction;
  };

  
  const getHalfsizeClass = () => {

    const halfsize = object.getNested(HalfSize, 'value');

    if (!halfsize) return '';

    return 'm-link-list--half-size';
  };

  const getWithMarginClass = () => {

    const withMargin = object.getNested(WithMargin, 'value');

    if (!withMargin) return '';

    return 'm-link-list--with-margin';
  };
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <ul className={`m-link-list ${classes} ${getWithMarginClass()} ${getHalfsizeClass()} ${getAlignClass()} ${getDirectionClass()}`}>
      {children ? children : renderItems()}
    </ul>
  );
};

export default LinkList;
