import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const withHandleNotFound = () => (Component) => {
    // Prevent GraphQL to execute without dataSource set
    return class extends React.Component {
      render() {
        const { props } = this;
        if (!props.rendering.dataSource) {
          // When course is not found (dataSource is null), render 404 page.
          const rendering = {
            ...props.sitecoreContext.notFound.sitecore.route.placeholders['jss-main']
              .filter(x => x.placeholders)
              .find(x => x.placeholders['app-main'])
          };
          return <Placeholder name='app-main' rendering={rendering} />;
        }
        return <Component {...props}/>
      }
    };
  }

export default withHandleNotFound;