/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ url, text, onClick }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='m-option-list__item'>
      <a href={url} className='m-option-list__link' onClick={onClick}>
        <span className='m-option-list__text'>
          {text}
        </span>

        <span className='m-option-list__icon'></span>
      </a>
    </li>
  );
};

export default Item;
