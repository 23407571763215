/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formfield from './../Formfield';
import { withFormsy } from 'formsy-react';
import { withInputFlyout } from '../../hoc';
import DateTimePicker from '../DateTimePicker';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { InputDateFormat } from '../../lib/Constants';
/**
 * Definition
 * =============================================================================
 */
class DateInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.hadError = false;
    this.state = {
      value: this.props.value || '',
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setClasses() {
    const { iconBefore, iconAfter, button } = this.props;

    const beforeClass = iconBefore
      ? 'a-formfield__wrapper--with-icon-before'
      : '';
    const afterClass = iconAfter ? 'a-formfield__wrapper--with-icon-after' : '';
    const buttonClass = button ? 'a-formfield__wrapper--with-button' : '';

    return `${beforeClass} ${afterClass} ${buttonClass}`;
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleFocus = () => {
    if (this.handleInputFlyoutFocus) this.handleInputFlyoutFocus();
    if (this.onFocus) this.onFocus();
  };

  handleBlur = () => {
    if (this.handleInputFlyoutBlur) this.handleInputFlyoutBlur();
    if (this.onBlur) this.onBlur();
  };
  handleChange = (value) => {
    const { onChange, name } = this.props;
    this.setState(
      {
        value: moment(value[0]).format(InputDateFormat),
      },
      () => {
        if (onChange) onChange(this.state.value);
      }
    );
    this.props.setValue(this.state.value);
  };

  handleIconAfterClick = () => {
    const { onIconAfterClick, name } = this.props;
    const { value } = this.state;

    if (onIconAfterClick) onIconAfterClick(name, value);
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderButton() {
    const { button } = this.props;

    return <div className='a-formfield__button'>{button}</div>;
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes = '',
      iconAfterClasses = '',
      label,
      iconBefore,
      name,
      id,
      placeholder,
      iconAfter,
      button,
      tooltip,
      children,
      setFlyoutState = '',
      handleInputFlyoutFocus,
      handleInputFlyoutBlur,
      handleInputFlyoutMouseEnter,
      handleInputFlyoutMouseLeave,
      message,
      contentStyle = {},
      reference,
      handleOverlayTriggerClick = null,
      showRequired,
      showError,
      getErrorMessage,
      noFlyout,
      dateFormat,
      minDate,
      maxDate,
      sitecoreContext,
      onFocus,
      onBlur,
    } = this.props;

    const { value } = this.state;

    const validClass =
      showRequired && this.hadError
        ? 'is-error'
        : showError
        ? 'is-error'
        : this.hadError
        ? 'is-succes'
        : null;
    if (showError) this.hadError = true;

    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const errorMessage = getErrorMessage;
    //this.handleInputFlyoutFocus = handleInputFlyoutFocus;
    //this.handleInputFlyoutBlur = handleInputFlyoutBlur;
    const formfieldClass = noFlyout ? 'm-formfield' : 'm-formfield-flyout';

    return (
      <div
        ref={reference}
        className={`${formfieldClass} ${classes} ${setFlyoutState()}`}
      >
        <Formfield
          classes={`m-formfield-flyout__trigger ${classes} ${validClass}`}
          label={label}
          name={id}
          tooltip={tooltip}
          handleOverlayTriggerClick={handleOverlayTriggerClick}
        >
          <div className={`a-formfield__wrapper ${this.setClasses()}`}>
            {iconBefore && (
              <span className={`a-formfield__icon-before ${iconBefore}`}></span>
            )}
            <DateTimePicker
              selected={value ? moment(value, InputDateFormat).toDate() : null}
              onChange={this.handleChange}
              value={value ? moment(value, InputDateFormat).toDate() : ''}
              id={id}
              name={name}
              className={`a-formfield__input ${classes}`}
              placeholder={placeholder || ''}
              onOpen={onFocus}
              onClose={onBlur}
              options={{
                dateFormat: dateFormat,
                minDate: minDate,
                maxDate: maxDate,
                locale: sitecoreContext.language.toLowerCase(),
              }}
            />

            {iconAfter && (
              <span
                className={`a-formfield__icon-after ${iconAfter} ${iconAfterClasses}`}
                onClick={this.handleIconAfterClick}
              />
            )}

            {button && this.renderButton()}
          </div>
        </Formfield>
        {(children || message || errorMessage) && (
          <div
            className='m-formfield-flyout__content'
            style={contentStyle}
            onMouseEnter={handleInputFlyoutMouseEnter}
            onMouseLeave={handleInputFlyoutMouseLeave}
          >
            {errorMessage && (
              <p className='m-formfield-flyout__message'>{errorMessage}</p>
            )}
            {!errorMessage && message && (
              <p className='m-formfield-flyout__message'>{message}</p>
            )}
            {!errorMessage && children && (
              <div className='m-formfield-flyout__content-container'>
                {children}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withSitecoreContext()(withFormsy(withInputFlyout()(DateInput)));
