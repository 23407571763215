/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Tooltip from '../Tooltip';

/**
 * Definition
 * =============================================================================
 */
const Definition = ({
  classes = '',
  term,
  description,
  tooltip
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-definition s-rte-definition ${classes}`}>
      <span className='a-definition__term'>
        {term}
      </span>

      <div className='a-definition__description'>
        {description}
      </div>
      
      {tooltip && <Tooltip
              position='top'
              content={tooltip}
            />}

    </div>
  );
};

export default Definition;
