/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Root = ({ isArticle, children }) => {
  const classes = 'm-card';

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return isArticle ? (
    <article className={classes}>
      {children}
    </article>
  ) : (
    <div className={classes}>
      {children}
    </div>
  );
};

export default Root;
