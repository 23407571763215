import IFilterItem from './IFilterItem';
import flatten from 'array-flatten';
import IFilter from './IFilter';
import { Base64 } from 'js-base64';

interface IFacet {
  name: string;
  values: Array<IFacetValue>;
}

interface IFacetValue {
  aggregateCount: number;
  name: string;
}

const ensureStartingSlash = (x: string) => x.startsWith('/') ? x : `/${x}`;

const retrievePlain = (
  facets: Array<IFacet>,
  name: string,
  lng: string,
  transformFunc?: (x: string) => string
): Array<IFilterItem> => {
  const transform = transformFunc || (x => x);
  const facet =
    facets.find(x => x.name === name) || <IFacet>{ name: '', values: [] };
  const result = facet.values.map(
    (x) =>
      <IFilterItem>{
        text: transform(getLocalized(x.name, lng)),
        itemId: getLocalized(x.name, 'id'),
        id: `${x.name}`,
        amount: x.aggregateCount,
      }
  );
  return result;
};

const retrieveHierarchical = (
  facets: Array<IFacet>,
  name: string,
  lng: string
): Array<IFilterItem> => {
  const facet = facets.find(x => x.name === name) || <IFacet>{ name: '', values: [] };

  const buildHierarchy = (
    paths: Array<string>,
    root: string,
    value: number
  ): IFilterItem => {
    return paths.length == 1
      ? <IFilterItem>{
          text: getLocalized(paths[0], lng),
          itemId: getLocalized(paths[0], 'id'),
          id: `${root}/${paths[0]}`,
          amount: value
        }
      : <IFilterItem>{
          text: getLocalized(paths[0], lng),
          itemId: getLocalized(paths[0], 'id'),
          id: `${root}/${paths[0]}`,
          children: [
            buildHierarchy(paths.slice(1), root + '/' + paths[0], value)
          ]
        };
  };


  const facetsWithHierarchy = facet.values.map(x =>
    buildHierarchy(ensureStartingSlash(x.name).split('/').slice(1), '', x.aggregateCount)
  );

  const buildMap = (arr: Array<IFilterItem>, srcMap: Map<string, any>) => {
    return arr.reduce((map, item) => {
      if (map.has(item.id)) {
        map.get(item.id).childrenMap = buildMap(
          item.children || [],
          map.get(item.id).childrenMap
        );
      } else {
        map.set(item.id, {
          item: item,
          childrenMap: buildMap(item.children || [], new Map<string, any>())
        });
      }
      return map;
    }, srcMap);
  };

  var fullMap = buildMap(facetsWithHierarchy, new Map());

  const convertToArray = (map: Map<string, any>): Array<IFilterItem> => {
    return Array.from(map).map(x => {
      return <IFilterItem>{
        id: x[1].item.id,
        itemId: x[1].item.itemId,
        amount: x[1].item.amount,
        text: x[1].item.text,
        name: x[1].item.name,
        children: convertToArray(x[1].childrenMap)
      };
    });
  };

  return convertToArray(fullMap);
};

const tryDecodeBase64 = (maybeBase64json: string): string => {
  if (typeof maybeBase64json !== 'string') {
    return maybeBase64json;
  }
  if (/^[a-zA-Z0-9+/=]+$/.test(maybeBase64json)) {
    try{
      const decoded = Base64.decode(maybeBase64json);
      if (/^[{[]/.test(decoded)) {
        return decoded;
      }
    }
    catch{
      console.error("Error decoding base64", maybeBase64json);
    }
  }

  return maybeBase64json;
};

const getLocalized = (maybeBase64json: string, lng: string): string => {
  if (typeof maybeBase64json !== 'string') {
    return maybeBase64json;
  }
  const decoded = tryDecodeBase64(maybeBase64json);
  let obj;
  try {
    obj = JSON.parse(decoded);
    const type = Object.prototype.toString.call(obj);
    if (type !== '[object Object]') {
      return decoded;
    }
  } catch (err) {
    return decoded;
  }

  const value = obj[lng] || obj['en'] || obj['nl'] || obj['fr'] || obj['de'] || obj[lng];
  return typeof value === "undefined" ? decoded : value;
};

const extractActiveFilterItems = (
  itemArr: IFilterItem[],
  mapFunc: (x: IFilterItem) => any = x => x
): IFilterItem[] | string[] | any[] => {
  return flattenFilterItems(itemArr)
    .filter(x => x.isChecked)
    .map(mapFunc);
};

const flattenFilterItems = (arr?: IFilterItem[]): IFilterItem[] =>
  arr
    ? <IFilterItem[]>(
        <unknown>flatten(arr.map(x => [x, flattenFilterItems(x.children)]))
      )
    : [];

const setFilterActiveState = (
  dst: IFilter,
  src: IFilter | undefined
): IFilter => {
  if (!src) {
    return dst;
  }
  const activate = (items: IFilterItem[], activeIds: string[]): IFilterItem[] =>
    items
      .map(x => {
        const item = {
          ...x,
          isChecked: activeIds.find(y => y === x.id) !== undefined
        };
        if (item.children) {
          item.children = activate(item.children, activeIds);
        }
        return item;
      });

  return {
    availablePlaces: src.availablePlaces,
    availableInCompany: src.availableInCompany,
    durations: activate(dst.durations, <string[]>(
      extractActiveFilterItems(src.durations, x => x.id)
    )),
    languages: activate(dst.languages, <string[]>(
      extractActiveFilterItems(src.languages, x => x.id)
    )),
    learnMethod: activate(dst.learnMethod, <string[]>(
      extractActiveFilterItems(src.learnMethod, x => x.id)
    )),
    locations: activate(dst.locations, <string[]>(
      extractActiveFilterItems(src.locations, x => x.id)
    )),
    sectors: activate(dst.sectors, <string[]>(
      extractActiveFilterItems(src.sectors, x => x.id)
    )),
    themes: activate(dst.themes, <string[]>(
      extractActiveFilterItems(src.themes, x => x.id)
    ))
  };
};

const setIsCheckedDeep = (
  items: IFilterItem[],
  isChecked: boolean
): IFilterItem[] =>
  items.map(x => {
    const item = { ...x, isChecked };
    if (item.children) {
      item.children = setIsCheckedDeep(item.children, isChecked);
    }
    return item;
  });

export {
  retrieveHierarchical,
  retrievePlain,
  extractActiveFilterItems,
  getLocalized,
  flattenFilterItems,
  setFilterActiveState,
  setIsCheckedDeep
};
