/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const IconButton = ({
  classes = '',
  icon = '',
  title='button',
  onClick = () => {}
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <button
      className={`a-icon-button ${classes}`}
      onClick={onClick}
      title={title}
    >
      <span className={`a-icon-button__icon ${icon}`}></span>
    </button>
  );
};

export default IconButton;
