/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import { ExperienceEditorHint } from './../building-blocks';

/**
 * Definition
 * =============================================================================
 */
const withHint = ({ content }) => (WrappedComponent) => {

  return class extends React.Component {

    /**
    * Renders
    * ---------------------------------------------------------------------------
    */
    renderHint() {
      return <ExperienceEditorHint content={content} />;
    };

    /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    render() {
      return (
        <React.Fragment>
          {(isEditorActive() && content) && this.renderHint()}

          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  }
}

export default withHint;
