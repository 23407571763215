/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import moment from 'moment';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from '../../hoc/withTranslation';
import { date } from '../../utils';
/**
 * Definition
 * =============================================================================
 */
const Modules = ({ items, isJobSeekerCourse, extraInformation, t }) => {
  const renderIcon = (icon) => {
    return icon ? (
      <span className={`a-sequence__icon icon-${icon}`}></span>
    ) : null;
  };

  const extraInformationMoment =
    extraInformation && moment(extraInformation, 'DD-MM-YYYY');
  const extraInformationFormatted =
    extraInformationMoment &&
    extraInformationMoment.isValid() &&
    extraInformationMoment.format("dd D MMM 'YY");

  const showExtraInformation = isJobSeekerCourse && extraInformationFormatted;

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <ul>
      {showExtraInformation && (
        <li>
          <strong>{t('sessionCard.jobseekerTrainingPeriod')}</strong>
        </li>
      )}
      {items.map((i, index) => (
        <li key={`sessino-card-modules-item-${index}`}>
          {renderIcon(i.icon)} {date.formatDateIfHourReact(i.start, i.end)}
        </li>
      ))}
      {showExtraInformation && (
        <>
          <li>
            <strong>{t('sessionCard.jobseekerExtraInformation')}</strong>
          </li>
          <li>{extraInformationFormatted}</li>
        </>
      )}
    </ul>
  );
};

export default withSitecoreContext()(withTranslation()(Modules));
