/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import Item from './Item';

 /**
    * Definition
    * ============================================================================
*/
const ProgressBar = ({
  classes = '',
  steps = 5,
  active = 3,
  t,
}) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getClasses = () => {
    const dots = steps > 7
      ? 'm-progress-bar--dots'
      : '';

    return `${classes} ${dots}`;
  };

  const getItemState = (index) => {
    return (
      (index < active && 'is-completed') ||
      (index === active && 'is-active') ||
      ''
    );
  };

  const getItemModifiers = (index) => {
    return (
      (index === 1 && 'm-progress-bar__item--first') ||
      (index === steps && 'm-progress-bar__item--last') ||
      ''
    );
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
    const renderSteps = () => {
      let stepsArray = [];

      for (let i = 1; i <= steps; i++) {
        stepsArray.push(i);
      }

      return stepsArray.map((s) => {
        return (
          <Item
            key={`progress-bar-item-${s}`}
            classes={`${getItemModifiers(s)} ${getItemState(s)}`}
            index={s}
          />
        );
      });
    }

    /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    return (
      <div className={`m-progress-bar ${getClasses()}`}>
        <div className="m-progress-bar__wrapper">
          <button className="m-progress-bar__arrow m-progress-bar__arrow--left" />

          <div className="m-progress-bar__items">
            {renderSteps()}
          </div>

          <button className="m-progress-bar__arrow m-progress-bar__arrow--right" />
        </div>

        <p className="m-progress-bar__text">
          {t('progressBar.currentStep')}

          <strong className="m-progress-bar__current">
            &nbsp;{active}/{steps}
          </strong>
        </p>
      </div>
    );
};

 export default withTranslation()(ProgressBar);
