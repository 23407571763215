/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object } from './../../utils';
import Col from './Col';

/**
 * Definition
 * =============================================================================
 */
const Grid = (props) => {
  const {
    GridType,
    GridHasSpacing,
    GridItemsHasSameHeight
  } = props.fields;

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getClasses = () => {
    const hasSpacing = object.getNested(GridHasSpacing, 'value') ? ' l-grid--with-margins' : '';
    const sameHeight = object.getNested(GridItemsHasSameHeight, 'value') ? ' l-grid--same-height' : '';

    return `${hasSpacing}${sameHeight}`;
  };

  /**
  * Renders
  * ---------------------------------------------------------------------------
  */
  const renderColumn = (side, name) => {
    return (
      <Col type={GridType} side={side}>
        <Placeholder {...props} renderEach={undefined} name={name} />
      </Col>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`l-grid ${getClasses()}`}>
      {renderColumn('left', 'grid-item-left')}
      {object.getNested(GridType, 'fields', 'Value', 'value') && object.getNested(GridType, 'fields', 'Value', 'value') === '1-1-1' && renderColumn('middle', 'grid-item-middle')}
      {renderColumn('right', 'grid-item-right')}
    </div>
  );
};

export default Grid;
