/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import ResolveHint from './ResolveHint';
import { Checkbox } from '..';

/**
 * Definition
 * =============================================================================
 */
const CheckboxWithHint = (props) => {
    const { itemId } = props;
    return (
      <div className="a-togglewithhint__container">
        <Checkbox {...props} />
        <ResolveHint itemId={itemId} />
      </div>
    );
}

export default CheckboxWithHint;
