/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../../hoc/withTranslation';
import Item from './Item';

const durationWeights = {
  '0d': 1,
  '0.5d': 2,
  '1d': 3,
  '1.5d': 4,
  '2d': 5,
  '3d': 6,
  '5d': 7,
  '20d': 8,
  '1m': 9,
  '2m': 10,
  '3m': 11,
  '4m': 12,
};

/**
 * Definition
 * =============================================================================
 */
class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    const { data: formData = {} } = props;
    this.state = {
      formData,
    };
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleUpdate = (name, checked) => {
    const { formData } = this.state;
    formData[name] = checked;
    this.setState(
      { formData },
      () => this.props.onChange && this.props.onChange(this.state.formData)
    );
  };

  handleOptionsUpdate = (name, checked) => {
    const updatedFormData = Object.fromEntries(checked.map(x => [x.id, x.isChecked]))
    let { formData } = this.state;
    formData = { ...formData, ...updatedFormData };
    this.setState(
      { formData },
      () => this.props.onChange && this.props.onChange(this.state.formData)
    );
  }

  componentWillReceiveProps(nextProps) {
    // copy formData
    const formData = JSON.parse(JSON.stringify(nextProps.data));

    this.setState({ formData });
  }

  /** 
   * Render item
   * ---------------------------------------------------------------------------
   * 
  */

  renderFilterItem(filter) {
    return (filter && filter.items && filter.items.length > 0 && (
      <Item
        name={filter.facetName}
        title={filter.title}
        data={filter.items}
        onUpdateFields={this.handleUpdate}
        isOpen={!filter.settings.foldedByDefault}
        forceOpenState={true}
      />
    ));
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes = '', t, getFacetSettings } = this.props;

    const { formData = {} } = this.state;

    const {
      locations,
      learnMethod,
      durations,
      themes,
      sectors,
      languages,
    } = formData;

    const options = [
      {
        text: t('searchResultsPage.filter.withAvailablePlaces'),
        itemId: 'availablePlaces',
        id: 'availablePlaces',
        amount: 0,
        isChecked: formData.availablePlaces,
      },
      {
        text: t('searchResultsPage.filter.thatAvailableInCompany'),
        itemId: 'availableInCompany',
        id: 'availableInCompany',
        amount: 0,
        isChecked: formData.availableInCompany,
      },
    ];

    const filters = [
      {
        facetName: 'languages',
        title: t(`searchResultsPage.filter.language`),
        items: languages,
        settings: getFacetSettings('languages')
      },
      {
        facetName: 'themes',
        title: t(`searchResultsPage.filter.themes`),
        items: themes,
        settings: getFacetSettings('themes')
      },
      {
        facetName: 'sectors',
        title: t(`searchResultsPage.filter.sectors`),
        items: sectors,
        settings: getFacetSettings('sectors')
      },
      {
        facetName: 'learnMethod',
        title: t(`searchResultsPage.filter.learningmethod`),
        items: learnMethod,
        settings: getFacetSettings('learnMethod')
      },
      {
        facetName: 'durations',
        title: t(`searchResultsPage.filter.duration`),
        items: durations?.sort((a, b) => durationWeights[a.id] - durationWeights[b.id]),
        settings: getFacetSettings('durations')
      },
      {
        facetName: 'locations',
        title: t(`searchResultsPage.filter.location`),
        items: locations,
        settings: getFacetSettings('locations')
      },
      {
        facetName: 'options',
        title: t(`searchResultsPage.filter.showOnlyTrainings`),
        items: options,
        settings: getFacetSettings('options')
      },
    ].sort((a, b) => a.settings.sortOrder - b.settings.sortOrder);

    return (
      <form className={`o-filter ${classes}`}>
        <div className='o-filter__content'>
          {filters.map(filter => this.renderFilterItem(filter))}
        </div>
        <div className='o-filter__footer'>
        </div>
      </form>
    );
  }
}

export default withTranslation()(SearchFilter);
