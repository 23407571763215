/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import BasketOverview from './../../pages/BasketOverview';

/**
 * Definition
 * =============================================================================
 */
const Connected = (props) => {

  return (
    <BasketOverview {...props} />
  );
}

export default Connected
