import { object } from '../../../../utils';

/**
 * Get dependent fields
 * =============================================================================
 * Returns fields that are dependent on some another field value
 * 
 */

const getDependentFields = (fieldsObject) => {
  let dependentFields = [];

  object.forEachNested(fieldsObject, function (curr) {
    if (curr && curr.constructor === Object &&
      curr.fieldTypeItemId) {

      if (curr.dependentField && dependentFields.indexOf(curr.dependentField) < 0) {
        dependentFields.push(curr.dependentField);
      }
    }
  });

  return dependentFields;
};

const getAllFieldValues = (fieldsObject) => {
  let fieldValues = {};

  object.forEachNested(fieldsObject, function (curr) {
    if (curr && curr.constructor === Object &&
      curr.fieldTypeItemId) {
      fieldValues[curr.name] = curr.value;
    }
  });

  return fieldValues;
};

/**
 * Exports
 * =============================================================================
 */
export default {
  getDependentFields,
  getAllFieldValues
};
