/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Breadcrumbs from './Breadcrumbs';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BreadcrumbsStructuredData from '../BreadcrumbsStructuredData';

const Connected = (props) => {
  const { sitecoreContext } = props;

  const breadcrumbs = (sitecoreContext.navigationData.breadcrumbs || []).slice(1, -1);
  const breadcrumbsStructuredData = sitecoreContext.navigationData.breadcrumbs || [];

  return (
    <React.Fragment>
      <Breadcrumbs {...props} data={breadcrumbs} />
      <BreadcrumbsStructuredData {...props} breadcrumbs={breadcrumbsStructuredData} />
    </React.Fragment>
  );
}

export default withSitecoreContext()(Connected);
