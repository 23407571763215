/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { type } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Badge = ({
  classes,
  text,
  onClick = () => {},
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const setDisabledState = () => {
    return text <= 0 ? 'is-disabled' : '';
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderNumber = () => {
    return parseInt(text) > 9 ? '9+' : text;
  };

 /**
  * Main render
  * ----------------------------------------------------------------------------
  */
  return (
    <span
      className={`a-badge ${classes} ${setDisabledState()}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {text && type.isReact(text) ? text : renderNumber()}
    </span>
  );
};

export default Badge;
