/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import object from '../../utils/object'
import { url as urlUtils } from '../../utils'

/**
 * Definition
 * =============================================================================
 */
const CourseCarouselStructuredData = ({ courses, sitecoreContext }) => {
  const pageUrlWithPort = object.getNested(sitecoreContext, 'navigationData', 'pageHost');
  const pageUrl = urlUtils.urlWithoutPort(pageUrlWithPort);
  const courseStructData = {
    "@context": "https://schema.org",
    "@type": "ItemList",
    itemListElement: courses.map(
      ({ url }, idx) => {
        const absoluteItemUrl = urlUtils.isRelative(url) ? pageUrl + url : url;
        return {
          '@type': 'ListItem',
          'position': idx + 1,
          'url': absoluteItemUrl
        }
      }
    )
  };

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(courseStructData) }}></script>
  );
}

export default CourseCarouselStructuredData;