import React from 'react';
import { withFlyout } from './../../hoc';
import IconButton from '../../building-blocks/IconButton';

const TooltipWrapper = ({
  tooltip,
  classes = '',
  cssClass = '',
  position = '', // top | left | bottom | right
  children,
  setFlyoutState = '',
  handleToggleFlyout,
  handleOpenFlyout,
  handleCloseFlyout,
  isMobile,
  label
}) => {

  const labelEl = label && <p className='m-tooltip__label'>{label}</p>;

  if (!tooltip) {
    return (
      <>
        {labelEl}
        {children}
      </>
    );
  }

  return (
    <div
      className={`m-tooltip ${position ? 'm-tooltip--'+position : ''} ${setFlyoutState()} ${cssClass}`}
    >
      {labelEl}
      <div className='m-tooltip__container'>
        <div
          className={classes}
          onClick={handleToggleFlyout}
          onMouseEnter={() => !isMobile && handleOpenFlyout()}
          onMouseLeave={() => !isMobile && handleCloseFlyout()}
        >
          {children}
        </div>

        <div className='m-tooltip__flyout'>
          <div
            className='m-tooltip__backdrop u-desktop-hide'
            onClick={handleCloseFlyout}
          />

          <div className='m-tooltip__content'>
            <IconButton
              classes='a-icon-button--animated a-icon-button--inverted m-tooltip__close u-desktop-hide'
              icon='icon-cancel'
              onClick={handleCloseFlyout}
            />

            <p className='m-tooltip__text'>{tooltip}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFlyout()(TooltipWrapper);
