/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { IconButton } from '../../building-blocks';
import { date, url } from '../../utils';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
/**
 * Definition
 * =============================================================================
 */
const Item = ({ item, onRemoveItemClick, history }) => {
  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleRemoveItem = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (onRemoveItemClick) {
      onRemoveItemClick(item.id);
    }
  };
  const getCourseTitle = item => {
    if (item == null) return '';

    var title = item.courseTitle;
    if (item.courseType == 2) return title;
    if (item.dateFrom && !item.isVirtual) {
      title += ' - ' + date.formatDate(item.dateFrom);
    }
    return title;
  };

  const handleClickItem = e => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    history.push(url.relativePath(e.currentTarget.href));
  };
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <li className='m-product-list__item'>
      <div className='a-added-product'>
        <a
          onClick={handleClickItem}
          className='a-added-product__link'
          href={item.courseUrl}
        >
          {getCourseTitle(item)}
        </a>

        <IconButton
          classes='a-icon-button--animated a-added-product__button'
          icon='icon-cancel'
          onClick={handleRemoveItem}
        />
      </div>
    </li>
  );
};

export default withRouter(Item);
