import React, { useState } from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import Formsy from 'formsy-react';
import { IconButton, Input } from '../../building-blocks';
import ChoiceList from '../ChoiceList';

const Filter = ({
  t,
  sectors,
  trainings,
  regions,
  filter: initialFilter = {},
  onChange
}) => {
  const {
    sector: initSector = {},
    training: initTraining = {},
    region: initRegion = {}
  } = initialFilter;
  const [filter, setFilter] = useState({
    sector: initSector,
    training: initTraining,
    region: initRegion
  });

  const setNewFilter = newFilter => {
    setFilter(newFilter);
    if (onChange) {
      onChange(newFilter);
    }
  };

  const handleChangeInput = (name, value, text) => {
    const newFilter = { ...filter };
    newFilter[name] = (value !== '-' && { text, value }) || {};
    setNewFilter(newFilter);
  };

  const handleRemoveFilterClick = () => {
    setNewFilter({ sector: {}, training: {}, region: {} });
  };

  const datasources = {
    sector: [
      { text: t('internships.filter.empty'), value: '-', id: 's-' },
      ...sectors
    ],
    training: [
      { text: t('internships.filter.empty'), value: '-', id: 't-' },
      ...trainings
    ],
    region: [
      { text: t('internships.filter.empty'), value: '-', id: 'r-' },
      ...regions
    ]
  };

  const field = fieldName => {
    return (
      <div className='o-search__item o-search__item--disperse l-grid__col-12-12'>
        <Input
          classes={`a-formfield--choice a-formfield--straight-corners@above-desktop-base`}
          label={t(`internships.filter.${fieldName}.label`)}
          placeholder={t(`internships.filter.${fieldName}.placeholder`)}
          iconAfter='icon-arrow-down'
          id={fieldName}
          name={`${fieldName}Text`}
          autoComplete={false}
          readonly={true}
          value={(filter[fieldName] && filter[fieldName].text) || ''}
        >
          <ChoiceList
            name={fieldName}
            active={filter[fieldName]}
            onChange={handleChangeInput}
            items={datasources[fieldName]}
          />
        </Input>
      </div>
    );
  };

  return (
    <>
      <Formsy>
        <div className='o-search__container l-container@below-desktop-base'>
          <div className='o-search__fields l-grid@below-desktop-base l-grid--with-margins'>
            {field('sector')}
            {field('training')}
            {field('region')}
          </div>
        </div>
      </Formsy>
    </>
  );
};

export default withTranslation()(Filter);
