import { splitTerms } from "../transform";

/**
 * Parse
 * =============================================================================
 */
const parse = (search, t, regionsMap) => {
  if (!search) {
    return [];
  }
  let { term = '', searchAs, closeTo } = search;
  let result = [];

  if (term) {
    result.push(
      ...splitTerms(term)
        .map((x, index) => {
          return {
            text: x,
            key: 'term',
            id: index
          };
        })
        .filter(t => t.text)
    );
  }

  result.push(
    ...['from', 'to']
      .filter(x => search[x])
      .map(x => ({
        text: `${t('search.pills.label.' + x)}: ${search[x]}`,
        id: `pills-${x}`,
        key: x
      }))
  );

  if (closeTo) {
    const regionItem =
      Array.isArray(regionsMap) && regionsMap.find(x => x.value === closeTo);
    const regionTranslated = (regionItem && regionItem.text) || closeTo;
    result.push({
      text: `${t('search.pills.label.closeTo')}: ${regionTranslated}`,
      id: 'pills-closeTo',
      key: 'closeTo'
    });
  }

  if (searchAs) {
    const searchAsText =
      { EM: 'asEmployee', JS: 'asJobSeeker', HR: 'asLiable' }[searchAs] ||
      searchAs;
    result.push({
      text: `${t('search.pills.label.searchAs')}: ${t('searchResultsSearch.searchAs.options.' + searchAsText)}`,
      id: 'pills-searchAs',
      key: 'searchAs'
    });
  }
  return result;
};

export default parse;
