/**
 * Imports
 * =============================================================================
 */
import { object } from "./../../../utils";

/**
 * Parse
 * =============================================================================
 */

const parse = (obj) => {
  let props = Object.assign({}, obj);
  if (!props.fields) {
    return;
  }
  props.fields = object.renameKey('ButtonText', 'DisclosureLinkText', props.fields);
  props.fields = object.renameKey('ButtonUrl', 'DisclosureLinkUrl', props.fields);

  return props;
};

/**
 * Exports
 * =============================================================================
 */
export default parse;
