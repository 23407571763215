/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import DisclosureLink from './../DisclosureLink';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import cardParsers from './parsers/card';
import cardSecondaryParsers from './parsers/cardSecondary';
import ctaParsers from './parsers/cta';
import DisclosureListItem from './../DisclosureList/Item';

/**
 * Definition
 * =============================================================================
 */
const MobileItem = (props) => {
  const { componentName } = props;
  const name = componentName.toLowerCase();

  const data = (
    (name === 'cta' && ctaParsers(props)) ||
    (name === 'card' && cardParsers(props)) ||
    (name === 'cardsecondary' && cardSecondaryParsers(props)) ||
    null
  );

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return data ? (
    <DisclosureListItem>
      <DisclosureLink {...data} />
    </DisclosureListItem>
  ) : (
    isEditorActive() ? (
      'componentName unsupported'
    ) : null
  );
};

export default MobileItem;
