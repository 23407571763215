/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object } from './../../utils';
import { Link } from '../../building-blocks';
import useLazyLoad from '../../hooks/useLazyLoad';

/**
 * Definition
 * =============================================================================
 */
const ArticleTeaser = props => {
    const {
        Image,
        ImageAltText,
        Title,
        LinkText,
        LinkUrl
    } = props.fields;

    const { value: altText } = ImageAltText;
    const hasBackground =
        object.hasNested(Image, 'value', 'src') &&
        'scEmptyImage' !== object.getNested(Image, 'value', 'class');

    /**
     * Helpers
     * ---------------------------------------------------------------------------
     */

    const getBackgroundImage = () => {
      const image = object.getNested(Image, 'value', 'src');
      return hasBackground ? `url(${image})`  : ``;
    };

    /**
     * Renders
     * ---------------------------------------------------------------------------
     */

    const renderCta = () => {
      return (
          <Link classes="a-link m-article-teaser__link a-link--decorated " fields={{
                LinkText: LinkText,
                LinkUrl: LinkUrl
            }}>
          </Link>
        )
    }

    useLazyLoad();

    /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    return (
      <article className="m-article-teaser ">
          <div className="m-article-teaser__container">
              <div className="m-article-teaser__image lazy" data-bg-multi={getBackgroundImage()} role="img" aria-label={altText}>
              </div>
              <div className="m-article-teaser__content">
                  <h4 className="m-article-teaser__title"><Text field={Title}/></h4>
                  {((LinkText && LinkUrl) || isEditorActive()) && renderCta()}
              </div>
          </div>
      </article>
    );
};

export default ArticleTeaser;
