/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import SubItem from './SubItem';

/**
 * Definition
 * =============================================================================
 */
const Sub = ({ items,isMobile }) => {
  const renderItems = () => {
    return items.map((i, index) => {
      return (
        <SubItem isMobile={isMobile} key={`navigation-items-sub-item-${index}`} link={i.link} title={isMobile?i.mobileTitle:i.title} />
      );
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <ul className='o-nav__sub'>
      {renderItems()}
    </ul>
  );
};

export default Sub;
