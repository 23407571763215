/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import RegisterForm from './../../pages/RegisterForm';

/**
 * Globals
 * =============================================================================
 */
const data = {
  fields: {
    // Convention: fields are starting with Capital, while non-field props with small letter
    HeroTitle: { value: 'Grafieken en 3D-effecten met Adobe Illustrator' },
    HeroDescription: { value: 'Illustrator is een veelzijdig pakket dat meestal de duimen moet leggen tegen die andere kleppers van Adobe, namelijk Photoshop (beeldbewerking) en InDesign (opmaak). Nochtans leef je je grafisch pas écht uit met Illustrator. Vaak is de beperkte technische beheersing van Illustrator een domper op de creativiteit. Deze specifieke opleiding mikt op een aantal gerichte thema’ s zoals grafieken en 3D-effecten om jouw grafische creativiteit een flinke boost te geven!' },
    HeroImage: { value: { src: '/data/media/img/banner/placeholder.png', alt: 'Grafieken en 3D-effecten met Adobe Illustrator' } },
    Objective: { value: '<ul><li>Stijlvolle grafieken aanmaken vanuit Adobe Illustrator</li><li>3D-effecten vanuit Adobe Illustrator realiseren</li><li>Grafische mogelijkheden van Adobe Illustrator uitgebreid benutten</li></ul>' },
    TargetAudience: { value: '<p><strong>Deze opleiding is bedoeld voor</strong></p><ul><li>Jobzoekers binnen de grafische sector (zie <a href="#">opleidingstraject</a>)</li><li>Grafische vormgevers die zich wensen te specialiseren</li></ul>' },
    Prerequisites: { value: '<ul><li>Basis kennis Adobe Illustrator</li><li>Basis kennis Grafische</li><li><a href="">Grafisch vormgever De basis</a> <small>(opleiding)</small></li></ul>' },
    Overview: { value: '<ol><li><h5>Overzicht van het programma</h5><ul><li>Drie fouten die je dient te vermijden indien je jouw inlevingsvermogen wilt tonen</li><li>Drie fouten die je dient te vermijden indien je jouw inlevingsvermogen wilt tonen</li></ul></li><li><h5>Hoe breng je jouw inlevingsvermogen in praktijk?</h5><ul><li>Drie belangrijke gewoontes om inlevingsvermogen te tonen in de praktijk</li><li>Een welwillende neutrale houding = essentie van inlevingsvermogen</li><li>Welke kwaliteiten heb je nodig om je in te leven?</li></ul></li></ol><video controls><source src="/data/media/video/cevora.mp4" type="video/mp4" /></video>' },
    JobOpportunities: { value: '<p>Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Nullam id dolor id nibh ultricies vehicula ut id elit. Integer posuere erat a ante venenatis dapibus posuere velit aliquet.</p>' },
    ExtraInformation: { value: '<p>Etiam porta sem malesuada magna mollis euismod.</p>' },
  }
};

/**
 * Definition
 * =============================================================================
 */
const Disconnected = (props) => (
  <RegisterForm
    {...props}
    data={data}
  />
);

export default Disconnected;
