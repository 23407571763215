/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Tab from './Tab';
import Collapse from './Collapse';
import { object } from './../../utils';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';

/**
 * Definition
 * =============================================================================
 */
class CookieSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      active: 0,
    };
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChangeActive = (id) => {
    this.setState({ active: id });
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderItems(Component, addProps) {
    const { data } = this.props;
    const { active } = this.state;
    const keys = Object.keys(data);

    return keys.map((i, index) => {
      const item = data[i];

      return (object.getNested(item, 'content') || isEditorActive()) && (
        addProps(Component, {
          key: `cookie-settings-item-${index}`,
          id: index,
          name: i,
          isActive: index === active,
          ...item
        })
      )
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { active } = this.state;

    return (
      <div className='o-cookie-settings'>
        <ul className='o-cookie-settings__tabs u-tablet-hide'>
          {this.renderItems(Tab, (NewTab, newProps) => (
            <NewTab
              {...newProps}
              onClick={this.handleChangeActive}
            />
          ))}
        </ul>

        <div className='o-cookie-settings__container'>
          {this.renderItems(Collapse, (NewCollapse, newProps) => (
            <NewCollapse
              {...newProps}
              isOpen={newProps.id === active}
              onClickCollapse={this.handleChangeActive}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CookieSettings;
