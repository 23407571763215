/**
 * Imports
 * =============================================================================
 */
import React from "react";
import { Pill } from "./../../building-blocks";

/**
 * Definition
 * =============================================================================
 */
const PillList = ({ items, classes, onRemoveItem }) => {
  return (
    <ul className={`m-pill-list ${classes}`}>
      {items.map((i, index) => (
        <li key={`pill-list-item-${index}`} className="m-pill-list__item">
          <Pill id={index} onClick={x => onRemoveItem && onRemoveItem(i)} {...i} />
        </li>
      ))}
    </ul>
  );
};

export default PillList;
