/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
class Hamburger extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isClosed: props.isClosed || false,
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isClosed !== this.props.isClosed) {
      this.setState({ isClosed: this.props.isClosed });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setClosedState() {
    return this.state.isClosed ? 'is-closed' : '';
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleToggle = () => {
    const { onClick } = this.props;

    this.setState((prevState) => ({ isClosed: !prevState.isClosed }), () => {
      if (onClick) onClick(this.state.isClosed);
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  render() {
    const { classes } = this.props;

    return (
      <button
        className={`a-hamburger ${this.setClosedState()} ${classes}`}
        onClick={this.handleToggle}
      >
        <span className='a-hamburger__container'>
          <span className='a-hamburger__bar a-hamburger__bar--top'></span>
          <span className='a-hamburger__bar a-hamburger__bar--middle'></span>
          <span className='a-hamburger__bar a-hamburger__bar--bottom'></span>

          <span className='a-hamburger__line a-hamburger__line--top-left'></span>
          <span className='a-hamburger__line a-hamburger__line--top-right'></span>
          <span className='a-hamburger__line a-hamburger__line--bottom-left'></span>
          <span className='a-hamburger__line a-hamburger__line--bottom-right'></span>
        </span>
      </button>
    );
  }
}

export default Hamburger;
