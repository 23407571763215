/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
const ProgressItem = (props) => {
  const {
    ProgressItemTitle,
    ProgressItemDescription
  } = props.fields;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderTitle = () => {
    return (
      <h6 className='m-progress-item__title'>
        <Text field={ProgressItemTitle} />
      </h6>
    )
  };

  const renderDescription = () => {
    return (
      <RichText className='s-jss-rte' field={ProgressItemDescription} />
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <article className='m-progress-item'>
      <div className='m-progress-item__decorator'>
        <span className='m-progress-item__icon'></span>
      </div>

      <div className='m-progress-item__container'>
        {(isEditorActive() || ProgressItemTitle) && renderTitle()}

        <div className='m-progress-item__content'>
          {(isEditorActive() || ProgressItemDescription) && renderDescription()}
        </div>
      </div>
    </article>
  );
};

export default ProgressItem;
