/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Item = ({
  text,
  highlight,
  onClick,
}) => {

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClick = () => {
    if (onClick) onClick(text);
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const setHighlight = () => {
    const split = text
      .toLowerCase()
      .split(highlight.toLowerCase());
    let component = [];

    for (let i = 0; i < split.length; i++) {
      const part = split[i];
      const original = getOriginal(text, part);

      let sub = i === split.length - 1
        ? original
        : renderSub(
            i, text, original,
            getOriginal(text, highlight.toLowerCase())
          );

      component.push(sub);
    }

    return component;
  }

  const getOriginal = (text, part) => {
    const index = text.toLowerCase().indexOf(part);

    return text.substring(index, index + part.length);
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderSub = (index, word, text, highlight) => {
    return (
      <React.Fragment key={`higlight-list-part-${word}-${index}`}>
        {text}
        <strong className='m-highlight-list__focus'>
          {highlight}
        </strong>
      </React.Fragment>
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='m-highlight-list__item' onClick={handleClick}>
      {setHighlight()}
    </li>
  ) || null;
};

export default Item;
