/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import object from '../../utils/object'
/**
 * Definition
 * =============================================================================
 */
const VideoStructuredData = (props) => {
    const { fields = {} } = props;
    const { VideoTitle, VideoDescription, VideoDuration, VideoUploadDate, VideoThumbnail, VideoUrl, VideoFile } = fields;
    const url = object.getNested(VideoUrl, 'value');
    const videoFileUrl = object.getNested(VideoFile, 'value', 'src');

    const getYoutubeId = (url, youtubeurl) => {
        if (url && url.includes(youtubeurl)) {
            url = url.split(youtubeurl).join('');
            if (url.includes('&')) {
                url = url.substring(0, url.indexOf('&'));
            }
            return url;
        }
        return null;
    }

    const getFormatedDuration = (duration) => {
        if (!duration) {
            return '';
        }
        var durationNum = parseInt(duration, 10);
        var hours = Math.floor(durationNum / 3600);
        var minutes = Math.floor((durationNum - (hours * 3600)) / 60);
        var seconds = durationNum - (hours * 3600) - (minutes * 60);
        var retVal = 'PT';
        retVal = hours ? retVal + hours + 'H' : retVal;
        retVal = minutes ? retVal + minutes + 'M' : retVal;
        retVal += seconds + 'S';
        return retVal;
    }

    var youtubeId = getYoutubeId(url, 'https://www.youtube.com/embed/');
    if (youtubeId == null) {
        youtubeId = getYoutubeId(url, 'https://youtu.be/');
        if (youtubeId == null) {
            youtubeId = getYoutubeId(url, 'https://www.youtube.com/watch?v=');
            if (youtubeId == null) {
                youtubeId = '';
            }
        }
    }

    const embedUrl = youtubeId
        ? "https://www.youtube.com/embed/" + youtubeId
        : videoFileUrl ? videoFileUrl : '';
    const contentUrl = VideoUrl && url
        ? url
        : videoFileUrl ? videoFileUrl : '';
    const thumbnailUrl = object.getNested(VideoThumbnail, 'value', 'src')
        ? object.getNested(VideoThumbnail, 'value', 'src') : '';
    const videoTitle = VideoTitle && object.getNested(VideoTitle, 'value')
        ? object.getNested(VideoTitle, 'value') : '';
    const videoDescription = VideoDescription && object.getNested(VideoDescription, 'value')
        ? object.getNested(VideoDescription, 'value') : '';
    const videoUploadDate = VideoUploadDate && object.getNested(VideoUploadDate, 'value')
        ? object.getNested(VideoUploadDate, 'value') : '';
    const videoDuration = VideoDuration && object.getNested(VideoDuration, 'value')
        ? getFormatedDuration(object.getNested(VideoDuration, 'value')) : '';

    const requiredDataAreValid = videoTitle && thumbnailUrl  && videoUploadDate;

    const srcIsNotEmpty = embedUrl || contentUrl;

    if(srcIsNotEmpty && !requiredDataAreValid) {
        console.debug(`Some of the required properties for video structured data (VideoTitle, VideoThumbnail, VideoUploadDate) for the video: ${contentUrl} are missing!`);
    }

    const videoStructData = {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        "name": videoTitle,
        "description": videoDescription,
        "thumbnailUrl": [thumbnailUrl],
        "uploadDate": videoUploadDate,
        "duration": videoDuration,
        "contentUrl": contentUrl,
        "embedUrl": embedUrl,
    };

    return (
        srcIsNotEmpty && requiredDataAreValid && <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(videoStructData) }}></script>
    );
}
export default VideoStructuredData;