import {
  retrieveHierarchical,
  retrievePlain,
  extractActiveFilterItems
} from './TransformData.ts';
import moment from 'moment';
import {InputDateFormat,QueryDateFormat} from'../../lib/Constants';
import {date} from '../../utils';

const transformSearchResults = (t, lng) => data => {
  if (!data) {
    return data;
  }

  const courses = data.search.courses;
  const transformed = {
    searchResults: {
      total: courses.totalCount,
      items: transformItems(courses.hits, t, lng)
    }
  };

  return transformed;
};

const transformFacets = (facets, lng, t) => {
  t = t || (x => x);
  // Here lies mapping between filters (in React) and facet names (in Solr)
  const filter = {
    locations: retrieveHierarchical(facets, 'sessionslocations', lng),
    learnMethod: retrieveHierarchical(facets, 'trainingformhierarchy', lng),
    durations: retrievePlain(facets, 'durations', lng, value => t('search.facets.durations.' + value)),
    themes: retrieveHierarchical(facets, 'themehierarchytitle', lng),
    sectors: retrieveHierarchical(facets, 'sectorhierarchytitle', lng),
    languages: retrievePlain(facets, 'traininglang', lng, value => t('language.' + value))
  };

  return filter;
};

const getMin = (acc, cur) => cur < acc ? cur : acc;

const transformItems = (items, t, lng) => {

  const res = items.map(x => ({
    ...x,
    trainingType: x.trainingForm,
    sessions: transformSessions(x.sessions, t, lng),
    url: x.courseUrl,
    courseId: x.cdb2CourseId,
    firstSession: x.sessions.map(y => y.startDateTime).reduce(getMin, "9999-01-01"),
    tag: x.highlightCode && x.highlightCode.map(z => t('infofiche.highlight.' + z)),
  }));
  
  setHighlightFirstSession(res, items);

  return res;
};

const setHighlightFirstSession = (res, items) => {
  const firstSessionEver = items.map(x => x.sessions.map(x => x.startDateTime).reduce(getMin, "9999-01-01")).reduce(getMin, "9999-01-01");
  if(firstSessionEver !== '9999-01-01'){
    const highlighted = res.find(x => x.firstSession === firstSessionEver);
    if(highlighted){
      highlighted.highlightFirstSession = true;
    }
  }
}

const transformSessions = (sessions, t, lng) => {
  const getPlacesLeft = ses => {
    const placesLeft = ses && ses.registrationMax - ses.registrationCount;
    return placesLeft < 6 ? placesLeft : undefined;
  };

  const extractRegion = seq =>
    seq && seq.length > 0 ? seq[0].locationRegion : '';

  const extractCity = seq => {
    const locationCities = seq && seq.length > 0 && seq[0].locationCity && seq[0].locationCity.length > 0 && seq[0].locationCity;
    const location = locationCities && (locationCities.find(x => x.language === lng) || locationCities[0]);
    return (location && location.cityName) || '';
  }

  return sessions.map(x => ({
    date: extractDatesIncludingHoursReact(x.sequences,"dd D MMM","HH:mm"),
    region: extractRegion(x.sequences),
    location: extractCity(x.sequences),
    placesLeft: getPlacesLeft(x),
    groupId: x.groupId
  }));
};

const extractDatesIncludingHoursReact = (seq, dateFormat, hourFormat) => {
  if(!seq){
    return undefined;
  }
  const dates = seq.map(x => x);
  dates.sort((a, b) => (a.startDateTime > b.startDateTime ? 1 : a.startDateTime < b.startDateTime ? -1 : 0));
  const red = dates.map(
      cur => dateFormat && hourFormat ?
        date.formatDateIfHourReact(cur.startDateTime,cur.endDateTime,dateFormat,hourFormat) :
        date.formatDateIfHourReact(cur.startDateTime,cur.endDateTime)
  );
  return red;
};

const extractDates = (seq, t, addYear) => {
  if(!seq){
    return undefined;
  }
  const dates = seq.map(x => new Date(x.startDateTime));
  dates.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  const red = dates.reduce(
    (acc, cur) => {
      const month = getMonthName(cur.getMonth(), t);
      const year = cur.getFullYear();
      if (acc.currentMonth === null) {
        acc.currentMonth = month;
      }
      if(acc.currentYear === null){
        acc.currentYear = year;
      }
      if (acc.currentMonth !== month || acc.currentYear !== year) {
        acc.date += ' ' + acc.currentMonth;
        acc.currentMonth = month;
      }
      if(acc.currentYear !== year && addYear){
        acc.date += ' ' + acc.currentYear;
        acc.currentYear = year;
      }
      if (acc.date !== '') {
        acc.date += '\n';
      }
      acc.date += cur.getDate();
      return acc;
    },
    { date: '', currentMonth: null, currentYear: null }
  );
  if (red) {
    red.date += ' ' + red.currentMonth;
    if(addYear){
      red.date += ' ' + red.currentYear;
    }
  }
  return red && red.date;
};

const getMonthName = (monthNo, t) =>
  t('date.months.'+['jan','feb','mar','apr','may','jun','jul','aug','sep','oct','nov','dec'][monthNo]);

const mapFilterToVariables = filter =>
  filter && {
    locations: extractActiveFilterItems(filter.locations, x => x.id),
    learningMethods: extractActiveFilterItems(filter.learnMethod, x => x.id),
    themesHierarchy: extractActiveFilterItems(filter.themes, x => x.id),
    sectorsHierarchy: extractActiveFilterItems(filter.sectors, x => x.id),
    languages: extractActiveFilterItems(filter.languages, x => x.id),
    resultFilters: extractActiveFilterItems(filter.resultFilters, x => x.id),
    durations: extractActiveFilterItems(filter.durations, x => x.id),
    onlyAvailable: filter.availablePlaces,
    inCompany: filter.availableInCompany,
  };

const mapSearchToVariables = search => {
  return {
    keywords: splitTerms(search.term).map(x => x.replace(/(^"|"$)/g, "")),
    dateFrom: search.from?moment(search.from,InputDateFormat).format(QueryDateFormat):null,
    dateTo: search.to?moment(search.to,InputDateFormat).format(QueryDateFormat):null,
    region: search.closeTo || null,
    persona: getSeachPersonaFromUserType(search.searchAs) || null,
    trainingTypeCode: search.trainingTypeCode || null,
    searchAs: search.searchAs || null
  };
};

const getSeachPersonaFromUserType = userType => {
  switch (userType) {
    case 'EM':
    case 'HR':
      return 'EM';
    case 'JS':
      return 'JS';
  }
};

const splitTerms = terms =>
  (terms && terms.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g)
    .filter(x => x)) || [];

export {
  transformSearchResults,
  transformFacets,
  mapFilterToVariables,
  mapSearchToVariables,
  splitTerms,
  extractDates,
  extractDatesIncludingHoursReact
};
