import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';

const SearchBarDisabled = ({ t }) => (
  <div className='a-highlight '>
    <div className='a-highlight__content'>
      <div className='a-title '>
        <h5 className='a-title__text'>
          {t('searchResultsPage.searchBarDisabled')}
        </h5>
      </div>
    </div>
  </div>
);

export default withTranslation()(SearchBarDisabled);
