/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Link from '../../building-blocks/SimpleLink'
/**
 * Definition
 * =============================================================================
 */
const NavItem = ({ link, title }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='o-footer__nav-item'>

      <Link
        className='o-footer__nav-link'
        to={link}
      >
        {title}
      </Link>
    </li>
  );
};

export default NavItem;
