/**
 * Imports
 * =============================================================================
 */
import { object } from "./../../../utils";

/**
 * Parse
 * =============================================================================
 */

const parse = (obj) => {
  let props = Object.assign({}, obj);

  props = object.renameKey('CookiesMoreInfoUrl', 'LinkUrl', props);

  return props;
};

/**
 * Exports
 * =============================================================================
 */
 export default parse;
