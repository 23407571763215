/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Title = ({
  col = 4,
  classes = '',
  text = '',
  onSortChange
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`o-table__title o-table__title-${col}-12 ${classes}`}  onClick={onSortChange} >
      {text}
    </div>
  );
};

export default Title;
