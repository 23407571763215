/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
class Checkbox extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: props.isChecked || false
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.isChecked !== this.props.isChecked &&
      this.props.isChecked !== this.state.isChecked
    ) {
      this.setState({ isChecked: this.props.isChecked });
    }
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChange = () => {
    const { onChange, name, id } = this.props;

    this.setState(
      prevState => ({ isChecked: !prevState.isChecked }),
      () => {
        if (onChange) onChange(name, this.state.isChecked, id);
      }
    );
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderLabel(itemId) {
    const { label, labelClasses } = this.props;

    return <span className={`a-toggle__label ${labelClasses}`}>{label}</span>;
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes = '', name, id, label, value, itemId } = this.props;
    const { isChecked } = this.state;
    return (
      <div className={`a-toggle ${classes}`}>
        <label className='a-toggle__container' htmlFor={name}>
          <div className='a-toggle__wrapper'>
            <input
              className='a-toggle__input'
              type='checkbox'
              id={id}
              name={name}
              defaultValue={value}
              checked={isChecked}
              onChange={this.handleChange}
            />

            <span className='a-toggle__icon a-toggle__icon--off icon-checkbox-unchecked'></span>

            <span className='a-toggle__icon a-toggle__icon--on icon-checkbox-checked'></span>
          </div>

          {label && this.renderLabel(itemId)}
        </label>
      </div>
    );
  }
}

export default Checkbox;
