/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Definition } from './../../building-blocks';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ col, term, description }) => {

  /**
   * Main renders
   * ---------------------------------------------------------------------------
   */
  return (
    <li className={`m-definition-list__item l-grid__col-${col || 3}-12`}>
      <Definition
        term={term}
        description={description}
      />
    </li>
  )
};

export default Item;
