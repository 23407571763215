/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withCollapse } from './../../../hoc';
import ToggleGroup from '../../../components/ToggleGroup';
import { FilterLoadMoreLimit } from '../../../lib/Constants.ts';

/**
 * Definition
 * =============================================================================
 */
const Item = ({
  classes = '',
  title,
  data,
  name,
  setCollapseState,
  handleToggleCollapse,
  onUpdateFields
}) => {
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <fieldset className={`o-filter__item ${classes} ${setCollapseState()}`}>
      <legend className='o-filter__header' onClick={handleToggleCollapse}>
        {title && <h5 className='o-filter__title'>{title}</h5>}

        <span className='o-filter__arrow' />
      </legend>

      <div className='o-filter__item-content'>
        <div className='o-filter__item-container'>
          <ToggleGroup
            data={data}
            onChange={checked => onUpdateFields(name, checked)}
            loadMore={{ limit: FilterLoadMoreLimit }}
            uncheckParent={true}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default withCollapse()(Item);
