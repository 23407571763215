import React, { useContext } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import FieldLabel from '../Helpers/FieldLabel';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import { checkConditions } from '../conditionalRendering';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const ButtonRadioList = (props) => {
  const {
    field,
    field: {
      model: { items, cssClass },
    },
    value,
    onChange,
    tracker,
    errors,
    t,
  } = props;
  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  var shouldHide = false;
  var shouldDisable = false;

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const isDependent = field.model.dependentField;
  const dependsOnValue = dependentFieldsContext.fieldValues[field.model.dependentField];
  var radioButtonItems = items;

  if (isDependent) {
    Object.entries(field.model.dependentItems).forEach(entry => {
      const [key, value] = entry;
      if (key.toLowerCase() === dependsOnValue.toLowerCase()) {
        radioButtonItems = value;
      }
    });
  }

  const updateContextsFieldsValues = (fieldValue) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, fieldValue);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, fieldValue);
    }
  }

  const shouldRender = (isDependent && items !== radioButtonItems) || (!isDependent);

  if(!shouldRender){
    console.log(`${field.model.name} is not shown. The dependent list is empty, because there are no corresponding values.`)
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const renderItems = (itemsArray) => {
    return (
      itemsArray && itemsArray.map((item, index) => {
        const itemId = field.valueField.id + index;
        const checked = Array.isArray(value) ? value[0] === item.value : value === item.value;
        const extraClass = checked ? '' : 'is-off';
        return (
          <div className='a-toggle a-toggle--inverse-order a-toggle--secondary a-toggle--card' key={itemId}>
            <label className="a-toggle__container" htmlFor={itemId}>
              <div className="a-toggle__wrapper">
                <input className="a-toggle__input" type="radio" id={itemId}
                  name={field.valueField.name}
                  value={item.value}
                  checked={checked}
                  disabled={shouldDisable}
                  onChange={(e) => handleOnChange(field, e.target.value, onChange, t)}
                  onFocus={() => tracker.onFocusField(field, value)}
                  onBlur={() => tracker.onBlurField(field, value, errors)}
                  onClick={(e) => updateContextsFieldsValues(e.target.value)} />
                <span className="a-toggle__icon a-toggle__icon--off icon-radio-hollow"></span>
                <span className="a-toggle__icon a-toggle__icon--on icon-radio-filled"></span>
              </div>
              <span className='a-toggle__label'>{item.text}</span>
            </label>

          </div>
        );
      })
    )
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    shouldRender &&
    <div className={`a-formfield__buttonList ${cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      {isDependent && renderItems(radioButtonItems)}
      {!isDependent && renderItems(items)}
      <FieldValidationErrors {...props} />
    </div>
  );
};

export default withTranslation()(ButtonRadioList);
