/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Hero from './../../components/Hero';
import SessionCard from './../../components/SessionCard';
import SessionTable from './../../components/SessionTable';
import { Loader } from '../../building-blocks';
import Definitions from './Definitions';
import SectionCollapse from './../../components/SectionCollapse';
import ProgramSheet from './../../components/ProgramSheet';
import { object, image, html } from './../../utils';
import {
  Placeholder,
  isEditorActive
} from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from '../../hoc/withTranslation';
import { withAuth0, withBasketData } from '../../hoc/';
import { Events } from './../../managers';
import Helmet from 'react-helmet';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { productView, calculatePersona } from '../../lib/Gtm';
import CourseStructuredData from './../../components/CourseStructuredData';
import Cta from '../../components/Cta';

/**
 * Definition
 * =============================================================================
 */
class Infofiche extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSessionGroupId: 0
    };
    this.handleAddIncompanyItem = this.handleAddIncompanyItem.bind(this);
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    Events.emitter.on('basket-add-incompany', this.handleAddIncompanyItem);
    var hash = document.location.hash;

    if (hash && hash.indexOf('#session-') === 0) {
      var sessionId = hash.replace('#session-', '');
      if (!isNaN(sessionId)) {
        this.setState({ selectedSessionGroupId: sessionId });
      }
    }
    const courseId = object.getNested(this.props, 'fields', 'Cdb2CourseId', 'value');
    const coursePersona = object.getNested(this.props, 'data', 'trainingAudienceType');
    productView(courseId, this.props.graphQLClient, calculatePersona(this.props.auth0, this.props.basketData, coursePersona));

    setTimeout(() => {
      if (hash !== '#session-table-section') {
        window.scroll({
          top: 0,
          behavior: 'instant'
        });
      }
    }, 100);
  }

  componentWillUnmount() {
    Events.emitter.removeListener(
      'basket-add-incompany',
      this.handleAddIncompanyItem
    );
  }

  handleAddIncompanyItem() {
    const { ReferenceOld, HeroTitle, Cdb2CourseId } = this.props.fields;
    const { trainingType } = this.props.data;

    const item = {
      courseId: Cdb2CourseId.value,
      courseTitle: HeroTitle.value,
      oldReference: ReferenceOld.value,
      trainingType: trainingType,
      courseType: 2
    };
    Events.emitter.emit('basket-add-item', item);
  }

  addRegisterUrl(sessionTable, courseInfo) {
    if (!Array.isArray(sessionTable)) return sessionTable;
    return sessionTable.map(x => {
      x.registerUrl = Events.createEventUrl('basket-add-item', {
        ...courseInfo,
        courseType: 1,
        groupId: x.groupId,
        dataHubId: x.dataHubId,
        dateFrom: x.startDate,
        dateTo: x.endDate,
        oldReference: x.courseOldReference,
        courseId: x.cdb2CourseId
      });
      return x;
    });
  }

  hasValue(property) {
    return (
      object.hasNested(property, 'value') &&
      object.getNested(property, 'value') !== '' &&
      object.getNested(property, 'value') !== undefined
    );
  }

  hasImageValue(property) {
    return (
      object.hasNested(property, 'value', 'src') &&
      object.getNested(property, 'value', 'src') !== '' &&
      object.getNested(property, 'value', 'src') !== undefined
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    if (this.props.fields === undefined) return '';
    const { selectedSessionGroupId } = this.state;
    const {
      fields,
      sessionsCard,
      sessionTable,
      heroImage,
      trainingAudienceType,
      trainingFormVirtual: isVirtual,
      onlineCourseCtaFields,
      isOnline
    } = this.props.data;
    const {
      ObjectiveTitle,
      Objective,
      TargetAudienceTitle,
      TargetAudience,
      MethodTitle,
      PrerequisitesTitle,
      Prerequisites,
      OverviewTitle,
      JobOpportunitiesTitle,
      JobOpportunities,
      ExtraInformationTitle,
      ExtraInformation,
      ReferenceOld,
      HeroTitle,
      HeroDescription,
      Cdb2CourseId,
      TrainingForm,
    } = this.props.fields;
    const { t, rendering, sitecoreContext } = this.props;
    const courseInfo = {
      courseId: Cdb2CourseId.value,
      courseTitle: HeroTitle.value,
      oldReference: ReferenceOld.value,
      trainingType: trainingAudienceType,
      isVirtual: isVirtual
    };
    var sessionTableWithRegisterUrl = this.addRegisterUrl(sessionTable, courseInfo);
    const siteName = object.getNested(
      sitecoreContext,
      'navigationData',
      'TitleName'
    );

    const isLoading = sessionsCard === undefined;
    const isWebinar = object.getNested(TrainingForm, 'fields', 'Webinar', 'value');
    const inCompany = fields.InCompany.value;
    const isJobSeekerCourse = courseInfo.trainingType == 'JS';

    const trainingFormTitle = object.getNested(
      fields,
      'TrainingForm',
      'fields',
      'Title',
      'value'
    );

    const objectiveTitle = object.getNested(ObjectiveTitle, 'value') ? object.getNested(ObjectiveTitle, 'value') : t('infofiche.objective.title');
    const targetAudienceTitle = object.getNested(TargetAudienceTitle, 'value') ? object.getNested(TargetAudienceTitle, 'value') : t('infofiche.targetAudience.title');
    const methodTitle = object.getNested(MethodTitle, 'value') ? object.getNested(MethodTitle, 'value') : t('infofiche.method.title');
    const prerequisitesTitle = object.getNested(PrerequisitesTitle, 'value') ? object.getNested(PrerequisitesTitle, 'value') : t('infofiche.prerequisites.title');
    const jobOpportunitiesTitle = object.getNested(JobOpportunitiesTitle, 'value') ? object.getNested(JobOpportunitiesTitle, 'value') : t('infofiche.jobOpportunities.title');
    const extraInformationTitle = object.getNested(ExtraInformationTitle, 'value') ? object.getNested(ExtraInformationTitle, 'value') : t('infofiche.extraInformation.title');
    const overviewTitle = object.getNested(OverviewTitle, 'value') ? object.getNested(OverviewTitle, 'value') : t('infofiche.overview.title');

    return (
      <div className='l-infofiche'>
        {isLoading && (
          <div className='l-infofiche__loader'>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div>
            <Helmet>
              <title>
                {object.getNested(HeroTitle, 'value')} | {siteName || ''}
              </title>
              {this.hasValue(HeroDescription) && (
                <meta
                  name='description'
                  content={html.htmlToText(
                    object.getNested(HeroDescription, 'value')
                  )}
                />
              )}
              {
                <meta
                  property='og:title'
                  content={object.getNested(HeroTitle, 'value')}
                />
              }
              {this.hasValue(HeroDescription) && (
                <meta
                  property='og:description'
                  content={html.htmlToText(
                    object.getNested(HeroDescription, 'value')
                  )}
                />
              )}

              <meta property='og:type' content='website' />

              <meta name='twitter:card' content='summary' />
              {this.hasValue(HeroTitle) && (
                <meta
                  name='twitter:title'
                  content={object.getNested(HeroTitle, 'value')}
                />
              )}
              {this.hasValue(HeroDescription) && (
                <meta
                  name='twitter:description'
                  content={html.htmlToText(
                    object.getNested(HeroDescription, 'value')
                  )}
                />
              )}
            </Helmet>
            <div className='l-infofiche__hero'>
              <Hero {...this.props.data} imageSrc={heroImage} />
            </div>
            <div className='l-infofiche__view l-container@above-desktop-base'>
              <div className='l-infofiche__card'>
                <SessionCard
                  data={sessionsCard}
                  allSessions={sessionTableWithRegisterUrl}
                  courseInfo={courseInfo}
                  selectSessionGroupId={selectedSessionGroupId}
                  showRegion={!isWebinar}
                  isVirtual={isVirtual}
                  inCompany={inCompany}
                />
              </div>

              <div className='l-infofiche__aside l-container@below-desktop-base'>
                {inCompany && !isOnline && (
                  <Placeholder rendering={rendering} name='infofiche-aside' />
                )}
                {isOnline && onlineCourseCtaFields && (
                  <Cta
                    key={onlineCourseCtaFields.id}
                    fields={{
                      ButtonText: onlineCourseCtaFields.buttonText.jss,
                      ButtonUrl: onlineCourseCtaFields.buttonUrl.jss,
                      LinkText: onlineCourseCtaFields.linkText.jss,
                      LinkUrl: onlineCourseCtaFields.linkUrl.jss,
                      CtaTitle: onlineCourseCtaFields.ctaTitle.jss,
                      CtaSubtitle: onlineCourseCtaFields.ctaSubtitle.jss,
                      CtaDescription: onlineCourseCtaFields.ctaDescription.jss,
                      CtaMiniImageUrl: onlineCourseCtaFields.ctaMiniImageUrl.jss,
                      CtaTagText: onlineCourseCtaFields.ctaTagText.jss,
                      CtaTheme: {
                        fields: onlineCourseCtaFields.ctaTheme.jss.fields,
                      },
                      CtaBackground: onlineCourseCtaFields.ctaBackground.jss,
                    }}
                  />
                )}
                {isJobSeekerCourse && (
                  <Placeholder rendering={rendering} name='infofiche-aside-jobseeker' />
                )}
              </div>

              <div className='l-infofiche__content'>
                <div className='l-section'>
                  <div className='l-section__content-container l-container'>
                    <Definitions {...this.props.data} />
                  </div>
                </div>

                {(isEditorActive() ||
                  (object.hasNested(fields, 'Objective', 'value') &&
                    fields.Objective.value !== '')) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: objectiveTitle,
                        SectionCollapseMainContent: Objective,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}

                {(isEditorActive() ||
                  (object.hasNested(fields, 'TargetAudience', 'value') &&
                    fields.TargetAudience.value !== '')) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: targetAudienceTitle,
                        SectionCollapseMainContent: TargetAudience,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}

                {(isEditorActive() ||
                  !!object.getNested(
                    fields,
                    'TrainingForm',
                    'fields',
                    'Description',
                    'value'
                  )) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: `${methodTitle}${trainingFormTitle ? ": " : ""}${trainingFormTitle}`,
                        SectionCollapseMainContent: object.getNested(
                          fields,
                          'TrainingForm',
                          'fields',
                          'Description'
                        ),
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}

                {(isEditorActive() ||
                  (object.hasNested(fields, 'Prerequisites', 'value') &&
                    fields.Prerequisites.value !== '')) && (
                    <SectionCollapse
                      sectionId='prerequisites'
                      fields={{
                        SectionCollapseTitle: prerequisitesTitle,
                        SectionCollapseMainContent: Prerequisites,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}

                {(isEditorActive() ||
                  !!object.getNested(
                    fields,
                    'TrainingForm',
                    'fields',
                    'TermsOfAccess',
                    'value'
                  )) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: t('infofiche.termsofaccess.title'),
                        SectionCollapseMainContent: object.getNested(
                          fields,
                          'TrainingForm',
                          'fields',
                          'TermsOfAccess'
                        ),
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}


                {(isEditorActive() ||
                  (object.hasNested(fields, 'Overview') &&
                    fields.Overview.value !== '')) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: overviewTitle,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    >
                      <ProgramSheet
                        content={object.getNested(fields, 'Overview')}
                        rendering={rendering}
                      />
                    </SectionCollapse>
                  )}

                {(isEditorActive() ||
                  (object.hasNested(
                    fields,
                    'JobOpportunities',
                    'value',
                    'value'
                  ) &&
                    fields.JobOpportunities.value !== '')) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: jobOpportunitiesTitle,
                        SectionCollapseMainContent: JobOpportunities,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}

                {(isEditorActive() ||
                  (object.hasNested(fields, 'ExtraInformation', 'value') &&
                    fields.ExtraInformation.value !== '')) && (
                    <SectionCollapse
                      fields={{
                        SectionCollapseTitle: extraInformationTitle,
                        SectionCollapseMainContent: ExtraInformation,
                        SectionCollapseDesktopOnly: true
                      }}
                      isOpen={isEditorActive() || undefined}
                    />
                  )}
              </div>

              <div className='l-infofiche__footer'>
                <Placeholder rendering={rendering} name='infofiche-content' />

                {sessionTableWithRegisterUrl && (
                  <SessionTable
                    data={sessionTableWithRegisterUrl}
                    isVirtual={isVirtual}
                  />
                )}
              </div>
            </div>{' '}
            <CourseStructuredData {...this.props.data} allSessions={sessionTableWithRegisterUrl} />
          </div>
        )}
      </div>
    );
  }
}

export default withAuth0(withSitecoreContext()(
  withBasketData()(withTranslation()(Infofiche)))
);
