import * as React from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import striptags from 'striptags';
import isHtml from 'is-html';

const defaultAllowedTags = [
  'a',
  'b',
  'i',
  'u',
  'strong',
  'em',
  'br',
  'span',
  'mark',
  'cite',
];

const allowedTags = defaultAllowedTags;

const withHtmlParsing = (t) => (key, props) => {
  const value = striptags(t(key, props), allowedTags);
  return isHtml(value) ? parse(value) : value;
};

const withTranslation = () => (Component) => (props) => {
  const { i18n } = useTranslation();
  const lng = i18n && i18n.language;
  const t = i18n && i18n.t.bind(i18n);
  return <Component {...props} t={withHtmlParsing(t, props)} lng={lng} />;
};

export { withTranslation };
