/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';

import ToggleGroup from './../ToggleGroup';
import Pagination from './../Pagination';
import Table from './../Table';
import { Attribute } from './../../building-blocks';
import moment from 'moment';
import { window } from 'global/window';
import { ShowPlacesLeftWarningFrom } from '../../lib/Constants.ts';
import { withBasketData } from '../../hoc/';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { object } from '../../utils';

/**
 * Definition
 * =============================================================================
 */
class SessionTable extends React.Component {
  constructor(props) {
    super(props);

    this.itemsPerPage = 10;

    this.state = {
      formData: {
        languages: []
      },
      currentPage: 1,
      minItems: 1,
      maxItems: this.itemsPerPage
    };
  }

  componentDidMount() {
    var hash = document.location.hash;

    setTimeout(() => {
      if (hash && hash == '#session-table-section') {
        const $anchor = document.getElementById('session-table-section');

        const offsetTop =
          $anchor.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
          top: offsetTop,
          behavior: 'smooth'
        });
      }
    }, 100);
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  getHasSelectedLanguages() {
    const { formData = {} } = this.state;
    const { languages = [] } = formData;

    return languages.length > 0;
  }

  setFilteredLanguages(data) {
    const { formData = {} } = this.state;
    const { languages = [] } = formData;
    //const { t } = this.props;
    //const translatedLanguages = languages.map((l) => t(`languages.${l}`));

    data = data.filter(d => languages.includes(d.language || ''));

    return data;
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderHeader() {
    const { t, isVirtual } = this.props;
    const { formData } = this.state;
    var className =
      formData && formData.sortDescending
        ? 'o-table__title--sortable--down'
        : 'o-table__title--sortable--up';
    return [
      ...(isVirtual
        ? []
        : [
            {
              classes:
                'o-table__title--sortable ' +
                (formData && formData.sortIndex === 0 ? className : ''),
              col: 3,
              text: t('sessionTable.headers.city')
            },
            {
              classes:
                'o-table__title--sortable ' +
                (formData && formData.sortIndex === 1 ? className : ''),
              col: 3,
              text: t('sessionTable.headers.startDate')
            }
          ]),
      {
        classes:
          'o-table__title--sortable ' +
          (formData && formData.sortIndex === 2 ? className : ''),
        col: 1,
        text: t('sessionTable.headers.language')
      },
      { col: 5, text: t('sessionTable.headers.info') }
    ];
  }

  renderBody(data = this.props.data) {
    const { t, isVirtual } = this.props;
    const pageTitle = object.getNested(
      this.props,
      'sitecoreContext',
      'pageMetadata',
      'pageTitle'
    );
    const courseReference = object.getNested(
      this.props,
      'sitecoreContext',
      'pageMetadata',
      'pageReference'
    );
    return data.map(d => {
      const mailTemplateObj = {
        ...{code: courseReference, title: pageTitle, language: d.language.toUpperCase()},
        ...(!isVirtual ? {location: d.location, dates: d.dates} : {})
      };
      const mailSubject = encodeURIComponent(t('sessionTable.coordinatorEmailSubject', mailTemplateObj));
      const mailBody = encodeURIComponent(t('sessionTable.coordinatorEmailBody', mailTemplateObj));
      return {
        columns: [
          ...(isVirtual
            ? []
            : [{ col: 3, text: d.location }, { col: 3, text: d.datesWithHours }]),
          { col: 1, text: t(`language.${d.language}`) },
          {
            col: 5,
            text: (
              <div id={`session-${d.groupId}`}>
                {d.coordinatorPhone && (
                  <a
                    href={`tel:${d.coordinatorPhone}`}
                    rel='noopener norefferer'
                  >
                    {d.coordinatorPhone}
                  </a>
                )}
                {d.coordinatorPhone && d.coordinatorEmail && ' / '}
                <a
                  href={`mailto:${d.coordinatorEmail}?subject=${mailSubject}&body=${mailBody}`}
                  rel='noopener norefferer'
                >
                  {t('sessionTable.contact')}
                </a>
                {d.placesLeft > 0 &&
                  d.placesLeft <= ShowPlacesLeftWarningFrom && (
                    <Attribute
                      classes='a-attribute--error o-table__seats'
                      iconBefore='icon-error'
                      text={t('sessionTable.seatsLeft', {
                        amount: d.placesLeft
                      })}
                    />
                  )}
                {d.placesLeft <= 0 && (
                  <Attribute
                    classes='a-attribute--error o-table__seats'
                    iconBefore='icon-error'
                    text={t('sessionCard.sessionIsFullTable')}
                  />
                )}
              </div>
            )
          }
        ],
        action: {
          text: t('sessionTable.action'),
          url: d.registerUrl,
          icon: 'icon-edit'
        }
      };
    });
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  handleFilterChange = data => {
    let { formData = {} } = this.state;
    // let { languages = [] } = formData;

    formData.languages = data.filter(g => g.isChecked).map(l => l.id);

    this.setState({
      formData: formData,
      currentPage: 1,
      minItems: 1,
      maxItems: this.itemsPerPage
    });
  };
  handleSortChange = event => {
    let { formData = {} } = this.state;
    var element = event.target;
    var index = Array.from(element.parentNode.children).indexOf(element);

    var prevSortIndex = formData.sortIndex;

    formData.sortIndex = index;
    if (prevSortIndex == index) {
      formData.sortDescending = !formData.sortDescending;
    } else formData.sortDescending = true;
    this.setState({
      formData: formData,
      currentPage: this.state.currentPage,
      minItems: this.state.minItems,
      maxItems: this.state.maxItems
    });
  };
  setSorted = data => {
    let { formData = {} } = this.state;
    data.sort((a, b) => {
      var up = formData.sortDescending ? 1 : -1;
      var down = formData.sortDescending ? -1 : 1;
      switch (formData.sortIndex) {
        case 0:
          return a.location > b.location ? up : down;
        case 1:
          return moment(a.startDate).isAfter(moment(b.startDate)) ? up : down;
        case 2:
          return a.language > b.language ? up : down;
      }
    });
    return data;
  };

  handleChangePage = ({ page, start, end }) => {
    this.setState({
      currentPage: page,
      minItems: start,
      maxItems: end
    });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { currentPage, minItems, maxItems } = this.state;
    const { t, data } = this.props;
    const filteredData = this.getHasSelectedLanguages()
      ? this.setFilteredLanguages(data)
      : data;
    const sortedData = this.setSorted(filteredData);

    const tableHeader = this.renderHeader();
    const shownElements = sortedData.slice(minItems - 1, maxItems);

    const tableBody = this.renderBody(shownElements) || [];
    const hasData = tableBody.length > 0;
    const languageFilters = Array.from(new Set(data.map(s => s.language)))
      .map(language => {
        return {
          id: language,
          text: t(`language.${language}`),
          isChecked: true
        };
      })
      .sort((a, b) => (a.id > b.id ? 1 : -1));

    return (
      <section id='session-table-section' className='l-table'>
        <div className='l-container'>
          <header className='l-table__header'>
            <h4 className='l-table__title'>{t('sessionTable.title')}</h4>

            <div className='l-table__filter'>
              {languageFilters.length > 1 && (
                <ToggleGroup
                  classes='m-toggle-group--horizontal'
                  data={languageFilters}
                  onChange={this.handleFilterChange}
                ></ToggleGroup>
              )}
            </div>
          </header>

          <div className='l-table__content'>
            {(hasData && (
              <Table
                id='session-table'
                type='with-actions'
                header={tableHeader}
                body={tableBody}
                onSortChange={this.handleSortChange}
              />
            )) || <p>{t('sessionTable.noResults')}</p>}
          </div>

          {hasData && (
            <div className='l-table__navigation'>
              <Pagination
                classes='o-table__pagination'
                total={filteredData.length}
                perPage={this.itemsPerPage}
                changeCb={this.handleChangePage}
                page={currentPage}
              />
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default withSitecoreContext()(
  withBasketData()(withTranslation()(SessionTable))
);
