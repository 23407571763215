/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Root from './../components/Row';
import Col from './../components/Col';
import { Link } from 'react-router-dom';

/**
 * Definition
 * =============================================================================
 */
const Row = ({
  classes = '',
  data = [],
  id:tableId,
  index,linkUrl= '/'
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderCols = () => {
    return data.map((d, index) => (
      <Col key={`table-body-col-${index}`} {...d} />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Root
      index={index}
      tableId={tableId}
      classes={classes}
      data={data}
      linkUrl={linkUrl}
    >
      <Link className='o-table__row-link' to={linkUrl}>
        {renderCols()}

        <span className='o-table__link icon-arrow-right'></span>
      </Link>
    </Root>
  );
};

export default Row;
