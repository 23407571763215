/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import useLazyLoad from '../../hooks/useLazyLoad';
/**
 * Definition
 * =============================================================================
 */
const Item = ({ item, t }) => {

  const setBackgroundImage = (imageUrl) => {
    return imageUrl ? `url(${imageUrl})` : ``;
  };
  useLazyLoad();

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='l-grid__col l-grid__col-4-12'>
      <article className='m-article-teaser '>
        <div className='m-article-teaser__container'>
          <div
            className='m-article-teaser__image lazy'
            data-bg-multi={setBackgroundImage(item.imageUrl)}
          ></div>
          <div className='m-article-teaser__content'>
            <h4 className='m-article-teaser__title'>{item.title}</h4>
            <a
              className='a-link m-article-teaser__link a-link--decorated '
              href={item.pageUrl}
            >
              <span className='a-link__text'>
                {t('relatedArticles.readMore')}
              </span>
            </a>
          </div>
        </div>
      </article>
    </div>
  );
};

export default withTranslation()(Item);
