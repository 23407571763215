/**
 * Imports
 * =============================================================================
 */
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const ContentList = (props) => {
  const {
    classes = '',
    rendering
  } = props;
 
  const type = object.getNested(props.fields, 'Type','fields','Value','value');
  const isLarge = object.getNested(props.fields, 'IsLarge','value');


  const getTagName = () =>{
    return type!='numbers'?'ul':'ol';
  }
  const getIcon = () =>{
    switch(type){
      case 'checkmarks':return 'icon-checkmark';
      case 'double-arrows':return 'icon-arrow-double-right';

    }
    return '';
  }

  const getLargeClass = () =>{
    return isLarge?'m-list--large':'';
    
  }

  const getTypeClasses = () =>{
    switch(type){
      case 'checkmarks':
        return 'm-list--with-icon m-list--with-icon-orange';
      case 'double-arrows':
        return 'm-list--with-icon m-list--with-icon';
        case 'numbers':
          return 'm-list--ordered';
    }
    return '';
   
  }
const tagprops = {tagName:getTagName()}; 
const icon = getIcon();
const typeclasses = getTypeClasses();
const largeclass = getLargeClass(); 
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

   
  const renderItem = (component, index) => {
    return (  
    <li className="m-list__item"  key={`m-list-item-${index}`}>
      <div className={`scEmptyPlaceholder m-list__text ${icon}`}>
      {component}</div>
      </li>
    );
  };

  return (
    <tagprops.tagName className={`m-list ${classes} ${typeclasses} ${largeclass}`}>
       <Placeholder
        name='content-list'
        rendering={rendering}
        renderEach={renderItem}
      />
    </tagprops.tagName>
  );
};

export default ContentList;
