/**
 * Imports
 * =============================================================================
 */
import React, {useEffect} from 'react';
import { useAuth0 } from '../../react-auth0-wrapper';
import { Loader } from './../../building-blocks';
import { Events } from '../../managers';
import UserActions from './UserActions';
/**
 * Definition
 * =============================================================================
 */
const UserMenu = () => {
  const { loading, user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if(window.location.search.includes('logout=')) {
      Events.emitter.emit('go-to-logout');
    }
  });

  if (loading || !user) {
    return (
      <div className='o-header__user__loader'>
        <Loader />
      </div>
    );
  }

  const handleLogout = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.nativeEvent) e.nativeEvent.stopImmediatePropagation();
    }
    Events.emitter.emit('go-to-logout');
  };
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
 

  return (
    isAuthenticated && (
      <div className='o-user js-user o-header__user'>
        <div className='o-user__trigger'>
          <div className='o-user__picture'>
            <img
              className='o-user__picture-image'
              src={user.picture}
              alt={user.nickname}
            />
          </div>
        </div>
        <div className='o-user__content'>
          <ul className='o-user__nav'></ul>

          <div className='o-user__footer'>
            <UserActions onLogout={handleLogout} wrapperCssClass='o-user__footer-item' />
          </div>
        </div>
      </div>
    )
  );
};

export default UserMenu;
