import React from 'react';
import { Tooltip } from '../../../building-blocks';
import ReactHtmlParser from 'react-html-parser';

const TextWithTooltip = ({ text }) => {
    const match = /([^{]*)(\{([^}]+)\})?([^}]*)/.exec(text);
    return (<>
        <span dangerouslySetInnerHTML={{__html: match[1]}} />
        {match[2] && <Tooltip position='top' content={ReactHtmlParser(match[3])} cssClass='m-tooltip--inline m-tooltip--inline-baseline'/> }
        <span dangerouslySetInnerHTML={{__html: match[4]}} />
    </>);
}

export default TextWithTooltip;