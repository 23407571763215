/**
 * Imports
 * =============================================================================
 */
import { object } from "./../../../utils";

/**
 * Parse
 * =============================================================================
 */

const parse = (obj) => {
  let props = Object.assign({}, obj);

  props.fields = object.hasNested(props, 'fields', 'CardTitle','value')
    ? object.renameKey('CardTitle', 'DisclosureLinkText', props.fields)
    : object.renameKey('ButtonText', 'DisclosureLinkText', props.fields);
  props.fields = object.renameKey('ButtonUrl', 'DisclosureLinkUrl', props.fields);


  return props;
};

/**
 * Exports
 * =============================================================================
 */
 export default parse;
