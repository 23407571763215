/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Label from './Label';
import Feedback from './Feedback';
import Tooltip from './../Tooltip';

/**
 * Definition
 * =============================================================================
 */
const Formfield = ({
  classes,
  labelClasses,
  label,
  name,
  feedback,
  tooltip,
  tooltipIsOpen,
  children,
  handleOverlayTriggerClick
}) => {

  const onClick = () => {
    if (handleOverlayTriggerClick) {
      handleOverlayTriggerClick();
    }
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderLabel = () => {
    return (
      <Label
        classes={labelClasses}
        name={name}
        text={label}
      >
        {tooltip && (
          <div className='a-formfield__tooltip'>
            <Tooltip
              position='top'
              content={tooltip}
              isOpen={tooltipIsOpen}
            />
          </div>
        )}
      </Label>
    );
  }

  const renderFeedback = () => {
    return (
      <Feedback text={feedback} />
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-formfield ${classes}`} onClick={onClick}>
      {label && renderLabel()}

      {children}

      {feedback && renderFeedback()}
    </div>
  );
};

export default Formfield;
