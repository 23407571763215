/**
 * Imports
 * =============================================================================
 */

 import React from 'react';
 import { Loader } from '../../building-blocks';
 import { loader as gqlLoader } from 'graphql.macro';
 import { useQuery } from '@apollo/react-hooks';
 import dateUtils from '../../utils/date';
 import { withTranslation } from '../../hoc/withTranslation';
 import { isEditorActive} from '@sitecore-jss/sitecore-jss-react';
import useLazyLoad from '../../hooks/useLazyLoad';
 
 const QUERY = gqlLoader('./query.graphql');

/**
 * Definition
 * =============================================================================
 */

const FeaturedArticles = (props) => {
  const { rendering, t, lng } = props;
  const query = useQuery(QUERY, {
    variables: {numberOfResults: 4, language: lng},
    fetchPolicy: 'cache-and-network'
  });

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */

  const setFeaturedBackgroundImage = (imageUrl) => {
    return imageUrl ? `url(${imageUrl})` : ``;
  };

  const setThemeBackgroundColor = (themeColor) => {
    return { backgroundColor: `${themeColor}` };
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

   const renderArticleTeasers = (articles) => {
    return articles.map((article) => {
      return (
        article && 
        (
          <article key={article.articleId} className="m-article-teaser">
            <div className="m-article-teaser__container">
              <div className="m-article-teaser__image lazy" data-bg-multi={setFeaturedBackgroundImage(article.imageUrl)}>
              </div>
              <div className="m-article-teaser__content">
                <a className="a-link m-article-teaser__link a-link--decorated " href={article.pageUrl}>
                  <h4 className="m-article-teaser__title">{article.title}</h4>
                </a>
                <a className="a-link m-article-teaser__link a-link--decorated " href={article.pageUrl}>
                  <span className="a-link__text">{t('blog.featuredArticles.readMore')}</span>
                </a>
              </div>
            </div>
          </article>
        )
      );
    });
  };

  const renderNoArticlesFound = () => 
  {
    return ( isEditorActive() && 
      <div class="l-grid__col l-grid__col--12">
        Featured Articles component: No articles were found
      </div>
    )
  }

  const renderFeaturedArticle = (featuredArticle) => {
    return (
      featuredArticle && 
      (
        <div className="m-featured-articles l-grid__col l-grid__col-12-12">
        <article className="m-featured-article">
          <div className="m-featured-article__image lazy" data-bg-multi={setFeaturedBackgroundImage(featuredArticle.imageUrl)}/>
          <div className="m-featured-article__container">
            {(featuredArticle.theme1Title || featuredArticle.theme2Title) && (
              <ul className="m-pill-list m-featured-article__tags">
                {featuredArticle.theme1Title && (
                  <li className="m-pill-list__item">
                    <div className="a-pill a-pill--green a-pill--nocursor" style={featuredArticle.theme1Color && setThemeBackgroundColor(featuredArticle.theme1Color)}>
                      <span className="a-pill__text">{featuredArticle.theme1Title}</span>
                    </div>
                  </li>)
                }
                {featuredArticle.theme2Title && (
                  <li className="m-pill-list__item">
                    <div className="a-pill a-pill--blue a-pill--nocursor" style={featuredArticle.theme2Color && setThemeBackgroundColor(featuredArticle.theme2Color)}>
                      <span className="a-pill__text">{featuredArticle.theme2Title}</span>
                    </div>
                  </li>)
                }
              </ul>)
            }
            <a className="a-link m-featured-article__link a-link--decorated " href={featuredArticle.pageUrl}>
              <h4 className="m-featured-article__title">{featuredArticle.title}</h4>
            </a>
            <div className="m-featured-article__meta">
              <div className="m-featured-article__timing">
                <time className="m-featured-article__timing-item">{featuredArticle.date && dateUtils.formatDate(featuredArticle.date, 'D MMMM YYYY')}</time>
                <div className="a-attribute m-featured-article__timing-item a-attribute--inherited">
                  <span className="a-attribute__icon a-attribute__icon--before icon-stopwatch"/>
                  <p className="a-attribute__text">{featuredArticle.readingTime && `${featuredArticle.readingTime} min`}</p>
                </div>
              </div>
              <div className="m-featured-article__footer">
                <div className="a-avatar m-featured-article__avatar">
                  <div className="a-image a-avatar__image">
                    <img className="a-image__visual" src={featuredArticle.authorImage} alt={featuredArticle.authorName} title={featuredArticle.authorName}></img>
                  </div>
                  <div className="a-avatar__content">
                    <p className="a-avatar__title">{featuredArticle.authorName}</p>
                    <p className="a-avatar__description">{featuredArticle.authorFunction}</p>
                  </div>
                </div>
                <a className="a-link m-featured-article__link a-link--decorated " href={featuredArticle.pageUrl}>
                  <span className="a-link__text">{t('blog.featuredArticles.readMore')}</span>
                </a>
              </div>
            </div>
          </div>
        </article>
        </div>
      )
    )
  }

/**
 * Main render
 * ---------------------------------------------------------------------------
 */
 useLazyLoad();
  
  return rendering && (
    <div className="l-grid l-grid__row l-grid--with-spacing l-grid--stretch">
      {
        query.loading && (
          <div className='o-team-member-card-group__loader'>
            <Loader />
          </div>
        )
      }
      {
        (!query.loading && query.data && query.data.articlepage.featuredArticles.hits && query.data.articlepage.featuredArticles.hits.length > 0) ? 
        (renderFeaturedArticle(query.data.articlepage.featuredArticles.hits[0])) : renderNoArticlesFound()
      }
      {
        !query.loading && query.data && query.data.articlepage.featuredArticles.hits && query.data.articlepage.featuredArticles.hits.length > 1 && (
          <div className="m-featured-articles l-grid__col l-grid__col-12-12">
            <div className="a-title u-margin-top-1x u-margin-bottom-1x">
				      <h6 className="a-title__text">{t('blog.featuredArticles.recentArticles')}</h6>
	          </div>
            {renderArticleTeasers(query.data.articlepage.featuredArticles.hits.slice(1))}
          </div>
        )
      }
    </div>
  );
}

export default withTranslation()(FeaturedArticles);
