/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Tag = ({ classes, text }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-tag ${classes}`}>
      <span className='a-tag__text'>{text}</span>
    </div>
  );
};

export default Tag;
