/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Col = ({
  classes = '',
  col,
  text
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`o-table__col o-table__col-${col}-12 ${classes}`}>
      {text}
    </div>
  );
};

export default Col;
