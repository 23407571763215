/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object } from '../../utils';

/**
 * Definition
 * =============================================================================
 */
const ContentListItemText = (props) => {
  const { ContentListItemTextContent } = props.fields;
 
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
 

  return (ContentListItemTextContent || isEditorActive()) && (
       <Text className='s-jss-rte' field={ContentListItemTextContent} />
  );
};

export default ContentListItemText;
