/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { type as utilType, object, url } from '../../utils';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import { Link as LinkJSS, Text } from '@sitecore-jss/sitecore-jss-react';
import { Events } from '../../managers';
import { Link as RLink } from 'react-router-dom';

const Button = ({
  fields = {},
  classes = '',
  type,
  onClick,
  onMouseDown,
  handleOverlayTriggerClick,
  handleModalTriggerClick,
  deepRef
}) => {

  const {
    ButtonText,
    ButtonUrl,
    RawButtonUrl,
    ButtonIconBefore,
    ButtonIconAfter
  } = fields;

  classes = `a-button ${classes}`;

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClick = (e) => {
    Events.handleEventClick(e);
    if (handleModalTriggerClick) handleModalTriggerClick(e);
    if (handleOverlayTriggerClick) handleOverlayTriggerClick(e);
    if (onClick) onClick(e);
  };

  const getIconClass = (iconValue) => {
    const iconClass = object.getNested(iconValue, 'fields', 'Value', 'value') || iconValue;
    return (
      typeof (iconClass) === 'string' && iconClass && !iconClass.startsWith('icon-')
        ? 'icon-' + iconClass
        : iconClass);
  }
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderContent = () => {
    return (
      <React.Fragment>
        {ButtonIconBefore && (
          <span className={`a-button__icon ${getIconClass(ButtonIconBefore)}`}></span>
        )}

        {ButtonText && (
          <span className='a-button__text'>
            {(utilType.isReact(ButtonText) && (
              ButtonText
            )) || ((ButtonText || isEditorActive()) && (
              <Text field={ButtonText} />
            ))}
          </span>
        )}

        {ButtonIconAfter && (
          <span className={`a-button__icon ${getIconClass(ButtonIconAfter)}`}></span>
        )}
      </React.Fragment>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return ((type === 'button' && (
    <button ref={deepRef} className={classes} href={ButtonUrl} onClick={handleClick} onMouseDown={onMouseDown}>
      {renderContent()}
    </button>
  )) ||
    (type === 'submit' && (
      <button ref={deepRef} className={classes} type="submit" onClick={handleClick} onMouseDown={onMouseDown}>
        {renderContent()}
      </button>
    )) ||
    (type === 'clickButton' && (
      <button ref={deepRef} className={classes} onClick={handleClick} onMouseDown={onMouseDown}>
        {renderContent()}
      </button>
    )) || (
      (utilType.isString(ButtonUrl) && url.isRelative(ButtonUrl) && (
        <RLink ref={deepRef} className={classes} to={ButtonUrl} onClick={handleClick} onMouseDown={onMouseDown}>
          {renderContent()}
        </RLink>
      )) || (
        (utilType.isString(ButtonUrl) && !url.isRelative(ButtonUrl) && (
          <a ref={deepRef} className={classes} href={ButtonUrl} onClick={handleClick} onMouseDown={onMouseDown} rel="noopener noreferrer">
            {renderContent()}
          </a>
        ))
        ||
        ((!ButtonUrl || !object.getNested(ButtonUrl, "value", "href")) && RawButtonUrl && (
          <a ref={deepRef} className={classes} href={RawButtonUrl.value} onClick={handleClick} onMouseDown={onMouseDown}>
            {renderContent()}
          </a>
        ))
        || ((ButtonUrl || isEditorActive()) && (
          <LinkJSS
            ref={deepRef}
            className={classes}
            field={ButtonUrl}
            onClick={handleClick}
            onMouseDown={onMouseDown}
          >
            {renderContent()}
          </LinkJSS>
        ))
      )))
};

export default Button;
