import { object } from '../../../../utils';

/**
 * Get condition fields
 * =============================================================================
 * Returns fields that are part of a condition or an action
 * 
 */

const getConditionFields = (fieldsObject) => {
  let conditionFields = [];
  let actions, conditions;

  object.forEachNested(fieldsObject, function (curr) {
    if (curr && curr.constructor === Object &&
      curr.conditionSettings && curr.conditionSettings.fieldConditions
      && curr.conditionSettings.fieldConditions.length) {

      curr.conditionSettings.fieldConditions.forEach(fieldConditionItem => {
        actions = fieldConditionItem.actions;
        conditions = fieldConditionItem.conditions;
        if (actions && actions.length) {
          actions.forEach(actionItem => {
            if (actionItem.fieldId && conditionFields.indexOf(actionItem.fieldId) < 0) {
              conditionFields.push(actionItem.fieldId);
            }
          });
        }
        if (conditions && conditions.length) {
          conditions.forEach(conditionItem => {
            if (conditionItem.fieldId && conditionFields.indexOf(conditionItem.fieldId) < 0) {
              conditionFields.push(conditionItem.fieldId);
            }
          });
        }
      });
    }
  });

  return conditionFields;
};

const getConditions = (fieldsObject) => {
  let conditionsObject = {};
  let actions, conditions;
  object.forEachNested(fieldsObject, function (curr) {
    if (curr && curr.constructor === Object &&
      curr.conditionSettings && curr.conditionSettings.fieldConditions
      && curr.conditionSettings.fieldConditions.length) {

      curr.conditionSettings.fieldConditions.forEach(fieldConditionItem => {
        actions = fieldConditionItem.actions;
        conditions = fieldConditionItem.conditions;
        if (actions && actions.length && conditions && conditions.length) {
          actions.forEach(actionItem => {
            if (typeof conditionsObject[actionItem.fieldId] === 'undefined') {
              conditionsObject[actionItem.fieldId] = [];
            }
            conditionsObject[actionItem.fieldId].push(
              {
                matchTypeId: fieldConditionItem.matchTypeId,
                conditions: conditions,
                actionTypeId: actionItem.actionTypeId,
                actionValue: actionItem.value
              });
          });
        }
      });
    }
  });

  return conditionsObject;
};

const getFieldValues = (fieldsObject) => {
  let fieldValues = {};

  object.forEachNested(fieldsObject, function (curr) {
    if (curr && curr.constructor === Object &&
      curr.conditionSettings) {
        fieldValues[curr.conditionSettings.fieldKey] = curr.value;
    }
  });

  return fieldValues;
};

/**
 * Exports
 * =============================================================================
 */
export default {
  getConditionFields,
  getConditions,
  getFieldValues
};
