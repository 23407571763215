/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Root from './Root';
import Item from './Item';
import { Button } from './../../building-blocks';
import { object } from './../../utils';
import RichText from '../../building-blocks/RichTextEx';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

/**
 * Definition
 * =============================================================================
 */
const CardSecondary = (props) => {
    const {
        CardDescription,
        CardTitle,
        CardEyebrowText,
        CardHeaderImage,
        CardAlignHeaderCenter,
        ButtonText,
        ButtonUrl,
        RawButtonUrl
    } = props.fields;

    /**
     * Renders
     * ---------------------------------------------------------------------------
     */

    const renderTitle = () => {
        return (
            <h3 className='m-card__title'>
                <Text field={CardTitle} />
            </h3>
        );
    };

    const renderDescription = () => {
        return (
            <div className="m-card__description">
                <RichText className='s-jss-rte' field={CardDescription} />
            </div>
        );
    };

    const renderEyebrowText = () => {
        return (
            <p className="m-card__eyebrow">
                <Text field={CardEyebrowText} />
            </p>
        );
    };

    const renderImage = () => {
        return (
            <figure className="m-card__figure">
                <img className="m-card__image" src={object.getNested(CardHeaderImage, 'value', 'src')} alt={object.getNested(CardHeaderImage, 'value', 'alt')} />
            </figure>
        );
    };

    const renderButton = () => {
        return (
            <div className='m-card__action'>
                <Button
                    classes='a-button a-button--secondary a-button--rounded u-width-100'
                    {...props}
                />
            </div>
        );
    };

    const renderItem = (component, index) => {
        return (
            <Item
                key={`card-secondary-item-${index}`}
            >
                {component}
            </Item>
        );
    };

    /**
     * Main render
     * ---------------------------------------------------------------------------
     */
    return (
        <Root isArticle={object.hasNested(CardTitle, 'value')}>
            {(object.getNested(CardHeaderImage, 'value', 'src') || isEditorActive()) && renderImage()}
            <div className="m-card__container">
                <header className={`m-card__header${CardAlignHeaderCenter.value ? ' m-card--align-center' : ''}`}>
                    {((CardEyebrowText && (CardEyebrowText.value.length > 0)) || isEditorActive()) && renderEyebrowText()}
                    {((CardTitle && (CardTitle.value.length > 0)) || isEditorActive()) && renderTitle()}
                    {((CardDescription && (CardDescription.value.length > 0)) || isEditorActive()) && renderDescription()}
                </header>
            </div>
            <div className="m-card__content">
                <div className="l-grid l-grid--with-spacing-small">
                    <Placeholder
                        {...props}
                        name='card-secondary-items'
                        renderEach={renderItem}
                    />
                </div>
                {(isEditorActive() || (ButtonText && (ButtonText.value.length > 0) && (ButtonUrl || RawButtonUrl))) && renderButton()}
            </div>
        </Root>
    );
};

export default CardSecondary;
