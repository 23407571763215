/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link } from 'react-router-dom';


/**
 * Definition
 * =============================================================================
 */
const Logo = ({ classes, url }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Link className={`a-logo ${classes}`} to={url || '/'}>
    </Link>
  );
};

export default Logo;
