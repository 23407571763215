/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object, styles } from './../../utils';
import Background from '../Background';
import RichText from '../../building-blocks/RichTextEx';
/**
 * Definition
 * =============================================================================
 */
const Header = ({
  title,
  props,
  classes = '',
  bottomSpacing,
  alignment,
  backgroundImage = null,
  backgroundType = null,
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderTitle = () => {
    return <RichText className='l-section__title s-jss-rte' field={title} />;
  };

  /**
	 * Helpers
	 * ---------------------------------------------------------------------------
   */
   const getMarginBottomClasses = () => {
    const spacing = object.getNested(bottomSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return styles.getMarginBottomUtil(spacing);
  };

  const getAlignClasses = () => {
    const align = object.getNested(alignment, 'fields', 'Value', 'value');

    if (!align) return '';

    return 'l-section__header--align-'+align;
  };

  const getClasses = () => {
    const bottom = getMarginBottomClasses();
    const align = getAlignClasses();

    return `${bottom} ${align}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <header className={`l-section__header ${classes} ${getClasses()}`}>
      <Background backgroundImage={backgroundImage} backgroundType={backgroundType} />
    
      {(title || isEditorActive()) && renderTitle()}

      {!title && (
        <Placeholder
          {...props}
          name='section-header'
        />
      )}
    </header>
  );
};

export default Header;
