/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Col = ({ type, side, children }) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getLeftClasses = () => {
    const col = object.getNested(type, 'fields', 'Value', 'value');

    if (!col) return 'l-grid__col-6-12';

    return classes.compare(col, {
      '1-1': 'l-grid__col-6-12',
      '2-1': 'l-grid__col-8-12',
      '1-2': 'l-grid__col-4-12',
      '1-1-1': 'l-grid__col-4-12'
    });
  };

  const getRightClasses = () => {
    const col = object.getNested(type, 'fields', 'Value', 'value');

    if (!col) return 'l-grid__col-6-12';

    return classes.compare(col, {
      '1-1': 'l-grid__col-6-12',
      '2-1': 'l-grid__col-4-12',
      '1-2': 'l-grid__col-8-12',
      '1-1-1': 'l-grid__col-4-12'
    });
  };

  const getMiddleClasses = () => {
    const col = object.getNested(type, 'fields', 'Value', 'value');

    if (!col) return 'l-grid__col-4-12';

    return classes.compare(col, {
      '1-1-1': 'l-grid__col-4-12',
    });
  };

  const getClasses = () => {
    const col = side === 'left' ? getLeftClasses() : side == 'middle' ? getMiddleClasses() : getRightClasses();

    return `${col}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={getClasses()}>
      {children}
    </div>
  );
};

export default Col;
