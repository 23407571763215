/**
 * isEqual
 * =============================================================================
 */

function isReact(value) {
  return typeof value === 'string' || typeof value === 'function';
}
function isString(value) {
  return typeof value === 'string';
}

/**
 * Exports
 * =============================================================================
 */
export default {
  isReact, isString
};
