/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Connected from './Connected';
import Disconnected from './Disconnected';

/**
 * Definition
 * =============================================================================
 */
const Header = (props) => (props.rendering.dataSource !== 'available-in-connected-mode'
  ? <Connected {...props} /> : <Disconnected {...props} />);

export default Header;
