/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Text = ({
  classes,
  text
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <p className={`a-text ${classes}`}>
      {text}
    </p>
  );
};

export default Text;
