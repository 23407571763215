var env = Object.assign({}, process.env);
env.addEnvVariables = function (vars) {
  if (typeof vars === 'object') {
    Object.keys(vars).forEach(x => {
      if (vars[x]) {
        this[x] = vars[x];
      }
    });
  }
}.bind(env);
module.exports = env;
//export default env;
