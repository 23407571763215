/**
 * Imports
 * =============================================================================
 */
import React from "react";
import BasketTest from "./BasketTest";

/**
 * Definition
 * =============================================================================
 */
class Connected extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <BasketTest></BasketTest>;
  }
}

export default Connected;
