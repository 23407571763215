import React from 'react';
import { Link } from 'react-router-dom';
import { object } from '../../utils';
import Image from '../Image';

const InternCard = ({ data, index, list, baseUrl }) => {
  const getPageName = (x) => `${baseUrl}/${x.split('/').pop()}`

  const url = getPageName(data.url);
  const urlsList = list.map(x => getPageName(x))

  return (
    <li className={`m-intern-card o-intern-card-group__item l-grid__col-3-12`}>
      <div className='m-intern-card-content-wrapper'>
        <Link
          to={{
            pathname: url ,
            state: { index, list: urlsList }
          }}
        >
          <div className='m-intern-card__picture a-image'>
            <Image
              classes='a-image__visual m-intern-card__visual m-intern-card__visual--is-normal'
              ImageUrl={data.profilePicture.src}
              EstimatedSize='3'
              Title={`${data.firstName.value} ${data.lastName.value}`}
            />
          </div>
          <div className='m-intern-card__title'>
            <h5>
              {data.firstName.value} {data.lastName.value[0]}.
            </h5>
          </div>
          {object.getNested(data, 'sector', 'value') && (
            <div className='m-intern-card__function'>{data.sector.value}</div>
          )}
          {object.getNested(data, 'trainingName', 'value') && (
            <div className='m-intern-card__department'>
              {data.trainingName.value}
            </div>
          )}
        </Link>
      </div>
    </li>
  );
};

export default InternCard;
