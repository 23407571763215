/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import MetaOgImage from '../MetaOgImage';
import MetaTwitterImage from '../MetaTwitterImage';
import { object, type } from './../../utils';
import Background from '../Background';
import RichText from '../../building-blocks/RichTextEx';
/**
 * Definition
 * =============================================================================
 */
const Hero = ({ classes = '', fields, imageSrc, imageSrcMobile }) => {
  const {
    HeroTitle,
    HeroDescription,
    HeroImage,
    HeroImageMobile,
    FullWidth
  } = fields;

  const imageUrl = object.getNested(HeroImage, 'value', 'src') || imageSrc;
  const imageUrlMobile = object.getNested(HeroImageMobile, 'value', 'src') || imageSrcMobile;
  const fullWidth = object.getNested(FullWidth, 'value');

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
   const renderImage = () => (
     <Background
       classes='o-hero__image'
       notLazy={true}
       backgroundImage={imageUrl}
       backgroundImageMobile={imageUrlMobile}
       columnSize='13'
     />
   );

  const renderTitle = () => {
    return (
      <h1 className='o-hero__title'>
        <Text field={HeroTitle} />
      </h1>
    );
  };

  const renderDesciption = () => {
    return <RichText className='o-hero__text s-jss-rte-hero' field={HeroDescription} />;
  };

  const renderMetaImage = () => {
    return (
      imageUrl && (
        <>
          <MetaOgImage imageUrl={imageUrl} />
          <MetaTwitterImage imageUrl={imageUrl} />
        </>
      )
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <>
      {renderMetaImage()}

      <section className={`o-hero ${classes}`}>
        {renderImage()}

        <div className='l-container'>
          <div
            className={`o-hero__content ${fullWidth ? 'o-hero--full-width' : ''}`}
          >
            <header className='o-hero__header'>
              {(isEditorActive() ||
                type.isReact(HeroTitle) ||
                object.getNested(HeroTitle, 'value')) &&
                renderTitle()}
            </header>

            {(isEditorActive() ||
              type.isReact(HeroDescription) ||
              object.getNested(HeroDescription, 'value')) &&
              renderDesciption()}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
