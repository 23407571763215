/**
 * Imports
 * =============================================================================
 */
import React from "react";
import Modal from "./../Modal";
import { Button } from "./../../building-blocks";
import { Events } from "../../managers";
import ModalTrigger from "./../Modal/Trigger";
import ActionGroup from "./../ActionGroup";
import { withTranslation } from "../../hoc/withTranslation";
/**
 * Definition
 * =============================================================================
 */
class Alert extends React.Component {

  constructor(props) {
    super(props);
    const {t,isOpen=false} = props;
    var contentText = t("alert.general.content");
    var titleText = t("alert.general.title");
    var buttonText = t("alert.general.close");

    this.state={isOpen:isOpen,title:titleText,content:contentText,button:buttonText};
   
    this.handleOpen=this.handleOpen.bind(this);
    this.handleClose=this.handleClose.bind(this);
    this.handleToggle=this.handleToggle.bind(this);
   //
  }

  componentDidMount() {
   
    Events.emitter.on("modal-alert-close", this.handleClose);
    Events.emitter.on("modal-alert-open", this.handleOpen);
    Events.emitter.on("modal-alert-toggle", this.handleToggle);


  }

  componentWillUnmount() {
  
    Events.emitter.removeListener("modal-alert-close", this.handleClose);
    Events.emitter.removeListener("modal-alert-open", this.handleOpen);
    Events.emitter.removeListener("modal-alert-toggle", this.handleToggle);
   }
   handleOpen(content,title,button,onClose){
     const {t} = this.props;
    if(title==null)title = "alert.general.title";
    if(button==null)button = "alert.general.close";
    if(content==null)content = "alert.general.content"
    var contentText = t(content);
    var titleText = t(title);
    var buttonText = t(button);

    this.setState({isOpen:true,title:titleText,content:contentText,button:buttonText,onClose:onClose});
    
    //Events.emitter.emit("modal-trigger-open:alert-modal");
   }

   handleToggle(){

    this.setState({isOpen:this.state.isOpen});
   }
   handleClose(){
   
    this.setState({isOpen:false});
   }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
   
    const {
     isOpen = false,
      title,
      content,
      button,
      onClose
    } = this.state;
  
   return (
      <Modal
      id="alert-modal"
       title={title}
       contentClasses="u-padding-bottom-none"
       content={content}
       onClose={onClose}
       isOpen={isOpen}
       footer={(
           <ActionGroup classes="m-action-group--space-between m-action-group--horizontal-center m-action-group--stretch">
             
             <ModalTrigger
               triggerComponent={Button}
               triggerType="close"
               targets="alert-modal"
                type="button"
               fields={{
                 ButtonText: button
               }}
             />
           
           </ActionGroup>
         )}
     />
    );
  }
}

export default withTranslation()(Alert);
