import React, { useContext, useEffect } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms';
import FieldLabel from '../Helpers/FieldLabel';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';
import FormNewsletterDataContext from '../../../contexts/FormNewsletterDataContext';

var alreadyInitializedCheckBoxLists = [];

const CheckboxList = (props) => {
  const {
    field,
    field: {
      model: { items, cssClass },
    },
    value,
    onChange,
    errors,
    tracker,
  } = props;

  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);
  const newsletterFormDataContext = useContext(FormNewsletterDataContext);
  var fieldValue = value;

  var shouldHide = false;
  var shouldDisable = false;
  const shouldAutoMapValue = cssClass && cssClass.includes('js-automap');
  if (shouldAutoMapValue && newsletterFormDataContext && newsletterFormDataContext.newsletterFormData && newsletterFormDataContext.newsletterFormData[field.model.name]) {
    fieldValue = value.length || alreadyInitializedCheckBoxLists.includes(field.model.name)
      ? value
      : newsletterFormDataContext.newsletterFormData[field.model.name];
  }

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const updateContextFieldValue = (valueToUpdateTo) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, valueToUpdateTo);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, valueToUpdateTo);
    }
  }

  useEffect(() => {
    // Used just to assign the value initially when first time get it from newsletterFormDataContext
    if (fieldValue.length && !alreadyInitializedCheckBoxLists.includes(field.model.name)) {
      onChange(field.valueField.name, fieldValue, true, []);
      alreadyInitializedCheckBoxLists.push(field.model.name);
    }
  }, [fieldValue]);

  // Used to cleanup
  useEffect(() => {
    return () => {
      alreadyInitializedCheckBoxLists = alreadyInitializedCheckBoxLists.filter(el => el !== field.model.name);
    };
  }, []);

  return (
    <div className={`a-formfield__checkboxList ${cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      <div className='a-formfield__checkboxList--wrapper'>
        {items.map((item, index) => {
          const itemId = field.valueField.id + index;
          return (
            <div className='a-toggle' key={itemId}>
              <label className='a-toggle__container' htmlFor={itemId}>
                <div className='a-toggle__wrapper'>
                  <input
                    className='a-toggle__input'
                    type='checkbox'
                    id={itemId}
                    name={field.valueField.name}
                    value={item.value}
                    checked={fieldValue.some((v) => v === item.value)}
                    disabled={shouldDisable}
                    onChange={(e) =>
                      handleOnChange(
                        field,
                        value,
                        e.target.value,
                        e.target.checked,
                        onChange
                      )
                    }
                    onFocus={() => tracker.onFocusField(field, value)}
                    onBlur={() => tracker.onBlurField(field, value, errors)}
                    onClick={(e) => updateContextFieldValue(e.target.value)}
                  />
                  <span className='a-toggle__icon a-toggle__icon--off icon-checkbox-unchecked'></span>
                  <span className='a-toggle__icon a-toggle__icon--on icon-checkbox-checked'></span>
                </div>
                <span className='a-toggle__label '>{item.text}</span>
              </label>
            </div>
          );
        })}
      </div>

      <FieldValidationErrors {...props} />
    </div>
  );
};

/**
 * @param {ValueFormField} field
 * @param {string[]} originalFieldValue
 * @param {string} changedElement
 * @param {boolean} checked
 * @param {FieldChangeCallback} callback
 */
function handleOnChange(
  field,
  originalFieldValue,
  changedElement,
  checked,
  callback
) {
  // we can have multiple selected values. So we need to push ALL the selected
  // values back up to the root form. This is done using an array, which the form
  // serializer knows how to expand into multiple values on post

  let value = originalFieldValue;

  if (checked) {
    value.push(changedElement);
  } else {
    value = value.filter((v) => v !== changedElement);
  }

  // (fieldName, fieldValue, isValid, validationErrors)
  callback(field.valueField.name, value, true, []);
}

export default CheckboxList;
