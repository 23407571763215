import React from 'react';
import ButtonRadioList from './ButtonRadioList';
import RadioButtonList from './RadioButtonList';
import CardRadioList from './CardRadioList';
const getListByType=(cssClass, props)=>{
  const classes= cssClass.split(" ");
  var type = classes.find(className=>className.indexOf('type-')>-1);

  if(type)type = type.replace("type-","");
  switch(type){
    case "button":
      return <ButtonRadioList {...props} />;
      case "card":
        return <CardRadioList {...props} />;
      default:
        return <RadioButtonList {...props} />;
  }
}
const RadioButtonListSelector = (props) => {
  const {
    field: {
      model: { cssClass },
    }
  } = props;
  return (<>
    {getListByType(cssClass,props)}
    </>);
};


export default RadioButtonListSelector;
