/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import DisclosureList from './../DisclosureList';
import MobileItem from './MobileItem';

/**
 * Definition
 * =============================================================================
 */
const MobileList = (props) => {
  const { placeholders } = props.rendering;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItem = (data, index) => {
    return (
      <MobileItem
        key={`card-group-mobile-item-${index}`}
        {...data}
      />
    );
  };

  const renderItems = () => {
    return placeholders['card-group-items']
      .filter(x => x.type !== 'text/sitecore')
      .map(renderItem);
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <DisclosureList classes='u-desktop-hide'>
      {renderItems()}
    </DisclosureList>
  );
};

export default MobileList;
