/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';



/**
 * Definition
 * =============================================================================
 */
const ContentListItem = (props) => {
  const {
    rendering
  } = props;
 

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <Placeholder
    name='content-list-item'
    rendering={rendering}
  />
  );
};

export default ContentListItem;
