/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Events } from './../../../managers';

/**
 * Definition
 * =============================================================================
 */
class Action extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      height: null
    };
  }

  componentDidMount() {
    const { tableId, index } = this.props;
    Events.emitter.on(`table-row-resize:${tableId}-row-${index}`, this.handleResize);
  }

  componentWillUnmount() {
    const { tableId, index } = this.props;

    Events.emitter.removeListener(`table-row-resize:${tableId}-row-${index}`, this.handleResize);
  }

  handleResize = ({ height }) => {
    this.setState({ height });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { height } = this.state;
    const { url, text, icon } = this.props;

    return (
      <div className='o-table__action-row' style={{ height: height ? `${height}px` : 'auto' }}>
        <a onClick={Events.handleEventClick} rel="noopener norefferer" className='o-table__action' href={url}>
          <span className='o-table__action-text'>{text}</span>

          {icon && (
            <span className={`o-table__action-icon ${icon}`}></span>
          )}
        </a>
      </div>
    );
  }
}

export default Action;

