/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { image, object } from './../../utils';
import { withLazyload } from './../../hoc';
/**
 * Definition
 * =============================================================================
 */

class Image extends React.Component {

  getImageProp(propName) {
    return typeof this.props[propName] == 'string'
      ? this.props[propName]
      : object.getNested(this.props, 'fields', propName, 'value', 'src');
  }

  getStateFromProps() {
    return {
      imagepath: this.getImageProp('ImageUrl'),
      imagepathMobile: this.getImageProp('ImageUrlMobile') || this.getImageProp('ImageUrl'),
      columnsize:
        (typeof this.props.EstimatedSize == 'string'
          ? this.props.EstimatedSize
          : object.getNested(
            this.props,
            'fields',
            'EstimatedSize',
            'fields',
            'Value',
            'value'
          )) || 12,
      title:
        typeof this.props.Title == 'string'
          ? this.props.Title
          : object.getNested(this.props, 'fields', 'Title', 'value'),
      notLazy:
        typeof this.props.NotLazy == 'boolean'
          ? this.props.NotLazy
          : object.getNested(this.props, 'fields', 'NotLazy', 'value'),
      classes: this.props.classes == null ? '' : this.props.classes,
    };
  }

  constructor(props) {
    super(props);
    this.state = this.getStateFromProps();
    this.imgRef = React.createRef();
    //this.updateState();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState(this.getStateFromProps());
      // Forces lazy load to reset img element internal status and then reloads image in case data-src changed
      if (window.lazyLoadInstance && this.imgRef.current) {
        window.lazyLoadInstance.resetElementStatus(this.imgRef.current);
        window.lazyLoadInstance.update();
      }
    }
  }

  createSourceTag({ type, platform, src }) {
    const sourceSet = `${image.getImageUrlByColumnSize(
      src,
      this.state.columnsize,
      platform,
      type,
      1
    )} 1x, ${image.getImageUrlByColumnSize(
      src,
      this.state.columnsize,
      platform,
      type,
      2
    )} 2x`;

    return (
      <source
        key={`${type}-${platform}`}
        media={image.getMediaTag(platform)}
        type={`image/${type}`}
        {...(this.state.NotLazy
          ? { srcSet: sourceSet }
          : { 'data-srcset': sourceSet })}
      />
    );
  }

  createSourcesets() {
    const originalformat = image.getExtension(this.state.imagepath);
    const originalformatMobile = image.getExtension(this.state.imagepathMobile);
    const webp = 'webp';
    
    // webp apparently doesn't support animated gifs, so we don't render it for gifs
    const webpSourcesets =
      originalformat === 'gif'
        ? []
        : [
          { platform: 'desktoplarge', type: webp, src: this.state.imagepath },
          { platform: 'desktop', type: webp, src: this.state.imagepath },
          { platform: 'tablet', type: webp, src: this.state.imagepath },
        ];

    const webpSourcesetsMobile =
      originalformatMobile === 'gif'
        ? []
        : [{ platform: 'mobile', type: webp, src: this.state.imagepathMobile }];

    const originalSourcesets = [
      { platform: 'desktoplarge', type: originalformat, src: this.state.imagepath },
      { platform: 'desktop', type: originalformat, src: this.state.imagepath },
      { platform: 'tablet', type: originalformat, src: this.state.imagepath },
      { platform: 'mobile', type: originalformatMobile, src: this.state.imagepathMobile },
    ];

    return [
      ...webpSourcesets,
      ...webpSourcesetsMobile,
      ...originalSourcesets,
    ].map((x) => this.createSourceTag(x));
  }

  render() {
    var url = image.getImageUrlByColumnSize(
      this.state.imagepath,
      this.state.columnsize,
      'desktoplarge',
      image.getExtension(this.state.imagepath),
      1
    );

    var urlMobile = image.getImageUrlByColumnSize(
      this.state.imagepathMobile,
      this.state.columnsize,
      'mobile',
      image.getExtension(this.state.imagepathMobile),
      1
    );

    /**
     * Main render
     * ---------------------------------------------------------------------------
     */
    return (
      <picture>
        {this.createSourcesets()}
        {!this.state.notLazy && this.state.imagepath && (
          <img
            className={`lazy ${this.state.classes}`}
            src='data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
            data-src={image.getImageUrlByColumnSize(
              this.state.imagepath,
              this.state.columnsize,
              'desktoplarge',
              image.getExtension(this.state.imagepath),
              1
            )}
            alt={this.state.title}
            title={this.state.title}
            ref={this.imgRef}
          />
        )}
        {this.state.notLazy && this.state.imagepath && this.state.imagepath === this.state.imagepathMobile && (
          <>
            <img
              src={url}
              className={`${this.state.classes}`}
              alt={this.state.title}
              title={this.state.title}
            />
          </>
        )}
        {this.state.notLazy && this.state.imagepath && this.state.imagepath !== this.state.imagepathMobile && (
          <>
            <img
              src={url}
              className={`a-image__desktop ${this.state.classes}`}
              alt={this.state.title}
              title={this.state.title}
            />
            <img
              src={urlMobile}
              className={`a-image__mobile ${this.state.classes}`}
              alt={this.state.title}
              title={this.state.title}
            />
          </>
        )}
      </picture>
    );
  }
}

export default withLazyload(Image);
