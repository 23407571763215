/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object } from '../../utils';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
class SimpleText extends React.Component {
  constructor(props) {
    super(props);
  }

  getAlignClass() {
    const { SimpleTextAligment} = this.props.fields;
    const align = object.getNested(
      SimpleTextAligment,
      'fields',
      'Value',
      'value'
    );

    if (!align) {
      return '';
    }

    return 'a-text--align-' + align;
  }

  getAlignContentClass() {
    const { SimpleTextContentAlignment } = this.props.fields;
    const align = object.getNested(
      SimpleTextContentAlignment,
      'fields',
      'Value',
      'value'
    );

    if (!align) {
      return '';
    }

    return ' a-text__align' + align;
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { SimpleTextContent, SimpleTextFullWidth } = this.props.fields;
    const textCssClass = object.getNested(SimpleTextFullWidth, 'value')
      ? '' + this.getAlignContentClass()
      : 'a-text__text' + this.getAlignContentClass();
    return (
      (SimpleTextContent || isEditorActive()) && (
        <div className={`a-text ${this.getAlignClass()}`}>
          <RichText
            className={`s-jss-rte ${textCssClass}`}
            field={SimpleTextContent}
          />
        </div>
      )
    );
  }
}

export default SimpleText;
