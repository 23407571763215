/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const hasActive = data =>
  Array.isArray(data) &&
  !!data.find(x => x.isChecked || hasActive(x.children));

const withCollapse = () => (WrappedComponent) => {
  return class extends React.Component {

    constructor(props) {
      super(props);

      const { data = null } = props;
      this.state = {
        isOpen: props.forceOpenState ? props.isOpen : props.isOpen || hasActive(data),
      };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

    /**
     * Helpers
     * -------------------------------------------------------------------------
     */
    setCollapseState = () => {
      const { isOpen } = this.state;

      return isOpen ? 'is-open' : '';
    }

    /**
     * Handlers
     * -------------------------------------------------------------------------
     */
    handleToggleCollapse = () => {
      this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
    }

    handleOpenCollapse = () => {
      if (this.state.isOpen) return;

      this.setState({ isOpen: true });
    }

    handleCloseCollapse = () => {
      if (!this.state.isOpen) return;

      this.setState({ isOpen: false });
    }

    /**
     * Main render
     * -------------------------------------------------------------------------
     */
    render() {
      const { isOpen } = this.state;

      return (
        <WrappedComponent
          setCollapseState={this.setCollapseState}
          handleToggleCollapse={this.handleToggleCollapse}
          handleOpenCollapse={this.handleOpenCollapse}
          handleCloseCollapse={this.handleCloseCollapse}
          getIsOpenState={() => this.state.isOpen}
          isOpen={isOpen}
          {...this.props}
        />
      );
    }
  }
}

export default withCollapse;
