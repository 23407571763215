/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Loader = ({
  classes = '',
  text,
}) => {

  /**
  * Renders
  * ----------------------------------------------------------------------------
  */
  const renderDots = () => {
    let component = [];

    for (let i = 0; i < 9; i++) {
      component.push((
        <div
          className='a-loader__shape'
          key={`loader-dot-${i}`}
        >
          <span className='a-loader__dot'></span>
        </div>
      ))
    }

    return component;
  };

 /**
  * Main render
  * ----------------------------------------------------------------------------
  */
  return (
    <div className={`a-loader ${classes}`}>
      <div className='a-loader__dots'>
        {renderDots()}
      </div>

      {text && (
        <p className='a-loader__feedback'>{text}</p>
      )}
  </div>
  );
};

export default Loader;
