/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import moment from 'moment';

import DateInput from './../DateInput';
import {InputDateFormat} from'../../lib/Constants';



/**
 * Definition
 * =============================================================================
 */
class Datepicker extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      value: props.value || null
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
  
      this.setState({ value: this.props.value });
    }
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChange = (value) => {
    const { name, onChange } = this.props;
   
    this.setState({ value }, () => {
      if (onChange) onChange(name, value);
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes,
      label,
      placeholder,
      id,
      name,
      disabled,
      iconAfter,
      minDate,
      maxDate,
      onFocus,
      onBlur
    } = this.props;
    const { value } = this.state;
 
    return (
      <div className='s-react-datepicker'>
        <DateInput
          label={label}
          classes={classes}
          dateFormat='d/m/Y'
          selected={value ? moment(value, InputDateFormat).toDate() : null}
          onChange={this.handleChange}
          placeholder={placeholder}
          value={value ? value : ''}
          id={id}
          disabled={disabled}
          name={name}
          minDate={minDate}
          maxDate={maxDate}
          iconAfter={iconAfter}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </div>
    );
  }
};

export default Datepicker;
