/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { classes as Classes } from './../../utils';
import Image from '../Image';

/**
 * Definition
 * =============================================================================
 */
const Background = (props) => {
  const {
    backgroundType,
    backgroundImage,
    backgroundImageMobile,
    classes = '',
    columnSize = '12',
    notLazy = false,
  } = props;

  const getBackgroundClasses = () => {
    // In the experience editor, the default image is set as backgroundImage instead of "null".
    // In such case, this additional class should not be set.
    if (backgroundImage && backgroundImage.indexOf("default_image") < 0) {
      return 'l-background l-background--with-image';
    }
    if (!backgroundType) return '';
    const modifier = Classes.compare(backgroundType, {
      dots: 'l-background--dots',
      lines: 'l-background--lines',
      'dots-faded': 'l-background--dots l-background--fades',
      'lines-faded': 'l-background--lines l-background--fades',
      orange: 'l-background--orange',
      purple: 'l-background--purple',
      grey: 'l-background--grey',
      lightblue: 'l-background--light-blue',
      illustrative: 'l-background--illustrative',
      'lightorange': 'l-background--light-orange',
      'lightorange-faded-dots': 'l-background--light-orange l-background--dots l-background--fades',
      'illustrative-grey': 'l-background--illustrative-grey',
      'illustrative-bottom': 'l-background--illustrative-bottom',
    });

    return `l-background ${modifier}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <div className={`l-background--cover ${classes} ${getBackgroundClasses()}`}>
      {backgroundImage && (
        <Image
          ColumnSize={columnSize}
          NotLazy={notLazy}
          ImageUrl={backgroundImage}
          ImageUrlMobile={backgroundImageMobile}
          Title='background section'
        />
      )}
    </div>
  );
};

export default Background;