/**
 * Imports
 * =============================================================================
 */
import document from 'global/document';

/**
 * Class
 * =============================================================================
 */
let instance = null;

class Main {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor() {
		if (!instance) {
			instance = this;
		}

		return instance;
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		/*
		 * Because the dom for the main element hasn't initialized yet, we
		 * cannot retrieve the node. So whenever we need the el for thee first * time, we initialize it in calling the function. If it already exists,* nothing happens.
		 */
		if (this.el) {
			return;
		}

		this.el = document.querySelector('main');
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	hasClass(name) {
		return this.el.classList.contains(name);
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleAddClass(name) {
		this.init();

		if (this.hasClass(name)) {
			return;
		}

		this.el.classList.add(name);
	}

	handleRemoveClass(name) {
		this.init();

		if (!this.hasClass(name)) {
			return;
		}

		this.el.classList.remove(name);
	}

	handleToggleClass(name) {
		this.init();

		const containsClass = this.hasClass(name);

		if (containsClass) {
			this.el.classList.remove(name);
		} else {
			this.el.classList.add(name);
		}
	}
}

export default new Main();
