/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Logo, Link } from './../../building-blocks';
import Breadcrumbs from '../Breadcrumbs';
import { withTranslation } from '../../hoc/withTranslation';
import { object } from './../../utils';
import { Body } from '../../managers';
/**
 * Definition
 * =============================================================================
 */
class SimpleHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false
    };
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setNavState() {
    return this.state.isNavOpen ? 'has-nav-open' : '';
  }

  getClasses(hasBreadcrumbs) {
    const breadcrumbs = hasBreadcrumbs ? 'o-header--with-breadcrumbs' : '';
    return `${breadcrumbs}`;
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleToggleNav = state => {
    state
      ? Body.handleAddClass('has-nav-open')
      : Body.handleRemoveClass('has-nav-open');
    this.setState({ isNavOpen: state });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { t } = this.props;
    const showBreadcrumb = object.getNested(
      this.props,
      'sitecoreContext',
      'route',
      'fields',
      'ShowBreadcrumb',
      'value'
    );

    return (
      <header className={`o-header o-header--simplified ${this.setNavState()}`}>
        <div className='o-header__container l-container@above-desktop-base'>
          <div className='o-header__left'>
            <Link
              classes='o-header__register-link a-link--decorated'
              fields={{
                LinkText: t('header.continueShopping'),
                LinkUrl: { href: '/' }
              }}
            />
          </div>
          <div className='o-header__center'>
            <Logo classes='o-header__logo' url='/' />
          </div>
          <div className='o-header__right'>
            <p>{t('header.welcome')}</p>
          </div>
        </div>

        {showBreadcrumb && (
          <div className='o-header__meta'>
            <div className='l-container'>
              <Breadcrumbs classes='o-header__breadcrumbs' />
            </div>
          </div>
        )}
      </header>
    );
  }
}

export default withTranslation()(SimpleHeader);
