/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
class Favorite extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isEnabled: props.isEnabled
    };
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setActiveState() {
    return this.state.isEnabled ? 'is-enabled' : '';
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleClick = () => {
    this.setState((prevState) => ({ isEnabled: !prevState.isEnabled }));
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {classes = ''} = this.props;
    return (
      <button
        className={`a-favorite ${this.setActiveState()} ${classes}`}
        onClick={this.handleClick}
      >
        <span className='a-favorite__icon'></span>
      </button>
    );
  }
}

export default Favorite;
