/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import { Events } from './../../managers';
/**
 * Definition
 * =============================================================================
 */
class ProductList extends React.Component {

  constructor(props) {
    super(props);

    const { items = [] } = props;

    this.state = {
      items,
    };
  }

  
  componentWillReceiveProps(nextProps){
   
    if (
      nextProps.items!= undefined && nextProps.items !== this.state.items 
    ) {
      this.setState({ items: nextProps.items });
    }

  }
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  handleRemoveItem = (lineItemId) => {
   
    Events.emitter.emit('basket-remove-item',lineItemId);

  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderItems = () => {
    const { items } = this.state;

    return items.map((i, index) => (
      <Item
          key={`product-list-item-${index}`}
          item={i}
          onRemoveItemClick={this.handleRemoveItem}
        />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {  classes = '' } = this.props;
    
    return (
      <ul className={`m-product-list ${classes}`}>
        {this.renderItems()}
      </ul>
    );
  }
}

export default ProductList;
