import document from 'global/document';

/**
 * parseURL
 * =============================================================================
 */
function htmlToText(html,maxlength=500) {
  return html.replace(/<\/?[^>]+>/g, '');
  /*var htmlObj = document.createElement('span');
  htmlObj.innerHTML = html;
  if(htmlObj.innerText==undefined)return html.replace(/<\/?[^>]+>/g, '');
  return htmlObj.innerText.length>maxlength?htmlObj.innerText.substring(0,maxlength):htmlObj.innerText;*/
}

/**
 * Exports
 * =============================================================================
 */
export default {
  htmlToText
};
