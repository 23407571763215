/**
 * Imports
 * =============================================================================
 */
import classes from './classes';

/**
 * Get padding-top utility class
 * =============================================================================
 */
const getPaddingTopUtil = (prop) => {
  return classes.compare(prop, {
    '0x': 'u-padding-top-none',
    '1x': 'u-padding-top-1x',
    '2x': 'u-padding-top-2x',
    '3x': 'u-padding-top-3x',
    '4x': 'u-padding-top-4x',
    '5x': 'u-padding-top-5x',
    '6x': 'u-padding-top-6x',
    '7x': 'u-padding-top-7x',
    '8x': 'u-padding-top-8x',
    '9x': 'u-padding-top-9x',
    '10x': 'u-padding-top-10x',
    '11x': 'u-padding-top-11x',
    '12x': 'u-padding-top-12x',
  });
}

/**
 * Get padding-bottom utility class
 * =============================================================================
 */
 const getPaddingBottomUtil = (prop) => {
  return classes.compare(prop, {
    '0x': 'u-padding-bottom-none',
    '1x': 'u-padding-bottom-1x',
    '2x': 'u-padding-bottom-2x',
    '3x': 'u-padding-bottom-3x',
    '4x': 'u-padding-bottom-4x',
    '5x': 'u-padding-bottom-5x',
    '6x': 'u-padding-bottom-6x',
    '7x': 'u-padding-bottom-7x',
    '8x': 'u-padding-bottom-8x',
    '9x': 'u-padding-bottom-9x',
    '10x': 'u-padding-bottom-10x',
    '11x': 'u-padding-bottom-11x',
    '12x': 'u-padding-bottom-12x',
  });
}

/**
 * Get margin-bottom utility class
 * =============================================================================
 */
 const getMarginBottomUtil = (prop) => {
  return classes.compare(prop, {
    '0x': 'u-margin-bottom-none',
    '1x': 'u-margin-bottom-1x',
    '2x': 'u-margin-bottom-2x',
    '3x': 'u-margin-bottom-3x',
    '4x': 'u-margin-bottom-4x',
    '5x': 'u-margin-bottom-5x',
    '6x': 'u-margin-bottom-6x',
    '7x': 'u-margin-bottom-7x',
    '8x': 'u-margin-bottom-8x',
    '9x': 'u-margin-bottom-9x',
    '10x': 'u-margin-bottom-10x',
    '11x': 'u-margin-bottom-11x',
    '12x': 'u-margin-bottom-12x',
  });
}

/**
 * Get margin-top utility class
 * =============================================================================
 */
 const getMarginTopUtil = (prop) => {
  return classes.compare(prop, {
    '0x': 'u-margin-top-none',
    '1x': 'u-margin-top-1x',
    '2x': 'u-margin-top-2x',
    '3x': 'u-margin-top-3x',
    '4x': 'u-margin-top-4x',
    '5x': 'u-margin-top-5x',
    '6x': 'u-margin-top-6x',
    '7x': 'u-margin-top-7x',
    '8x': 'u-margin-top-8x',
    '9x': 'u-margin-top-9x',
    '10x': 'u-margin-top-10x',
    '11x': 'u-margin-top-11x',
    '12x': 'u-margin-top-12x',
  });
}

/**
 * Get alignment utils
 * =============================================================================
 */
 const getAlignUtil = (prop) => {
  return classes.compare(prop, {
    'left': 'u-align-left',
    'right': 'u-align-right',
    'center': 'u-align-center',
  });
}

/**
 * Exports
 * =============================================================================
 */
 export default {
  getPaddingTopUtil,
  getPaddingBottomUtil,
  getMarginTopUtil,
  getMarginBottomUtil,
  getAlignUtil
};
