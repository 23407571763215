/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const ExperienceEditorHint = ({ content }) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='a-experience-hint'>
      <span className='a-experience-hint__icon icon-error'></span>

      <div
        className='a-experience-hint__content s-jss-experience-hint'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default ExperienceEditorHint;
