/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link as BBLink } from './../../building-blocks';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Link = (props) => {
  const {
    LinkType,
    LinkSize
  } = props.fields;

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getTypeClasses = () => {
    const type = object.getNested(LinkType, 'fields', 'Value', 'value');

    if (!type) return '';

    return classes.compare(type, {
      'decorated': 'a-link--decorated',
      'arrow': 'a-link--arrow'
    });

  };

  const getSizeClasses = () => {
    const size = object.getNested(LinkSize, 'fields', 'Value', 'value');

    if (!size) return '';

    return classes.compare(size, {
      'large': 'a-link--large'
    });
  };

  const getClasses = () => {
    const type = getTypeClasses();
    const size = getSizeClasses();

    return `${type} ${size}`;
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <BBLink
      classes={getClasses()}
      {...props}
    />
  );
};

export default Link;
