import React from 'react';
import { Button } from './../../building-blocks';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from '../../hoc/withTranslation';

const UserActions = ({ onLogout, sitecoreContext, t, wrapperCssClass }) => {
  const renderDashboardAction = () => {
    return sitecoreContext.navigationData.DashboardUrl ? (
      <div className={wrapperCssClass}>
        <Button
          classes=''
          fields={{
            ButtonUrl: sitecoreContext.navigationData.DashboardUrl,
            ButtonText: t('header.register.gotoDashboard')
          }}
        />
      </div>
    ) : null;
  };

  const renderLogoutAction = () => {
    return (
      <div className={wrapperCssClass}>
        {sitecoreContext.navigationData.DashboardUrl ? (
          <Button
            onClick={onLogout}
            type='button'
            classes='a-button--small a-button--outline'
            fields={{
              ButtonText: t('header.register.logout')
            }}
          />
        ) : (
          <Button
            onClick={onLogout}
            classes='a-button--outline'
            type='button'
            fields={{
              ButtonText: t('header.register.logout')
            }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {renderDashboardAction()}
      {renderLogoutAction()}
    </>
  );
};

export default withSitecoreContext()(withTranslation()(UserActions));
