/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Tab = ({
  title:text,
  id,
  onClick,
  isActive
}) => {

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const setActiveState = () => {
    return isActive ? 'is-active' : '';
  };

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClick = () => {
    if (onClick) onClick(id);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className={`o-cookie-settings__tab ${setActiveState()}`}>
      <button
        className='o-cookie-settings__button'
        onClick={handleClick}
      >
        {text}
      </button>
    </li>

  );
};

export default Tab;
