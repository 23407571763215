/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { withTranslation } from '../../hoc/withTranslation';
import Glide from '@glidejs/glide';
import { Link, Button } from './../../building-blocks';
import Item from './Item';
import { object, url } from './../../utils';
import Modules from './Modules';
import SessionsSeen from './SessionsSeen';
import { Events } from './../../managers';
import document from 'global/document';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

/**
 * Definition
 * =============================================================================
 */
class SessionCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevArrowEnabled: '',
      nextArrowEnabled: '',
      sessionIsFull: this.fullSessionStatus(),
    };
    this.cardEl = React.createRef();
    this.handleCarouselChange = this.handleCarouselChange.bind(this);
    this.handleAddToBasket = this.handleAddToBasket.bind(this);
    this.handleCarouselReset = this.handleCarouselReset.bind(this);
    this.getIndexBySessionGroupId = this.getIndexBySessionGroupId.bind(this);
    this.state = { selectSessionGroupId: props.selectSessionGroupId };
  }

  getIndexBySessionGroupId(groupId) {
    return (
      this.props.data &&
      this.props.data.items &&
      this.props.data.items.findIndex((x) => x.groupId === groupId)
    ) || -1;
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    this.createCarousel();
  }
  componentDidUpdate() {
    this.createCarousel();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectSessionGroupId != this.state.selectSessionGroupId) {
      this.setState({ selectSessionGroupId: nextProps.selectSessionGroupId });
      const selectedIndex = this.getIndexBySessionGroupId(nextProps.selectSessionGroupId);
      if (this.carousel && selectedIndex >= 0) this.carousel.go('=' + selectedIndex);
    }
  }

  createCarousel() {
    const { data } = this.props;
    const { selectSessionGroupId } = this.state;
    if (!data) return;
    if (this.carousel && this.cardEl.current !== this.prevCardEl.current) {
      this.carousel.destroy();
      this.carousel = null;
    }
    if (
      !this.carousel &&
      object.hasNested(data, 'items') &&
      data.items.length > 0 &&
      object.getNested(this.cardEl, 'current')
    ) {
      this.prevCardEl = this.cardEl;
      this.max = data.items.length;
      if (this.max == 5) this.max = 6;
      // when session is not found by groupId, getIndex returns -1. 
      // in this case show 1st session
      const selectedIndex = Math.max(this.getIndexBySessionGroupId(selectSessionGroupId), 0);
      this.carousel = new Glide(this.cardEl.current, {
        type: 'slider',
        perView: 1,
        rewind: false,
        startAt: selectedIndex,
        gap: 0,
      }).mount();
      this.carousel.on('run.after', this.handleCarouselChange);

      this.node = ReactDOM.findDOMNode(this);
      this.handleCarouselReset();
    }
  }

  handleAddToBasket(event) {
    event.preventDefault();
    if (!this.carousel) return;

    const { data = {}, courseInfo, isVirtual } = this.props;
    this.currentSessionItem = data.items[this.carousel.index];
    var sessionInfo = {
      courseId: courseInfo.courseId,
      courseTitle: courseInfo.courseTitle,
      oldReference: courseInfo.oldReference,
      trainingType: courseInfo.trainingType,
      groupId: this.currentSessionItem.groupId,
      dateFrom: this.currentSessionItem.startDate,
      dateTo: this.currentSessionItem.endDate,
      isVirtual: isVirtual,
      courseType: 1,
    };

    Events.emitter.emit('basket-add-item', sessionInfo);
  }

  handleCarouselReset() {
    this.setButtonStateClasses();
    //fix for ie11
    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) {
      var list;
      list = document.querySelectorAll(
        'li.m-session__slide .m-session__slide-container'
      );

      for (var i = 0; i < list.length; ++i) {
        list[i].style.overflowY = 'hidden';
      }
      var item = document.querySelector(
        'li.glide__slide--active .m-session__slide-container'
      );
      if (item) item.style.overflowY = 'auto';
    }
  }

  handleCarouselChange() {
    this.handleCarouselReset();
    if (this.prevIndex != this.carousel.index + 1) {
      this.prevIndex = this.carousel.index + 1;
      const groupId =
        this.props.data &&
        this.props.data.items &&
        this.props.data.items.length >= this.prevIndex &&
        this.props.data.items[this.prevIndex-1].groupId;
      groupId &&
        window.history.replaceState(
          null,
          null,
          '#session-' + groupId
        );
    }
  }

  setButtonStateClasses() {
    var prevArrowEnabled =
      this.carousel && this.carousel.index != 0
        ? ''
        : 'm-session__arrow--disabled';
    var nextArrowEnabled =
      this.carousel && this.carousel.index < this.max - 1
        ? ''
        : 'm-session__arrow--disabled';
    this.setState({
      prevArrowEnabled: prevArrowEnabled,
      nextArrowEnabled: nextArrowEnabled,
      sessionIsFull: this.fullSessionStatus(),
    });
  }

  fullSessionStatus() {
    const carouselIndex =
      this.carousel && this.carousel.index >= 0 ? this.carousel.index : -1;
    if (carouselIndex == -1) {
      return false;
    }

    const { data = {} } = this.props;
    const currentSessionItem = data.items[carouselIndex];

    return (
      currentSessionItem &&
      (currentSessionItem.location ||
        currentSessionItem.region ||
        currentSessionItem.remainingDaysForRegistration >= 0) &&
      currentSessionItem.placesLeft <= 0
    );
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderBullets() {
    const { data } = this.props;

    return data.items.map((_, index) => {
      return this.renderBullet(index);
    });
  }

  renderBullet(index) {
    return (
      <button
        key={`session-card-bullet-${index}`}
        className='m-session__bullet glide__bullet'
        data-glide-dir={`=${index}`}
      />
    );
  }

  renderItems() {
    const { t, data, courseInfo, showRegion, isVirtual } = this.props;
    const isJobSeekerCourse = courseInfo.trainingType == 'JS';
    return data.items.map((i, index) => {
      const { modules, nextSessionDate } = i;
      const modulesRender =
        (modules && (
          <Modules
            items={modules}
            isJobSeekerCourse={isJobSeekerCourse}
            extraInformation={i.extraInformation}
          />
        )) ||
        (nextSessionDate && <h4>{nextSessionDate}</h4>);
      return (
        <Item
          {...i}
          key={`session-card-item-${index}`}
          showRegion={showRegion}
          isVirtual={isVirtual}
        >
          {isVirtual ? (
            <h4>{t('sessionCard.virtualSession')}</h4>
          ) : (
            modulesRender
          )}
        </Item>
      );
    });
  }

  renderSessionsSeen() {
    const { data } = this.props;

    return (
      <Item classes='m-session__slide--white'>
        <SessionsSeen amount={data.sessionsSeen} />
      </Item>
    );
  }

  getSessionShareSubject(courseInfo) {
    if (document.location == undefined) return '';
    return encodeURIComponent(courseInfo.courseTitle);
  }

  getSessionShareContent(courseInfo) {
    if (document.location == undefined) return '';
    return encodeURIComponent(
      courseInfo.courseTitle +
        ': ' +
        document.location.protocol +
        '//' +
        document.location.host +
        document.location.pathname
    );
  }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  render() {
    const {
      data = {},
      t,
      allSessions,
      courseInfo,
      isVirtual,
      inCompany,
    } = this.props;
    const { prevArrowEnabled, nextArrowEnabled, sessionIsFull } = this.state;
    const hasSessionsSeen = object.hasNested(data, 'sessionsSeen');
    const hasCurrentItem =
      this.carousel && this.carousel.index < data.items.length;
    const isNoJobseekerCourse = courseInfo.trainingType != 'JS';
    const hasItems = object.hasNested(data, 'items') && data.items.length > 0;
    const hasMoreThenOneItem =
      object.hasNested(data, 'items') && data.items.length > 1;

    const hasMoreThenFiveItems = allSessions && allSessions.length > 5;
    const { sitecoreContext } = this.props;
    const subscriptionConditionsUrl = object.getNested(
      sitecoreContext,
      'navigationData',
      'SubscriptionConditions'
    );

    return (
      <div className='m-session'>
        {
          // create empty anchors on the page so that is sessionId is known, it is scrolled here
          hasItems &&
            data.items.map((i, index) => (
              <div id={`session-${i.groupId}`}></div>
            ))
        }
        <article
          ref={this.cardEl}
          className={`m-session__card glide s-glide-session`}
        >
          <header className='m-session__header'>
            <h4 className='m-session__title'>
              {hasItems
                ? isVirtual
                  ? t('sessionCard.sessionsTitleVirtual')
                  : isNoJobseekerCourse
                  ? t('sessionCard.sessionsTitleRegular')
                  : t('sessionCard.sessionsTitleJobSeeker')
                : isNoJobseekerCourse
                ? t('sessionCard.noSessionsTitle')
                : t('sessionCard.noSessionsTitleJobSeeker')}
            </h4>

            {hasMoreThenOneItem && (
              <div
                className='m-session__bullets glide__bullets'
                data-glide-el='controls[nav]'
              >
                {this.renderBullets()}
                {hasMoreThenFiveItems && this.renderBullet(5)}
              </div>
            )}
          </header>

          {(hasItems && (
            <div className='m-session__content'>
              <div
                className='m-session__content-container glide__track'
                data-glide-el='track'
              >
                <ul className='m-session__slides glide__slides'>
                  {this.renderItems()}

                  {hasSessionsSeen &&
                    hasMoreThenFiveItems &&
                    this.renderSessionsSeen()}
                </ul>
              </div>
              {hasMoreThenOneItem && (
                <div
                  className='m-session__arrows glide__arrows'
                  data-glide-el='controls'
                >
                  <button
                    className={`m-session__arrow m-session__arrow--left glide__arrow ${prevArrowEnabled}`}
                    data-glide-dir='<'
                  />
                  <button
                    className={`m-session__arrow m-session__arrow--right glide__arrow ${nextArrowEnabled}`}
                    data-glide-dir='>'
                  />
                </div>
              )}
            </div>
          )) || (
            <div className='m-session__content m-session__content--fallback'>
              <p className='m-session__fallback'>
                {isNoJobseekerCourse
                  ? t('sessionCard.noSessions')
                  : t('sessionCard.noSessionsJobSeeker')}
                {isNoJobseekerCourse && (
                  <a
                    href={`mailto:?&subject=${this.getSessionShareSubject(
                      courseInfo
                    )}&body=${this.getSessionShareContent(courseInfo)}`}
                    rel='noopener norefferer'
                  >
                    {inCompany ? t('sessionCard.incompanyShareLink') : null}
                  </a>
                )}
              </p>
            </div>
          )}

          <footer className='m-session__footer'>
            <React.Fragment>
              {hasCurrentItem && (
                <Button
                  classes='m-session__button'
                  onClick={this.handleAddToBasket}
                  fields={{
                    ButtonText: sessionIsFull
                      ? t('sessionCard.buttonWaitingList')
                      : isVirtual
                      ? t('sessionCard.buttonVirtual')
                      : isNoJobseekerCourse
                      ? t('sessionCard.buttonRegular')
                      : t('sessionCard.buttonJobSeeker'),
                    ButtonUrl: { href: '#' },
                  }}
                />
              )}
              {hasItems && (
                <Link
                  classes='m-session__link a-link--decorated a-link--align-center'
                  fields={{
                    LinkUrl: { href: '#session-table-section' },
                    LinkText: t('sessionCard.link'),
                  }}
                />
              )}
            </React.Fragment>

            {isNoJobseekerCourse && !hasCurrentItem && inCompany && (
              <Button
                classes='m-session__button'
                fields={{
                  ButtonText: t('sessionCard.incompanyOrganise'),
                  ButtonUrl: {
                    href: Events.createEventUrl('basket-add-incompany'),
                  },
                }}
              />
            )}
          </footer>
        </article>

        {hasItems && subscriptionConditionsUrl && (
          <Link
            classes='m-session__meta a-link--light'
            fields={{
              LinkText: t('sessionCard.subscriptionConditions'),
              LinkUrl: subscriptionConditionsUrl,
            }}
          />
        )}

        {t('sessionCard.additionalInfoHtml') !==
          'sessionCard.additionalInfoHtml' &&
          isNoJobseekerCourse && (
            <div className='m-session__meta m-session__extraInfo'>
              {t('sessionCard.additionalInfoHtml')}
            </div>
          )}
      </div>
    );
  }
}

export default withSitecoreContext()(withTranslation()(SessionCard));
