import React from 'react';
import { Link } from 'react-router-dom';
import url from '../../utils/url';

const SimpleLink = props => {
  const { to } = props;
  return url.isRelative(to) ? (
    <Link {...props}>
      {props.children}
    </Link>
  ) : (
    <a {...props} href={to} >
      {props.children}
    </a>
  );
};

export default SimpleLink;
