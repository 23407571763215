/**
 * Imports
 * =============================================================================
 */
import React from 'react';
/**
 * Definition
 * =============================================================================
 */
const ArticleStructuredData = ({ articleTitle, articleDate, authorName }) => {
    const articleStructData = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": articleTitle,
        "datePublished": articleDate,
        "author": [{
            "@type": "Person",
            "name": authorName
        }]
    };
    return (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(articleStructData) }}></script>
    );
}
export default ArticleStructuredData;