/**
 * Imports
 * =============================================================================
 */
import React, { useState } from 'react';
import {
  Favorite,
  Definition,
  Tag,
  Button,
  Link,
  Highlight,
  Attribute,
} from './../../building-blocks';
import { Link as RLink, useHistory, useLocation } from 'react-router-dom';
import { withTranslation } from '../../hoc/withTranslation';
import SearchResultTable from './../SearchResultTable';
import { getLocalized } from '../../pages/SearchResults/TransformData.ts';
import { withBasketData, withAuth0 } from '../../hoc';
import { url as Url } from '../../utils';
import { Events } from '../../managers';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ShowPlacesLeftWarningFrom } from '../../lib/Constants.ts';
import { clickSearchResults, calculatePersona } from '../../lib/Gtm';
import TooltipWrapper from '../../building-blocks/TooltipWrapper';
import window from 'global/window';

/**
 * Definition
 * =============================================================================
 */
const SearchResult = (props) => {
  const {
    classes,
    favorite,
    title,
    courseId,
    trainingType,
    trainingFormHint,
    persona,
    referenceOld,
    tag,
    strict,
    sessions = [],
    t,
    lng,
    sitecoreContext,
    isVirtual,
    isWebinar,
    inCompany,
    searchResultIcons,
  } = props;
  let { url } = props;
  const history = useHistory();
  const location = useLocation();
  const sessionsLimit = 8;
  const [maximumSessionsToShow, setMaxSessionsRange] = useState(3); // initially show 3 sessions
  const noSessionsMessageDictionaryCopy = {
    'EM': t('searchResultsCard.noSessionsEmployee'),
    'JS': t('searchResultsCard.noSessionsJobseeker')
  }[persona] || t('searchResultsCard.noSessions');
  if (
    sitecoreContext &&
    sitecoreContext.trainingSettings &&
    sitecoreContext.trainingSettings.trainingPageRoot
  ) {
    // replace first part of the URL with the localized version in pirate way!
    const arr = url.split('/');
    url = `${sitecoreContext.trainingSettings.trainingPageRoot}/${arr[arr.length - 1]
      }`;
  } else {
    url = Url.removeHostName(url);
  }

  /**
   * Table header
   * ---------------------------------------------------------------------------
   */
  const tableHeader = [
    { col: 4, text: t('searchResultCard.table.when') },
    { col: 4, text: t('searchResultCard.table.where') },
    { col: 4 },
  ];

  /**
   * Table body
   * ---------------------------------------------------------------------------
   */
  const getPlacesLeft = (placesLeft) => {
    if (placesLeft <= 0) {
      return (
        <Attribute
          classes='a-attribute--error'
          iconBefore='icon-error'
          text={t('searchResultCard.table.sessionIsFull')}
        />
      );
    }
    if (placesLeft <= ShowPlacesLeftWarningFrom) {
      return (
        <Attribute
          classes='a-attribute--error'
          iconBefore='icon-error'
          text={t('searchResultCard.table.seatsLeft', {
            amount: placesLeft,
          })}
        />
      );
    }
    return '';
  };

  const loadMoreSessions = () => {
    setMaxSessionsRange(sessionsLimit);
  };

  const getClasses = () => {
    const highlight = tag ? 'm-search-result--highlight' : '';
    const looseResult = strict === false ? ' m-search-result--loose' : '';
    return `${highlight}${looseResult}`;
  };

  const handleAddIncompanyItem = (e) => {
    e.preventDefault();
    const item = {
      courseId: courseId,
      courseTitle: title,
      oldReference: referenceOld,
      trainingType: persona,
      courseType: 2,
      url: url,
    };

    Events.emitter.emit('basket-add-item', item);
  };

  const pushGtm = () => {
    clickSearchResults(
      props,
      0,
      calculatePersona(props.auth0, props.basketData)
    );
    return true;
  };

  const renderIcons = () => {
    const icons = (searchResultIcons || []).map((x, idx) => [
      <span
        key={`${x}-${idx}`}
        className={`a-search-result-icon__icon icon-${x}`}
      ></span>,
      <span
        key={`delim-${x}-${idx}`}
        className='a-search-result-icon__delimiter'
      >
        +
      </span>,
    ]);
    const flattenIcons = [].concat(...icons);
    flattenIcons.pop(); // remove last hanging delimiter
    return flattenIcons;
  };

  const tagElement = (cl) =>
    tag && tag.map((x, index) => <Tag key={x + index} classes={cl} text={x} />);

  const sessionsTable = () => {
    const visibleSessions = sessions
      .slice(0, maximumSessionsToShow)
      .map((x, index) => ({
        ...x,
        linkUrl: `${url}#session-${index < 5 ? x.groupId : 'table-section'}`,
      }));
    const tableBody = visibleSessions.map((d) => ({
      linkUrl: d.linkUrl,
      columns: [
        { col: 4, text: d.date },
        {
          col: 4,
          text: `${d.location}${d.region && !isWebinar ? `, ${d.region}` : ''}`,
        },
        {
          col: 4,
          text: getPlacesLeft(d.placesLeft),
        },
      ],
    }));
    return (
      <SearchResultTable
        classes='o-table--alternate-rows o-table--no-text-wrap o-table--compact m-search-result__session-table-section m-search-result__table'
        type='with-links'
        id={`result-${courseId}`}
        header={tableHeader}
        body={tableBody}
      ></SearchResultTable>
    );
  };


  const noSessionsMessage = () => {
    return (
      <p className="m-search-result__no-sessions-message">
        {noSessionsMessageDictionaryCopy}
      </p>
    );
  };


  const pushHash = () => {
    history.push({
      ...location,
      hash: `${window.scrollY}`
    })
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <article className={`m-search-result ${classes} ${getClasses()}`}>
      <header className='m-search-result__header'>
        <div className='m-search-result__title-container'>
          <div className='m-search-result__name'>
            <Favorite
              classes='m-search-result__favorite'
              isEnabled={favorite}
            />
            {title && (
              <RLink
                className='m-search-result__title-link'
                to={url}
                onClick={() => {
                  pushGtm();
                  pushHash();
                }}
                onMouseDown={(e) => e.button !== 0 && pushGtm()} //Middle/Right click -> optimistic "Open in new tab" behavior
              >
                <h5 className='m-search-result__title'>{title}</h5>
              </RLink>
            )}
          </div>
          {tagElement('m-search-result__tag')}{' '}
        </div>
        <div className='m-search-result__type-container'>
          <div className='m-search-result__type'>
            {trainingType && (
              <div className='m-search-result__training-type'>
                {getLocalized(trainingType, lng)}
              </div>
            )}
            <TooltipWrapper
              position='left'
              classes='m-search-result__icons'
              tooltip={getLocalized(trainingFormHint, lng)}
            >
              {renderIcons()}
            </TooltipWrapper>
          </div>
          {tagElement('m-search-result__tag_mobile')}{' '}
        </div>
      </header>

      {!isVirtual && sessions.length > 0 && (
        <div className='m-search-result__content'>
          {sessionsTable()}

          {sessions.length > maximumSessionsToShow &&
            maximumSessionsToShow < sessionsLimit && (
              <div className='m-search-result__highlight'>
                <Highlight
                  onclick={loadMoreSessions}
                  text={t('searchResultCard.remainingSessions')}
                />
              </div>
            )}
        </div>
      )}

      {sessions.length === 0 && noSessionsMessageDictionaryCopy &&(
        <div className='m-search-result__content'>
          {noSessionsMessage()}
        </div>
      )}

      <footer className='m-search-result__footer'>
        <Button
          classes='m-search-result__cta'
          onClick={() => {
            pushGtm();
            pushHash();
          }}
          onMouseDown={(e) => e.button !== 0 && pushGtm()} //Middle/Right click -> optimistic "Open in new tab" behavior
          fields={{
            ButtonUrl: url,
            ButtonText: t('searchResultCard.viewTraining'),
          }}
        />
        {/*   This piece of code hasn't been working because GraphQL query hasn't been returning inCompany prop due to a bug. 
      The query has been fixed now, but this is commented to avoid introducing unexpected functionality.
      Ref https://cevora.atlassian.net/browse/WEB-1684
        { inCompany &&
          basketData.persona !== 'JS' &&
          persona == 'EM' && (
            <Link
              classes='a-link--decorated'
              onClick={handleAddIncompanyItem}
              fields={{
                LinkText: t('searchResultCard.incompanyRequest'),
              }}
            />
          )} */}
      </footer>
    </article>
  );
};

export default withBasketData()(
  withTranslation()(withSitecoreContext()(withAuth0(SearchResult)))
);
