/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Button, Link, IconButton } from '../../building-blocks';
import { withTranslation } from '../../hoc/withTranslation';
import ModalTrigger from './../Modal/Trigger';
import { Events } from './../../managers';

/**
 * Definition
 * =============================================================================
 */
class CookieBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.isOpen !== this.props.isOpen &&
      this.props.isOpen !== this.state.isOpen
    ) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setCloseState() {
    const { isOpen } = this.state;

    return isOpen ? '' : 'is-closed';
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleClose = () => {
    this.setState({ isOpen: false});
  }

  handleApprove() {
    Events.emitter.emit('cookie-bar-approve');
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderText() {
    const { text } = this.props;

    return (
      <small className='o-cookie-bar__text'>
        <Text field={text} />
      </small>
    );
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes = '',
      text,
      t
    } = this.props;

    return (
      <div className={`o-cookie-bar ${classes} ${this.setCloseState()}`}>
        <div className='o-cookie-bar__content'>
          {(text || isEditorActive()) && this.renderText()}
        </div>

        <div className='o-cookie-bar__actions'>
          <ModalTrigger
            triggerComponent={Button}
            triggerType='close'
            targets='cookie-settings-modal'
            classes='o-cookie-bar__button'
            onClick={this.handleApprove}
            type='button'
            fields={{
              ButtonIconBefore: 'icon-checkmark',
              ButtonText: t('cookieBar.approve'),
            }}
          />

          <ModalTrigger
            triggerComponent={Link}
            triggerType='open'
            classes='a-link--decorated o-cookie-bar__link'
            targets='cookie-settings-modal'
            fields={{
              LinkUrl: { href: "#" },
              LinkText: t('cookieBar.settings'),
            }}
          />

          <IconButton
            classes='a-icon-button--animated o-cookie-bar__close'
            icon='icon-cancel'
            onClick={this.handleClose}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(CookieBar);
