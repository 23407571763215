/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import SearchBar from './SearchBar';

/**
 * Globals
 * =============================================================================
 */
const data = {
  termOptions: {
    // amount: 54,
    // items: [
    //   { text: 'Excel voor beginners', value: 'excel-starter' },
    //   { text: 'Excel module 12', value: 'excel-module-12' },
    //   { text: 'Excel module 13', value: 'excel-module-13' },
    //   { text: 'Excel module 14', value: 'excel-module-14' },
    //   { text: 'Excel module 15', value: 'excel-module-15' }
    // ]
  }
};

/**
 * Definition
 * =============================================================================
 */
const Connected = (props) => (
  <SearchBar
    {...props}
    data={data}
  />
);

export default Connected;
