/**
 * Imports
 * =============================================================================
 */
import Emitter from 'eventemitter3';
import {url as Url} from '../utils';
/**
 * Definition (singleton)
 * =============================================================================
 */
let instance = null;

class EventEmitter {

  constructor() {
    if (!instance) {
      instance = this;
      this.protocol = "https://event/";
    }
    this.handleEventClick=this.handleEventClick.bind(this);
    this.init();

    return instance;
  }

  /* ====================================================================== *
   * Inits
   * ====================================================================== */
  init() {
    this.emitter = new Emitter();
  }
  checkEventUrl(href) {
   
    if(href==undefined || href==null || href=='')return false;
    var url = Url.parseURL(href);
    if (url.href.startsWith(this.protocol)) {
      var eventName = url.href.replace(this.protocol, "").replace(url.search, "");

      var eventParam = Url.getParametersObjectFromUrl(url);
      if (eventParam != null)
        this.emitter.emit(eventName, eventParam);
      else
        this.emitter.emit(eventName);
      return true;
    }
    return false;
  }
 

 

  handleEventClick(e) {

    var href = e.currentTarget.getAttribute("href");
    if (href && this.checkEventUrl(href)) { 
      e.preventDefault(); 
      e.stopPropagation();
      if (e.nativeEvent)
        e.nativeEvent.stopImmediatePropagation();
      
      return true 
    }
    return false;
  }

  createEventUrl(eventName, eventObject = null) {
    return this.protocol + eventName + Url.createURLParameters(eventObject);
  }


}

export default new EventEmitter();
