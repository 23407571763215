/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Logo, Hamburger, Button, Link } from './../../building-blocks';
import Languages from './../Languages';
import Navigation from './../Navigation';
import Breadcrumbs from '../Breadcrumbs';
import Basket from '../Basket';
import { withTranslation } from '../../hoc/withTranslation';
import { object, url as Url } from './../../utils';
import { Body, Events } from '../../managers';
import UserMenu from '../UserMenu';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import env from './../../env';
import EventEmitter from 'eventemitter3';

/**
 * Definition
 * =============================================================================
 */
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false,
      loading: props.loading,
      authLoading: props.authLoading,
    };
    this.handleGoToLogin = this.handleGoToLogin.bind(this);
    this.handleGoToLogout = this.handleGoToLogout.bind(this);
    this.handleGoToRegister = this.handleGoToRegister.bind(this);
    this.handleAuthenticationLoaded = this.handleAuthenticationLoaded.bind(
      this
    );
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setNavState() {
    return this.state.isNavOpen ? 'has-nav-open' : '';
  }

  getClasses(hasBreadcrumbs) {
    const breadcrumbs = hasBreadcrumbs ? 'o-header--with-breadcrumbs' : '';

    return `${breadcrumbs}`;
  }

  componentDidMount() {
    Events.emitter.on(`go-to-logout`, this.handleGoToLogout);
    Events.emitter.on(`go-to-login`, this.handleGoToLogin);
    Events.emitter.on(`go-to-register`, this.handleGoToRegister);
    Events.emitter.on(`authentication-loaded`, this.handleAuthenticationLoaded);
  }

  componentWillUnmount() {
    Events.emitter.removeListener(`go-to-logout`, this.handleGoToLogout);

    Events.emitter.removeListener(`go-to-login`, this.handleGoToLogin);
    Events.emitter.removeListener(`go-to-register`, this.handleGoToRegister);
    Events.emitter.removeListener(
      `authentication-loaded`,
      this.handleAuthenticationLoaded
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading != this.state.loading) {
      this.setState({ loading: nextProps.loading });
    }
    if (nextProps.authLoading != this.state.loading) {
      this.setState({ authLoading: nextProps.authLoading });
    }
  }
  handleAuthenticationLoaded() {
    this.setState({ authLoading: false });
  }

  handleGoToLogin() {
    const { loginWithRedirect, sitecoreContext } = this.props;
    loginWithRedirect({
      ui_locales: sitecoreContext.language.toLowerCase(),
      //ui_locales: 'nl' // currently only NL works on Auth0
    });
  }

  logoutUser() {
    return fetch(
      `${env.REACT_APP_JSS_API_HOST}/sitecore/api/jss/track/identification/logout?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
  async handleGoToLogout() {
    const { logout } = this.props;
    const response = await this.logoutUser();
    if (response.status == '200') {
      logout({
        returnTo: window.location.origin,
      });
    } else {
      Events.emitter.emit('modal-open-alert', 'alert.user.logout.content');
    }
  }

  handleGoToRegister(args = {}) {
    const { loginWithRedirect, sitecoreContext } = this.props;

    const { redirect } = args;

    const pageItemName = object.getNested(
      sitecoreContext,
      'navigationData',
      'OnBoardingSelector'
    );
    const redirectUrl =
      window.location.origin +
      '?redirectUrl=' +
      encodeURIComponent(Url.relativePath(pageItemName)) +
      '&nev=1';
    loginWithRedirect({
      ui_locales: sitecoreContext.language.toLowerCase(),
      //ui_locales: 'nl', // currently only NL works on Auth0
      show_registration: true,
      redirect_uri: redirect || redirectUrl,
    });
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleToggleNav = (state) => {
    state
      ? Body.handleAddClass('has-nav-open')
      : Body.handleRemoveClass('has-nav-open');
    this.setState({ isNavOpen: state });
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      language,
      menu,
      t,
      lng,
      isAuthenticated,
      sitecoreContext,
      user,
      userPersona,
    } = this.props;

    const { isNavOpen, loading, authLoading } = this.state;
    const showBreadcrumb = object.getNested(
      this.props,
      'sitecoreContext',
      'route',
      'fields',
      'ShowBreadcrumb',
      'value'
    );

    return (
      <header className={`o-header ${this.setNavState()}`}>
        <div className='o-header__container l-container@above-desktop-base'>
          <div className='o-header__left'>
            <Logo classes='o-header__logo' url={`/${lng}`} />

            <Languages
              classes='o-header__settings'
              active={language.current}
              items={language.languages}
            />

            {menu && menu.length > 0 && (
              <Navigation
                classes='o-header__nav'
                isOpen={isNavOpen}
                items={menu}
                isAuthenticated={isAuthenticated}
                logout={this.props.logout}
                onGoToLogin={this.handleGoToLogin}
              />
            )}
          </div>

          <div className='o-header__right'>
            <Basket
              classes='o-header__basket'
              loading={authLoading && loading}
              isAuthenticated={isAuthenticated}
              user={user}
              userPersona={userPersona}
            />

            {!authLoading && !isAuthenticated && (
              <div className='o-header__login u-desktop-show'>
                <Link
                  classes='o-header__register-link'
                  fields={{
                    LinkText: t('header.register.action'),
                    LinkUrl: object.getNested(
                      sitecoreContext,
                      'navigationData',
                      'Register'
                    ),
                  }}
                />

                <span className='o-header__login-divider'>
                  {t('header.register.separator')}
                </span>

                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleGoToLogin();
                  }}
                  type='button'
                  fields={{
                    ButtonText: t('header.register.login'),
                  }}
                />
              </div>
            )}

            {!loading && isAuthenticated && <UserMenu {...this.props} />}

            <Hamburger
              classes='o-header__hamburger u-desktop-hide'
              isClosed={isNavOpen}
              onClick={this.handleToggleNav}
            />
          </div>
        </div>

        {showBreadcrumb && (
          <div className='o-header__meta'>
            <div className='l-container'>
              <Breadcrumbs classes='o-header__breadcrumbs' />
            </div>
          </div>
        )}
      </header>
    );
  }
}

export default withSitecoreContext()(withTranslation()(Header));
