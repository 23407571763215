import React from 'react';
import ButtonCheckboxList from './ButtonCheckboxList';
import CheckboxList from './CheckboxList';
const CheckboxListSelector = (props) => {
  const {
    field: {
      model: { items, cssClass },
    }
  } = props;

  const classes= cssClass.split(" ");
  const hasButton = classes.find((className) => className === 'button');
  return (<>
    {hasButton && <ButtonCheckboxList {...props} />}
    {!hasButton && <CheckboxList {...props} />}
    </>);
};


export default CheckboxListSelector;
