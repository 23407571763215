/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Root from '../Table/components/Row';
import Col from '../Table/components/Col';
import { Link } from 'react-router-dom';

/**
 * Definition
 * =============================================================================
 */
const Row = ({
  classes = '',
  data = [],
  id: tableId,
  index,
  linkUrl = '/',
  alert,
}) => {
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderCols = () => {
    data.forEach((columnData, index) => {
      switch (index) {
        case 0:
          columnData.col = 4;
          break;
        case 1:
          columnData.col = alert ? 6 : 8;
          break;
        case 2:
          columnData.col = 2;
          break;
      }
    });
    if (!alert) data.splice(-1, 1);
    return data.map((d, index) => (
      <Col key={`table-body-col-${index}`} {...d} />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Root
      index={index}
      tableId={tableId}
      classes={classes}
      data={data}
      linkUrl={linkUrl}
    >
      <Link className='o-table__row-link' to={linkUrl}>
        {renderCols()}

        <span className='o-table__link icon-arrow-right'></span>
      </Link>
    </Root>
  );
};

export default Row;
