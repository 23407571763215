import React, { Fragment, useContext } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms';
import TextWithTooltip from '../Helpers/TextWithTooltip';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';

const Checkbox = (props) => {
  const { field, value, onChange, tracker, errors } = props;
  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);

  var shouldHide = false;
  var shouldDisable = false;

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  const updateContextFieldValue = (fieldValue) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, fieldValue);
    }
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, fieldValue);
    }
  }
  
  return (
    <Fragment>
      <div className={`a-toggle ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}>
        <label
          className='a-toggle__container'
          htmlFor={props.field.valueField.id}
        >
          <div className='a-toggle__wrapper'>
            <input
              type='checkbox'
              className={`a-toggle__input `}
              id={field.valueField.id}
              name={field.valueField.name}
              value='true'
              checked={value}
              disabled={shouldDisable}
              onChange={(e) =>
                handleOnChange(field, e.target.checked, onChange)
              }
              onClick={(e) => updateContextFieldValue(e.target.value)}
              onFocus={() => tracker.onFocusField(field, value)}
              onBlur={() => tracker.onBlurField(field, value, errors)}
            />
            <span className='a-toggle__icon a-toggle__icon--off icon-checkbox-unchecked'></span>
            <span className='a-toggle__icon a-toggle__icon--on icon-checkbox-checked'></span>
          </div>
          <span className='a-toggle__label '><TextWithTooltip text={props.field.model.title} /></span>
        </label>
      </div>
      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

/**
 * @param {ValueFormField} field
 * @param {boolean} fieldValue
 * @param {FieldChangeCallback} callback
 */
function handleOnChange(field, fieldValue, callback) {
  // (fieldName, fieldValue, isValid, validationErrors)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback(field.valueField.name, fieldValue, true, []);
}

export default Checkbox;
