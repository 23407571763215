import React from 'react';
import { object } from '../../../utils';
import ProgressBarComponent from '../../ProgressBar';

const ProgressBar = ({field}) => {
    const currentPage = Number(object.getNested(field, 'model', 'currentPage'));
    const totalPages = Number(object.getNested(field, 'model', 'totalPages'));
    const additionalClasses = object.getNested(field, 'model', 'cssClass');
    const classes = `js-progress-bar  m-progress-bar--compact ${additionalClasses}`;
    return (
        <ProgressBarComponent classes={classes} steps={totalPages} active={currentPage}/>
    );
}

export default ProgressBar;
