/**
 * Imports
 * =============================================================================
 */
import document from 'global/document';

/**
 * Class
 * =============================================================================
 */
let instance = null;

class Body {

  /* ====================================================================== *
   * Constructor
   * ====================================================================== */
  constructor() {
    if (!instance) {
      instance = this;
    }

    this.init();

    return instance;
  }

  /* ====================================================================== *
   * Inits
   * ====================================================================== */
  init() {
    this.el = document.querySelector && document.querySelector("body");
  }

  /* ====================================================================== *
   * Helpers
   * ====================================================================== */
  hasClass(name) {
    if(!this.el)return;
    return this.el.classList.contains(name);
  }

  show() {
    if(this.el)
    this.el.style.display='block';
  }

  /* ====================================================================== *
   * Handlers
   * ====================================================================== */
  handleAddClass(name) {
    if (this.hasClass(name)) {
      return;
    }

    this.el.classList.add(name);
  }

  handleRemoveClass(name) {
    if (!this.hasClass(name)) {
      return;
    }

    this.el.classList.remove(name);
  }

  addOnClick(callback){
    this.el.addEventListener("click",callback);

  }
 removeOnClick(callback){
    this.el.removeEventListener("click",callback);
    
  }
  handleToggleClass(name) {
    const containsClass = this.hasClass(name);

    if (containsClass) {
      this.el.classList.remove(name);
    } else {
      this.el.classList.add(name);
    }
  }
}

export default new Body();
