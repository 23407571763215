/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import { Button, Link, Loader } from '../../building-blocks';
import ProductList from '../../components/ProductList';
import ActionGroup from '../../components/ActionGroup';
import { Body, Main, Events } from '../../managers';
import { withBasketData, withAuth0 } from '../../hoc';
import { basketView, calculatePersona } from '../../lib/Gtm';

import {
  withRouter
} from 'react-router-dom';
/**
 * Definition
 * =============================================================================
 */
class BasketOverview extends React.Component {

  constructor(props) {
    super(props);
    this.getReturnUrl = this.getReturnUrl.bind(this);
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    Body.handleAddClass('l-full-height');
    Main.handleAddClass('l-full-height__container');
    Main.handleAddClass('l-background--illustrative-bottom@above-tablet');
    const { basketData = {}, auth0 = {} } = this.props;
    console.log(basketData)
    basketView(basketData.graphQLClient, basketData.items, calculatePersona(auth0, basketData));
  }

  getItemsByCourseType(items, courseType) {

    return items.filter((item) => (item.courseType + "") == (courseType + ""));

  }
  getReturnUrl() {

    const { history } = this.props;
    history.goBack();
  }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  render() {
    const { t } = this.props;
    const { basketData } = this.props;
    if (!basketData) return;
    const sessionItems = this.getItemsByCourseType(basketData.items, 1);
    const incompanyItems = this.getItemsByCourseType(basketData.items, 2);
    const hasSessionItems = (sessionItems && sessionItems.length > 0);
    const hasIncompanyItems = (incompanyItems && incompanyItems.length > 0);
    const hasItems = hasSessionItems || hasIncompanyItems;
    return (
      <section className='l-section l-container@above-tablet-base l-box@above-tablet-base l-full-height__content o-basket-overview'>
        {basketData.isLoading && <div className="o-basket-overview__loader"><Loader /></div>}
        {!basketData.isLoading && !hasItems && (
          <div className='l-section__content'>
            <p className="u-text-align-center">{t('basketOverview.empty')}</p>
          </div>)}

        {!basketData.isLoading && hasSessionItems && (
          <h4 className='l-section__title l-section__title--align-center'>{t('basketOverview.titleSession')}</h4>
        )}
        {!basketData.isLoading && hasSessionItems && (
          <div className='l-section__content'>
            <ProductList
              classes='o-basket-overview__list'
              items={sessionItems}

            />
          </div>)}

        {!basketData.isLoading && hasIncompanyItems && (
          <h4 className='l-section__title l-section__title--align-center'>{t('basketOverview.titleIncompany')}</h4>
        )}
        {!basketData.isLoading && hasIncompanyItems && (
          <div className='l-section__content'>
            <ProductList
              classes='o-basket-overview__list'
              items={incompanyItems}

            />
          </div>)}


        <footer className='l-section__footer l-fixed-actions__content'>
          <ActionGroup classes='m-action-group--split-space@above-tablet-base m-action-group--col@below-tablet-base m-action-group--vertical-center@below-tablet-base m-action-group--stretch@below-tablet-base'
          >
            <Link
              classes='a-link--decorated'
              onClick={this.getReturnUrl}
              fields={{
                LinkText: t('basketOverview.continueShopping')

              }}
            />
            {hasItems && (
              <Button
                classes='a-button--fill-space@below-tablet-base'
                fields={{
                  ButtonUrl: Events.createEventUrl('checkout'),
                  ButtonText: t('basketOverview.subscribe')
                }}
              />)}
          </ActionGroup>
        </footer>
        {!basketData.isLoading && basketData.persona && (basketData.persona === "EM" || basketData.persona === "HR") &&
          <p className="a-text a-text--light-with-padding">
            {t('basket.askEmployeeModal.content')}
          </p>
        }
      </section>
    );
  }
}

export default withRouter(withAuth0(withBasketData()(withTranslation()(BasketOverview))));
