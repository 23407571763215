import React, { useState, useEffect } from 'react';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Route, Switch } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as HooksApolloProvider } from '@apollo/react-hooks';
import componentFactory from './temp/componentFactory';
import RouteHandler from './RouteHandler';
import { CookiesProvider } from 'react-cookie';
import MainLoader from './components/MainLoader';
import { url as Url } from './utils';
import { BasketProvider } from './providers';
import Alert from './components/Alert';
import window from 'global/window';
import { Auth0Provider } from './react-auth0-wrapper';
import env from './env';
import { Body } from './managers';
import useHashScrollWorkaround from './useHashScrollWorkaround';
import GraphQLClientProvider from './providers/GraphQLClientProvider';
// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

let sitecoreContext = null;

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, graphQLClient, isIdentified, ssrState }) => {
  const [ssrRenderComplete, setSsrRenderComplete] = useState(false);

  if (ssrState && !ssrRenderComplete) {
    sitecoreContext = ssrState.sitecore && ssrState.sitecore.route
      ? {
        route: ssrState.sitecore.route,
        itemId: ssrState.sitecore.route.itemId,
        ...ssrState.sitecore.context,
      }
      : ssrState.sitecore.context
  }

  useEffect(() => {
    setSsrRenderComplete(true);
  }, []);

  const routeRenderFunction = props => (
    <RouteHandler route={props} graphQLClient={graphQLClient} ssrRenderComplete={ssrRenderComplete} />
  );

  const onRedirectCallback = appState => {
    var redirectUrl = getRedirectUrl();
    var error = getParameterFromQuery('error');

    if (error) {
      redirectUrl += redirectUrl.indexOf('?') > -1 ? '&e=lf' : '?e=lf';
      console.error('Error logging in', error);
    }

    window.location.replace(Url.relativePath(redirectUrl));
  };

  const getRedirectUrl = appState => {
    if (appState && appState.targetUrl) {
      return appState.targetUrl;
    }
    return getParameterFromQuery('redirectUrl');
  };
  const redirectURL = window.location
    ? window.location.origin +
    '?redirectUrl=' +
    encodeURIComponent(window.location.pathname + '?ce=1')
    : '';

  const getParameterFromQuery = parameter => {
    if (window.location && window.location.search.length) {
      var queryStringParams = new URLSearchParams(window.location.search);
      if (queryStringParams.has(parameter)) {
        return queryStringParams.get(parameter);
      }
    }
    return null;
  };

  var redirectPath = getParameterFromQuery('redirectUrl');
  if (redirectPath == null) {
    Body.show();
  }

  useHashScrollWorkaround();

  return (
    <Auth0Provider
      domain={env.REACT_APP_AUTH0_DOMAIN}
      client_id={env.REACT_APP_AUTH0_CLIENTID}
      redirect_uri={redirectURL}
      onRedirectCallback={onRedirectCallback}
    >
      <CookiesProvider>
        <BasketProvider
          isIdentified={isIdentified}
          graphQLClient={graphQLClient}
        >
          <ApolloProvider client={graphQLClient}>
            <HooksApolloProvider client={graphQLClient}>
              <GraphQLClientProvider graphQLClient={graphQLClient}>
                <SitecoreContext
                  componentFactory={componentFactory}
                  context={sitecoreContext}
                >
                  <Router location={path} context={{}}>
                    <Switch>
                      {routePatterns.map(routePattern => (
                        <Route
                          key={routePattern}
                          path={routePattern}
                          render={routeRenderFunction}
                          ssrRenderComplete={ssrRenderComplete}
                        />
                      ))}
                    </Switch>
                  </Router>
                  <MainLoader
                    isLoading={
                      window.location === undefined || redirectPath != null
                    }
                  />
                  <Alert />
                </SitecoreContext>
              </GraphQLClientProvider>
            </HooksApolloProvider>
          </ApolloProvider>
        </BasketProvider>
      </CookiesProvider>
    </Auth0Provider>
  );
};

export default AppRoot;
