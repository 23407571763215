/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import object from '../../utils/object';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import ReactDOM from 'react-dom';
import window from 'global/window';
import VideoStructuredData from '../VideoStructuredData';
/**
 * Definition
 * =============================================================================
 */
class Video extends React.Component {
  constructor(props) {
    super(props);

    this.videoEl = React.createRef();

    this.state = {
      isPlaying: false
    };
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */

  setPlayState() {
    return this.state.isPlaying ? 'is-playing' : '';
  }
  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleClick = () => {
    this.setState(
      prevState => ({ isPlaying: !prevState.isPlaying }),
      () => {
        if (this.state.isPlaying) {
          this.videoEl.current.play();
        }
      }
    );
  };

  getVideoIframe(url) {
    //check youtube
    if (!url){
      return '';
    }

    var youtubeId = this.getYoutubeId(url, 'https://www.youtube.com/embed/');
    if (youtubeId == null) {
      youtubeId = this.getYoutubeId(url, 'https://youtu.be/');
      if (youtubeId == null) {
        youtubeId = this.getYoutubeId(url, 'https://www.youtube.com/watch?v=');
      }
    }
    if (youtubeId != null) url = 'https://www.youtube.com/embed/' + youtubeId;
    else return '';
    return (
      <iframe
        width='560'
        height='315'
        src={url}
        frameBorder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen='allowFullScreen'
      ></iframe>
    );
  }

  getYoutubeId(url, youtubeurl) {
    if (url && url.includes(youtubeurl)) {
      url = url.split(youtubeurl).join('');
      if (url.includes('&')) {
        url = url.substring(0, url.indexOf('&'));
      }
      return url;
    }
    return null;
  }

  handleResize() {
    if (!this.node) return;
    var width = this.node.clientWidth;
    var style = window.getComputedStyle(this.node, null);
    var padding =
      Math.floor(style.getPropertyValue('padding-left').replace('px', '')) +
      Math.floor(style.getPropertyValue('padding-right').replace('px', ''));
    width -= padding;
    var iframe = this.node.querySelector('iframe');
    var ratio = this.getRatio();
    iframe.style.width = width + 'px';
    iframe.style.height = width * ratio + 'px';
  }

  getRatio() {
    const { fields = {} } = this.props;
    const { VideoRatio } = fields;
    return object.hasNested(VideoRatio, 'fields', 'Value', 'value') &&
      object.getNested(VideoRatio, 'fields', 'Value', 'value') != ''
      ? parseFloat(object.getNested(VideoRatio, 'fields', 'Value', 'value'))
      : 0.5625;
  }

  setBackgroundImage = () => {
    const { fields = {} } = this.props;
    const { VideoCoverImage } = fields;
    const backgroundImageUrl =
      ('scEmptyImage' !== object.getNested(VideoCoverImage, 'value', 'class') &&
        object.getNested(VideoCoverImage, 'value', 'src')) ||
      object.getNested(VideoCoverImage, 'value', 'src');
    return backgroundImageUrl ? { backgroundImage: `url('${backgroundImageUrl}')`, backgroundSize: 'cover' } : {};
  };

  componentWillUnmount() {
    const { fields = {} } = this.props;
    const { VideoUrl } = fields;
    const videoUrl = object.getNested(VideoUrl, 'value');
    if (!isEditorActive() && videoUrl) {
      window.removeEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate() {
    this.handleResize();
  }

  componentDidMount() {
    const { fields = {} } = this.props;
    const { VideoUrl } = fields;
    const videoUrl = object.getNested(VideoUrl, 'value');

    if (!isEditorActive() && videoUrl) {
      this.node = ReactDOM.findDOMNode(this);
      this.handleResize();
      window.addEventListener('resize', this.handleResize.bind(this));
    }
  }

  /**
   * Main renders
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes = '', fields = {} } = this.props;
    const { FillHeight, VideoFile, VideoUrl } = fields;
    var videoSource = object.getNested(VideoFile, 'value', 'src');
    var videoUrl = object.getNested(VideoUrl, 'value');
    const heightClass =
      object.hasNested(FillHeight, 'value') &&
      object.getNested(FillHeight, 'value') == true
        ? 'a-video--fill-height'
        : '';
    return (
      <div
        className={`a-video ${classes} ${heightClass} ${this.setPlayState()}`}
      >
        {(isEditorActive() || videoSource) && (
          <div className='a-video__controls' style={this.setBackgroundImage()}>
            <button className='a-video__play' onClick={this.handleClick} />
          </div>
        )}
        {!isEditorActive() && videoSource && (
          <video ref={this.videoEl} className='a-video__source' controls>
            <source
              src={
                object.hasNested(VideoFile, 'value', 'src')
                  ? object.getNested(VideoFile, 'value', 'src')
                  : ''
              }
              type={
                object.hasNested(VideoFile, 'value', 'mimeType')
                  ? object.getNested(VideoFile, 'value', 'mimeType')
                  : ''
              }
            />
          </video>
        )}
        {!isEditorActive() != false && this.getVideoIframe(videoUrl)}
        <VideoStructuredData {...this.props}/>
      </div>
    );
  }
}

export default Video;
