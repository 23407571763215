import React, {useContext} from 'react';
import sanitizeHtml from 'sanitize-html';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';

const TextField = ({ field }) => {
  const Tag = field.model.htmlTag || 'p';

  const context = useContext(FormConditionsContext);
  var shouldHide = false;

  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
  }

  if(Tag=='rawhtml') {
    return (
      <div dangerouslySetInnerHTML={{__html: field.model.text}} className={`${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`} />
    );
  }
  else {
    let tagClass = '';
    if(Tag=='p')tagClass='a-text ';
    return (
      <Tag className={`${tagClass} ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`} dangerouslySetInnerHTML={{__html: sanitizeHtml(field.model.text)}}></Tag>
    );
  }
};

export default TextField;
