/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  RichText,
  isEditorActive
} from '@sitecore-jss/sitecore-jss-react';
import document from 'global/document';

/**
 * Definition
 * =============================================================================
 */
class RichTextEx extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleTooltipToggle = this.handleTooltipToggle.bind(this);
  }

  componentDidMount() {
    if (!isEditorActive()) {
      this.createTooltips();
    }
  }

  componentDidUpdate() {
    if (!isEditorActive()) {
      this.createTooltips();
    }
  }

  handleTooltipClose(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent) e.nativeEvent.stopPropagation();
    var openClass = 'is-open';
    var tooltip = e.currentTarget;
    tooltip.classList.remove(openClass);
  }

  handleTooltipToggle(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent) e.nativeEvent.stopPropagation();
    var openClass = 'is-open';
    var tooltip = e.currentTarget.parentNode.parentNode;
    if (tooltip.classList.contains(openClass)) {
      tooltip.classList.remove(openClass);
    } else {
      tooltip.classList.add(openClass);
    }
  }

  createTooltips() {
    var el = ReactDOM.findDOMNode(this);
    if (!el) {
      return;
    }

    var tooltips = el.querySelectorAll('.rte-tooltip');
    for (var nr = 0; nr < tooltips.length; nr++) {
      var tooltip = tooltips[nr];
      var html = tooltip.innerHTML;
      var tooltipElement = document.createElement('div');
      tooltipElement.innerHTML =
        '<div class="m-tooltip__container"><span class="a-badge a-badge--theme-purple m-tooltip__trigger">?</span><div class="m-tooltip__flyout"><div class="m-tooltip__backdrop u-desktop-hide"></div><div class="m-tooltip__content"><button class="a-icon-button a-icon-button--animated a-icon-button--inverted m-tooltip__close u-desktop-hide"><span class="a-icon-button__icon icon-cancel"></span></button><p class="m-tooltip__text"></p></div></div></div>';
      tooltipElement.classList.add('m-tooltip');
      tooltipElement.classList.add('m-tooltip--top');
      tooltipElement.classList.add('m-tooltip--inline');

      tooltipElement.querySelector('.m-tooltip__text').innerHTML = html;
      tooltip.parentNode.insertBefore(tooltipElement, tooltip.nextSibling);
      tooltip.parentNode.removeChild(tooltip);
    }

    tooltips = el.querySelectorAll('.m-tooltip');
    var tooltiptriggers = el.querySelectorAll('.m-tooltip__trigger');
    var me = this;
    for (var nr = 0; nr < tooltips.length; nr++) {
      var tooltip = tooltips[nr];
      var tooltiptrigger = tooltiptriggers[nr];
      tooltip.removeEventListener('click', me.handleTooltipClose);
      tooltip.addEventListener('click', me.handleTooltipClose);
      tooltiptrigger.removeEventListener('click', me.handleTooltipToggle);
      tooltiptrigger.addEventListener('click', me.handleTooltipToggle);
    }
  }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    let classes = this.props.className || "";
    if(!classes.includes('s-jss-rte')){
        classes += ' s-jss-rte';
    }
    return <RichText {...this.props} className={classes} />;
  }
}

export default RichTextEx;
