/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import Term from './Term';
import ChoiceList from '../ChoiceList';
import { Button, Datepicker, Input } from '../../building-blocks';
import { object } from '../../utils';
import { withBasketData, withAuth0 } from '../../hoc';
import Overlay from './../Overlay';
import OverlayTrigger from '../Overlay/Trigger';
import Formsy from 'formsy-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Redirect } from 'react-router-dom';
import isSearchBarDisabled from './isSearchBarDisabled';
import moment from 'moment';
import { Events, Body } from '../../managers';
import window from 'global/window';
import { withRouter } from 'react-router-dom';
import { InputDateFormat } from '../../lib/Constants';
import generateSearchUrlQuery from '../../pages/SearchResults/generateSearchUrlQuery.ts';
import { getIsMobile } from '../../utils/mobile';
import { loader as gqlLoader } from 'graphql.macro';
import withGraphQLClient from '../../hoc/withGraphQLClient';
import {url as Url} from '../../utils';
/**
 * Definitions
 * =============================================================================
 */

const query = gqlLoader('./queryCourseByCode.graphql');

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    const { fields = {}, formData, basketData, auth0, graphQLClient } = props;

    this.searchSubmitRef = React.createRef();

    const term =
      object.getNested(fields, 'SearchBarKeywords', 'value') ||
      object.getNested(formData, 'term') ||
      '';
    const closeTo =
      object.getNested(
        fields,
        'SearchBarProvince',
        'fields',
        'Code',
        'value'
      ) ||
      object.getNested(formData, 'closeTo') ||
      '';
    var from =
      object.getNested(fields, 'SearchBarDateFrom', 'value') ||
      object.getNested(formData, 'from') ||
      null;

    var to =
      object.getNested(fields, 'SearchBarDateTo', 'value') ||
      object.getNested(formData, 'to') ||
      null;

    const persona =
      basketData && basketData.persona
        ? basketData.persona
        : '';
    const searchAs =
      this.getUserType(fields) ||
      object.getNested(formData, 'searchAs') ||
      persona;
    if (from === '01/01/0001' || from === '0001-01-01T00:00:00Z') {
      from = null;
    }
    if (to === '01/01/0001' || to === '0001-01-01T00:00:00Z') {
      to = null;
    }

    const trainingTypeCode =
      object.getNested(
        fields,
        'SearchBarTrainingType',
        'fields',
        'Code',
        'value'
      ) ||
      object.getNested(formData, 'trainingTypeCode') ||
      '';
    var dateValues = SearchBar.getDateValues(from, to);
    from = dateValues.from;

    to = dateValues.to;

    this.state = {
      formData: {
        term,
        closeTo,
        from,
        to,
        searchAs,
        trainingTypeCode: trainingTypeCode
      },
      isSearchAsHighlighted: false,
      graphQLClient: graphQLClient,
      bypassSearchCourseUrl: '',
      redirectToCourse: false
    };

    this.handlePersonaSet = this.handlePersonaSet.bind(this);
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', () => {
        // We execute the same script as before
        this.setWindowHeightVar();
      });
      this.setWindowHeightVar();
    }
    Events.emitter.on('user-persona-set', this.handlePersonaSet);

    this.checkPersona(this.props);

    // set focus on search submit button if applicable
    this.focusSearchSubmit();
  }

  componentWillUnmount() {
    Events.emitter.removeListener('user-persona-set', this.handlePersonaSet);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.formData &&
      JSON.stringify(this.state.formData) !== JSON.stringify(nextProps.formData)
    ) {
      var nextFormData = nextProps.formData;
      var dateValues = SearchBar.getDateValues(
        nextFormData.from,
        nextFormData.to
      );
      nextFormData.to = dateValues.to;
      nextFormData.from = dateValues.from;

      this.setState({ formData: nextFormData });
    }
    this.checkPersona(nextProps);
  }

  checkPersona(props) {
    var { basketData, auth0 } = props;
    const { formData } = this.state;
    const userTypeValue = object.getNested(formData, 'searchAs');

    if (userTypeValue === '' && auth0 && basketData && auth0.isAuthenticated && basketData.persona) {
      formData.searchAs = basketData.persona;
      this.setState({ formData });
    }
  }

  handlePersonaSet(userdata) {
    const { formData } = this.state;
    formData.searchAs = userdata.persona;
    this.setState({ formData });
  }

  setWindowHeightVar() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  focusSearchSubmit() {
    const { formData, bypassSearchCourseUrl } = this.state;
    const bypassSearch = bypassSearchCourseUrl.length > 0;
    // Give focus to search submit button if form field searchAs (which is the only required field) has a value
    if (
      !isSearchBarDisabled(formData, bypassSearch) &&
      this.searchSubmitRef &&
      this.searchSubmitRef.current
    ) {
      this.searchSubmitRef.current.focus();
    }
  }

  convertUrlToAppropriateFormat(url) {
    const {sitecoreContext} = this.props;
    if (
      sitecoreContext &&
      sitecoreContext.trainingSettings &&
      sitecoreContext.trainingSettings.trainingPageRoot
    ) {
      // replace first part of the URL with the localized version in pirate way!
      const arr = url.split('/');
      url = `${sitecoreContext.trainingSettings.trainingPageRoot}/${arr[arr.length - 1]
        }`;
    } else {
      url = Url.removeHostName(url);
    }
    return url;
  }

  handleTermKeyPress(event) {
    var key = event.key || event.keyCode; // fallback to deprecated implementation (keyCode) if needed
    if (
      key &&
      (key === 'Enter' || key === 13) &&
      this.searchSubmitRef &&
      this.searchSubmitRef.current
    ) {
      //submit the form
      this.handleSubmit(event);
      this.setState({ hideTermSuggestions: true });
    }
  }

  handleTermSuggestionsHidden = () => {
    this.setState({ hideTermSuggestions: false });
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChangeInput = (name, text) => {
    const { formData, graphQLClient } = this.state;

    formData[name] = text;

    var dateValues = SearchBar.getDateValues(formData.from, formData.to);
    formData.to = dateValues.to;
    formData.from = dateValues.from;

    this.setState({
      formData,
      isSearchAsHighlighted: false // setting to false first, because rerender always required 
    }, () => {
      this.setState({
        isSearchAsHighlighted: this.isSeachAsEmptyButOtherFieldsNot(formData)
      });
    });

    if (name == 'term') {
      graphQLClient.query({
        query: query,
        variables: {
          code: text,
        }
      }).then((result) => {
        const course = object.getNested(result, 'data', 'search', 'course');
        if (course) {
          this.setState({ ...this.state, 
            bypassSearchCourseUrl: this.convertUrlToAppropriateFormat(course.courseUrl) });
        } else {
          this.setState({ ...this.state, bypassSearchCourseUrl: '' });
        }
      });
    }

    //for all input field except term, set focus to search submit after change
    if (name != 'term') {
      this.focusSearchSubmit();
    }
  };

  isSeachAsEmptyButOtherFieldsNot(formData) {
    return !formData.searchAs &&
      !!(formData.term || formData.from || formData.to || formData.closeTo);
  }

  handleSubmit = e => {
    const { formData, bypassSearchCourseUrl } = this.state;
    const bypassSearch = bypassSearchCourseUrl.length > 0;
    if (isSearchBarDisabled(formData, bypassSearch)) {
      return;
    }

    if (e) e.preventDefault();

    if(bypassSearch) {
      this.setState({...this.state, redirectToCourse: true});
      return;
    } 

    const { onSubmit, sitecoreContext, basketData, auth0 } = this.props;

    if (!auth0.isAuthenticated && basketData.persona !== formData.searchAs
      && (formData.searchAs === "EM" || formData.searchAs === "HR")) {
      basketData.onPersonaSet(formData.searchAs);
    }

    if (onSubmit) {
      onSubmit(formData);
      return;
    }

    var searchUrl = object.getNested(
      this.props,
      'fields',
      'SearchBarSearchPageUrl',
      'value',
      'href'
    );

    if (!searchUrl)
      searchUrl = object.getNested(sitecoreContext, 'navigationData', 'Search');
    Body.handleRemoveClass('has-overlay-open');
    if (searchUrl) {
      const searchQuery = generateSearchUrlQuery(formData);
      this.setState({ redirect: `${searchUrl}${searchQuery}` });
    } else {
      console.log('No Search Page defined in Searchbar Component');
    }
  };

  handleChangeSearchTerm = value => {
    /**
     * End point for searching based on term
     */
  };

  handleKeyDown(event) {
    /*if(event.keyCode==40){
          var flyOut = dom.queryParentElement(event.target,".m-formfield-flyout");
          var radio = flyOut.querySelector("input[type=radio]");
          radio.focus();
         
      }*/
  }

  handleOverlayOpen() {
    document.getElementById('term').focus();
  }

  getUserType(fields) {
    const type = object.getNested(
      fields,
      'SearchBarSearchAs',
      'fields',
      'Code',
      'value'
    );
    if (type === 'asEmployee') return 'EM';
    if (type === 'asLiable') return 'HR';
    if (type === 'asJobSeeker') return 'JS';
    return type;
  }

  static getDateValues(from, to) {
    var fromMinDate = moment().startOf('day');

    var fromDate = '';
    var toDate = '';
    if (!from) from = '';
    if (!to) to = '';
    var hasFrom = from != '';
    var hasTo = to != '';

    if (from.indexOf('/') > -1) {
      from = moment(from, InputDateFormat).toDate();
    }
    if (to.indexOf('/') > -1) {
      to = moment(to, InputDateFormat).toDate();
    }
    if (hasFrom) {
      if (moment(from).isAfter(fromMinDate)) {
        fromDate = moment(from).format(InputDateFormat);
      } else {
        fromDate = moment(fromMinDate).format(InputDateFormat);
      }
    }
    if (hasTo) {
      to = moment(to);
      if (hasFrom && moment(fromDate, InputDateFormat).isAfter(to)) {
        to = moment(fromDate, InputDateFormat);
      }
      toDate = to.format(InputDateFormat);
    }

    fromMinDate = fromMinDate.format(InputDateFormat);
    var toMinDate = hasFrom ? fromDate : fromMinDate;
    from = fromDate;
    to = toDate;

    return {
      from: from,
      fromMinDate: fromMinDate,
      to: to,
      toMinDate: toMinDate
    };
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { formData, redirect, bypassSearchCourseUrl, redirectToCourse } = this.state;
    const bypassSearch = bypassSearchCourseUrl.length > 0; 
    if (redirect) {
      return <Redirect push to={redirect} />;
    }

    if (redirectToCourse) {
      console.log('Redirecting...');
      return <Redirect push to={bypassSearchCourseUrl} />;
    }

    var { from = '', to = '', term = '' } = formData;

    var dateValues = SearchBar.getDateValues(from, to);

    if (dateValues.from != '') from = dateValues.from;

    var fromMinDate = dateValues.fromMinDate
      ? moment(dateValues.fromMinDate, InputDateFormat).toDate()
      : '';
    if (dateValues.to != '') to = dateValues.to;
    var toMinDate = dateValues.toMinDate
      ? moment(dateValues.toMinDate, InputDateFormat).toDate()
      : '';

    const { t, data, sitecoreContext } = this.props;
    const { termOptions } = data || { termOptions: [] };
    const regions = [
      ...sitecoreContext.regions.map(x => ({
        ...x,
        id: 'region-' + x.value
      })),
      {
        id: 'region-clear',
        text: t('searchResultsSearch.closeTo.setEmptyText'),
        value: ''
      }
    ];

    if (!formData) return '';

    const userTypes = [
      {
        text: t('searchResultsSearch.searchAs.options.asEmployee'),
        id: 'searchAs-asEmployee',
        value: 'EM'
      },
      {
        text: t('searchResultsSearch.searchAs.options.asJobSeeker'),
        id: 'searchAs-asJobSeeker',
        value: 'JS'
      },
      {
        text: t('searchResultsSearch.searchAs.options.asLiable'),
        id: 'searchAs-asLiable',
        value: 'HR'
      }
    ];

    const findSafe = (value, inArray) =>
      (value &&
        inArray.find(e => {
          return e.value === value;
        })) || { text: '', id: '' };

    const userTypeValue = object.getNested(formData, 'searchAs');

    const userType = findSafe(userTypeValue, userTypes);
    const { text: userTypeText, id: userTypeId } = userType;

    const closeToValue = object.getNested(formData, 'closeTo') || '';
    const region = findSafe(closeToValue, regions);
    const { text: closeToText, id: closeToId } = region;


    const audience = { EM: 'em', JS: 'js', HR: 'em' }[userTypeValue];

    return (
      <React.Fragment>
        <Formsy>
          <OverlayTrigger
            targets='search-overlay'
            triggerComponent={Input}
            triggerType='open'
            classes='a-formfield--straight-corners u-desktop-hide'
            iconBefore='icon-search'
            placeholder={t('searchResultsSearch.term.placeholder')}
            readOnly={true}
            noFlyout={true}
            name='search'
            onOverlayOpen={this.handleOverlayOpen}
          />

          <Overlay
            id='search-overlay'
            suffix='@below-desktop-base'
            close={{
              component: Button,
              props: {
                classes:
                  'a-button--outline a-button--secondary a-button--fill-space',
                type: 'button',
                fields: {
                  ButtonText: t('misc.close')
                }
              }
            }}
          >
            <div className='o-search'>
              <div className='o-search__form'>
                <Term
                  options={termOptions}
                  value={term}
                  onChange={this.handleChangeInput}
                  onDelayedChange={this.handleChangeSearchTerm}
                  onKeyPress={e => this.handleTermKeyPress(e)}
                  hideSuggestions={this.state.hideTermSuggestions}
                  onSuggestionsHidden={this.handleTermSuggestionsHidden}
                  audience={audience}
                />

                <div className='o-search__container l-container@below-desktop-base'>
                  <div className='o-search__fields l-grid@below-desktop-base l-grid--with-margins'>
                    <div className='o-search__item l-grid__col-12-12'>
                      <Input
                        classes={`a-formfield--choice a-formfield--straight-corners@above-desktop-base ${this.state.isSearchAsHighlighted ? 'a-formfield--highlighted' : ''}`}
                        label={`${t('searchResultsSearch.searchAs.label')} *`}
                        placeholder={t(
                          'searchResultsSearch.searchAs.placeholder'
                        )}
                        iconAfter='icon-arrow-down'
                        id='searchAs'
                        name='searchAsText'
                        autoComplete={false}
                        readonly={true}
                        tooltip={t('searchResultsSearch.searchAs.help')}
                        tooltipIsOpen={!getIsMobile() && this.state.isSearchAsHighlighted}
                        value={userTypeText}
                        onKeyDown={this.handleKeyDown}
                      >
                        <ChoiceList
                          name='searchAs'
                          active={userTypeId}
                          onChange={this.handleChangeInput}
                          items={userTypes}
                        />
                      </Input>
                    </div>

                    <div className='o-search__item l-grid__col-12-12'>
                      <Input
                        classes='a-formfield--choice a-formfield--straight-corners@above-desktop-base'
                        label={`${t('searchResultsSearch.closeTo.label')}`}
                        placeholder={t(
                          'searchResultsSearch.closeTo.placeholder'
                        )}
                        iconAfter='icon-arrow-down'
                        id='closeTo'
                        name='closeToText'
                        autoComplete={false}
                        value={closeToText}
                        readonly={true}
                        onKeyDown={this.handleKeyDown}
                      >
                        <ChoiceList
                          name='closeTo'
                          active={closeToId}
                          onChange={this.handleChangeInput}
                          items={regions}
                        />
                      </Input>
                    </div>

                    <div className='o-search__item l-grid__col-6-12'>
                      <Datepicker
                        classes='a-formfield--straight-corners@above-desktop-base'
                        label={t(`searchResultsSearch.from.label`)}
                        placeholder={t(`searchResultsSearch.from.placeholder`)}
                        iconAfter='icon-calendar'
                        id='from'
                        name='from'
                        minDate={fromMinDate}
                        value={from}
                        onChange={this.handleChangeInput}
                      />
                    </div>

                    <div className='o-search__item l-grid__col-6-12 o-search__field-to'>
                      <Datepicker
                        classes='a-formfield--straight-corners@above-desktop-base'
                        label={t(`searchResultsSearch.to.label`)}
                        placeholder={t(`searchResultsSearch.to.placeholder`)}
                        iconAfter='icon-calendar'
                        id='to'
                        name='to'
                        minDate={toMinDate}
                        value={to}
                        onChange={this.handleChangeInput}
                      />
                    </div>
                  </div>
                  <OverlayTrigger
                    deepRef={this.searchSubmitRef}
                    targets='search-overlay'
                    triggerComponent={Button}
                    triggerType='close'
                    type='submit'
                    classes={`o-search__submit a-button--fill-space@below-desktop-base ${isSearchBarDisabled(formData, bypassSearch) ? 'is-disabled-default' : ''
                      }`}
                    onClick={this.handleSubmit}
                    fields={{
                      ButtonIconBefore: 'icon-search',
                      ButtonText: t('searchResultsSearch.showResults')
                    }}
                  />
                </div>
              </div>
            </div>
          </Overlay>
        </Formsy>
      </React.Fragment>
    );
  }
}

export default withRouter(withBasketData()(
  withSitecoreContext()(withTranslation()(withAuth0(withGraphQLClient(SearchBar)))))
);
