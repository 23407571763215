/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Footer from './Footer';

/**
 * Data
 * =============================================================================
 */
const data = [
  {
    title: { value: 'Over Cevora' },
    mobileTitle: { value: 'Over Cevora' },
    link: { value: '/' }
  },
  {
    title: { value: 'Werken bij Cevora' },
    mobileTitle: { value: 'Werken bij Cevora' },
    link: { value: '/' }
  },
  {
    title: { value: 'Pers' },
    mobileTitle: { value: 'Pers' },
    link: { value: '/' }
  },
  {
    title: { value: 'FAQ' },
    mobileTitle: { value: 'FAQ' },
    link: { value: '/' }
  },
  {
    title: { value: 'Contacteer ons' },
    mobileTitle: { value: 'Contacteer ons' },
    link: { value: '/' }
  }
];

/**
 * Definition
 * =============================================================================
 */
const Disconnected = (props) => {

  return (
    <Footer
      {...props}
      menu={data}
    />
  );
}

export default Disconnected;
