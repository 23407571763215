/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { date, html } from '../../utils';
import moment from 'moment-timezone';
import { withTranslation } from '../../hoc/withTranslation';
import sanitizeHtml from 'sanitize-html';

/**
 * Definition
 * =============================================================================
 */
const CourseStructuredData = ({ fields, trainingForm: trainingType, allSessions, t }) => {
  const {
    HeroTitle,
    HeroDescription
  } = fields;

  const sessions = allSessions || [];

  const courseStructData = {
    "@context": "https://schema.org",
    "@type": "Course",
    name: sanitizeHtml(HeroTitle.value),
    description: sanitizeHtml(html.htmlToText(HeroDescription.value)),
    provider: {
      "@type": "Organization",
      name: t('infofiche.structuredData.organization'),
      sameAs: "https://www.cevora.be/",
    },
    hasCourseInstance: sessions.map(
      ({ location, startDate, endDate }) => {

        const duration = new Date(endDate) - new Date(startDate);

        return {
          '@type': 'CourseInstance',
          'courseMode': trainingType,
          'location': location,
          'startDate': date.formatDate(startDate, 'YYYY-MM-DD'),
          'endDate': date.formatDate(endDate, 'YYYY-MM-DD'),
          'duration': moment.duration(duration).toISOString()
        }
      }
    )
  };

  return (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(courseStructData) }}></script>
  );
}

export default withTranslation()(CourseStructuredData);