/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withCollapse } from './../../hoc';
import Link from '../../building-blocks/SimpleLink';

/**
 * Definition
 * =============================================================================
 */
const Collapse = ({
  mobileTitle,
  link,
  children,
  setCollapseState,
  handleToggleCollapse
}) => { 

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`o-nav__collapse ${setCollapseState()}`}>
      <div className='o-nav__collapse-header'>
        <Link
          to={link}
          className='o-nav__collapse-text'
        >
          {mobileTitle}
        </Link>

          <button
            className='o-nav__collapse-trigger'
            onClick={handleToggleCollapse}
          />
      </div>

      <div className='o-nav__collapse-content'>
        {children}
      </div>
    </div>
  );
};

export default withCollapse()(Collapse);
