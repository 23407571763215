/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import RegisterForm from './../../pages/RegisterForm';
import { Format, Level } from './TimeInDaysFormatter';
import { loader as gqlLoader } from "graphql.macro";
import GraphQLData from "../../lib/GraphQLData";
import { WildcardUrlPattern } from "../../lib/Constants.ts"
import { withTranslation } from '../../hoc/withTranslation';

const Query = gqlLoader("./query.graphql");

const mapRoute = (paths) => paths.length > 0
  ? paths.map(x => ({ description: x.fields.Title.value, meta: x.fields.Subtitle.value, url: x.fields.LinkUrl.value.href }))[0]
  : [];

const makeUrl = (url, contextItem) => {
  const pageName = url.split("/").pop();
  return contextItem.url.replace(WildcardUrlPattern, pageName);
}

const getCityName = (locations, languageCode) => {
  var loc = locations.find(x => x.language === languageCode) || locations.find(x => x !== undefined);
  return loc && loc.cityName;
}

const mapSessions = (sessions, languageCode, t) => {
  var items = sessions.map(x => ({
    location: x.sequences && x.sequences.length>0 && getCityName(x.sequences[0].locationCity, languageCode),
    region: x.sequences && x.sequences.length>0 && x.sequences[0].locationRegion,
    language: t(`language.${languageCode.toLowerCase()}`),
    startDate: x.startDateTime,
    coordinatorPhone: x.coordinator.telephone,
    coordinatorEmail: x.coordinator.email,
    registerUrl: "https://www.cevora.be",
    placesLeft: x.registrationMax && x.registrationCount && x.registrationMax - x.registrationCount,
    modules: x.sequences && x.sequences.map(z => ({ start: z.startDateTime, end: z.endDateTime }))
  }));
  return items;
}

/**
 * Definition
 * =============================================================================
 */
const Connected = (props) => {
  const { connectedQuery: graphQLResult, sitecoreContext, fields, t } = props;
  const { error, datasource, contextItem } = graphQLResult || {};

  if (error) {
    console.log("Error executing InfoFiche graphql", error);
  }

  const sessionCardsToShow = 5;

  const alternativeLanguages = (datasource && contextItem &&
    datasource.alternativeLanguageVersion.targetItems.map(x => ({ language: x.language.value, url: makeUrl(x.url, contextItem), sessions: x.sessions })));

  const languageCode = fields.TrainingLang.value || sitecoreContext.language;

  const ownSessions = datasource && datasource.sessions && mapSessions(datasource.sessions, languageCode, t);

  const allSessions = alternativeLanguages && ownSessions
    && [...ownSessions].concat(...alternativeLanguages.map(x => mapSessions(x.sessions, x.language, t)));

  const ownSessionsObject = ownSessions && {
    sessionsSeen: ownSessions.length > sessionCardsToShow ? sessionCardsToShow : undefined,
    items: ownSessions.slice(0, sessionCardsToShow)
  };

  const data = {
    sessionTable: allSessions,
    sessionsCard: ownSessionsObject,
    sessions: ownSessionsObject,
    fields: { ...fields },
    trainingType: fields && fields.TrainingForm && fields.TrainingForm.fields.Title.value,
    duration: fields && Format(fields.DurationInDays.value, sitecoreContext.language, Level.months),
    internshipDuration: fields && Format(fields.InternshipDurationInDays.value, sitecoreContext.language, Level.weeks),
    route: fields && mapRoute(fields.EducationalPath),
    languages: fields && [
      { language: languageCode },
      ...(alternativeLanguages || [])
    ]
  }
  return <RegisterForm {...props} data={data} />;
}

export default withTranslation()(GraphQLData(Query, { name: "connectedQuery" })(Connected));
