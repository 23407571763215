/**
 * Imports
 * =============================================================================
 */
 import React from 'react';

 /**
  * Definition
  * =============================================================================
  */
 const Root = ({ isArticle, children }) => {
   const classes = 'm-card m-card--secondary m-card-action-bottom u-border-radius-none@below-viewport-7';
 
   /**
    * Main render
    * ---------------------------------------------------------------------------
    */
   return isArticle ? (
     <article className={classes}>
       {children}
     </article>
   ) : (
     <div className={classes}>
       {children}
     </div>
   );
 };
 
 export default Root;
 