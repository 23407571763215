/**
 * Imports
 * =============================================================================
 */
import React from "react";
import BasketTest from "./BasketTest";

/**
 * Definition
 * =============================================================================
 */
const Disconnected = props => {
  const basket = [{ lineItems: [] }];

  return <BasketTest {...props} basket={basket} />;
};

export default Disconnected;
