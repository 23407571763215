/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */
const CharacterLimit = ({ amount, limit, t }) => {
  const rest = limit - amount;

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getState = () => {
    let state = '';

    if (rest <= limit * 0.5 && rest > 0) {
      state = 'is-warning';
    } else if (rest <= 0) {
      state = 'is-error';
    }

    return state;
  }

  const getTranslationState = () => {
    return (
      ((rest > 1 || rest === 0) && 'textarea.charactersLeft') ||
      (rest === 1 && 'textarea.oneCharacterLeft') ||
      (rest < -1 && 'textarea.charactersOverLimit') ||
      (rest === -1 && 'textarea.oneCharactersOverLimit')
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <small className={`a-formfield__countdown ${getState()}`}>
      {t(getTranslationState(), { amount: rest })}
    </small>
  );
};

export default withTranslation()(CharacterLimit);

