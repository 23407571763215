/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import  ProgressBar  from '../ProgressBar';


/**
 * Definition
 * =============================================================================
 */
class MultiPaneItem extends React.Component {
  constructor(props) {
    super(props);

    const {
      Name
    } = props.fields;
    

  }


  render() {
  return (
    <Placeholder
          rendering={this.props.rendering}
          name='multipane-item'
        />
  );
      }
}

export default MultiPaneItem;
