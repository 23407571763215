import React from 'react';
import ReactDOM from "react-dom";
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import window from 'global/window';
import RichText from '../../building-blocks/RichTextEx';


class ProgramSheet extends React.Component {
  constructor(props) {
    super(props);

    const { content } = props;
    

  }
  handleResize(){
    if(!this.node)return;
    var width = this.node.clientWidth;
    var style = window.getComputedStyle(this.node, null);
    var padding = Math.floor(style.getPropertyValue("padding-left").replace('px','')) +Math.floor(style.getPropertyValue("padding-right").replace('px',''));
    width-=padding;
    for(var iframenr=0;iframenr<this.iframes.length;iframenr++){
      var iframe = this.iframes[iframenr];
      var ratio = iframe.getAttribute('data-ratio');
      iframe.style.width = width+"px";
      iframe.style.height = width * ratio+"px";
    }
  }

 
  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    
    this.changeListItems();
   
  }
  changeListItems(){
    
    this.listitems = this.node.querySelectorAll("ol > li");
    for(var itemnr=0;itemnr<this.listitems.length;itemnr++){
      var listitem = this.listitems[itemnr];
      var html = ''; 
      var nodeText = listitem.childNodes[0];
      if(nodeText.nodeName == '#text' && nodeText.nodeValue!=undefined && nodeText.nodeValue!=null && nodeText.nodeValue.length!=0){
       var text = nodeText.nodeValue;
       listitem.removeChild(nodeText);
       var html = listitem.innerHTML;
       listitem.innerHTML = (text!=''?("<h5>"+text+"</h5>"):'')+html;
      }
     
    }
 


  }
  render() {
    const { content,rendering } = this.props;
    return (
      <div className="m-program-sheet s-jss-rte-program-sheet">
      <RichText field={content} />
      <Placeholder name='video-content'  rendering={rendering} />
      </div>
    );
  }
}

export default ProgramSheet;
