/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import env from './../../env';
/**
 * Definition
 * =============================================================================
 */
class BasketTest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      basket: { lineItems: [] },
      status: { hasFailed: false, hasCompleted: false, description: null }
    };
  }

  componentDidMount() {
    fetch(
      env.REACT_APP_JSS_API_HOST + '/api/cevora/session/write/BasketApi/Get'
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ basket: data });
      })
      .catch(console.log);
  }

  addCourse(courseId) {
    fetch(
      env.REACT_APP_JSS_API_HOST + '/api/cevora/session/write/BasketApi/Post',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          courseId: courseId,
          courseTitle: 'Title fixed',
          courseType: 1,
          dateFrom: new Date(),
          dateTo: new Date(),
          groupId: 1,
          oldReference: courseId
        })
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ basket: data });
      })
      .catch(console.log);
  }

  removeCourse(lineItemId) {
    fetch(
      env.REACT_APP_JSS_API_HOST + '/api/cevora/session/write/BasketApi/Delete',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ lineItemId: lineItemId })
      }
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ basket: data });
      })
      .catch(console.log);
  }

  async startCheckout(checkoutType) {
    var result = await fetch(
      env.REACT_APP_JSS_API_HOST +
        '/api/cevora/session/write/CheckoutApi/Start',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ checkoutType: checkoutType })
      }
    );
  }

  getStatus() {
    fetch(
      env.REACT_APP_JSS_API_HOST +
        '/api/cevora/session/write/CheckoutApi/Status'
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ status: data });
      })
      .catch(console.log);
  }

  buildOptions() {
    var arr = [];

    if (this.state.basket && this.state.basket.lineItems) {
      for (let lineItem of this.state.basket.lineItems.filter(
        li => li.IsDeleted === false
      )) {
        arr.push(
          <option key={lineItem.Id} value={lineItem.Id}>
            {lineItem.CourseId}
          </option>
        );
      }
    }

    return arr;
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { basket, status } = this.state;
    let addInput, removeInput, checkoutTypeList;

    return (
      <React.Fragment>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.addCourse(addInput.value);
            addInput.value = '';
          }}
        >
          <input
            ref={node => {
              addInput = node;
            }}
          />
          <button type='submit'>Add Course</button>
        </form>
        <form
          onSubmit={e => {
            e.preventDefault();
            this.removeCourse(removeInput.value);
            addInput.value = '';
          }}
        >
          <select
            ref={node => {
              removeInput = node;
            }}
          >
            {this.buildOptions()}
          </select>
          <button type='submit'>Remove Line item</button>
        </form>
        <hr />
        <form
          onSubmit={e => {
            e.preventDefault();
            this.startCheckout(checkoutTypeList.value);
          }}
        >
          <select
            ref={node => {
              checkoutTypeList = node;
            }}
          >
            <option value='1'>Open Course</option>
            <option value='2'>InCompany</option>
          </select>
          <button type='submit'>Start Checkout</button>
        </form>
        <hr />
        <button
          type='button'
          onClick={e => {
            e.preventDefault();
            this.getStatus();
          }}
        >
          Get Status
        </button>
        <pre>{JSON.stringify(basket)}</pre>
        <pre>{JSON.stringify(status)}</pre>
      </React.Fragment>
    );
  }
}

export default BasketTest;
