import React, { useContext, useEffect } from 'react';
import { FieldValidationErrors } from './FieldValidationErrors';
import handleOnChange from './handleOnChangeWithRequiredValidation';
import { withTranslation } from '../../../hoc/withTranslation';
import FieldLabel from '../Helpers/FieldLabel';
import { checkConditions } from '../conditionalRendering';
import FormConditionsContext from '../../../contexts/FormConditionsContext';
import FormDependentFieldsContext from '../../../contexts/FormDependentFieldsContext';
import FormNewsletterDataContext from '../../../contexts/FormNewsletterDataContext';

const Email = (props) => {
  const { field, value, onChange, tracker, errors, isValid, t } = props;
  const emailRegex = /^([\w.-]+)@([\w.-]+)((\.(\w){2,3})+)$/;
  var shouldHide = false;
  var shouldDisable = false;
  var justInitialized = true;
  const context = useContext(FormConditionsContext);
  const dependentFieldsContext = useContext(FormDependentFieldsContext);
  const newsletterFormDataContext = useContext(FormNewsletterDataContext);
  if (context?.conditions[field.model.conditionSettings.fieldKey]) {
    const conditionDetails = checkConditions(context?.fieldValues, context?.conditions[field.model.conditionSettings.fieldKey]);
    shouldHide = conditionDetails.shouldHide;
    shouldDisable = conditionDetails.shouldDisable;
  }

  var fieldValue = value;
  const shouldAutoMapValue = field.model 
  && field.model.cssClass 
  && field.model.cssClass.includes('js-automap');
  if (shouldAutoMapValue && newsletterFormDataContext && newsletterFormDataContext.newsletterFormData && newsletterFormDataContext.newsletterFormData[field.model.name]) {
    fieldValue = value.length ? value : newsletterFormDataContext.newsletterFormData[field.model.name];
  }

  const updateContextFieldValue = (valueToUpdateTo) => {
    if (Object.keys(context?.fieldValues).indexOf(field.model.conditionSettings.fieldKey) > 0) {
      context.updateFieldValue(field.model.conditionSettings.fieldKey, valueToUpdateTo);
    }
    
    if (Object.keys(dependentFieldsContext?.fieldValues).indexOf(field.model.name) > 0) {
      dependentFieldsContext.updateFieldValue(field.model.name, valueToUpdateTo);
    }

    if (shouldAutoMapValue && emailRegex.test(valueToUpdateTo)) {
      newsletterFormDataContext.getNewsletterFormDataByEmail(valueToUpdateTo);
    }

    handleOnChange(field, valueToUpdateTo, onChange, t);
  }

  useEffect(() => {
    // Used just to assign the value initially when first time get it from newsletterFormDataContext
    if (fieldValue && justInitialized) {
      updateContextFieldValue(fieldValue);
      justInitialized = false;
    }
  }, [fieldValue]);

  return (
    <div className={`a-formfield ${!isValid ? 'is-error' : ''}  ${field.model.cssClass} ${shouldHide ? "u-hide" : ""}`}>
      <FieldLabel field={field} />
      <div className='a-formfield__wrapper'>
        <input
          type='email'
          className={`a-formfield__input`}
          id={field.valueField.id}
          name={field.valueField.name}
          value={fieldValue}
          maxLength={field.model.maxLength}
          placeholder={field.model.placeholderText}
          disabled={shouldDisable}
          onChange={(e) => updateContextFieldValue(e.target.value)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
        />
      </div>
      <FieldValidationErrors {...props} />
    </div>
  );
};

export default withTranslation()(Email);
