/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Tag = (props) => {
  const {
    children
  } = props;

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <props.type className='a-title__text'>
      {children}
    </props.type>
  )
};

export default Tag;
