import { useEffect } from 'react';
import LazyLoad from 'vanilla-lazyload';
import { event } from './../utils';

const initLazyLoad = () => {
  if (!window.lazyLoadInstance) {
    window.lazyLoadInstance = new LazyLoad({
      elements_selector: '.lazy',
    });
  }
  if (window.lazyLoadInstance) {
    window.lazyLoadInstance.update();
  }
}

const debounced = event.debounce(initLazyLoad, 100);

const useLazyLoad = () => {
  useEffect(debounced);
};

export default useLazyLoad;
