/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Header from './Header';
import SimpleHeader from './SimpleHeader';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import * as Constants from '../../lib/Constants.ts';
import { object, url } from '../../utils';
import { useAuth0 } from '../../react-auth0-wrapper';
import Helmet from 'react-helmet';
import window from 'global/window';

const ConnectedDemoQuery = gqlLoader('./query.graphql');

/**
 * Definition
 * =============================================================================
 */
const Connected = (props) => {
  const authContext = useAuth0();
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    userPersona,
  } = authContext;
  const { connectedQuery: graphQLResult, sitecoreContext } = props;
  const authLoading = authContext.loading;

  // Async loading and error handling
  // Remember to never return null from a JSS component when loading,
  // this will break Experience Editor.
  const { error, loading } = graphQLResult;
  // // Query results load in using the name of their root field (see query.graphql)
  const { contextItem } = graphQLResult;

  const menu = sitecoreContext.navigationData.headerMenu || [];

  const wildcardItemName = Constants.WildcardUrlPattern;
  const pageItemName =
    (sitecoreContext.pageMetadata &&
      sitecoreContext.pageMetadata.pageItemName) ||
    wildcardItemName;

  let language = {};
  let currentPageUrl = '';

  const pageUrlWithPort = object.getNested(
    sitecoreContext,
    'navigationData',
    'pageHost'
  );
  const pageUrl = url.urlWithoutPort(pageUrlWithPort);

  if (sitecoreContext.itemId) {
    const currentLanguage =
      sitecoreContext && sitecoreContext.language.toUpperCase();

    const languageVersions =
      (contextItem &&
        contextItem.versions &&
        contextItem.versions
          .reduce((acc, cur) => {
            const existing = acc.find(
              (x) => x.language.name === cur.language.name
            );
            if (existing === undefined) {
              acc.push({ ...cur });
            }
            return acc;
          }, [])
          .map((item) => {
            var queryString = window.location ? window.location.search : '';
            return {
              language: item.language.name.toUpperCase(),
              url: `/${item.language.name}${item.url.replace(
                wildcardItemName,
                pageItemName
              )}${queryString}`,
            };
          })
          .sort((a, b) =>
            a.language < b.language ? -1 : a.language > b.language ? 1 : 0
          )) ||
      [];

    const allLanguages = languageVersions.filter(
      (x) => x.language !== currentLanguage
    );

    const currentLanguageVersion = languageVersions.find(
      (x) => x.language === currentLanguage
    );

    currentPageUrl = currentLanguageVersion
      ? currentLanguageVersion.url
      : '/en/';
    language = { languages: allLanguages, current: currentLanguage };
  } else {
    language = { languages: [{ language: 'EN', url: '/en/' }], current: 'EN' };
    currentPageUrl = '/en/';
  }

  const alternateLanguageLinks = language.languages.map((value, index) => (
    <link
      rel='alternate'
      key={`lang-${index}`}
      href={pageUrl + value.url}
      hreflang={value.language}
    />
  ));

  var headerType =
    object.getNested(
      sitecoreContext,
      'route',
      'fields',
      'HeaderType',
      'fields',
      'Value',
      'value'
    ) || 'normal-header';

  return (
    <React.Fragment>
      <Helmet>
        <link rel='alternate' href={`${pageUrl}/nl/`} hreflang='x-default' />
        <link
          rel='alternate'
          href={pageUrl + currentPageUrl}
          hreflang={language.current}
        />
        {alternateLanguageLinks}
        <link
          id='LinkCanonical'
          rel='canonical'
          href={pageUrl + currentPageUrl}
        />
        <meta property='og:url' content={pageUrl + currentPageUrl} />
      </Helmet>

      {error && (
        <p className='alert alert-danger'>
          GraphQL query error: {error.toString()}
        </p>
      )}
      {headerType === 'normal-header' && (
        <Header
          {...props}
          menu={menu}
          language={language}
          isAuthenticated={isAuthenticated}
          loginWithRedirect={loginWithRedirect}
          logout={logout}
          authLoading={authLoading}
          loading={loading}
          user={user}
          userPersona={userPersona}
        />
      )}
      {headerType === 'simple-header' && (
        <SimpleHeader {...props} menu={menu} language={language} />
      )}
    </React.Fragment>
  );
};

export default GraphQLData(ConnectedDemoQuery, { name: 'connectedQuery' })(
  Connected
);
