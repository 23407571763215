import React from 'react';
import i18n from 'i18next';
import Helmet from 'react-helmet';
import {
  isEditorActive,
  withSitecoreContext
} from '@sitecore-jss/sitecore-jss-react';
import { dataFetcher } from './dataFetcher';
import config from './temp/config';
import Layout from './Layout';
import NotFound from './NotFound';
import document from 'global/document';
import { withCookies } from 'react-cookie';
import moment from 'moment';
import { Loader } from './building-blocks';
import { Body } from './managers';
import { layoutServiceFactory } from './lib/layout-service-factory';
// Dynamic route handler for Sitecore items.
// Because JSS app routes are defined in Sitecore, traditional static React routing isn't enough -
// we need to be able to load dynamic route data from Sitecore after the client side route changes.
// So react-router delegates all route rendering to this handler, which attempts to get the right
// route data from Sitecore - and if none exists, renders the not found component.

class RouteHandler extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notFound: true,
      //routeData: ssrInitialState, // null when client-side rendering
      defaultLanguage: 
        this.props.allCookies['cevora-app#lang'] || config.defaultLanguage
    };

    const routeData = this.extractRouteData();

    // route data from react-router - if route was resolved, it's not a 404
    if (props.route !== null) {
      this.state.notFound = false;
    }

    // if we have an initial SSR state, and that state doesn't have a valid route data,
    // then this is a 404 route.
    if (routeData && (!routeData.sitecore || !routeData.sitecore.route)) {
      this.state.notFound = true;
    }

    // if we have an SSR state, and that state has language data, set the current language
    // (this makes the language of content follow the Sitecore context language cookie)
    // note that a route-based language (i.e. /de-DE) will override this default; this is for home.
    if (
      routeData &&
      routeData.sitecore &&
      routeData.sitecore.context &&
      routeData.sitecore.context.language
    ) {
      this.state.defaultLanguage = routeData.sitecore.context.language;
    }

    this.componentIsMounted = false;
    this.languageIsChanging = false;

    // tell i18next to sync its current language with the route language
    this.updateLanguage();
  }

  componentDidMount() {
    const routeData = this.extractRouteData();

    // if no existing routeData is present (from SSR), get Layout Service fetching the route data
    if (!routeData || this.props.ssrRenderComplete) {
      this.updateRouteData();
    }

    this.componentIsMounted = true;
  }

  extractRouteData = () => {
    if (!this.props.sitecoreContext) return null;
    const { route, ...context } = this.props.sitecoreContext;

    return  {
      sitecore: {
        route,
        context
      }
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  getLanguage = () => {
    return this.props.route.match.params.lang || this.state.defaultLanguage;
  }

  /**
   * Loads route data from Sitecore Layout Service into state.routeData
   */
  updateRouteData() {
    let sitecoreRoutePath = this.props.route.match.params.sitecoreRoute || '/';
    if (!sitecoreRoutePath.startsWith('/')) {
      sitecoreRoutePath = `/${sitecoreRoutePath}`;
    }

    const language = this.getLanguage();
    this.setLocaleForMoment(language);

    // get the route data for the new route
    return getRouteData(sitecoreRoutePath, language).then(routeData => {
      // Handle 404: retrieve layout from static notFound layout
      if (
        routeData &&
        routeData.notFound &&
        routeData.sitecore &&
        routeData.sitecore.route === null &&
        routeData.sitecore.context.notFound
      ) {
        routeData.sitecore.route = {
          ...routeData.sitecore.context.notFound.sitecore.route
        };
      }

      if (routeData && routeData.sitecore && routeData.sitecore.route) {
        // set the sitecore context data and push the new route
        this.props.updateSitecoreContext({
          route: routeData.sitecore.route,
          itemId: routeData.sitecore.route.itemId,
          ...routeData.sitecore.context
        });

        this.setState({ routeData, notFound: false });
      } else {
        this.setState({ routeData, notFound: true }, () =>
          this.props.updateSitecoreContext(routeData.sitecore.context)
      );
      }
    });
  }

  /**
   * Updates the current app language to match the route data.
   */
  updateLanguage() {
    const newLanguage = this.getLanguage();
    if (i18n.language !== newLanguage) {
      this.languageIsChanging = true;
      if (document && document.documentElement)
        document.documentElement.setAttribute('lang', newLanguage);
      return i18n.changeLanguage(newLanguage, () => {
        this.languageIsChanging = false;
        // if the component is not mounted, we don't care
        // (next time it mounts, it will render with the right language context)
        if (this.componentIsMounted) {
          // after we change the i18n language, we need to force-update React,
          // since otherwise React won't know that the dictionary has changed
          // because it is stored in i18next state not React state
          this.forceUpdate();
        }
      });
    } else {
      return Promise.resolve();
    }
  }

  componentDidUpdate(previousProps) {
    const existingRoute = previousProps.route.match.url;
    const newRoute = this.props.route.match.url;

    // don't change state (refetch route data) if the route has not changed
    if (existingRoute === newRoute) {
      // always set locale for moment, even if we just refresh the page (same url).
      // Otherwise the page might get another locale somehow untill the next updateRouteData is executed
      this.setLocaleForMoment(this.getLanguage());
      return;
    }

    // if in experience editor - force reload instead of route data update
    // avoids confusing Sitecore's editing JS
    if (isEditorActive()) {
      window.location.assign(newRoute);
      return;
    }

    this.updateRouteData().then(() => this.updateLanguage());
  }

  setLocaleForMoment(language)
  {
    if (language) {
      moment.locale(language.toLowerCase());
    }
  }

  render() {
    const { notFound } = this.state;
    const routeData = this.extractRouteData();

    // no route data for the current route in Sitecore - show not found component.
    // Note: this is client-side only 404 handling. Server-side 404 handling is the responsibility
    // of the server being used (i.e. node-headless-ssr-proxy and Sitecore intergrated rendering know how to send 404 status codes).
    if (notFound) {
      return (
        <div>
          <Helmet>
            <title>{i18n.t('Page not found')}</title>
          </Helmet>
          <NotFound
            context={
              routeData && routeData.sitecore && routeData.sitecore.context
            }
          />
        </div>
      );
    }
    // Don't render anything if the route data or dictionary data is not fully loaded yet.
    // This is a good place for a "Loading" component, if one is needed.
    if (!routeData || this.languageIsChanging) {
      return (
        <div className={`m-main-loader`}>
          <Loader />
        </div>
      );
    }
    Body.handleRemoveClass("has-overlay-open");
    Body.handleRemoveClass("has-nav-open");
   
    // Render the app's root structural layout
    routeData.sitecore.route.isConnected =
      routeData.sitecore.route.databaseName !== 'available-in-connected-mode';
    return (
      <Layout
        route={routeData.sitecore.route}
        context={routeData.sitecore.context}
      />
    );
  }
}

/**
 * Sets the initial state provided by server-side rendering.
 * Setting this state will bypass initial route data fetch calls.
 * @param {object} ssrState
 */
// export function setServerSideRenderingState(ssrState) {
//   ssrInitialState = ssrState;
// }

/**
 * Gets route data from Sitecore. This data is used to construct the component layout for a JSS route.
 * @param {string} route Route path to get data for (e.g. /about)
 * @param {string} language Language to get route data in (content language, e.g. 'en')
 */
function getRouteData(route, language) {
  const layoutServiceInstance = layoutServiceFactory.create();
  return  layoutServiceInstance.fetchLayoutData(route, language).catch(error => {
    if (
      error.response &&
      error.response.status === 404 &&
      error.response.data
    ) {
      return { ...error.response.data, notFound: true };
    }

    console.error('Route data fetch error', error, error.response);

    return null;
  });
}

export default withCookies(withSitecoreContext({ updatable: true })(RouteHandler));
