/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { event } from './../../../utils';
import window from 'global/window';
import { Events } from './../../../managers';

/**
 * Definition
 * =============================================================================
 */
class Row extends React.Component {
  constructor(props) {
    super(props);

    this.rowEl = React.createRef();

    this.state = {
      height: null,
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    window.addEventListener('resize', event.debounce(this.handleResize, 250));

    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps) {
    const { data } = prevProps;
    const { data: newData } = this.props;

    if (data !== newData) {
      this.handleResize();
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  getHeight() {
    const el = this.rowEl.current;

    if (!el) return;

    const height = el.getBoundingClientRect().height;

    return height;
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleResize = () => {
    const { tableId, index } = this.props;
    setTimeout(() => {
      const height = this.getHeight();

      this.setState({ height }, () => {
        Events.emitter.emit(`table-row-resize:${tableId}-row-${index}`, {
          height,
        });
      });
    }, 100);
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes = '', children } = this.props;

    return (
      <div ref={this.rowEl} className={`o-table__row ${classes}`}>
        {children}
      </div>
    );
  }
}

export default Row;
