import React, { useState, useEffect, useContext } from 'react';
import { Events } from './managers';
import createAuth0Client from '@auth0/auth0-spa-js';
import env from './env';
import Auth0Context from './contexts/Auth0Context';

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

/* WEB-1246
if( Claim(https://cevora.be/roles) contains "HR" ) then Persona := "HR"
else if( Claim(https://cevora.be/roles) contains "Participant" ) then Persona := "EM"
else if( Claim(https://cevora.be/roles) contains "JobberSquare") then Persona := "JS"
else Persona := "EM"
*/
export const getParticipationRole = (roles) => (Array.isArray(roles) && (
    roles.find(x => x === "HR")
    || roles.find(x => x === "Participant")
    || roles.find(x => x === "JobberSquare")
  ) || "Participant");

const getUserPersona = (user) => (user && user['https://cevora.be/roles'] && {
  "Participant" : "EM",
  "HR": "HR",
  "JobberSquare": "JS"
}[getParticipationRole(user['https://cevora.be/roles'])])
  
const identifyUser = user => {

  return fetch(
    `${env.REACT_APP_JSS_API_HOST}/sitecore/api/jss/track/identification/identify?sc_apikey=${env.REACT_APP_JSS_API_KEY}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: user['https://cevora.be/app_metadata'].cdb2Id,
        emailVerified: user.email_verified,
        jsonData: JSON.stringify({
          participationRole: getParticipationRole(user['https://cevora.be/roles'])
        })
      })
    }
  );
};

//export const Auth0Context = React.createContext({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [userPersona, setUserPersona] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [isIdentified, setIsIdentified] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({...initOptions, useRefreshTokens: true, cacheLocation: 'localstorage'});
      setAuth0(auth0FromHook);
     
      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      } else if (window.location.search.includes('redirectUrl=')) {
        onRedirectCallback(null);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        setUser(user);
        setUserPersona(getUserPersona(user));
        if (
          user &&
          user['https://cevora.be/app_metadata'] &&
          user['https://cevora.be/app_metadata'].cdb2Id
        ) {
          const response = await identifyUser(user);
        
          if (response.ok) {
            setIsIdentified(true);
            Events.emitter.emit('user-init', user);
          }
        }
      }
      
      setTimeout(() =>{
        Events.emitter.emit("authentication-loaded");
      },100);
      setLoading(false);
    };

    initAuth0()
    .then(x => typeof localStorage !== 'undefined' && localStorage.removeItem("cevora-auth0:recovering"))
    .catch(e => {
      console.error('Exception initializing Auth0', e, typeof(e), String(e));
      if(typeof localStorage !== 'undefined' 
        && String(e).indexOf('Unknown or invalid refresh token') >= 0
        && !localStorage.getItem("cevora-auth0:recovering")){
        //attempt to recover - remove Auth0 cache and reload page
        const cacheKey = Object.entries(localStorage).map(x => x[0]).find(x => /@@auth0spajs@@/i.test(x));
        localStorage.removeItem(cacheKey);
        localStorage.setItem("cevora-auth0:recovering", new Date().toUTCString());
        document.location.reload();
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();

    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
    setUserPersona(getUserPersona(user));
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userPersona,
        loading,
        isIdentified,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        checkSession: (...p) => auth0Client.checkSession(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
