/**
 * Imports
 * =============================================================================
 */
import React from 'react';

 /**
    * Definition
    * ============================================================================
*/
const Item = ({
  classes = '',
  index,
}) => {

   /**
    * Main render
    * ---------------------------------------------------------------------------
    */
    return (
      <div className={`m-progress-bar__item ${classes}`}>
        <span className="m-progress-bar__number">{index}</span>
      </div>
    );
};

 export default Item;
