/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Link } from 'react-router-dom';
import { Events } from './../../../managers';

/**
 * Definition
 * =============================================================================
 */
class Mobile extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      height: null
    };
  }

  componentDidMount() {
    const { tableId, index } = this.props;
   
    Events.emitter.on(`table-row-resize:${tableId}-row-${index}`, this.handleResize);
  }

  componentWillUnmount() {
    const { tableId, index } = this.props;

    Events.emitter.removeListener(`table-row-resize:${tableId}-row-${index}`, this.handleResize);
  }

  handleResize = ({ height }) => {
    this.setState({ height });
  }
  handleOnClick = (e) =>{
    Events.handleEventClick(e);
    
  }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { height } = this.state;
    const { url, icon } = this.props;

    return (
      <div className='o-table__mobile-row' style={{ height: height ? `${height}px` : 'auto' }}>
        <a onClick={this.handleOnClick} href={url || '/'} rel="noopener norefferer" className={`o-table__mobile-action ${icon}`} />
      </div>
    );
  }
}

export default Mobile;
