/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';
import ReactDOM from 'react-dom';
import { dom } from '../../utils';

/**
 * Definition
 * =============================================================================
 */
class ChoiceList extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      active: this.props.active,
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    
    if (
      this.props.active !== this.state.active
    ) {
      this.setState({ active: this.props.active });
    }
  }



  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleChange = (text, value, id) => {
    const { onChange,onClicked, name } = this.props;

    this.setState({ active: id }, () => {
     
      if (onChange) onChange(name, value, text);
      if(onClicked) onClicked();
    });
  }

  handleClick = (e) => {
    const { onClicked } = this.props;
    if(onClicked) onClicked();
  }

 
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
   renderItems() {
    const { items, name } = this.props;
    const { active } = this.state;
  
    return items.map((i, index) => (
      <Item
        key={`choice-list-item-${index}`}
        name={name}
        onChange={this.handleChange}
        isActive={active === i.id}
        {...i}
      />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const { classes='' } = this.props;

    return (
      <ul className={`m-choice-list ${classes}`} onClick={this.handleClick}>
        {this.renderItems()}
      </ul>
    );
  }
}

export default ChoiceList;
