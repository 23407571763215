/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { object } from '../../utils';
import Modal from '../../components/Modal';
import ModalTrigger from '../../components/Modal/Trigger';
import ActionGroup from '../../components/ActionGroup'; 
import DefinitionList from '../../components/DefinitionList';
import DefinitionListItem from '../../components/DefinitionList/Item';
import { withTranslation } from '../../hoc/withTranslation';
import { Events } from '../../managers';
import { Checkbox,Button } from '../../building-blocks';
import { withCookies } from 'react-cookie';
import { window } from 'global/window';
import {url} from '../../utils';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Body } from '../../managers';
import {
  withRouter
} from 'react-router-dom';
/**
 * Definition
 * =============================================================================
 */
const Definitions = ({
  fields,
  trainingForm: trainingType,
  duration,
  internshipDuration,
  languages,
  route,
  modalShow,
  cookies,
  history,
  referenceOld,
  t
}) => {
 var languageUrl=null;
 var showModalAnymore = false;
 var cookiename = 'sh-lng';
  const hasPrerequisities = object.getNested(fields, 'Prerequisites', 'value');
  showModalAnymore = cookies.get(cookiename) || 0;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderLanguages = () => {
    return languages.map((l, index) => {
      if(l.language.length==0)return;
      const key = `infofiche-definition-language-${index}`;
      const hasComma = (index != languages.length - 1);
      const text = `${l.language.toUpperCase()}${hasComma ? ',' : ''}`;
      
      return l.url ? (
        <span key={key}><a href={l.url || '/'} onClick={handleLanguageClick}>{text}</a>{'\u00A0'}</span>
      ) : (
        <span key={key}>{text}{'\u00A0'}</span>
      );
    });
  };

const handleLanguageModalAccept = (e) =>{
  e.preventDefault();
  Events.emitter.emit("modal-trigger-close:language-modal");
  if(showModalAnymore){
    cookies.set(cookiename, 1, { path: '/' });
  }
  if(languageUrl){
    Body.handleRemoveClass('has-modal-open');
    history.push(url.relativePath(languageUrl));
  }
 // window.open(languageUrl, "_self");
}

const handleShowModalLanguageChange = (name, isChecked, id) =>{
  showModalAnymore=isChecked;
}

  const handleLanguageClick = (e) =>{
 

   e.preventDefault();
   languageUrl = e.target.href;

   if(showModalAnymore)
   {
      window.open(languageUrl, "_self");
   }
   else {
  
   Events.emitter.emit("modal-trigger-open:language-modal");
  }
    
  }
  var hasLanguages = true;

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <DefinitionList>
      {trainingType && (
        <DefinitionListItem
          col='3'
          term={t('infofiche.definitions.trainingType')}
          description={(
            <p>{trainingType}</p>
          )}
        />
      )}

      {duration && (
        <DefinitionListItem
          col='3'
          term={t('infofiche.definitions.duration')}
          description={(
            <p>{duration}</p>
          )}
        />
      )}

      {internshipDuration && (
        <DefinitionListItem
          col='3'
          term={t('infofiche.definitions.internshipDuration')}
          description={(
            <p>{internshipDuration}</p>
          )}
        />
      )}

      {languages && (
        <DefinitionListItem
          col='3'
          term={t('infofiche.definitions.languages')}
          description={renderLanguages()}
        />
      )}

      {route && route.length!=0 && (
        <DefinitionListItem
          col='6'
          term={t('infofiche.definitions.traject')}
          description={(
            <React.Fragment>
              <a href={route.url} rel="noopener norefferer">{route.description}</a>
              <small>&nbsp;({route.meta})</small>
            </React.Fragment>
          )}
        />
      )}
      {hasLanguages && (<Modal
       id="language-modal"
        isOpen={modalShow}
        title={t('infofiche.languageModal.title')}
        content={t('infofiche.languageModal.content')}
        footer={(
            <ActionGroup classes='m-action-group--col m-action-group--vertical-center'>
              <Checkbox onChange={handleShowModalLanguageChange} label={t('infofiche.languageModal.dontShowAnymore')} />
             
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='language-modal'
                onClick={handleLanguageModalAccept}
                type='button'
                fields={{
                  ButtonText: t('infofiche.languageModal.cta')
                }}
              />
            </ActionGroup>
          )}
      />)
       }
       {referenceOld && (
        <DefinitionListItem
          col='3'
          term={t('infofiche.definitions.referenceOld')}
          description={(
            <p>{referenceOld}</p>
          )}
        />
      )}
    </DefinitionList>
  );
};

export default withRouter(withCookies(withTranslation()(Definitions)));


