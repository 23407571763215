/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Item from './Item';

/**
 * Definition
 * =============================================================================
 */
const DefinitionList = ({ children, items }) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <Item
          key={`definition-list-item-${index}`}
          {...item}
        />
      );
    });
  };

  /**
   * Main renders
   * ---------------------------------------------------------------------------
   */
  return (
    <ul className='m-definition-list l-grid l-grid--with-margins'>
      {children ? children : renderItems()}
    </ul>
  )
};

export default DefinitionList;
