/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, Link, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { object, classes } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const DisclosureLink = (props) => {
  const {
    DisclosureLinkText,
    DisclosureLinkUrl,
    DisclosureLinkIconImage,
    DisclosureLinkLayout,
    DisclosurePillText
  } = props.fields;

  const layout = object.getNested(DisclosureLinkLayout, 'fields', 'Value', 'value');

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getLinkLayoutClasses = () => {
    if(object.getNested(DisclosureLinkIconImage, 'value', 'src')) return 'a-disclosure-link--itsme';
    if (!layout) return 'a-disclosure-link--primary';

    return classes.compare(layout, {
      'primary': 'a-disclosure-link--primary',
      'secondary': 'a-disclosure-link--secondary',
      'itsme': 'a-disclosure-link--itsme'
    });
  };

  const getIconClasses = () => {
    if (!layout) return 'icon-arrow-right';

    return classes.compare(layout, {
      'primary': 'icon-arrow-right',
      'secondary': 'icon-arrow-double-right',
      'itsme': 'icon-arrow-right'
    });
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderText = () => {
    return (
      <div className='a-disclosure-link__text'>
        <span><Text field={DisclosureLinkText} /></span>
        {(object.getNested(DisclosurePillText, 'value') || isEditorActive()) && renderPill()}
      </div>
    );
  };

  const renderPill = () => {
    return (
      <div className="a-pill a-disclosure-link__pill">
        <span className="a-pill__text"><Text field={DisclosurePillText}/></span>
      </div>
    );
  };

  const renderIconImage = () => {
    return (
      <span className='a-disclosure-link__icon-left'>
        <img src={object.getNested(DisclosureLinkIconImage, 'value', 'src')} alt={object.getNested(DisclosureLinkIconImage, 'value', 'alt')} />
      </span>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Link
      className={`a-disclosure-link ${getLinkLayoutClasses()}`}
      field={DisclosureLinkUrl}
    >
      {(object.getNested(DisclosureLinkIconImage, 'value', 'src') || isEditorActive()) && renderIconImage()}
      {(object.getNested(DisclosureLinkText, 'value') || isEditorActive()) && renderText()}

      <span className={`a-disclosure-link__icon ${getIconClasses()}`}></span>
    </Link>
  );
}

export default DisclosureLink;
