/*
 courseId: courseDataHubId ,
 courseTitle: HeroTitle,
          courseType: 1--> self, 2 --> incompany
          dateFrom: startDateSession, --> sessionDate
          dateTo:  endDateSession),
          groupId: dataHubId,
          oldReference: ReferenceOld 

*/

/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { AmountIndicator, Button, Link } from './../../building-blocks';
import ProductList from './../ProductList';
import Modal from './../Modal';
import ModalTrigger from './../Modal/Trigger';
import ActionGroup from './../ActionGroup';
import { withFlyout, withBasketData, withAuth0 } from './../../hoc';
import { withTranslation } from '../../hoc/withTranslation';
import { Events } from './../../managers';
import window from 'global/window';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { object, date, url as Url } from '../../utils';
import { withRouter } from 'react-router-dom';
import { redirectToCheckout, calculatePersona } from '../../lib/Gtm';

/**
 * Definition
 * =============================================================================
 */
class Basket extends React.Component {

  constructor(props) {
    super(props);
    this.remindedEmail = false;
    this.state = { userData: null };
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleAddToBasket = this.handleAddToBasket.bind(this);
  }
  /**
     * Life cycle
     * ---------------------------------------------------------------------------
     */
  componentDidMount() {

    Events.emitter.on('basket-add-item', this.handleAddToBasket);
    Events.emitter.on('go-to-checkout', this.handleGoToCheckout);
    Events.emitter.on('basket-item-added', this.handleItemAddedToBasket);
    Events.emitter.on('basket-remove-item', this.handleRemoveFromBasket);
    Events.emitter.on('basket-item-removed', this.handleItemRemovedFromBasket);

    Events.emitter.on('user-set-persona', this.handleSetPersona);
    Events.emitter.on('redirect', this.handleRedirect);
    Events.emitter.on('checkout', this.handleCheckout);

    this.checkLoginFailed();
    this.checkReminder();

  }

  componentDidUpdate() {
    this.checkLoginFailed();
    this.checkReminder();
  }

  componentWillUnmount() {
    Events.emitter.removeListener('checkout', this.handleCheckout);
    Events.emitter.removeListener('go-to-checkout', this.handleGoToCheckout);
    Events.emitter.removeListener('basket-add-item', this.handleAddToBasket);
    Events.emitter.removeListener('basket-item-added', this.handleItemAddedToBasket);
    Events.emitter.removeListener('basket-remove-item', this.handleRemoveFromBasket);
    Events.emitter.removeListener('basket-item-removed', this.handleItemRemovedFromBasket);

    Events.emitter.removeListener('user-set-persona', this.handleSetPersona);
    Events.emitter.removeListener('redirect', this.handleRedirect);

  }

  canChangePersona = (isAuthenticated, basketData, userData) => {
    if (isAuthenticated) return true;
    if (userData.persona == basketData.persona) return true;
    if (!basketData.items || basketData.items.length == 0) return true;
    if (userData.persona == "HR" && basketData.persona == "EM") return true;
    if (userData.persona == "EM" && basketData.persona == "HR" && basketData.items.filter(item => item.courseType == 2).length == 0) return true;
    return false;
  }
  handleRedirect(userData) {
    const { history } = this.props;

    if (userData.redirect) {
      if (userData.redirect == "1") {
        history.goBack();
      }
      else {
        if (!Events.checkEventUrl(userData.redirect)) {
          if (Url.isRelative(userData.redirect)) {
            history.push(userData.redirect);
          }
          else {
            setTimeout(() => {
              window.open(userData.redirect);
            }, 500);
          }
        }
      }
    }
  }
  handleSetPersona = (userData) => {
    const { isAuthenticated, basketData } = this.props;
    if (!basketData.persona) {
      basketData.onPersonaSet(userData.persona);
      Events.emitter.emit("user-persona-set", userData);
      Events.emitter.emit("redirect", userData);
    }
    else if (!this.canChangePersona(isAuthenticated, basketData, userData)) {

      this.setState({ userData });
      Events.emitter.emit("modal-trigger-open:ask-change-persona-modal");

    }
    else {


      if (!isAuthenticated && userData.persona != basketData.persona) {
        basketData.onPersonaSet(userData.persona);
        Events.emitter.emit("user-persona-set", userData);

      }
      Events.emitter.emit("redirect", userData);
    }

  }

  handlePersonaSet = (userData) => {

    Events.emitter.emit("user-persona-set", userData);
    Events.emitter.emit("redirect", userData);

  }

  handleChangePersona = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.nativeEvent)
        e.nativeEvent.stopImmediatePropagation();
    }
    const { basketData } = this.props;
    const { userData } = this.state;
    basketData.onAllItemsDeleted();
    basketData.onPersonaSet(userData.persona);
    this.handlePersonaSet(userData);

  }

  checkLoginFailed = () => {
    const { location } = this.props;

    if (location && !this.loginChecked) {
      var queryStringParams = new URLSearchParams(location.search);

      if (queryStringParams.has('e') && queryStringParams.get('e') == 'lf') {
        this.loginChecked = true;
        setTimeout(() => {
          Events.emitter.emit("modal-alert-open", "alert.user.loginFailed.content", 'alert.user.loginFailed.title');
        }, 500);
      }
    }

  }

  checkReminder = () => {
    const { location, user, isAuthenticated } = this.props;

    if (location) {
      var queryStringParams = new URLSearchParams(location.search);

      if (queryStringParams.has('ce') && isAuthenticated && user && !user.email_verified && !this.remindedEmail) {
        this.remindedEmail = true;
        setTimeout(() => {
          Events.emitter.emit('modal-alert-open', 'alert.user.emailReminder.content', 'alert.user.emailReminder.title');
        }, 500);
      }
      else if (user && user.email_verified) { this.remindedEmail = true; }

      if (queryStringParams.has('ce')) {
        var pathname = location.pathname;
        queryStringParams.delete('ce');
        var queryString = queryStringParams.toString();
        if (queryString) {
          pathname += `?${queryString}`;
        }
        window.history.replaceState(null, "", pathname);
      }
    }
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */

  handleGoToCheckout = (checkoutUrl) => {
    const { basketData = {}, auth0 = {} } = this.props;
    redirectToCheckout(checkoutUrl, basketData.graphQLClient, basketData.items, calculatePersona(auth0, basketData));
  }

  handleAddToBasket = (item) => {
    if (!(item && item.courseId)) {
      Events.emitter.emit(
        'modal-alert-open',
        'alert.basket.courseHaveNoCourseId',
        'alert.basket.errorAddingToBasketTitle'
      );
      console.error("Training has no courseId, cannot add to basket", item, this.props);
      return;
    }

    const { basketData } = this.props;

    if (item.trainingType === "JS") {
      // for JS addToCart has to be sent before the hasCorrectPersona popup
      basketData.sendAddToCardGtm(item);
    }

    //add item to basket
    this.addingItem = item;
    //check persona
    if (this.hasCorrectPersona(item)) {
      if (basketData.persona === "JS") {
        this.handleCheckoutJobSeeker();
      } else {
        this.addToBasket(item);
      }
    }

  }

  addToBasket = (item) => {
    const { basketData } = this.props;
    item.CourseUrl = Url.getCurrentPath();
    basketData.onItemAdded(item);
  }

  hasCorrectPersona = (item) => {
    const { basketData, isAuthenticated } = this.props;

    if (isAuthenticated) {
      if (item.courseType === 2) {
        if (basketData.persona === "HR") return true;
        Events.emitter.emit("modal-alert-open", 'alert.basket.forLiableOnly.content', 'alert.basket.forLiableOnly.title');
      }
      else {
        if (item.trainingType === "JS" && basketData.persona !== "JS") {
          Events.emitter.emit("modal-alert-open", 'alert.basket.forJobSeekersOnly.content', 'alert.basket.forJobSeekersOnly.title');
        }
        else if (item.trainingType !== "JS" && basketData.persona === "JS") {
          Events.emitter.emit("modal-trigger-open:employee-only-alert");
          //Events.emitter.emit("modal-alert-open", 'alert.basket.forEmployeesOnly.content', 'alert.basket.forEmployeesOnly.title');
        }
        else return true;
      }
    }
    else {
      if (item.courseType === 2) {
        if (basketData.persona === "HR") return true;
        Events.emitter.emit("modal-trigger-open:ask-liable-modal");
      }
      else {
        if (item.trainingType === "JS") {
          if (basketData.persona === "JS") return true;
          Events.emitter.emit("modal-trigger-open:ask-jobseeker-modal");
        }
        else {
          if (basketData.persona === "EM" || basketData.persona === "HR") return true;
          Events.emitter.emit("modal-trigger-open:ask-employee-modal");
        }
      }
    }
    return false;
  }

  redirectToRegister = () => {
    const { t } = this.props;
    const registerUrl = t('basket.employeeOnlyAlert.emRegisterUrl');
    if (registerUrl.startsWith('http')) {
      setTimeout(() => {
        window.open(registerUrl, "_self"); // WEB-1480 - should open in same tab as requested
      }, 250);
    } else {
      console.log("basket.employeeOnlyAlert.emRegisterUrl: set correct register url", registerUrl);
    }
  }

  handleItemAddedToBasket = (item) => {
    this.setState({ addedItem: item });
    Events.emitter.emit("modal-trigger-open:basket-item-added-modal");


  }
  handleRemoveFromBasket = (lineItemId) => {
    const { basketData } = this.props;

    basketData.onItemDeleted(lineItemId);

  }

  handleCheckout = (e) => {

    this.stopEventPropagation(e);
    const { basketData, userPersona } = this.props;

    //check if logged in
    const { isAuthenticated, user } = this.props;

    if (!isAuthenticated) {
      Events.emitter.emit("modal-trigger-open:ask-login-modal");
    } else {
      if (user.email_verified) {
        //check which courseType
        var nrOfTypes = basketData.items.map(item => item.courseType)
          .filter((value, index, self) => self.indexOf(value) === index).length;
        if (userPersona === "JS") {
          Events.emitter.emit("modal-trigger-open:employee-only-alert");
        } else if (nrOfTypes > 1) {
          Events.emitter.emit("modal-trigger-open:ask-coursetype-modal");
        } else if (userPersona === "HR" && basketData.items.length > 0 && basketData.items[0].courseType === 1) {
          Events.emitter.emit("modal-trigger-open:ask-checkout-employee-or-hr-modal");
        } else {
          basketData.onCheckOut(basketData.items[0].courseType);
        }
      }
      else Events.emitter.emit("modal-alert-open", 'alert.basket.emailNotVerified.content', 'alert.basket.emailNotVerified.title');

    }
  }


  handleCheckoutJobSeeker = (e) => {
    this.stopEventPropagation(e);

    const { sitecoreContext } = this.props;

    const pageItemName = object.getNested(sitecoreContext, "navigationData", "JobSeekerSubscribe");
    var url = Url.replaceURL(pageItemName, this.addingItem);

    window.open(url, "_self");
  }

  stopEventPropagation = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.nativeEvent) {
        e.nativeEvent.stopImmediatePropagation();
      }
    }
  }

  handleGoToPersona = (e) => {
    this.stopEventPropagation(e);

    const { sitecoreContext, history } = this.props;
    const pageItemName = object.getNested(sitecoreContext, "navigationData", "PersonaSelector");
    history.push(Url.relativePath(pageItemName));
  }

  handleCheckoutEmployee = (e) => {
    this.stopEventPropagation(e);

    const { basketData } = this.props;
    if (basketData && basketData.persona === 'HR') {
      Events.emitter.emit("modal-trigger-open:ask-checkout-employee-or-hr-modal");
    }
    else {
      this.handleCheckoutByType(1);
    }
  }

  handleCheckoutInCompany = (e) => {
    this.stopEventPropagation(e);

    const { basketData } = this.props;
    if (basketData && basketData.persona === 'HR') {
      this.handleCheckoutByType(2);
    } else {
      Events.emitter.emit("modal-alert-open", "alert.basket.checkOut.inCompanyOnlyForHrContent", 'alert.basket.checkOut.inCompanyOnlyForHrTitle');
    }
  }

  handleCheckoutHrAsEmployee = (e) => {
    this.stopEventPropagation(e);
    this.handleCheckoutByType(1, "EM");
  }

  handleCheckoutHrAsHr = (e) => {
    this.stopEventPropagation(e);
    this.handleCheckoutByType(1, "HR");
  }

  handleCheckoutByType = (checkoutType, persona) => {
    const { basketData } = this.props;
    if (persona) {
      basketData.onPersonaSet(persona, true);
    }
    basketData.onCheckOut(checkoutType);
  }

  handleItemRemovedFromBasket = () => {

  }

  handleUpdateProducts = (data) => {
    const { onUpdate } = this.props;

    this.setState({ data }, () => {
      if (onUpdate) {
        onUpdate(data);
      }
    });
  }

  handleBasketClick = (e) => {
    e.preventDefault();
    const { sitecoreContext, history } = this.props;
    const pageItemName = object.getNested(sitecoreContext, "navigationData", "BasketOverview");
    history.push(Url.relativePath(pageItemName));
  }

  handleGoToRegisterClick = (e) => {
    Events.emitter.emit("go-to-register");
  }

  handleGoToLoginClick = (e) => {
    Events.emitter.emit("go-to-login");
  }

  handleEmployeeConfirm = () => {

    const { basketData } = this.props;


    basketData.onPersonaSet("EM");
    Events.emitter.emit("modal-trigger-close:ask-employee-modal");
    var me = this;
    setTimeout(() => {

      me.addToBasket(me.addingItem);
    }, 300);


  }
  handleLiableConfirm = () => {
    const { basketData } = this.props;

    basketData.onPersonaSet("HR");
    Events.emitter.emit("modal-trigger-close:ask-employee-modal");

    var me = this;
    setTimeout(() => {

      me.addToBasket(me.addingItem);
    }, 300);

  }
  getItemsByCourseType = (items, courseType) => {
    if (!items) return [];
    return items.filter((item) => (item.courseType + "") == (courseType + ""));

  }
  getCourseTitle = (item) => {
    if (item == null) return "";
    var title = item.courseTitle;
    if (item.dateFrom && !item.isVirtual) title += " - " + date.formatDate(item.dateFrom);
    return title;
  }
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      setFlyoutState = '',
      handleOpenFlyout,
      handleCloseFlyout,
      isMobile,
      t,
      classes = '',
      basketData,
      loading,
      sitecoreContext
    } = this.props;

    const { addedItem } = this.state;
    const sessionItems = this.getItemsByCourseType(basketData.items, 1);
    const incompanyItems = this.getItemsByCourseType(basketData.items, 2);
    const hasSessionItems = (sessionItems && sessionItems.length > 0);
    const hasIncompanyItems = (incompanyItems && incompanyItems.length > 0);
    const hasItems = hasSessionItems || hasIncompanyItems;
    const hideBasket = object.getNested(sitecoreContext, "route", "fields", "HideBasketIcon", "value");

    var loadingClass = loading || basketData.isLoading ? 'o-basket--loading' : '';

    return (
      <div className={`o-basket ${loadingClass}`}>

        {!hideBasket
          ? <div
            className={`o-basket__icon ${classes} ${setFlyoutState()}`}
            onMouseEnter={() => !isMobile && handleOpenFlyout()}
            onMouseLeave={() => !isMobile && handleCloseFlyout()}
            onClick={this.handleBasketClick}
          >
            <div className='o-basket__label'>
              <AmountIndicator
                classes='o-header__badge'
                amount={(basketData.items && basketData.items.length) || 0}
                icon='icon-basket'
              />
            </div>
            <div className='o-basket__container'>
              <div className='o-basket__items'>
                {(hasSessionItems || !hasItems) && (
                  <h5 className='o-basket__title'>{t(`basket.titleSessions`)}</h5>
                )}
                {(hasSessionItems && (
                  <ProductList
                    classes='o-basket__content'
                    items={sessionItems}
                  />
                ))}
                {(hasIncompanyItems && <h5 className='o-basket__title'>{t(`basket.titleIncompany`)}</h5>
                )}
                {(hasIncompanyItems && (
                  <ProductList
                    classes='o-basket__content'
                    items={incompanyItems}
                  />
                ))}
                {!hasItems && (
                  <p className='o-basket__fallback'>{t('basket.empty')}</p>
                )}
              </div>
              {hasItems && (
                <div className='o-basket__action'>
                  <Button
                    classes='a-button--fill-space'
                    type='clickButton'
                    onClick={this.handleCheckout}
                    fields={{
                      ButtonText: t('basket.subscribe')

                    }}
                  />
                </div>
              )}

            </div>
          </div>
          : null}
        <Modal
          id="basket-item-added-modal"
          title={addedItem != undefined && addedItem.courseType == 1 ? t('basket.addedItemModal.titleSession') : t('basket.addedItemModal.titleIncompany')}
          contentClasses='u-padding-bottom-none'
          content={<div className="a-highlight a-highlight--spacing-large">
            <div className="a-highlight__content">
              <div className="a-title ">
                <h5 className="a-title__text">{this.getCourseTitle(addedItem)}</h5>
              </div>
            </div>
          </div>
          }
          footer={(
            <ActionGroup classes='m-action-group--horizontal-center@below-tablet-base  m-action-group--space-between@above-tablet-base m-action-group--column@below-tablet-base'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='basket-item-added-modal'
                onClick={this.handleCheckout}
                type='button'
                fields={{
                  ButtonText: t('basket.subscribe')
                }}
              />
              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                classes='a-link--decorated'
                targets='basket-item-added-modal'
                fields={{
                  LinkText: t('basket.continueShopping'),
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-change-persona-modal"
          title={t('basket.askChangePersonaModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askChangePersonaModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--row m-action-group--space-between'>

              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                classes='a-link--decorated'
                targets='ask-change-persona-modal'
                fields={{
                  LinkText: t('basket.askChangePersonaModal.no')
                }}
              />
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-change-persona-modal'
                onClick={this.handleChangePersona}
                type='button'
                fields={{
                  ButtonText: t('basket.askChangePersonaModal.yes')
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-coursetype-modal"
          title={t('basket.askCoursetypeModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askCoursetypeModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--col m-action-group--vertical-center'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-coursetype-modal'
                onClick={this.handleCheckoutEmployee}
                type='button'
                fields={{
                  ButtonText: t('basket.askCoursetypeModal.employee')
                }}
              />
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-coursetype-modal'
                onClick={this.handleCheckoutInCompany}
                type='button'
                fields={{
                  ButtonText: t('basket.askCoursetypeModal.liable')
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-login-modal"
          title={t('basket.askLoginModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askLoginModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--horizontal-center@below-tablet-base  m-action-group--space-between@above-tablet-base m-action-group--column-reverse@below-tablet-base'>

              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                targets='ask-login-modal'
                classes='a-link--decorated'
                onClick={this.handleGoToRegisterClick}
                fields={{
                  LinkText: t('basket.askLoginModal.goToRegister')
                }}
              />
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-login-modal'
                onClick={this.handleGoToLoginClick}
                type='button'
                fields={{
                  ButtonText: t('basket.askLoginModal.goToLogin')
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-jobseeker-modal"
          title={t('basket.askJobSeekerModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askJobSeekerModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--horizontal-center@below-tablet-base  m-action-group--space-between@above-tablet-base m-action-group--column-reverse@below-tablet-base'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-jobseeker-modal'
                onClick={this.handleCheckoutJobSeeker}
                type='button'
                fields={{
                  ButtonText: t('basket.askJobSeekerModal.jobSeekerConfirm')
                }}
              />
              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                classes='a-link--decorated'
                targets='ask-jobseeker-modal'
                onClick={this.handleGoToPersona}
                fields={{

                  LinkText: t('basket.askJobSeekerModal.other'),
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-liable-modal"
          title={t('basket.askLiableModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askLiableModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--horizontal-center@below-tablet-base  m-action-group--space-between@above-tablet-base m-action-group--column-reverse@below-tablet-base'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-liable-modal'
                onClick={this.handleLiableConfirm}
                type='button'
                fields={{
                  ButtonText: t('basket.askLiableModal.liableConfirm')
                }}
              />
              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                classes='a-link--decorated'
                targets='ask-liable-modal'
                onClick={this.handleGoToPersona}
                fields={{
                  LinkText: t('basket.askLiableModal.other'),
                }}
              />
            </ActionGroup>
          )}
        />
        <Modal
          id="ask-employee-modal"
          title={t('basket.askEmployeeModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askEmployeeModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--col m-action-group--vertical-center'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-askEmployee-modal'
                onClick={this.handleEmployeeConfirm}
                type='button'
                fields={{
                  ButtonText: t('basket.askEmployeeModal.employeeConfirm')
                }}
              />
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-askEmployee-modal'
                onClick={this.handleLiableConfirm}
                type='button'
                fields={{
                  ButtonText: t('basket.askEmployeeModal.liableConfirm')
                }}
              />
              <ModalTrigger
                triggerComponent={Link}
                triggerType='close'
                classes='a-link--decorated'
                targets='ask-askEmployee-modal'
                onClick={this.handleGoToPersona}
                fields={{
                  LinkText: t('basket.askEmployeeModal.other')
                }}
              />
            </ActionGroup>
          )}
        />

        <Modal
          id="ask-checkout-employee-or-hr-modal"
          title={t('basket.askCheckoutEmployeeOrHrModal.title')}
          contentClasses='u-padding-bottom-none'
          content={t('basket.askCheckoutEmployeeOrHrModal.content')}
          footer={(
            <ActionGroup classes='m-action-group--col m-action-group--vertical-center'>

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-checkout-employee-or-hr-modal'
                onClick={this.handleCheckoutHrAsEmployee}
                type='button'
                fields={{
                  ButtonText: t('basket.askCheckoutEmployeeOrHrModal.employeeConfirm')
                }}
              />
              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='ask-checkout-employee-or-hr-modal'
                onClick={this.handleCheckoutHrAsHr}
                type='button'
                fields={{
                  ButtonText: t('basket.askCheckoutEmployeeOrHrModal.liableConfirm')
                }}
              />
            </ActionGroup>
          )}
        />

        <Modal
          id="employee-only-alert"
          title={t('basket.employeeOnlyAlert.title')}
          contentClasses="u-padding-bottom-none"
          content={t('basket.employeeOnlyAlert.content')}


          footer={(
            <ActionGroup classes="m-action-group--space-between m-action-group--horizontal-center m-action-group--stretch">

              <ModalTrigger
                triggerComponent={Button}
                triggerType='close'
                targets='employee-only-alert'
                onClick={this.redirectToRegister}
                type='button'
                fields={{
                  ButtonText: t('basket.employeeOnlyAlert.redirectToEmRegisterCta')
                }}
              />

              <ModalTrigger
                triggerComponent={Button}
                triggerType="close"
                targets="employee-only-alert"
                type="button"
                fields={{
                  ButtonText: t('alert.general.close')
                }}
              />

            </ActionGroup>
          )}
        />
      </div>
    );
  }
}

export default withRouter(withSitecoreContext()(withAuth0(withBasketData()(withTranslation()(withFlyout()(Basket))))));
