/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Title from './../components/Title';
import Mobile from './../components/Mobile';
import Action from './Action';
import Row from './Row';

/**
 * Definition
 * =============================================================================
 */
const Table = ({
  classes,
  header,
  body,
  id,
  onSortChange
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderHeader = () => {
    return header.map((h, index) => (
      <Title key={`table-header-row-${index}`} {...h} onSortChange={onSortChange} />
    ));
  }

  const renderBody = () => {
    return body.map((b, index) => (
      <Row
        key={`table-body-row-${index}`}
        index={index}
        id={id}
        data={b.columns}
      />
    ));
  }

  const renderActions = (Component) => {
    return body.map((d, index) => (
      <Component
        key={`table-action-${index}`}
        index={index}
        tableId={id}
        {...d.action}
      />
    ));
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div
      id={id}
      className={`o-table o-table--custom o-table--with-actions ${classes}`}
    >
      <div className='o-table__mobile-actions u-desktop-hide'>
        <div className='o-table__mobile-header-row'></div>

        {renderActions(Mobile)}
      </div>

      <div className='o-table__wrapper'>
        <div className='o-table__content'>
          <div className='o-table__header-row'>
            {renderHeader()}
          </div>

          <div className='o-table__body'>
            {renderBody()}
          </div>
        </div>

        <div className='o-table__actions u-desktop-show'>
          <div className='o-table__header-row'></div>

          {renderActions(Action)}
        </div>
      </div>
    </div>
  );
};

export default Table;

