/**
 * Imports
 * =============================================================================
 */
import React from 'react';

import { object, styles } from './../../utils';
import Background from '../Background';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react'
/**
 * Definition
 * =============================================================================
 */
const Content = ({
  content,
  props,
  classes = '',
  itemSpacing,
  alignment,
  backgroundImage = null,
  backgroundType = null
}) => {
  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getMarginTop = () => {
    const spacing = object.getNested(itemSpacing, 'fields', 'Value', 'value');

    if (!spacing) return '';

    return styles.getMarginTopUtil(spacing);
  };

  const getAlignClass = () => {
    const align = object.getNested(alignment, 'fields', 'Value', 'value');

    if (!align) return '';

    return 'l-section__content--align-' + align;
  };

  classes += getAlignClass();
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  const renderItem = (component, index) => {
    return (
        <div
          key={`section-content-item-${index}`}
          className={`l-section__item ${index > 0 ? getMarginTop() : ''}`}
        >
          {component}
        </div>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */

  return (
    <div className={`l-section__content ${classes}`}>
      <Background
        backgroundImage={backgroundImage}
        backgroundType={backgroundType}
      />

      <Placeholder
        {...props}
        name='section-main-content'
        renderEach={renderItem}
      />
    </div>
  );
};

export default Content;
