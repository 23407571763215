import { RestLayoutService } from '@sitecore-jss/sitecore-jss-react';
import config from '../temp/config';

function dataFetcher(url, data) {

  return fetch(url, {
    method: 'GET',
    redirect: 'manual',
  }).then((res) => {
    if(res.type === "opaqueredirect"){
      window.location = res.url;
    }
    return ({
      status: res.status,
      statusText: res.statusText,
      data: res.json()
    })
  });

}

export class LayoutServiceFactory {
  create() {

    return new RestLayoutService({
      apiHost: config.sitecoreApiHost(),
      apiKey: config.sitecoreApiKey(),
      siteName: config.jssAppName,
      dataFetcherResolver: () => dataFetcher
    });
  }
}

export const layoutServiceFactory = new LayoutServiceFactory();
