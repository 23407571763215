import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';

const TermPopup = ({ term, t }) => {
  term = term || '';
  const title = t('searchResultsPage.termPopup.title');
  const text = t('searchResultsPage.termPopup.text');
  const buttonText = t('searchResultsPage.termPopup.buttonText');
  const buttonUrl = t('searchResultsPage.termPopup.buttonUrl');
  const terms = t('searchResultsPage.termPopup.terms')
    .split(',')
    .map((x) => x.trim().toLowerCase())
    .filter((x) => x);

  return (
    terms.includes(term.toLowerCase()) && (
      <div className='u-margin-bottom-2x'>
        <section className='l-section u-desktop-show u-mobile-hide'>
          <div className='l-background--cover  l-background l-background--illustrative-grey'></div>
          <div className='l-section__container l-container '>
            <div className='l-section__content u-text-align-center'>
              <div className='l-background--cover'></div>
              <h3>{title}</h3>

              <p className='u-margin-bottom-2x'>{text}</p>
              <a
                href={buttonUrl}
                class='a-button a-button--secondary'
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className='a-button__text'>{buttonText}</span>
              </a>
            </div>
          </div>
        </section>
        <div className='u-text-align-center u-desktop-hide'>
          <h5>{title}</h5>
          <p>{text}</p>
          <a
            href={buttonUrl}
            class='a-button a-button--secondary'
            target='_blank'
            rel='noopener noreferrer'
          >
            <span className='a-button__text'>{buttonText}</span>
          </a>
        </div>
      </div>
    )
  );
};

export default withTranslation()(TermPopup);
