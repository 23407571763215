/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Text, isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Root from './Root';
import { Link } from './../../building-blocks';
import { object } from './../../utils';
import RichText from '../../building-blocks/RichTextEx';

/**
 * Definition
 * =============================================================================
 */
const Card = (props) => {
  const {
    CardDescription,
    CardTitle,
    LinkText,
    LinkUrl,
    RawLinkUrl
  } = props.fields;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderTitle = () => {
    return (
      <header className='m-card__header'>
        <h6 className='m-card__title'>
          <Text field={CardTitle} />
        </h6>
      </header>
    );
  };

  const renderLink = () => {
    return (
      <div className='m-card__action'>
        <Link
          classes='a-link--decorated'
          {...props}
        />
      </div>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Root isArticle={object.hasNested(CardTitle, 'value')}>
      {((CardTitle && (CardTitle.value.length > 0)) || isEditorActive()) && renderTitle()}

      <div className='m-card__description'>
        <RichText className='s-jss-rte' field={CardDescription} />
      </div>

      {(isEditorActive() || (LinkText && (LinkText.value.length > 0) && (LinkUrl || RawLinkUrl))) && renderLink()}
    </Root>
  );
};

export default Card;
