/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { type, url as Url } from './../../utils';

/**
 * Definition
 * =============================================================================
 */
const Item = ({ text, url }) => {
  const linkClassName = 'm-language__link';

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderLink = () => {
    const hrefValue = type.isString(url) 
    ? Url.relativePath(url) 
    : url && url.hasOwnProperty('href') ? url.href : url;
    return (
      <a className={linkClassName} href={hrefValue}>
        {text}
      </a>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <li className='m-language__item'>
      {renderLink()}
    </li>
  );
};

export default Item;
