import React from 'react';
import {
  RichText,
  Text,
  isEditorActive,
  Image as RImage,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import dateUtils from '../../utils/date';
import { Link, Pill } from '../../building-blocks';
import { object } from './../../utils';
import { withTranslation } from '../../hoc/withTranslation';
import { useHistory } from 'react-router-dom';
import useLazyLoad from '../../hooks/useLazyLoad';

const renderPill = (pilltag, style) => {
  const pillTagRender =
    typeof pilltag === 'object' ? <Text field={pilltag} /> : pilltag;
  const showPilltag =
    typeof pilltag === 'object'
      ? (pilltag && pilltag.value) || isEditorActive()
      : pilltag;
  return (
    showPilltag && (
      <li class='m-pill-list__item'>
        <div class='a-pill a-pill--nocursor' style={style}>
          <span class='a-pill__text'>{pillTagRender}</span>
        </div>
      </li>
    )
  );
};

const ArticlePreview = (props) => {
  const {
    PillTag1,
    PillTag2,
    Image,
    ImageAltText,
    Date,
    ReadingTime,
    Title,
    Intro,
    AuthorPicture,
    AuthorName,
    AuthorFunction,
    LinkText,
    LinkUrl,
  } = props.fields || {};

  const {
    imageUrl,
    theme1Title,
    theme2Title,
    theme1Color,
    theme2Color,
    title,
    intro,
    authorImage,
    authorName,
    authorFunction,
    pageUrl,
    sitecoreContext,
    t,
  } = props;

  const { value: articleDate = props.date } = Date || {};
  const { value: readingTime = props.readingTime } = ReadingTime || {};

  const formattedDate = dateUtils.formatDateUsingSitecoreContextLanguage(
    sitecoreContext,
    articleDate,
    'D MMMM YYYY'
  );
  const formattedReadingTime = `${readingTime} min`;

  const history = useHistory();

  const backgroundImageUrl =
    ('scEmptyImage' !== object.getNested(Image, 'value', 'class') &&
      object.getNested(Image, 'value', 'src')) ||
    imageUrl;
  const articleBackgroundImg = backgroundImageUrl
    ? `url(${backgroundImageUrl})`
    : ``;

  const ctaComponent = ((LinkText && LinkUrl) ||
    isEditorActive() ||
    pageUrl) && (
    <Link
      classes='a-link a-link--decorated '
      fields={{
        LinkText: LinkText || t('blog.predefinedArticles.readMore'),
        LinkUrl: LinkUrl || pageUrl,
      }}
    ></Link>
  );

  const titleComponent =
    LinkUrl || pageUrl ? (
      <Link
        classes='a-link a-link--orange-hover'
        fields={{
          LinkText: Title || title,
          LinkUrl: LinkUrl || pageUrl,
        }}
      ></Link>
    ) : Title ? (
      <Text field={Title} />
    ) : (
      title
    );

  const avatarComponent = (
    <div class='a-avatar m-article-preview__avatar'>
      <div class='a-image a-avatar__image'>
        {AuthorPicture ? (
          <RImage
            class='a-image__visual'
            field={AuthorPicture}
            alt={AuthorName.value}
            title={AuthorName.value}
            mediaUrlPrefix={/^$/}
          />
        ) : (
          <img
            className='a-image__visual'
            src={authorImage}
            alt={authorName}
            title={authorName}
          ></img>
        )}
      </div>
      <div class='a-avatar__content'>
        <p class='a-avatar__title'>
          {AuthorName ? <Text field={AuthorName} /> : authorName}
        </p>
        <p class='a-avatar__description'>
          {AuthorFunction ? <Text field={AuthorFunction} /> : authorFunction}
        </p>
      </div>
    </div>
  );

  const pillsComponent = (
    <ul class='m-pill-list m-article-preview__tags'>
      {renderPill(PillTag1 || theme1Title, {
        backgroundColor: theme1Color || '#1F145D',
      })}
      {renderPill(PillTag2 || theme2Title, {
        backgroundColor: theme2Color || '#FF7900',
      })}
    </ul>
  );

  const destinationUrl = pageUrl || object.getNested(LinkUrl, 'value', 'href');
  
  useLazyLoad();

  return (
    <article className='m-article-preview '>
      <div
        className={`m-article-preview__image lazy ${
          destinationUrl && 'm-article-preview__image--pointer-cursor'
        }`}
        data-bg-multi={articleBackgroundImg}
        role='img'
        aria-label={ImageAltText}
        onClick={() => {
          destinationUrl && history.push(destinationUrl);
        }}
      >
        {pillsComponent}
      </div>
      <div className='m-article-preview__content'>
        <div className='m-article-preview__meta'>
          <time className='m-article-preview__meta-item'>{formattedDate}</time>
          <div className='a-attribute m-article-preview__meta-item a-attribute--inherited'>
            <span className='a-attribute__icon a-attribute__icon--before icon-stopwatch'></span>
            <p className='a-attribute__text'>{formattedReadingTime}</p>
          </div>
        </div>
        <h4 className='m-article-preview__title'>{titleComponent}</h4>
        <p className='m-article-preview__description'>
          {Intro ? <RichText field={Intro} /> : intro}
        </p>
        <div className='m-article-preview__footer'>
          {avatarComponent}
          {ctaComponent}
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(withSitecoreContext()(ArticlePreview));
