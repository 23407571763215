import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import Helmet from 'react-helmet';
import { object, image, url } from '../../utils';

const MetaTwitterImage = ({sitecoreContext, imageUrl}) => {
    const pageUrl = object.getNested(sitecoreContext, 'navigationData', 'pageHost');
    const absoluteImageUrl = url.isRelative(imageUrl) ? pageUrl + imageUrl : imageUrl;
    return imageUrl && (
      <Helmet>
        <meta name='twitter:image' content={image.getMetaImageUrl(absoluteImageUrl, 'twitter')} />
      </Helmet>
    );
}

export default withSitecoreContext()(MetaTwitterImage);