/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const ActionGroup = ({
  classes = '',
  children
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItems = () => {
    const items = children.length > 1
      ? children
      : [children];
    
    return items.map((i, index) => (
      <li key={`action-group-item-${index}`} className='m-action-group__item'>
       {i}
      </li>
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <ul className={`m-action-group ${classes}`}>
      {children && renderItems()}
    </ul>
  );
};

export default ActionGroup;
