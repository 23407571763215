import React, { useEffect } from 'react';
import {
  Placeholder,
  VisitorIdentification,
} from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import { object, image, url } from './utils';
import env from './env';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import MetaOgImage from './components/MetaOgImage';
import MetaTwitterImage from './components/MetaTwitterImage';

// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/app.css';
// import logo from './assets/sc_logo.png';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/
const getMetaValue = (context, route, property) => {
  var value = null;

  if (object.hasNested(route, 'fields', property, 'value')) {
    value = object.getNested(route, 'fields', property, 'value');
    if (value == '' || value == undefined) return null;
  }

  return value;
};
const getMetaImageValue = (route, property, defaultImage) => {
  const src =
    object.getNested(route, 'fields', property, 'value', 'src') || defaultImage;
  if (!src) {
    return null;
  }
  return src;
};

const pageTitle = (context, route) => {
  const titlename = titleName(context);
  var value = getMetaValue(context, route, 'pageTitle');

  if (value == '' || value == null) value = titlename;
  else value += ' | ' + titlename;

  return value;
};
const pageDescription = (context, route) => {
  var value = getMetaValue(context, route, 'pageDescription');
  return value;
};
const keywords = (context, route) => {
  var value = getMetaValue(context, route, 'keywords');
  return value;
};
const openGraphTitle = (context, route) => {
  var value = getMetaValue(context, route, 'openGraphTitle');
  return value;
};
const openGraphDescription = (context, route) => {
  var value = getMetaValue(context, route, 'openGraphDescription');
  return value;
};
const twitterTitle = (context, route) => {
  var value = getMetaValue(context, route, 'twitterTitle');
  return value;
};
const twitterDescription = (context, route) => {
  var value = getMetaValue(context, route, 'twitterDescription');
  return value;
};
const twitterImage = (route, defaultImage) => {
  var value = getMetaImageValue(route, 'twitterImage', defaultImage);

  return value;
};

const openGraphImage = (route, defaultImage) => {
  var value = getMetaImageValue(route, 'openGraphImage', defaultImage);
  return value;
};
const facebookVerificationCode = (context) => {
  return object.getNested(
    context,
    'navigationData',
    'FacebookVerificationCode'
  );
};
const siteName = (context) => {
  return object.getNested(context, 'navigationData', 'SiteName');
};
const titleName = (context) => {
  return object.getNested(context, 'navigationData', 'TitleName');
};

const Query = gql`
  query DefaultMetaImage($language: String!) {
    item(path: "/sitecore/content/cevora-app/Home", language: $language) {
      ... on AppRoute {
        openGraphImage {
          src
        }
        twitterImage {
          src
        }
      }
    }
  }
`;

const Layout = ({ route, context }) => {
  const { data } = useQuery(Query, {
    variables: { language: context.language },
  });
  
  const defaultOgImage = object.getNested(
    data,
    'item',
    'openGraphImage',
    'src'
  );

  const defaultTwitterImage = object.getNested(
    data,
    'item',
    'twitterImage',
    'src'
  );

  const openGraphImageSrc = openGraphImage(route, defaultOgImage);
  const twitterImageSrc = twitterImage(route, defaultTwitterImage);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <MetaOgImage imageUrl={openGraphImageSrc} />
      <MetaTwitterImage imageUrl={twitterImageSrc} />

      <Helmet>
        <title>{pageTitle(context, route)}</title>
        {pageDescription(context, route) != null && (
          <meta name='description' content={pageDescription(context, route)} />
        )}
        {keywords(context, route) != null && (
          <meta name='keywords' content={keywords(context, route)} />
        )}
        {openGraphTitle(context, route) != null && (
          <meta property='og:title' content={openGraphTitle(context, route)} />
        )}
        {openGraphDescription(context, route) != null && (
          <meta
            property='og:description'
            content={openGraphDescription(context, route)}
          />
        )}
        <meta property='og:type' content='website' />
        <meta name='twitter:card' content='summary' />
        {twitterTitle(context, route) != null && (
          <meta name='twitter:title' content={twitterTitle(context, route)} />
        )}
        {twitterDescription(context, route) != null && (
          <meta
            name='twitter:description'
            content={twitterDescription(context, route)}
          />
        )}

        {facebookVerificationCode(context) != null && (
          <meta
            name='facebook-domain-verification'
            content={facebookVerificationCode(context)}
          />
        )}
        <meta property='og:site_name' content={siteName(context)} />
        <meta name='twitter:site' content={siteName(context)} />
      </Helmet>
      {/*
        VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
        If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
        For XM (CMS-only) apps, this should be removed.

        VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
      */}
      <VisitorIdentification />
      <Placeholder
        name='jss-main'
        rendering={route}
        options={{ showServerError: true }}
      />
    </React.Fragment>
  );
};

export default Layout;
