import React, { useState, useEffect } from 'react';
import InputFlyout from '../InputFlyout';
import OptionList from './../OptionList';
import { Loader } from './../../building-blocks';
import { splitTerms } from '../../pages/SearchResults/transform';
import { withTranslation } from '../../hoc/withTranslation';
import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { AutocompleteMinimumLength } from '../../lib/Constants.ts';

const Query = gqlLoader('./query.graphql');

const TermWithSuggest = props => {
  const {
    t,
    lng,
    value,
    onChange,
    name,
    hideSuggestions,
    onSuggestionsHidden,
    audience
  } = props;

  const quotify = text => /,/.test(text) ? `"${text}"` : text;

  const handleSuggestionClick = text => {
    const newTerms = [...terms];
    newTerms[searchTerm.index] = quotify(text);
    setTerms(newTerms);
    onChange && onChange(name, newTerms.join(', '));
  };

  const [searchTerm, setSearchTerm] = useState(null);
  const [terms, setTerms] = useState(splitTerms(value));

  useEffect(() => {
    if (hideSuggestions) {
      setSearchTerm(null);
      onSuggestionsHidden && onSuggestionsHidden();
    }
  }, [hideSuggestions]);

  useEffect(() => {
    const newTerms = splitTerms(value);
    const updatedItems = newTerms
      .map((x, idx) => ({
        isUpdated: x !== terms[idx],
        value: x,
        index: idx
      }))
      .filter(x => x.isUpdated);

    const _searchTerm =
      updatedItems.length === 1 &&
      updatedItems[0].value.length >= AutocompleteMinimumLength
        ? updatedItems[0]
        : null;
    setSearchTerm(_searchTerm);
    setTerms(newTerms);
  }, [value]);

  const query = useQuery(Query, {
    variables: {
      term: searchTerm && searchTerm.value,
      audience: audience,
      language: lng
    },
    fetchPolicy: 'cache-and-network',
    skip: !searchTerm
  });
  const { data, error, loading } = query;

  const options =
    (searchTerm && !loading && !error && data && data.search.autocomplete) ||
    [];
  const items = options.map(x => ({
    onClick: () => {
      handleSuggestionClick(x);
    },
    text: x
  }));
  const showOptions = items.length > 0;

  return (
    <InputFlyout {...props} isOpen={showOptions}>
      {showOptions ? (
        <OptionList items={items} />
      ) : value ? (
        <Loader text={t('searchResultsSearch.loading')} />
      ) : null}
    </InputFlyout>
  );
};

export default withTranslation()(TermWithSuggest);
