import React, { useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import flatten from 'array-flatten';

import { loader as gqlLoader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { Button, Loader } from '../../building-blocks';

import InternCard from './InternCard';
import Filter from './Filter';
import { withTranslation } from '../../hoc/withTranslation';

import window from 'global/window';


const QUERY = gqlLoader('./query.graphql');

const PAGE_SIZE = 24;
const SESSION_STORAGE_KEY = 'Cevora:Filter';


const InternshipsList = ({ sitecoreContext, t, lng }) => {

  const { sessionStorage = { getItem: ()=>{}, setItem: ()=>{}}} = window;
  const sessionFilter = JSON.parse(
    sessionStorage.getItem(SESSION_STORAGE_KEY) || 'false'
  ) || { sector: {}, training: {}, region: {} };

  const { data, error, loading } = useQuery(QUERY, {
    variables: {
      pageId: sitecoreContext.itemId,
      lang: lng
    }
  });
  const [top, setTop] = useState(PAGE_SIZE);
  const [filter, setFilter] = useState(sessionFilter);

  if (error) {
    console.error('Error loading internships', error);
    return <></>;
  }

  const handleFilterChange = f => {
    setFilter(f);
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(f));
    setTop(PAGE_SIZE);
  };

  const baseUrl = data && data.page.url;
  const applications = (data && data.item && data.item.children) || [];
  applications.sort((a, b) =>
    (a.updated || a.created) < (b.updated || b.created) ? 1 : 0
  );

  const distinct = arr => [...new Set(arr)];

  const sectors = flatten(
    applications.map(x =>
      x.sectors.targetItems.map(y => ({
        text: y.title.value,
        value: y.id,
        id: y.id
      }))
    )
  ).reduce(
    (acc, cur) => (acc.find(x => x.id === cur.id) ? acc : [...acc, cur]),
    []
  );
  sectors.sort((a,b) => a < b ? 1 : -1);

  const regions = sitecoreContext.regions.map(x => x);

  const trainings = distinct(
    applications
      .filter(x => x.trainingName && x.trainingName.value)
      .map(x => x.trainingName.value)
  ).map(x => ({ text: x, value: x, id: 't' + x }));

  const filteredApplications = applications
    .filter(x => !filter.sector.value || x.sectors.targetItems.find(y => y.id === filter.sector.value))
    .filter(
      x => !filter.training.value || x.trainingName.value === filter.training.value
    )
    .filter(
      x =>
        !filter.region.value ||
        x.province.targetItems.find(y => y.code.value === filter.region.value)
    );

  const noResults = (
    <div className='a-highlight '>
      <div className='a-highlight__content'>
        <div className='a-title '>
          <h5 className='a-title__text'>{t('internships.noResults')}</h5>
        </div>
      </div>
    </div>
  );

  const hasMore = top < filteredApplications.length;
  const handleMoreClick = () => {
    setTop(top + PAGE_SIZE);
  };

  const filteredUrls = filteredApplications.map(x => x.url);

  return (
    <>
      <div className='o-interns-filter'>
        <Filter
          filter={filter}
          regions={regions}
          sectors={sectors}
          trainings={trainings}
          onChange={handleFilterChange}
        />
      </div>
      {loading && <Loader />}
      {filteredApplications.length > 0 ? (
        <ul className='o-intern-card-group__list l-grid l-grid--with-margins'>
          {filteredApplications.slice(0, top).map((x, idx) => (
            <InternCard key={x.id} data={x} index={idx} list={filteredUrls} baseUrl={baseUrl}/>
          ))}
        </ul>
      ) : (
        noResults
      )}
      {hasMore && (
        <div className='o-intern-card-group__action'>
          <Button
            type='button'
            classes='a-button--outline'
            onClick={handleMoreClick}
            fields={{ ButtonText: t('internships.showMore') }}
          />
        </div>
      )}
    </>
  );
};

export default withTranslation()(withSitecoreContext()(InternshipsList));
