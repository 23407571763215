/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Root from './../components/Row';
import Col from './../components/Col';

/**
 * Definition
 * =============================================================================
 */
const Row = ({
  classes = '',
  data = [],
  id:tableId,
  index,
}) => {

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderCols = () => {
    return data.map((d, index) => (
      <Col key={`table-body-col-${index}`} {...d} />
    ));
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <Root
      index={index}
      tableId={tableId}
      classes={classes}
      data={data}
    >
      {renderCols()}
    </Root>
  );
};

export default Row;
