/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import InfoFiche from './../../pages/Infofiche';
import { Format, Level } from './TimeInDaysFormatter';
import { loader as gqlLoader } from 'graphql.macro';
import { WildcardUrlPattern } from '../../lib/Constants.ts';
import { withTranslation } from '../../hoc/withTranslation';
import moment from 'moment';
import { object } from '../../utils';
import withHandleNotFound from './withHandleNotFound';
import { useQuery } from '@apollo/react-hooks';
import { extractDates } from '../../pages/SearchResults/transform';
import { extractDatesIncludingHoursReact } from '../../pages/SearchResults/transform';

const Query = gqlLoader('./query.graphql');

const mapRoute = paths =>
  paths.length > 0
    ? paths.map(x => ({
      description: x.fields.Title.value,
      meta: x.fields.Subtitle.value,
      url: x.fields.LinkUrl.value.href
    }))[0]
    : [];

const makeUrl = (url, contextItem, language) => {
  const pageName = url.split('/').pop();
  return `/${language}${contextItem.url.replace(WildcardUrlPattern, pageName)}`;
};

const getCityName = (locations, languageCode) => {
  var loc =
    locations &&
    (locations.find(x => x.language === languageCode) ||
      locations.find(x => x !== undefined));
  return loc && loc.cityName;
};

const getDaysLeftToSubscribe = lastDayForRegistration => {
  try {
    if (lastDayForRegistration) {
      let lastDayMoment = moment.utc(lastDayForRegistration);
      let lastDayForRegistrationDateTime = new Date(
        lastDayMoment.year(),
        lastDayMoment.month(),
        lastDayMoment.date(),
        lastDayMoment.hours(),
        lastDayMoment.minutes(),
        lastDayMoment.seconds()
      );
      let timeDifference =
        lastDayForRegistrationDateTime.getTime() - new Date().getTime();
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }
  } catch (err) {
    console.error(
      'Error calculating getDaysLeftToSubscribe',
      err,
      lastDayForRegistration
    );
  }
};

const mapSessions = (sessions, languageCode, t) => {
  var items = sessions.map(x => {
    const sequences = x.sequences;
    if (sequences) {
      sequences.sort((a, b) =>
        a.startDateTime < b.startDateTime
          ? -1
          : a.startDateTime > b.startDateTime
            ? 1
            : 0
      );
    }
    return {
      location:
        sequences &&
        sequences.length > 0 &&
        getCityName(sequences[0].locationCity, languageCode),
      region: sequences && sequences.length > 0 && sequences[0].locationRegion,
      remainingDaysForRegistration: getDaysLeftToSubscribe(x.lastDayForRegistration),
      language: x.language,
      groupId: x.groupId,
      startDate: x.startDateTime,
      endDate: x.endDateTime,
      datesWithHours: extractDatesIncludingHoursReact(sequences),
      dates: extractDates(sequences, t, true),
      dataHubId: x.dataHubId,
      coordinatorPhone: x.coordinator.telephone,
      coordinatorEmail: x.coordinator.email,
      registerUrl: null,
      courseOldReference: x.courseOldReference,
      cdb2CourseId: x.cdb2CourseId,
      extraInformation: x.extraInformation,
      placesLeft:
        x.registrationMax !== null &&
        x.registrationMax !== undefined &&
        x.registrationCount != null &&
        x.registrationCount != undefined &&
        x.registrationMax - x.registrationCount,
      modules:
        sequences &&
        sequences.map(z => ({ start: z.startDateTime, end: z.endDateTime, icon: z.trainingFormTypeIcon }))
    };
  });

  return items;
};

const sessionSort = (a, b) =>
  a.startDate < b.startDate ? -1 : a.startDate > b.startDate ? 1 : 0;
/**
 * Definition
 * =============================================================================
 */
const Connected = props => {
  const { sitecoreContext, fields, t, lng } = props;

  const variables = {
    datasource: props.rendering && props.rendering.dataSource,
    contextItem: props.sitecoreContext && props.sitecoreContext.itemId,
    language: props.sitecoreContext && props.sitecoreContext.language,
    trainingSettingsPath: 'sitecore/Content/cevora-app/Settings/Trainings'
  };

  const graphQLResult = useQuery(Query, {
    variables: variables,
    fetchPolicy: 'cache-and-network'
  });
  const { error, data: queryData } = graphQLResult || {};
  const { datasource, contextItem, trainingSettings } = queryData || {};
  const onlineCourseCtaFields = trainingSettings && trainingSettings.onlineCourseCta && trainingSettings.onlineCourseCta.targetItem;

  if (error) {
    console.log('Error executing InfoFiche graphql', error);
  }

  const sessionCardsToShow = 5;

  const alternativeLanguages =
    datasource &&
    contextItem &&
    datasource.alternativeLanguageVersion.targetItems.map(x => ({
      language: x.language.value,
      url: makeUrl(x.url, contextItem, lng),
      sessions: x.sessions
    }));

  const languageCode =
    (fields && fields.TrainingLang.value) || sitecoreContext.language;
  moment.locale(sitecoreContext.language.toLowerCase());
  const ownSessions =
    datasource &&
    datasource.sessions &&
    mapSessions(datasource.sessions, languageCode, t);

  const allSessions =
    alternativeLanguages &&
    ownSessions &&
    [...ownSessions].concat(
      ...alternativeLanguages.map(x => mapSessions(x.sessions, x.language, t))
    );

  if (allSessions) {
    allSessions.sort(sessionSort);
  }

  const ownSessionsObject = ownSessions && {
    sessionsSeen:
      ownSessions.length > sessionCardsToShow ? sessionCardsToShow : undefined,
    items: ownSessions.slice(0, sessionCardsToShow)
  };

  const trainingForm = object.getNested(fields, 'TrainingForm');
  const trainingFormFields = object.getNested(fields, 'TrainingForm', 'fields');
  const isOnline = trainingForm
    && ((trainingForm.url && trainingForm.url.toLowerCase().includes('online'))
    || !!(trainingFormFields.SearchResultIcon && trainingFormFields.SearchResultIcon.find(x => x.name && x.name === 'Computer'))
    || !!(trainingFormFields.Webinar && trainingFormFields.Webinar.value));

  const data = {
    sessionTable: allSessions,
    sessionsCard: ownSessionsObject,
    heroImage: datasource && datasource.resolvedHeroImage,
    sessions: ownSessionsObject,
    fields: { ...fields },
    trainingAudienceType: object.getNested(
      fields,
      'TrainingType',
      'fields',
      'Code',
      'value'
    ),
    trainingForm: object.getNested(
      fields,
      'TrainingForm',
      'fields',
      'Title',
      'value'
    ),
    trainingFormVirtual: object.getNested(
      fields,
      'TrainingForm',
      'fields',
      'Virtual',
      'value'
    ),
    duration:
      fields &&
      Format(
        fields.DurationInDays.value,
        sitecoreContext.language,
        Level.months
      ),
    internshipDuration:
      fields &&
      Format(
        fields.InternshipDurationInDays.value,
        sitecoreContext.language,
        Level.weeks
      ),
    referenceOld: object.getNested(fields, 'ReferenceOld', 'value'),
    route: fields && mapRoute(fields.EducationalPath),
    languages: fields && [
      { language: languageCode },
      ...(alternativeLanguages || [])
    ],
    onlineCourseCtaFields: onlineCourseCtaFields,
    isOnline: isOnline
  };

  return <InfoFiche {...props} data={data} graphQLClient={graphQLResult.client} />;
};

export default withTranslation()(
  withHandleNotFound()(Connected)
);
