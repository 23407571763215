/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Item from './Item';

/**
 * Definition
 * =============================================================================
 */
const DisclosureList = (props) => {
  const {
    children,
    classes
  } = props;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItem = (component, index) => {
    return (
      <Item key={`disclosure-list-item-${index}`}>
        {component}
      </Item>
    );
  };

  const renderItems = () => {
    return (
      <Placeholder
        {...props}
        name='disclosure-list-items'
        renderEach={renderItem}
      />
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <ul className={`m-disclosure-list ${classes}`}>
      {children ? children : renderItems()}
    </ul>
  );
};

export default DisclosureList;
