import * as React from 'react';

const BasketContext = React.createContext({
  items: [],
  isLoading: false,
  persona: null,
  hasMultipleCourseTypes: false,
  graphQLClient: {},
  onPersonaSet: (persona, disableLoadingSpinner) => { },
  onCheckOut: (courseType) => { },
  onAllItemsDeleted: () => { },
  onItemAdded: (id) => { },
  onItemDeleted: (id) => { },
  sendAddToCardGtm: (id) => { }
});

export default BasketContext;