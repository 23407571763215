import {
  Form,
  FieldTypes,
  createDefaultFieldFactory,
} from '@sitecore-jss/sitecore-jss-react-forms';
import Formsy from 'formsy-react';
import env from '../../env';
import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import window from 'global/window';

import { withTranslation } from '../../hoc/withTranslation';
import { submitForm } from '../../lib/Gtm';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import {
  Button,
  Checkbox,
  ButtonCheckboxList,
  DateField,
  DropdownList,
  Email,
  FileUpload,
  MultipleLineText,
  NumberField,
  Password,
  RadioButtonListSelector,
  Section,
  SingleLineText,
  Telephone,
  TextField,
  CheckboxListSelector,
  Turnstile,
  ProgressBar
} from './Fields';
import FormConditionsContext from '../../contexts/FormConditionsContext';
import { conditionsData } from './conditionalRendering/utils';
import { dependentFieldsData } from './conditionalRendering/utils';
import FormDependentFieldsContext from '../../contexts/FormDependentFieldsContext';
import FormNewsletterDataContext from '../../contexts/FormNewsletterDataContext';
const defaultFieldFactory = createDefaultFieldFactory();

const TurnstileFieldType = "{12858717-2777-4C86-9330-3B56D7B945B7}";
const ProgressBarFieldType = "{3B475F95-60F1-4993-AB1C-3E4A3AEAA4B9}";
const DependentRadioButtonListType = "{CA8C1FD0-4A56-40C1-939A-28EA7409ED03}";

defaultFieldFactory.setComponent(FieldTypes.SingleLineText, SingleLineText);
defaultFieldFactory.setComponent(FieldTypes.TextField, TextField);
defaultFieldFactory.setComponent(FieldTypes.DateField, DateField);
defaultFieldFactory.setComponent(FieldTypes.Telephone, Telephone);
defaultFieldFactory.setComponent(FieldTypes.Email, Email);
defaultFieldFactory.setComponent(FieldTypes.NumberField, NumberField);
defaultFieldFactory.setComponent(FieldTypes.Password, Password);
defaultFieldFactory.setComponent(FieldTypes.FileUpload, FileUpload);
defaultFieldFactory.setComponent(FieldTypes.MultipleLineText, MultipleLineText);
defaultFieldFactory.setComponent(FieldTypes.RadioButtonList, RadioButtonListSelector);
defaultFieldFactory.setComponent(FieldTypes.CheckboxList, CheckboxListSelector);
defaultFieldFactory.setComponent(FieldTypes.Checkbox, Checkbox);
defaultFieldFactory.setComponent(FieldTypes.DropdownList, DropdownList);
defaultFieldFactory.setComponent(FieldTypes.Section, Section);
defaultFieldFactory.setComponent(FieldTypes.Button, Button);
defaultFieldFactory.setComponent(FieldTypes.ListBox, ButtonCheckboxList);
defaultFieldFactory.setComponent(TurnstileFieldType, Turnstile);
defaultFieldFactory.setComponent(ProgressBarFieldType, ProgressBar);
defaultFieldFactory.setComponent(DependentRadioButtonListType, RadioButtonListSelector);

const FieldErrorComponent = (props) => <></>;

let formWrapperElement;

const FormWrapperComponent = (props) => {
  formWrapperElement = useRef(null);
  return (<div ref={formWrapperElement} {...props}></div>);
}

const JssReactForm = (props) => {
  const { fields, history, lng } = props;
  const handleRedirect = (url) => {
    submitForm(fields.metadata.name, lng);
    window.scrollTo(0, 0);
    history.push(url);
  };

  const [formData, setFormData] = useState(null);

  const [context, setContext] = useState({
    conditionFields: conditionsData.getConditionFields(fields),
    conditions: conditionsData.getConditions(fields),
    fieldValues: conditionsData.getFieldValues(fields),
    extendFormConditions: (conditionFields, conditions, fieldValues) => extendContextConditionValues(conditionFields, conditions, fieldValues),
    updateFieldValue: (key, value) => updateContextFieldValue(key, value)
  });

  const extendContextConditionValues = (conditionFields = [], conditions = {}, fieldValues = {}) => {
    setContext(currentContext => {
      const contextUpdates = { ...currentContext };

      conditionFields.forEach(conditionField => {
        if (contextUpdates.conditionFields.indexOf(conditionField) < 0)
          contextUpdates.conditionFields.push(conditionField);
      });

      Object.keys(conditions).forEach(conditionKey => {
        if (typeof contextUpdates.conditions[conditionKey] === 'undefined') {
          contextUpdates.conditions[conditionKey] = conditions[conditionKey];
        } else {
          let conditionDetails = conditions[conditionKey];
          conditionDetails.forEach(item => {
            let isPresentInArray = false;
            contextUpdates.conditions[conditionKey].forEach(conditionItem => {
              if (JSON.stringify(conditionItem) == JSON.stringify(item)) {
                isPresentInArray = true;
              }
            });
            if (!isPresentInArray)
              contextUpdates.conditions[conditionKey].push(item);
          });
        }
      });
      Object.keys(fieldValues).forEach(fieldKey => {
        if (Object.keys(contextUpdates.fieldValues).indexOf(fieldKey) < 0) {
          contextUpdates.fieldValues[fieldKey] = fieldValues[fieldKey];
        }
      });
      return (contextUpdates);
    });
  }

  const updateContextFieldValue = (key, value) => {
    setContext(currentContext => {
      const contextUpdates = { ...currentContext };
      contextUpdates.fieldValues[key] = value;
      return (contextUpdates);
    });
  }

  const [dependentFieldsContext, setDependentFieldsContext] = useState({
    dependentFields: dependentFieldsData.getDependentFields(fields),
    fieldValues: dependentFieldsData.getAllFieldValues(fields),
    extendDependentFields: (dependentFields, fieldValues) => extendDependentFieldsValues(dependentFields, fieldValues),
    updateFieldValue: (key, value) => updateDependentFieldsContextValue(key, value)
  });

  const [newsletterDataContext, setNewsletterDataContext] = useState();

  const getAutoMappedContactNewsletterData = async () => {
    const data = await (
      await fetch(
        env.REACT_APP_JSS_API_HOST +
        '/api/cevora/session/write/NewsletterFormsApi/AutomaticallyMappedContact'
      )
    ).json();

    setNewsletterDataContext({ newsletterFormData: data, getNewsletterFormDataByEmail: (email) => fetchNewsletterDataByEmail(email) });
  }
  
  const fetchNewsletterDataByEmail = async (email) => {
    const data = await (
      await fetch(
        env.REACT_APP_JSS_API_HOST +
        '/api/cevora/session/write/NewsletterFormsApi/Contact?' + new URLSearchParams({
          emailAddress: email
        })
      )
    ).json();
    setNewsletterDataContext({ newsletterFormData: data, getNewsletterFormDataByEmail: (email) => fetchNewsletterDataByEmail(email) });
  }

  const updateDependentFieldsContextValue = (key, value) => {
    setDependentFieldsContext(currentContext => {
      const contextUpdates = { ...currentContext };
      contextUpdates.fieldValues[key] = value;
      return (contextUpdates);
    });
  }

  const extendDependentFieldsValues = (dependentFields = [], fieldValues = {}) => {
    setDependentFieldsContext(currentContext => {
      const contextUpdates = { ...currentContext };

      dependentFields.forEach(dependentField => {
        if (contextUpdates.dependentFields.indexOf(dependentField) < 0)
          contextUpdates.dependentFields.push(dependentField);
      });

      Object.keys(fieldValues).forEach(fieldKey => {
        let isPresent = false;
        Object.keys(contextUpdates.fieldValues).forEach(key => {
          if (key == fieldKey) {
            isPresent = true;
          }
        });
        if (!isPresent) {
          contextUpdates.fieldValues[fieldKey] = fieldValues[fieldKey];
        }
      });
      return (contextUpdates);
    });
  }

  useEffect(() => {
    if (formData != null && formWrapperElement != null) {
      scrollFormIfNeeded(formData);
    }
  }, [formData, formWrapperElement]);

  useEffect(() => {
    getAutoMappedContactNewsletterData();
  }, []);

  const scrollFormIfNeeded = (formData) => {
    setTimeout(() => {
      const validationErrors = Object.keys(formData.validationErrors);
      let scrollToFormTop = true;
      if (validationErrors.length) {

        let validationErrorElements = [];
        for (let el of validationErrors) {
          if (document.getElementsByName(el)[0])
            validationErrorElements.push(document.getElementsByName(el)[0]);
        }

        if (validationErrorElements.length) {
          let firstValidationErrorElement = validationErrorElements[0];
          validationErrorElements.forEach(el =>
            firstValidationErrorElement = (el.compareDocumentPosition(firstValidationErrorElement) & Node.DOCUMENT_POSITION_FOLLOWING) ? el : firstValidationErrorElement);

          if (firstValidationErrorElement) {
            firstValidationErrorElement.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'start'
            });
            scrollToFormTop = false;
          }
        }
      }

      if (scrollToFormTop && formData.nextForm && typeof window.scrollTo === 'function') {
        if (formWrapperElement !== undefined && formWrapperElement.current != null) {
          formWrapperElement.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          });
        } else {
          window.scrollTo(0, 0);
        }
      }
    }, 0);
  }

  const fetcher = async (formData, endpoint) => {
    let res;
    try {
      res = await fetch(endpoint, Object.assign({
        body: formData.toMultipartFormData(), method: 'post',
        // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
        credentials: 'include'
      }));
      const data = await res.json();
      if (data.nextForm) {
        context.extendFormConditions(
          conditionsData.getConditionFields(data.nextForm.fields),
          conditionsData.getConditions(data.nextForm.fields),
          conditionsData.getFieldValues(data.nextForm.fields)
        );
        dependentFieldsContext.extendDependentFields(
          dependentFieldsData.getDependentFields(data.nextForm.fields),
          dependentFieldsData.getAllFieldValues(data.nextForm.fields)
        );
      }
      setFormData(data);
      return data;
    } catch (e) {
      console.log('Error fetching form', e);
      return res;
    }
  }

  return (
    <FormConditionsContext.Provider value={context}>
      <FormDependentFieldsContext.Provider value={dependentFieldsContext}>
        <FormNewsletterDataContext.Provider value={newsletterDataContext}>
          <Formsy formElement={FormWrapperComponent}>
            <Form
              form={fields}
              fieldFactory={defaultFieldFactory}
              errorComponent={FieldErrorComponent}
              sitecoreApiHost={sitecoreApiHost()}
              sitecoreApiKey={sitecoreApiKey()}
              onRedirect={handleRedirect}
              className={`l-form s-jss-rte ${fields.metadata.cssClass}`}
              formFetcher={fetcher} />
          </Formsy>
        </FormNewsletterDataContext.Provider>
      </FormDependentFieldsContext.Provider>
    </FormConditionsContext.Provider>
  );
};

export default withTranslation()(withRouter(JssReactForm));