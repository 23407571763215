import React from 'react';
import { Link } from 'react-router-dom';

const ReactLinkWithRoundIcon = ({className, text, iconBefore, iconAfter, to}) => {
    return (
      <span className={`a-nav-link ${className}`}>
        <Link className='a-link a-link--decorated' to={to}>
          {iconBefore && (
            <span className={`icon-${iconBefore} a-icon`}></span>
          )}
          <span className='a-link__text'>{text}</span>
          {iconAfter && (
            <span className={`icon-${iconAfter} a-icon`}></span>
          )}
        </Link>
      </span>
    );
}

export default ReactLinkWithRoundIcon;