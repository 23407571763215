/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { type, object, url } from '../../utils';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import { Link as LinkJSS, Text } from '@sitecore-jss/sitecore-jss-react';
import { Link as RLink } from 'react-router-dom';
/**
 * Definition
 * =============================================================================
 */
const Link = ({
  fields = {},
  classes = '',
  onClick,
  handleModalTriggerClick
}) => {
  const { LinkText, LinkUrl, LinkIcon, RawLinkUrl } = fields;

  classes = `a-link ${classes}`;

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  const handleClick = e => {

    if (handleModalTriggerClick) handleModalTriggerClick(e);
    if (onClick) onClick(e);
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */

  var linkIcon = object.getNested(LinkIcon, 'fields', 'Value', 'value') || LinkIcon;
  linkIcon =
    typeof (linkIcon) === 'string' && linkIcon && !linkIcon.startsWith('icon-')
      ? 'icon-' + linkIcon
      : linkIcon;
  const renderContent = () => {
    return (
      <React.Fragment>
        {LinkIcon && <span className={`a-link__icon ${linkIcon}`}></span>}

        {LinkText && (
          <span className='a-link__text'>
            {(type.isReact(LinkText) && LinkText) ||
              ((LinkText || isEditorActive()) && (
                <Text field={LinkText} />
              ))}
          </span>
        )}
      </React.Fragment>
    );
  };
  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    (!LinkUrl && !RawLinkUrl && (
      <a className={classes} onClick={handleClick}>
        {renderContent()}
      </a>
    ))
    ||
    (LinkUrl && type.isString(LinkUrl) && url.isRelative(LinkUrl) && (
      <RLink className={classes} to={LinkUrl} onClick={handleClick}>
        {renderContent()}
      </RLink>
    ))
    ||
    (LinkUrl && type.isString(LinkUrl) && !url.isRelative(LinkUrl) && (
      <a className={classes} href={LinkUrl} onClick={handleClick} rel="noopener noreferrer">
        {renderContent()}
      </a>
    ))
    ||
    ((!LinkUrl || !object.getNested(LinkUrl, "value", "href")) && RawLinkUrl && (
      <a className={classes} href={RawLinkUrl.value} onClick={handleClick}>
        {renderContent()}
      </a>
    ))
    ||
    ((LinkUrl || isEditorActive()) && (
      <LinkJSS
        className={classes}
        field={LinkUrl}
        showLinkTextWithChildrenPresent={false}
        editable={false}
      >
        {renderContent()}
      </LinkJSS>
    ))
  );
};

export default Link;
