/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Events, Body } from '../../managers';

/**
 * Definition
 * =============================================================================
 */
class Overlay extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen
    };
  }

  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidMount() {
    const { id } = this.props;

    Events.emitter.on(`overlay-trigger-close:${id}`, this.handleClose);
    Events.emitter.on(`overlay-trigger-open:${id}`, this.handleOpen);
    Events.emitter.on(`overlay-trigger-toggle:${id}`, this.handleToggle);
  }

  componentWillUnmount() {
    const { id } = this.props;

    Events.emitter.removeListener(`overlay-trigger-close:${id}`, this.handleClose);
    Events.emitter.removeListener(`overlay-trigger-open:${id}`, this.handleOpen);
    Events.emitter.removeListener(`overlay-trigger-toggle:${id}`, this.handleToggle);
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  getOpenState() {
    const { isOpen } = this.state;

    return isOpen ? 'is-open' : '';
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleToggle = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen = () => {
    const { isOpen } = this.state;

    if (isOpen) return;

    this.setState({ isOpen: true }, () => {
      Body.handleAddClass('has-overlay-open');
    });
  }

  handleClose = () => {
    const { isOpen } = this.state;

    if (!isOpen) return;

    this.setState({ isOpen: false }, () => {
      Body.handleRemoveClass('has-overlay-open');
    });
  }

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes = '',
      children,
      suffix,
      close = {},
    } = this.props;

    const {
      component:CloseButton,
      props:CloseProps
    } = close;

    return (
      <div className={`l-overlay${suffix} ${classes} ${this.getOpenState()}`}>
        <div className={`l-overlay__container${suffix}`}>
          {children}
        </div>

        {close && CloseButton && (
          <div className='l-overlay__close@below-desktop-base u-desktop-hide'>
            <div className='l-container'>
              <CloseButton
                onClick={this.handleClose}
                {...CloseProps}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Overlay;
