/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Item from './Item';
import MobileList from './MobileList';
import { object } from '../../utils';

/**
 * Definition
 * =============================================================================
 */
const CardGroup = (props) => {
  const {
    CardGroupItemsColumns,
    CardGroupChangeOnMobile,
    CardGroupCenterCards
  } = props.fields;
  
 /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const setMobileState = () => {
  
    return CardGroupChangeOnMobile && object.getNested(CardGroupChangeOnMobile,'value')? 'u-desktop-show' : '';
  };
  const setMobileListState = () => {
  
    return CardGroupChangeOnMobile && object.getNested(CardGroupChangeOnMobile,'value')? 'u-desktop-hide u-tablet-hide u-mobile-hide' : 'u-desktop-hide u-tablet-hide u-mobile-hide';
  };

  const CenterCardsClass = () => {
  
    return CardGroupCenterCards && object.getNested(CardGroupCenterCards,'value')? 'o-card-group__list--align-center':'';
  };
  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItem = (component, index) => {
    return (
      <Item
        key={`card-group-item-${index}`}
        column={CardGroupItemsColumns}
        
      >
        {component}
      </Item>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
 
  return (
    <div className='o-card-group'>
      <ul className={`o-card-group__list l-grid l-grid--with-margins ${setMobileState()} ${CenterCardsClass()}`}>
        <Placeholder
          {...props}
          name='card-group-items'
          renderEach={renderItem}
        />
      </ul>

      {CardGroupChangeOnMobile &&  object.getNested(CardGroupChangeOnMobile,'value') && (
        <MobileList {...props} classes={setMobileListState()}/>
      )}
    </div>
  );
};

export default CardGroup;
