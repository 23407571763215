/**
 * Imports
 * =============================================================================
 */
import React from 'react';

/**
 * Definition
 * =============================================================================
 */
const Label = ({
  classes = '',
  text,
  name,
  children
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className={`a-formfield__header ${classes}`}>
      <label className='a-formfield__label' htmlFor={name}>
        {text}
      </label>

      {children}
    </div>
  );
};

export default Label;
