import IFilterItem from "../IFilterItem";
import { extractActiveFilterItems } from "../TransformData";

const parseFilter = (filterData: any, t: any): any[] => {
  if (!filterData) {
    return [];
  }
  let result = [];
  let keys = Object.keys(filterData);

  for (let i = 0; i < keys.length; i++) {
    const k = keys[i];

    if (!Array.isArray(filterData[k])) {
      continue;
    }
    const flatActiveFilterItems = <IFilterItem[]>extractActiveFilterItems(filterData[k]);

    if (flatActiveFilterItems.length > 0) {
      result.push({
        text: `${t(`search.pills.label.${[k]}`)}: ${getPillsText(flatActiveFilterItems)}`,
        key: k
      });
    }
  }
  return result;
};

const getPillsText = (obj: IFilterItem[] | IFilterItem): string => {
  if (Array.isArray(obj)) {
    return obj
      .reduce((acc, cur) => acc + ", " + getPillsText(cur), "")
      .substring(2);
  } else {
    return obj.text;
  }
};

export default parseFilter;
