/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import {object } from '../../utils';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';


/**
 * Definition
 * =============================================================================
 */
const ContainerEnvelope = (props) => {
  const { rendering,sitecoreContext } = props;
  
  var backgroundClass = object.getNested(sitecoreContext, 'route', 'fields', 'ShowBottomBackground','value')?'l-background--illustrative-bottom@above-tablet l-full-height__container':'';
  return (
    <main className={`s-jss-environment ${backgroundClass}`}>
      <Placeholder name='app-main' rendering={rendering} />
    </main>
  )
};


export default withSitecoreContext()(ContainerEnvelope);
