/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Item from './Item';

/**
 * Definition
 * =============================================================================
 */
const Progress = (props) => {
  const { NrOfColumns } = props.fields;

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderItem = (component, index) => {
    return (
      <Item
        key={`progress-items-${index}`}
        column={NrOfColumns}
      >
        {component}
      </Item>
    );
  };

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div className='o-progress'>
      <ul className='o-progress__list l-grid l-grid--with-margins@above-tablet-base'>

        <Placeholder
          {...props}
          name='progress-items'
          renderEach={renderItem}
        />

      </ul>
    </div>
  );
};

export default Progress;
