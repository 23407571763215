/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import SearchResults from '../../pages/SearchResults';

/**
 * Definition
 * =============================================================================
 */
const Connected = () => <SearchResults /> 

export default Connected;
