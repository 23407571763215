/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import {
  Text,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { object, classes } from './../../utils';
import { Button, Link, Tag } from './../../building-blocks';
import RichText from '../../building-blocks/RichTextEx';
import Image from '../Image';
import useLazyLoad from '../../hooks/useLazyLoad';

/**
 * Definition
 * =============================================================================
 */
const Cta = (props) => {
  const {
    CtaTitle,
    CtaSubtitle,
    CtaDescription,
    CtaTheme,
    CtaBackground,
    ButtonText,
    ButtonUrl,
    LinkText,
    LinkUrl,
    CtaTagText,
    CtaMiniImageUrl,
    EnableBackgroundOverlay,
    QuoteAuthor,
    QuoteTitle
  } = props.fields;

  const enableBgOverlay = object.getNested(EnableBackgroundOverlay, 'value');
  const hasBackground =
    object.hasNested(CtaBackground, 'value', 'src') &&
    'scEmptyImage' !== object.getNested(CtaBackground, 'value', 'class');

  const hasQuoteTheme = () => {
    if (hasBackground) {
      return false;
    }

    const theme = object.getNested(CtaTheme, 'fields', 'Value', 'value');

    if (!theme) return false;
    if (theme === 'quote') return true;

    return false;
  };

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  const getModifiers = () => {
    let modifiers = '';

    if (hasBackground) {
      modifiers += 'm-cta--with-background';
    }

    return modifiers;
  };

  const getThemeClasses = () => {
    if (hasBackground) {
      return '';
    }

    const theme = object.getNested(CtaTheme, 'fields', 'Value', 'value');

    if (!theme) return '';

    return classes.compare(theme, {
      blue: 'm-cta--secondary-blue',
      yellow: 'm-cta--secondary-yellow',
      purple: 'm-cta--secondary-purple',
      quote: 'm-cta--secondary-quote',
    });
  };

  const getClasses = () => {
    const theme = getThemeClasses();
    const modifiers = getModifiers();

    return `${theme} ${modifiers}`;
  };

  const getBackgroundImage = () => {
    const image = object.getNested(CtaBackground, 'value', 'src');
    return hasBackground
      ? enableBgOverlay
        ? `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%),linear-gradient(129.73deg, rgba(255,121,0,0) 0%, rgba(172,152,219,0.6) 100%),url('${image}')`
        : `url(${image})`
      : ``;
  };

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  const renderDescription = () => {
    return (
      <div className='m-cta__description'>
        <RichText
          className={`s-jss-rte s-jss-rte-cta ${hasBackground ? 's-jss-rte-cta--white' : ''}`}
          field={CtaDescription}
        />
      </div>
    );
  };

  const renderButton = () => {
    return (
      <Button
        classes={
          object.getNested(CtaTheme, 'fields', 'Value', 'value') &&
            canAuthorBeRendered ? '' : 'a-button--secondary'
        }
        {...props}
      />
    );
  };

  const renderLink = () => {
    return (
      <Link
        classes={`m-cta__link ${hasBackground ? 'm-cta__link--white' : ''}`}
        {...props}
      />
    );
  };

  const canFieldBeRendered = (field) =>
    (field &&
      object.hasNested(field, 'value') &&
      object.getNested(field, 'value')) ||
    isEditorActive();

  const canAuthorBeRendered = hasQuoteTheme() && (canFieldBeRendered(QuoteAuthor) || canFieldBeRendered(QuoteTitle));

  const hasStringOrFieldValue = (field) =>
    (typeof field === 'string' && field) ||
    (typeof field === 'object' && field.value);

  const image2 = object.getNested(CtaBackground, 'value', 'src');
  
  useLazyLoad();

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <article className={`m-cta lazy ${getClasses()}`} data-bg-multi={getBackgroundImage()}>
      {object.getNested(CtaTagText, 'value') && (
        <div className='m-cta__tag-container'>
          <Tag
            text={object.getNested(CtaTagText, 'value')}
            classes='m-cta__tag'
          />
        </div>
      )}
      {(canFieldBeRendered(CtaTitle) || canFieldBeRendered(CtaSubtitle)) && (
        <header className='m-cta__header'>
          {canFieldBeRendered(CtaSubtitle) && (
            <small className='m-cta__sub'>
              <Text field={CtaSubtitle} />
            </small>
          )}

          {canFieldBeRendered(CtaTitle) && (
            <h4 className='m-cta__title'>
              <Text field={CtaTitle} />
            </h4>
          )}
        </header>
      )}

      {object.getNested(CtaMiniImageUrl, 'value', 'src') && (
        <div className='m-cta__image-container'>
          <Image
            classes='m-cta__image'
            EstimatedSize='1'
            NotLazy={true}
            ImageUrl={
              object.getNested(CtaMiniImageUrl, 'value', 'src').split('?')[0]
            }
            Title={object.getNested(CtaMiniImageUrl, 'value', 'alt')}
          />
        </div>
      )}

      {canFieldBeRendered(CtaDescription) && renderDescription()}

      {canAuthorBeRendered && (
        <div className='m-cta__quoteauthor'>
          {canFieldBeRendered(QuoteAuthor) && (
            <>
              <Text field={QuoteAuthor} />
              {canFieldBeRendered(QuoteTitle) &&
                !/[, ]+$/.test(object.getNested(QuoteAuthor, 'value')) &&
                ','}
            </>
          )}
          {' '}
          {canFieldBeRendered(QuoteTitle) && (
            <span className='m-cta__quotetitle'>
              <Text field={QuoteTitle} />
            </span>
          )}
        </div>
      )}

      <div className='m-cta__actions'>
        {(hasStringOrFieldValue(ButtonText) ||
          isEditorActive()) &&
          renderButton()}

        {(hasStringOrFieldValue(LinkText) ||
          isEditorActive()) &&
          renderLink()}
      </div>
    </article>
  );
};

export default Cta;
