/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withTranslation } from '../../hoc/withTranslation';
import useLazyLoad from '../../hooks/useLazyLoad';
/**
 * Definition
 * =============================================================================
 */
const Item = ({ item, t }) => {

  const setBackgroundImage = (imageUrl) => {
    return imageUrl ? `url(${imageUrl})` : ``;
  };
  useLazyLoad();

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <article class='m-article-teaser l-grid__col u-padding-none u-margin-none u-margin-bottom-2x'>
      <div class='m-article-teaser__container'>
        <div
          class='m-article-teaser__image lazy'
          data-bg-multi={setBackgroundImage(item.imageUrl)}
        ></div>
        <div class='m-article-teaser__content'>
          <h4 class='m-article-teaser__title'>{item.title}</h4>
          <a
            class='a-link m-article-teaser__link a-link--decorated '
            href={item.pageUrl}
          >
            <span class='a-link__text'>{t('relatedArticles.readMore')}</span>
          </a>
        </div>
      </div>
    </article>
  );
};

export default withTranslation()(Item);
