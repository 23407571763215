/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formsy from 'formsy-react';
import Input from './../../building-blocks/Input';
import { withTranslation } from '../../hoc/withTranslation';

/**
 * Definition
 * =============================================================================
 */

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.disableButton = this.disableButton.bind(this);
    this.enableButton = this.enableButton.bind(this);
    this.state = { canSubmit: false };
  }

  disableButton() {
    this.setState({ canSubmit: false });
  }

  enableButton() {
   
    this.setState({ canSubmit: true });
  }

  submit(model) {
    fetch('http://example.com/', {
      method: 'post',
      body: JSON.stringify(model),
    });
  }

  render() {
    return (
      <Formsy onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
        <Input name="email" validations="isEmail" validationError="Dit is geen geldig em-mailadres" required 
        button={(<input type="submit" className="a-button" value="Schrijf je in" disabled={!this.state.canSubmit} />)}
        />
      </Formsy>
    );
  }
}
export default withTranslation()(RegisterForm);
