import React, { useEffect } from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import { object } from '../../utils';
import { useLazyQuery } from '@apollo/react-hooks';
import { withTranslation } from '../../hoc/withTranslation';
import { Tooltip } from '..';

const Query = gqlLoader('./resolveHint.graphql');

const ResolveHint = ({ itemId, lng }) => {
  const [query, { data }] = useLazyQuery(Query, {
    variables: { lng: lng, id: itemId }
  });
  useEffect(() => {
    if (itemId) {
      query();
    }
  }, [itemId, lng]);
  const hint = object.getNested(data, 'item', 'hint', 'value');
  return hint ? <Tooltip position='right' content={hint} /> : null;
};

export default withTranslation()(ResolveHint);
