/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import { withFlyout } from './../../hoc';
import Link from '../../building-blocks/SimpleLink';

/**
 * Definition
 * =============================================================================
 */
const Flyout = ({
  classes = '',
  title,
  link,
  children,
  setFlyoutState,
  handleToggleFlyout,
  handleOpenFlyout,
  handleCloseFlyout,
  isMobile,
}) => {

  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  return (
    <div
      className={`o-nav__flyout ${classes} ${setFlyoutState()}`}
      onMouseEnter={() => !isMobile && handleOpenFlyout()}
      onMouseLeave={() => !isMobile && handleCloseFlyout()}
    >
      <Link
        to={link}
        className='o-nav__flyout-trigger'
        onClick={handleToggleFlyout}
      >
        {title}
      </Link>

      {children}
    </div>
  );
};

export default withFlyout()(Flyout);
