/**
 * Imports
 * =============================================================================
 */
import React from 'react';
import Formfield from './../Formfield';
import { withFormsy } from 'formsy-react';
import { withInputFlyout } from '../../hoc';
/**
 * Definition
 * =============================================================================
 */
class Input extends React.PureComponent {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.hadError = false;
    this.state = {
      value: this.props.value || ''
    };
  }
 
  /**
   * Life cycle
   * ---------------------------------------------------------------------------
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      this.setState({ value: this.props.value });
    }
  }

  /**
   * Helpers
   * ---------------------------------------------------------------------------
   */
  setClasses() {
    const {
      iconBefore,
      iconAfter,
      button
    } = this.props;

    const beforeClass = iconBefore ? 'a-formfield__wrapper--with-icon-before' : '';
    const afterClass = iconAfter ? 'a-formfield__wrapper--with-icon-after' : '';
    const buttonClass = button ? 'a-formfield__wrapper--with-button' : '';

    return `${beforeClass} ${afterClass} ${buttonClass}`;
  }

  /**
   * Handlers
   * ---------------------------------------------------------------------------
   */
  handleFocus = () => {
    if (this.handleInputFlyoutFocus) this.handleInputFlyoutFocus();
    if (this.onFocus) this.onFocus();
  };

  handleClick = () => {
    this.handleToggleFlyout();
  };

  handleBlur = () => {
    if (this.handleInputFlyoutBlur) this.handleInputFlyoutBlur();
    if (this.onBlur) this.onBlur();
  };

  handleChange = (e) => {
   
   const { onChange, name } = this.props;
    
    this.setState({
      value: e.target.value,
    }, () => {
      if (onChange) onChange(name, this.state.value)
    });
    this.props.setValue(e.currentTarget.value);
  
  }


  handleIconAfterClick = () => {
    const { onIconAfterClick, name } = this.props;
    const { value } = this.state;

    if (onIconAfterClick) onIconAfterClick(name, value);
  }

  handleChildChange = () =>{
  //  this.handleToggleFlyout();
  }

  /**
   * Renders
   * ---------------------------------------------------------------------------
   */
  renderButton() {
    const { button } = this.props;
 
    return (
      <div className='a-formfield__button'>
        {button}
      </div>
    );
  }



  /**
   * Main render
   * ---------------------------------------------------------------------------
   */
  render() {
    const {
      classes = '',
      iconAfterClasses = '',
      label,
      iconBefore,
      type,
      name,
      id,
      readonly = false,
      placeholder,
      iconAfter,
      button,
      onFocus,
      onBlur,
      onKeyDown,
      tooltip,
      tooltipIsOpen,
      autocomplete,
      children,
      setFlyoutState ='',
      handleInputFlyoutFocus,
      handleInputFlyoutBlur,
      handleInputFlyoutMouseEnter,
      handleInputFlyoutMouseLeave,
      handleToggleFlyout,
      message,
      contentStyle = {},
      reference,
      handleOverlayTriggerClick = null,
      showRequired,
      showError,
      getErrorMessage,
      noFlyout
    } = this.props;
   
    const { value } = this.state;
    
    const validClass = showRequired && this.hadError ? 'is-error' : showError ? 'is-error' : this.hadError ? "is-succes":null;
    if(showError)this.hadError=true;
    
    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const errorMessage = getErrorMessage;
    this.handleInputFlyoutFocus = handleInputFlyoutFocus;
    this.handleInputFlyoutBlur = handleInputFlyoutBlur;
    this.handleToggleFlyout = handleToggleFlyout;
    this.onKeyDown = onKeyDown;
    var node = null;
    if (children != null) {
      node = React.cloneElement(React.Children.only(children), {
        onClicked: this.handleChildChange
      });
    }
    
    const formfieldClass = noFlyout?'m-formfield':'m-formfield-flyout';
    return (
      <div
      ref={reference}
      className={`${formfieldClass} ${classes} ${setFlyoutState()}`}
    >
      <Formfield
        classes={`m-formfield-flyout__trigger ${classes} ${validClass}`}
        label={label}
        name={id}
        tooltip={tooltip}
        tooltipIsOpen={tooltipIsOpen}
        handleOverlayTriggerClick={handleOverlayTriggerClick}
      >
        <div className={`a-formfield__wrapper ${this.setClasses()}`}>
          {iconBefore && (
            <span className={`a-formfield__icon-before ${iconBefore}`}></span>
          )}

          <input
            className='a-formfield__input'
            type={type || 'text'}
            name={name}
            id={id}
            placeholder={placeholder || ''}
            value={value}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            readOnly={readonly}
            autoComplete={autocomplete}
            onClick={this.handleClick}
            onKeyDown={this.onKeyDown}
          />

          {iconAfter && (
            <span className={`a-formfield__icon-after ${iconAfter} ${iconAfterClasses}`} onClick={this.handleIconAfterClick} />
          )}

          {button && this.renderButton()}
        </div>
      </Formfield>
      {(message || errorMessage) && !node && (
        <div
          className='m-formfield-flyout__content'
          style={contentStyle}
          onMouseEnter={handleInputFlyoutMouseEnter}
          onMouseLeave={handleInputFlyoutMouseLeave}
        >
            {errorMessage && (
            <p className='m-formfield-flyout__message'>{errorMessage}</p>
          )}
          {!errorMessage && message && (
            <p className='m-formfield-flyout__message'>{message}</p>
          )}
        </div>
      )}
      {(node) && (
        <div
          className='m-formfield-flyout__content'
          style={contentStyle}
          onMouseEnter={handleInputFlyoutMouseEnter}
          onMouseLeave={handleInputFlyoutMouseLeave}
        >
             <div className='m-formfield-flyout__content-container'>
            {
              node
           }
          </div>
        </div>
      )}
      </div>
    );
  }
}

export default withFormsy(withInputFlyout()(Input));
