import React, { useEffect, useState } from 'react';
import { loader as gqlLoader } from 'graphql.macro';
import { transformSearchResults } from './transform';
import { mergeSearchResults } from './merge';
import { object } from '../../utils';
import SearchResult from '../../components/SearchResult';
import HighlightedSession from '../../components/HighlightedSession';
import { useQuery } from '@apollo/react-hooks';
import { withTranslation } from '../../hoc/withTranslation';
import { Button, Text, Input, Loader } from '../../building-blocks';
import { isEditorActive } from '@sitecore-jss/sitecore-jss';
import ChoiceList from '../../components/ChoiceList';
import { Sorting, DefaultSorting } from '../../lib/Constants.ts';
import Formsy from 'formsy-react';
import { Events } from '../../managers';
import { impressionSearchResults, calculatePersona } from '../../lib/Gtm';
import { withBasketData } from '../../hoc';
import { useAuth0 } from '../../react-auth0-wrapper';
import { SearchPageSize } from '../../lib/Constants';
import CourseCarouselStructuredData from '../../components/CourseCarouselStructuredData';

const ITEMS_PER_LOAD = SearchPageSize;

const Query = gqlLoader('./query.graphql');

const SearchResultsList = props => {
  const [sorting, setSorting] = useState(props.variables.sorting || DefaultSorting);
  const [results, setResults] = useState(null);
  const [loadedCount, setLoadedCount] = useState(null);

  const auth0 = useAuth0();
  const { basketData } = props;

  const { searchAs, persona } = props.variables;
  const { hideSortingOption, initialPageSize } = props;

  const query = useQuery(Query, {
    variables: { ...props.variables, first: initialPageSize || ITEMS_PER_LOAD, sorting, site_language: props.lng },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      Events.emitter.emit('stop-loading');

      if (data) {
        data = mergeSearchResults(data);
        const courses = data.search.courses;
        setResults(data);

        setTimeout(() => {
          if (props.onCompleted) {
            props.onCompleted(
              data,
              courses.totalCount,
              courses.hits.length
            );
          }
        }, 0);

        let itemsLoaded = loadedCount || courses.hits.length;
        const currentResults = courses.hits.slice(-itemsLoaded);
        itemsLoaded = currentResults.length;
        const pageOffset = Math.floor((courses.hits.length - itemsLoaded) / ITEMS_PER_LOAD) * ITEMS_PER_LOAD;
        impressionSearchResults(courses.hits.slice(-itemsLoaded), pageOffset, calculatePersona(auth0, basketData, searchAs));
      }
    }
  });

  const { fetchMore, error, loading } = query;

  if (loading) {
    Events.emitter.emit('start-loading');
  }

  const loadMore = () =>
    fetchMore({
      variables: {
        first: ITEMS_PER_LOAD,
        offset: results.search.courses.hits.length
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        setLoadedCount(fetchMoreResult.search.courses[0].hits.length + fetchMoreResult.search.courses[1].hits.length);
        const obj = JSON.parse(JSON.stringify(prev));
        obj.search.courses[0].hits = [
          ...obj.search.courses[0].hits,
          ...fetchMoreResult.search.courses[0].hits
        ];
        obj.search.courses[1].hits = [
          ...obj.search.courses[1].hits,
          ...fetchMoreResult.search.courses[1].hits
        ];
        return obj;
      }
    });

  if (error) console.log('Error while searching: ' + error);

  return (
    <RenderResults
      {...props}
      data={results}
      t={props.t}
      lng={props.lng}
      loadMore={loadMore}
      sorting={sorting}
      setSorting={setSorting}
      persona={persona}
      hideSortingOption={hideSortingOption}
      searchAs={searchAs}
    />
  );
};

const RenderResults = ({ data, t, lng, loadMore, sorting, setSorting, persona, hideSortingOption, searchAs, sitecoreContext }) => {
  if (!data) {
    return null;
  }

  const transformed = transformSearchResults(t, lng)(data);
  const { searchResults } = transformed;
  const itemsLoaded = object.getNested(searchResults, 'items', 'length');
  const itemsPerLoad = ITEMS_PER_LOAD;
  const itemsTotal = object.getNested(searchResults, 'total');
  const itemsLeftToLoad = itemsTotal - itemsLoaded;
  const structuredDataCount = object.getNested(data, 'search', 'courses', 'structuredDataCount') || 0;
  const structuredDataCourses = searchResults.items
    ? searchResults.items.slice(0, structuredDataCount)
    : [];

  const sortingValues = [
    {
      text: t('searchResultsPage.sort.mostRelevant'),
      id: Sorting.MostRelevant,
      value: Sorting.MostRelevant
    },
    {
      text: t('searchResultsPage.sort.onlineFirst'),
      id: Sorting.OnlineFirst,
      value: Sorting.OnlineFirst
    },
    {
      text: t('searchResultsPage.sort.firstSession'),
      id: Sorting.FirstSession,
      value: Sorting.FirstSession
    },
    {
      text: t('searchResultsPage.sort.newCourses'),
      id: Sorting.NewCourses,
      value: Sorting.NewCourses
    }
  ];

  if (persona === 'EM') {
    sortingValues.push({
      text: t('searchResultsPage.sort.additionalDay'),
      id: Sorting.AdditionalDay,
      value: Sorting.AdditionalDay
    });
  }

  const defaultSortValue = sortingValues.find(e => {
    return e.id === DefaultSorting;
  });

  const requestedSortValue = sortingValues.find(e => {
    return e.id === sorting;
  });

  const sortValue = (requestedSortValue || defaultSortValue).text;

  const noResultsMessage = {
    'EM': t('searchResultsPage.noResultsEmployee'),
    'HR': t('searchResultsPage.noResultsEmployer'),
    'JS': t('searchResultsPage.noResultsJobseeker')
  }[searchAs] || t('searchResultsPage.noResults');

  const noResults = (
    <div className='a-highlight '>
      <div className='a-highlight__content'>
        <div className='a-title '>
          <h5 className='a-title__text'>{noResultsMessage}</h5>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className='o-search'>
        {isEditorActive() &&
          searchResults.items &&
          searchResults.items.length === 0
          ? experienceEditorNoResults
          : null}
        {searchResults.items && searchResults.items.length === 0
          ? noResults
          : null}

        {searchResults.items && searchResults.items.length !== 0 && (
          <Formsy>
            <div className='o-search__sort'>
              {hideSortingOption ? null : (
                <Input
                  classes='a-formfield--choice a-formfield--horizontal'
                  label={t('searchResultsPage.sort.label')}
                  labelClasses='u-desktop-show'
                  placeholder={defaultSortValue.text}
                  iconAfter='icon-arrow-down'
                  name='search-results-sort-text'
                  id='search-results-sort-text'
                  autoComplete={false}
                  readonly={true}
                  value={sortValue}
                >
                  <ChoiceList
                    name='search-results-sort'
                    id='search-results-sort'
                    classes='o-search__sort-sortlist'
                    onChange={(name, value) => setSorting(value)}
                    active={sorting}
                    items={sortingValues}
                  />
                </Input>
              )}
              <Text
                classes='a-text--light a-text--smaller o-search__sort-resultnrs'
                text={t('searchResultsPage.results.amount', {
                  amount: itemsLoaded || 0,
                  total: itemsTotal || 0
                })}
              />
            </div>
          </Formsy>
        )}
        {renderSearchResultItems(searchResults.items, t, sorting)}
      </div>

      {itemsLeftToLoad <= 0 ? null : (
        <div className='l-aside__footer'>
          {typeof itemsTotal === 'number' &&
            typeof itemsPerLoad === 'number' &&
            itemsTotal > itemsPerLoad && (
              <Button
                type='clickButton'
                classes='a-button--outline a-button--fill-space m-search-results__load-more'
                fields={{
                  ButtonText: t('searchResultsPage.loadMore', {
                    amount:
                      itemsLeftToLoad <= itemsPerLoad
                        ? itemsLeftToLoad
                        : itemsPerLoad
                  })
                }}
                onClick={() => loadMore()}
              />
            )}
        </div>
      )}
      <CourseCarouselStructuredData sitecoreContext={sitecoreContext} courses={structuredDataCourses} />
    </React.Fragment>
  );
};


const experienceEditorNoResults = (
  <div className='s-jss-experience-warning'>
    <p>
      PredefinedSearchResults component returned no results.
      <br />
      If you just added it to a page: save the page to retrieve data.
      <br />
      Otherwise provide less strict search criteria.
      <sub>This message only visible in Experience Editor.</sub>
    </p>
  </div>
);

const renderSearchResultItems = (items, t, sorting) => {
  if ((items || []).length === 0) {
    return null;
  }

  const rendering = (i, index) => {
    return sorting === Sorting.FirstSession && i.highlightFirstSession ? (
      <HighlightedSession
        key={`search-result-item-${index}`}
        text={t('searchResultsPage.firstSession')}
      >
        <SearchResult {...i} idx={index} />
      </HighlightedSession>
    ) : (
      <SearchResult key={`search-result-item-${index}`} {...i} idx={index} />
    );
  };

  const strict = items.filter(x => x.strict).map(rendering);
  const loose = items.filter(x => !x.strict).map(rendering);

  return (
    <React.Fragment>
      {strict}
      {loose.length > 0
        ? looseSearchWarning(t('searchResultsPage.looseSearchWarning'))
        : null}
      {loose}
    </React.Fragment>
  );
};

const looseSearchWarning = text => (
  <div class='l-box l-box--flyout'>
    <div class='a-highlight '>
      <div class='a-highlight__content'>
        <div class='a-supertitle '>
          <span class='a-supertitle__before'></span>
          <p class='a-supertitle__text'>{text}</p>
          <span class='a-supertitle__after'></span>
        </div>
      </div>
    </div>
  </div>
);

export default withBasketData()(withTranslation()(SearchResultsList));
